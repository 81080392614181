import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import i18n from 'i18n';
import REQ, { ReqType } from 'utils/REQ';
import {
  track,
  BASKET_CLICK_SEND_ORDER,
  BASKET_PAGE_VIEW
} from 'utils/mixpanel';
import { postCheckout, getHasFirstOrder, fetchDeliveryFees } from 'api';
import { AuthState, getUserFirstName } from '_common/reducers/auth';
import { clearCheckout } from '_common/reducers/checkout';
import { completeOrder } from '_consumer/reducers/basket';
import { fetchOrders } from '_common/reducers/orders';
import {
  sumPriceForProductsInBasket,
  getProductsInBasket,
  getOrderPayloads,
  countProducersInBasket,
  getBasketByDeliveryDate,
  ProductWithBasket
} from '_consumer/reducers/productsForSale';
import PageHeader from '@components/page-header/PageHeader';
import CheckoutFooter from '_consumer/components/checkout-footers/CheckoutFooter';
import BasketOrderLinesByDeliveryDate from '_common/components/products-in-basket/BasketOrderLinesByDeliveryDate';
import EmptyBasket from '_common/components/empty-basket/EmptyBasket';
import OrderConfirmation from '_common/components/order-confirmation/OrderConfirmation';
import { basketContainsCustomOffer } from 'utils/pricing';
import { isGuestConsumer } from 'utils/role';
import { DeliveryFeesResponse } from 'types/Basket';
import { deleteCookie, setCookie } from 'services/CookieService';
import {
  PREFILL_PROCESS_STATE_COOKIE_KEY,
  PREFILL_PROCESS_COOKIE_MAX_AGE,
  PREFILL_AVAILABILITY_LIST_KEY,
  ORDER_WEEKLY_PROCESS_STATE_COOKIE_KEY,
  OrderWeeklyProcessStates
} from 'types/FeatureOrderWeekly';
import { removeItemFromLocalStorage } from 'utils/clientcache';
import {
  FeatureFlags,
  hasUserFeatureFlag
} from '../../../services/FeatureFlagService';
import { SubmitActionBlocker } from './CheckoutPage.style';
import { Page } from '@components/page';
import {
  useAppDispatch,
  useAppSelector
} from '../../../_common/hooks/reduxHooks';
import { DeliveryWindow } from '../../../types/Consumer';
import { CommonNavbar } from '@components/navbar';
import { useMediaQuery } from '@carrot/utils/media-query';
import { BottomActions } from '@components/bottom-actions';

type Props = {
  consumer: AuthState;
  deliveryInfo: string | undefined;
  deliveryAddress: string | undefined;
  deliveryWindow: DeliveryWindow | undefined;
  productsInBasket: ProductWithBasket[];
  totalPrice: number;
  numProducts: number;
  numProducers: number;
  messages: Record<string, string> | undefined;
  deliveryDateCount: number;
  distributionAreaId: string | undefined;
};

const CheckoutPage = ({
  consumer,
  deliveryInfo,
  deliveryAddress,
  deliveryWindow,
  productsInBasket = [],
  totalPrice,
  numProducts = 0,
  numProducers = 0,
  messages,
  deliveryDateCount = 0,
  distributionAreaId
}: Props) => {
  const dispatch = useAppDispatch();
  const [submitReq, setSubmitReq] = useState<ReqType>(REQ.INIT);
  const [hasFirstOrder, setHasFirstOrder] = useState<boolean | undefined>(
    undefined
  );
  const [basketServiceReq, setBasketServiceReq] = useState<ReqType>(REQ.INIT);
  const [deliveryFees, setDeliveryFees] = useState<
    DeliveryFeesResponse | undefined
  >(undefined);
  const [daysOverThreshold, setDaysOverThreshold] = useState<string[]>([]);
  const { desktopUp } = useMediaQuery();
  const navigate = useNavigate();

  useEffect(() => {
    const consumerId = consumer._id;
    getHasFirstOrder({ consumerId }).then(res => {
      setHasFirstOrder(res);
    });

    setBasketServiceReq(REQ.PENDING);
    track(BASKET_PAGE_VIEW, {
      numProducts,
      numProducers,
      numDeliveryDays: deliveryDateCount,
      orderTotal: totalPrice
    });
  }, []);

  const exemptByThreshold = useCallback((date: string, over: boolean) => {
    if (over) setDaysOverThreshold([...daysOverThreshold, date]);
    else
      setDaysOverThreshold(
        daysOverThreshold.filter(d => {
          return d !== date;
        })
      );
  }, []);

  const handlePlaceOrderSuccess = (containsCustomOffer: any) => {
    setSubmitReq(REQ.SUCCESS);
    track(BASKET_CLICK_SEND_ORDER, {
      numProducts,
      numProducers,
      numDeliveryDays: deliveryDateCount,
      orderTotal: totalPrice,
      containsCustomOffer
    });
    dispatch(completeOrder());
    dispatch(clearCheckout());
    dispatch(
      fetchOrders({ consumerId: consumer._id }, { clearStore: false }) as any
    );
  };

  useEffect(() => {
    if (productsInBasket.length > 0) {
      const strippedBasket = productsInBasket.map(prod => {
        return {
          deliveryDate: prod.deliveryDate,
          _id: prod._id,
          producer: {
            _id: prod.producer._id
          }
        };
      });

      fetchDeliveryFees({
        consumerId: consumer._id,
        distributionAreaId,
        basket: strippedBasket
      })
        .then(res => {
          setDeliveryFees(res);
          setBasketServiceReq(REQ.SUCCESS);
        })
        .catch(() => {
          setBasketServiceReq(REQ.ERROR);
        });
    }
  }, [numProducts]);

  const placeOrder = () => {
    setSubmitReq(REQ.PENDING);

    const containsCustomOffer = basketContainsCustomOffer(productsInBasket);

    const payloadCommonFields = {
      consumerId: consumer._id,
      createdAs: consumer._id,
      createdBy: consumer.uid
    };

    const payloads = getOrderPayloads(
      productsInBasket,
      payloadCommonFields,
      messages
    );

    const groupedByDeliveryDay = _.chain(payloads)
      .groupBy('deliveryDate')
      .map(producerBasket => {
        return producerBasket;
      })
      .value();
    Promise.all(
      groupedByDeliveryDay.map(async group => {
        await postCheckout({
          orders: group,
          deliveryDate: group[0].deliveryDate,
          consumerId: consumer._id
        });
      })
    )
      .then(() => {
        handlePlaceOrderSuccess(containsCustomOffer);
        if (
          hasUserFeatureFlag(FeatureFlags.ORDER_YOUR_WEEKLY, consumer?.features)
        ) {
          setCookie(
            `${ORDER_WEEKLY_PROCESS_STATE_COOKIE_KEY}_${consumer._id}`,
            OrderWeeklyProcessStates.COMPLETED,
            {
              maxAge: PREFILL_PROCESS_COOKIE_MAX_AGE
            }
          );
          deleteCookie(`${PREFILL_PROCESS_STATE_COOKIE_KEY}_${consumer._id}`);
          removeItemFromLocalStorage(
            `${PREFILL_AVAILABILITY_LIST_KEY}_${consumer._id}`
          );
        }
      })
      .catch(() => {
        setSubmitReq(REQ.ERROR);
      });
  };

  if (submitReq === REQ.SUCCESS) {
    if (hasFirstOrder === false) {
      navigate('/delivery-information', {
        state: { redirect: '/orders' }
      });
    }
    return (
      <OrderConfirmation
        headerText={i18n.t('consumer:ThankUser', {
          userFirstName: getUserFirstName(consumer)
        })}
        subTitle={i18n.t('consumer:OrderAdded')}
        deliveryAddress={deliveryAddress}
        deliveryWindow={deliveryWindow}
        deliveryInfo={deliveryInfo}
        deliveryDateCount={deliveryDateCount}
      />
    );
  }

  const basketByDeliveryDate = getBasketByDeliveryDate(productsInBasket);

  const subTitle = i18n.t('consumer:numProductsAndProducers', {
    count: numProducers,
    numProducts
  });

  if (productsInBasket.length < 1 || isGuestConsumer(consumer._id))
    return (
      <Page header={<CommonNavbar showLogo />}>
        <EmptyBasket />
      </Page>
    );

  return (
    <Page
      header={<CommonNavbar showLogo={desktopUp} />}
      bottom={
        basketServiceReq === REQ.SUCCESS && (
          <BottomActions border>
            <CheckoutFooter
              isApprovedToOrder={Boolean(consumer.isApprovedToOrder)}
              submitReq={submitReq}
              placeOrder={placeOrder}
              basketByDeliveryDate={basketByDeliveryDate}
              deliveryFees={deliveryFees}
              newExemptions={daysOverThreshold}
            />
          </BottomActions>
        )
      }
    >
      {submitReq === REQ.PENDING && <SubmitActionBlocker />}
      <PageHeader
        headerText={i18n.t('consumer:Checkout')}
        subTitle={subTitle}
      />
      {basketServiceReq === REQ.SUCCESS && (
        <BasketOrderLinesByDeliveryDate
          deliveryFees={deliveryFees}
          basketByDeliveryDate={basketByDeliveryDate}
          exemptionCallback={exemptByThreshold}
        />
      )}
    </Page>
  );
};

const CheckoutPageFetcher = () => {
  const { req, ...props } = useAppSelector(
    ({
      auth,
      basket,
      productsForSale: { req, items },
      checkout: { messages }
    }) => {
      const {
        distributionAreaId,
        deliveryInfo,
        deliveryAddress,
        deliveryWindow
      } = auth;
      const productsInBasket = getProductsInBasket(items, basket.items);

      const deliveryDates = Array.from(
        new Set(
          productsInBasket.map(({ deliveryDate }) => {
            return deliveryDate;
          })
        )
      );

      const deliveryDateCount = deliveryDates.length;

      return {
        req,
        consumer: auth,
        deliveryInfo,
        deliveryAddress,
        deliveryWindow,
        productsInBasket,
        totalPrice: sumPriceForProductsInBasket(productsInBasket),
        numProducts: productsInBasket.length,
        numProducers: countProducersInBasket(items, basket.items),
        messages,
        deliveryDateCount,
        distributionAreaId
      };
    }
  );

  if (req !== REQ.SUCCESS) {
    return <Page.Status req={req} />;
  }

  return <CheckoutPage {...props} />;
};

export default CheckoutPageFetcher;
