import { Navigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import REQ from 'utils/REQ';
import { createUser, createConsumer } from 'api';
import {
  track,
  CONSUMER_SIGNUP_PAGE_VIEW,
  CONSUMER_SIGNUP_SUBMITTED,
  CONSUMER_SIGNUP_FAILED
} from 'utils/mixpanel';
import SignupConsumerFields from '_common/components/signup/SignupConsumerFields';
import SignupRequestButton from '_common/components/signup/SignupRequestButton';
import { useSignupConsumer } from '_common/hooks/useCreateRole';
import usePageView from '_common/hooks/usePageView';
import { Illustrations } from '../../../utils/Illustrations';
import SignupIntroduction from './SignupIntroduction';
import { Page } from '@components/page';

const SignupConsumers = () => {
  const [
    req,
    setReq,
    fields,
    onFieldChange,
    validation,
    validate,
    organization,
    onOrganizationChanged
  ] = useSignupConsumer();
  const { t } = useTranslation();

  usePageView(CONSUMER_SIGNUP_PAGE_VIEW);

  const { search } = useLocation();
  const { redirectLogin = '' } = queryString.parse(search);

  const onSubmit = async () => {
    if (!validate()) return;

    const {
      name,
      contactPerson,
      deliveryAddress,
      phone,
      orgNumber,
      email,
      password,
      howDidYouHearAboutUs
    } = fields;

    setReq(REQ.PENDING);
    track(CONSUMER_SIGNUP_SUBMITTED);

    try {
      const { userId } = await createUser({
        email: email.trim(),
        password,
        source: name,
        redirectLogin
      });

      await createConsumer({
        name,
        contactPerson,
        deliveryAddress,
        phone,
        orgNumber,
        email: email.trim(),
        userId,
        howDidYouHearAboutUs
      });

      setReq(REQ.SUCCESS);
    } catch (e) {
      setReq(REQ.ERROR);
      track(CONSUMER_SIGNUP_FAILED);
    }
  };

  const {
    name,
    contactPerson,
    deliveryAddress,
    phone,
    orgNumber,
    email,
    password,
    passwordRepeat,
    howDidYouHearAboutUs,
    acceptTerms
  } = fields;

  const { field: validationErrorField, message: validationErrorMessage } =
    validation;

  if (req === REQ.SUCCESS && redirectLogin) {
    return (
      <Navigate
        to={`/login?calloutType=verifyEmail&redirectLogin=${encodeURIComponent(
          redirectLogin.toString()
        )}`}
      />
    );
  }

  return (
    <Page>
      <SignupIntroduction
        illustration={Illustrations.PROUD_PRODUCER_BREAD}
        header={t('signup:consumerPageHeader')}
        introductionText={t('signup:consumerPageParagraph')}
        forOtherUser={t('signup:SignUpForProducers')}
        otherUserQuery="/sell"
      />
      <form
        onSubmit={e => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <SignupConsumerFields
          name={name}
          contactPerson={contactPerson}
          deliveryAddress={deliveryAddress}
          phone={phone}
          orgNumber={orgNumber}
          email={email}
          password={password}
          passwordRepeat={passwordRepeat}
          howDidYouHearAboutUs={howDidYouHearAboutUs}
          acceptTerms={acceptTerms}
          validationErrorField={validationErrorField}
          onChange={onFieldChange}
          organization={organization}
          onOrganizationChanged={onOrganizationChanged}
        />
        <SignupRequestButton
          req={req}
          onSubmit={onSubmit}
          validationErrorMessage={validationErrorMessage}
        />
      </form>
    </Page>
  );
};

export default SignupConsumers;
