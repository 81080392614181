/*
Responsive design guidelines:
· We design for mobile first by default
· Add media query with descriptive naming here if you need a new one
· All media queries should lives here for reusability and single source of truth
*/

export const DeviceBreakpoint = {
  tablet: 460,
  desktop: 1260
};

export const MediaQuery = {
  mobileOnly: `@media screen and (max-width: ${DeviceBreakpoint.tablet}px)`,
  tabletUp: `@media screen and (min-width: ${DeviceBreakpoint.tablet}px)`,
  desktopUp: `@media screen and (min-width: ${DeviceBreakpoint.desktop}px)`,
  print: '@media print'
};
