import { CountryCode, isCountry } from '@dagensmat/core';
import { useAppSelector } from '_common/hooks/reduxHooks';

const DagensPhoneLink = () => {
  const { organization } = useAppSelector(({ auth }) => {
    return {
      organization: auth.organization
    };
  });
  let tel = '+4781503266';
  let formattedTel = '+47 815 03 266';

  const isDkOrganization =
    organization && isCountry(organization, CountryCode.DK);

  if (isDkOrganization) {
    tel = '+4589871343';
    formattedTel = '+45 89 87 13 43';
  }

  return <a href={`tel:${tel}`}>{formattedTel}</a>;
};

export default DagensPhoneLink;
