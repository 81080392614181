import { useCallback } from 'react';

type Args = {
  selectedDeliveryDates: {
    deliveryDate: string;
    withLogistics: boolean;
  }[];
  onChange: (
    date: {
      deliveryDate: string;
      withLogistics: boolean;
    }[]
  ) => void;
};

export const useOnDeliveryDateWithLogisticsChange = ({
  selectedDeliveryDates,
  onChange
}: Args) => {
  const onChangeLocal = useCallback(
    (date: string, val: boolean) => {
      const updatedSelectedDeliveryDays = selectedDeliveryDates.map(
        ({ deliveryDate, withLogistics: deliveryDateWithLogistics }) => {
          if (deliveryDate === date) {
            return {
              deliveryDate,
              withLogistics: val
            };
          }
          return {
            deliveryDate,
            withLogistics: deliveryDateWithLogistics
          };
        }
      );
      onChange(updatedSelectedDeliveryDays);
    },
    [selectedDeliveryDates, onChange]
  );

  return onChangeLocal;
};
