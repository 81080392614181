import { forSaleStatuses } from '@dagensmat/core';
import { useTranslation } from 'react-i18next';
import NewRadioButtons from '_common/components/radio-buttons/NewRadioButtons';
import type { ForSaleStatus } from 'types/Product';

type EditProductSaleStatusProps = {
  forSaleStatus: ForSaleStatus | undefined;
  onChange: (value: ForSaleStatus) => void;
};

const EditProductSaleStatus = ({
  forSaleStatus,
  onChange
}: EditProductSaleStatusProps) => {
  const { t } = useTranslation();
  return (
    <NewRadioButtons
      labelText={t('producer:productSalesStatusHeader')}
      options={[
        {
          key: forSaleStatuses.FOR_SALE,
          value: t('common:FOR_SALE'),
          explanationText: t('producer:productForSaleExplanation')
        },
        {
          key: forSaleStatuses.ON_REQUEST,
          value: t('common:ON_REQUEST'),
          explanationText: t('producer:productOnRequestExplanation')
        },
        {
          key: forSaleStatuses.NOT_FOR_SALE,
          value: t('common:NOT_FOR_SALE'),
          explanationText: t('producer:productNotForsaleExplanation')
        }
      ]}
      currentValue={forSaleStatus}
      onChange={value => {
        return onChange(value as ForSaleStatus);
      }}
      radioGroupName="editForSaleStatusOptions"
    />
  );
};

export default EditProductSaleStatus;
