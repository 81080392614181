import REQ, { ReqType } from 'utils/REQ';
import { DeliveryAddressResultElement } from './DeliveryAddressResultElement';

type Props = {
  req: ReqType;
  results: string[];
};

/**
 * @package
 */
export const DeliveryAddressResult = ({ req, results }: Props) => {
  if (req === REQ.PENDING) {
    return <DeliveryAddressResultElement value="Loading..." disabled />;
  }
  if (results.length === 0) {
    return (
      <DeliveryAddressResultElement value="No address found..." disabled />
    );
  }

  return (
    <>
      {results.map(address => {
        return <DeliveryAddressResultElement key={address} value={address} />;
      })}
    </>
  );
};
