import { Nav, NavLinkProps } from '@carrot/style-components/navbar';
import { useMediaQuery } from '../../_common/hooks/useMediaQueries';

type Props = {
  links: readonly NavLinkProps[];
};

/**
 * @package
 */
export const NavTabs = ({ links }: Props) => {
  const { desktopUp } = useMediaQuery();
  if (desktopUp) {
    return null;
  }

  return <Nav.Tabs links={links} />;
};
