import { NavBadge } from './badge';
import { NavBar } from './bar';
import { NavContext } from './context';
import { NavIconButton } from './icon-button';
import { NavLinks } from './links';
import { NavLogoHome, NavLogoLandingPage } from './logo';
import {
  NavMenu,
  NavMenuGroup,
  NavMenuDivider,
  NavMenuPrimaryItem,
  NavMenuSecondaryItem
} from './menu';
import { NavTabs } from './tabs';

export type { NavLinkProps } from './link';

export const Nav = {
  Badge: NavBadge,
  Bar: NavBar,
  Context: NavContext,
  IconButton: NavIconButton,
  Links: NavLinks,
  LogoHome: NavLogoHome,
  LogoLandingPage: NavLogoLandingPage,
  Menu: NavMenu,
  MenuGroup: NavMenuGroup,
  MenuDivider: NavMenuDivider,
  MenuPrimaryItem: NavMenuPrimaryItem,
  MenuSecondaryItem: NavMenuSecondaryItem,
  Tabs: NavTabs
};
