import { CommandPaletteResult } from '@components/command-palette/result';
import { ComboboxOption } from '@headlessui/react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Fragment } from 'react/jsx-runtime';
import { ExtendedRouteObject } from 'types/routes';

type Props = {
  pageResults: { route: ExtendedRouteObject; matchCount?: number }[];
};

/**
 * @package
 */
export const CommandPaletteResultsPages = ({ pageResults }: Props) => {
  const { t } = useTranslation();
  if (pageResults.length === 0) {
    return null;
  }

  return (
    <li key="pages">
      <h2 className={clsx('text-microText', 'px-xs', 'mb-xxs')}>
        {t('common:Pages')}
      </h2>
      <ul className={clsx('flex', 'flex-col', 'justify-start')}>
        {pageResults.map(item => {
          const to = item.route.index ? '/' : item.route.path;
          return (
            <ComboboxOption as={Fragment} key={item.route.title} value={to}>
              {({ focus }) => (
                <CommandPaletteResult
                  icon={item.route.icon}
                  text={item.route.title}
                  focused={focus}
                  to={to}
                />
              )}
            </ComboboxOption>
          );
        })}
      </ul>
    </li>
  );
};
