import { FC } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { FormFieldInputProps } from '../form-field/types';
import { selectMultiStyles } from './styles';
import { SelectMultiRemove } from './remove';
import { SelectMultiOption } from './option';
import { SelectMultiClearIndicator } from './clear-indicator';
import { SelectMultiDropdownIndicator } from './dropdown-indicator';
import { Option } from './types';

type Props = FormFieldInputProps & {
  options: readonly Option[];
  value: readonly Option[];
  onChange: (newValue: readonly Option[]) => void;
};

export const SelectMulti: FC<Props> = ({
  options,
  value,
  onChange,
  invalid,
  showError
}) => {
  const { t } = useTranslation();
  return (
    <Select<Option, true>
      placeholder={t('common:MultiSelectPlaceholder')}
      isMulti
      value={value}
      name="multi-select"
      onChange={onChange}
      options={options}
      // eslint-disable-next-line tailwindcss/no-custom-classname
      className="basic-multi-select"
      classNamePrefix="select"
      styles={selectMultiStyles({ showError: invalid && showError })}
      components={{
        MultiValueRemove: SelectMultiRemove,
        Option: SelectMultiOption,
        ClearIndicator: SelectMultiClearIndicator,
        DropdownIndicator: SelectMultiDropdownIndicator
      }}
    />
  );
};

export type SelectMultiProps = Props;
