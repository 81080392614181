import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Theme from '@dagensmat/carrot/Theme';
import { Typography, Callout } from '@dagensmat/carrot/Components';
import { Container } from '@dagensmat/carrot/Layout';
import { MarginProps } from 'utils/style';
import useToggle from '_common/hooks/useToggle';
import ToggleHelpCalloutButton from '../callout/ToggleHelpCalloutButton';

export const Optional = styled.span`
  font-size: ${Theme.FontSizes.paragraphSmall}px;
  line-height: ${Theme.Spacings.m}px;
  font-weight: ${Theme.FontWeights.medium};
  letter-spacing: 0.1px;
  margin: 0;
  color: ${Theme.Colours.secondary};
`;

type FormLabelProps = {
  labelText: string;
  helpText?: string;
  id?: string;
  maxLength?: number;
  optional?: boolean;
  explanationText?: React.ReactNode;
  value?: string | number;
} & MarginProps;

const FormLabel = ({
  labelText,
  helpText,
  id = labelText,
  maxLength,
  optional,
  explanationText,
  value
}: FormLabelProps) => {
  const { t } = useTranslation();
  const [showExplanation, toggleExplanation] = useToggle(false);

  return (
    <Container flex justify="center" direction="column" mb="xxs">
      <Container flex alignItems="center">
        <Typography variant="inputLabel" id={id}>
          {labelText}
          {optional && (
            <>
              {' '}
              <Optional>{t('common:optional')}</Optional>
            </>
          )}
        </Typography>
        {explanationText && (
          <ToggleHelpCalloutButton
            onToggle={toggleExplanation}
            isToggled={showExplanation}
          />
        )}
      </Container>
      {showExplanation && (
        <Callout colour="lightGrey" mb="xxs" mt="xxs">
          {explanationText}
        </Callout>
      )}
      <Container flex width="full">
        {helpText && (
          <Typography variant="paragraphSmall" color="secondary">
            {helpText}
          </Typography>
        )}
        {maxLength && (
          <div style={{ marginLeft: 'auto', whiteSpace: 'nowrap' }}>
            <Typography variant="paragraphSmall" color="secondary">
              {value?.toString().length || 0} / {maxLength}
            </Typography>
          </div>
        )}
      </Container>
    </Container>
  );
};

export default FormLabel;
