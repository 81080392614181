import React from 'react';
import Theme from '@dagensmat/carrot/Theme';
import { Typography } from '@dagensmat/carrot/Components';
import i18n from 'i18n';
import Input, { InputContainer } from './Input.style';

type InputWithLabelProps = {
  type?: string;
  labelText: string;
  saved?: boolean;
  savedText?: string;
  explanationText?: React.ReactNode;
  margin?: number;
  maxLength?: number;
  value?: string | number;
  disabled?: boolean;
  placeholder?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const InputWithLabel = ({
  type = 'text',
  labelText,
  saved = false,
  savedText = i18n.t('common:Saved'),
  explanationText,
  margin = Theme.Spacings.m,
  maxLength,
  value,
  disabled,
  onChange,
  placeholder
}: InputWithLabelProps) => {
  const inputEl = React.useRef<HTMLInputElement | null>(null);

  return (
    <InputContainer margin={margin}>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between'
        }}
      >
        <Typography
          variant="inputLabel"
          mb="xxs"
          onClick={() => {
            return inputEl.current?.focus();
          }}
        >
          {labelText}
        </Typography>
        {maxLength && (
          <Typography variant="paragraphSmall" color="secondary">
            {(value && value.toString().length) || 0} / {maxLength}
          </Typography>
        )}
      </div>
      <Input
        type={type}
        maxLength={maxLength}
        ref={inputEl}
        value={value}
        disabled={disabled}
        onChange={onChange}
        placeholder={placeholder}
      />
      {explanationText && (
        <Typography variant="paragraphSmall">{explanationText}</Typography>
      )}
      {saved && (
        <Typography variant="paragraphSmall" style={{ textAlign: 'right' }}>
          {savedText}
        </Typography>
      )}
    </InputContainer>
  );
};

export default InputWithLabel;
