import { roundNumber } from '@dagensmat/core';
import { Currency, Dinero, toDecimal, toSnapshot } from 'dinero.js';
import i18n from 'i18n';

export const formatNok = (value: number): string => {
  return `${Math.floor(value)} kr`;
};

export const formatNok2Decimals = (value: number): string => {
  return `${roundNumber(value).toFixed(2).replace('.', ',')} kr`;
};

export const formatWithXDecimals = (
  value: number,
  decimals: number
): number => {
  return Number(roundNumber(value).toFixed(decimals));
};

function createFormatter(
  transformer: (value: { value: string; currency: Currency<number> }) => string
) {
  return function format(dineroObject: Dinero<number>): string {
    return toDecimal(dineroObject, transformer);
  };
}

const nokFormat = createFormatter(({ value }: { value: string }) => {
  return `${value.replace('.', ',')} kr`;
});

const defaultFormat = createFormatter(
  ({ value, currency }: { value: string; currency: Currency<number> }) => {
    return `${value.replace('.', ',')} ${currency.code}`;
  }
);

export const fmtMoney = (money: Dinero<number>): string => {
  const { currency } = toSnapshot(money);
  switch (currency.code) {
    case 'NOK':
      return nokFormat(money);
    case 'DKK':
      return nokFormat(money);
    default:
      return defaultFormat(money);
  }
};

export const formatProductNameAndType = ({
  name,
  type
}: {
  name?: string;
  type?: string;
}): string => {
  return [name, type].filter(Boolean).join(' ');
};

export const byProductNameAndId = ({
  name,
  type,
  _id
}: {
  name?: string;
  type?: string;
  _id?: string;
}): string => {
  return [name, type, _id].filter(Boolean).join(' ');
};

export const formatTextWithUnits = (unit: string, units: number): string => {
  return `${units} ${i18n.t(`units:${unit}`, { count: units })}`;
};

export const booleanToYesNoUndecided = (value?: boolean | ''): string => {
  if (value === true) return i18n.t('common:yes');
  if (value === false) return i18n.t('common:no');
  return '';
};

export const joinArrayOptions = (
  names: string[],
  endSeparator: string
): string => {
  return names.length > 1
    ? `${names.slice(0, -1).join(', ')} ${endSeparator} ${names.at(-1)}`
    : names[0] || '';
};
