import { UnitType } from '@dagensmat/core';
import { useTranslation } from 'react-i18next';
import { Typography } from '@dagensmat/carrot/Components';
import { ColourNames } from '@dagensmat/carrot/Theme/Colours';

type OrderedUnitContentsProps = {
  unit: UnitType;
  count: number;
  color: ColourNames;
};

const OrderedUnitContents = ({
  unit,
  count,
  color
}: OrderedUnitContentsProps) => {
  const { t } = useTranslation();
  return (
    <Typography variant="paragraphSmall" color={color}>
      {count} {t(`units:${unit}`, { count })} á
    </Typography>
  );
};
export default OrderedUnitContents;
