import { useTranslation } from 'react-i18next';
import { Nav } from '@carrot/style-components/navbar';
import { PUBLIC_RELEASE_NOTES_URL } from '../../config';

/**
 * @package
 */
export const NavMenuSecondaryItems = () => {
  const { t } = useTranslation();
  return (
    <Nav.MenuGroup>
      <Nav.MenuSecondaryItem
        text={t('common:header.faqs')}
        to="https://dagens.farm/faqs"
      />
      <Nav.MenuSecondaryItem
        text={t('common:header.updates')}
        to={PUBLIC_RELEASE_NOTES_URL}
      />
      <Nav.MenuSecondaryItem
        text={t('common:header.aboutUs')}
        to="https://dagens.farm/about-us"
      />
    </Nav.MenuGroup>
  );
};

/**
 * @package
 */
export const NavMenuLogout = () => {
  const { t } = useTranslation();
  return (
    <Nav.MenuGroup>
      <Nav.MenuSecondaryItem
        icon="back"
        text={t('common:header.logOut')}
        to="/logout"
      />
    </Nav.MenuGroup>
  );
};
