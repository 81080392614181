import { ButtonHTMLAttributes, PropsWithChildren, Ref } from 'react';
import { buttonStyle, ButtonStyles } from '../styles';
import { twMerge } from 'tailwind-merge';

type ButtonProps = Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children'>;

export type ButtonComponentProps = PropsWithChildren<
  ButtonProps & {
    as?: 'button';
    buttonRef?: Ref<HTMLButtonElement>;
    styles: ButtonStyles;
  }
>;

export const ButtonComponent = ({
  buttonRef: ref,
  styles,
  children,
  className,
  ...props
}: ButtonComponentProps) => {
  return (
    <button
      ref={ref}
      className={twMerge(buttonStyle(styles), className)}
      {...props}
    >
      {children}
    </button>
  );
};
