import { useState } from 'react';

type Errors = Record<string, boolean>;

function useFormErrors() {
  const [errors, setErrors] = useState<Errors>({});

  const setFieldError = (fieldName: string, hasError: boolean) => {
    setErrors(prevErrors => ({
      ...prevErrors,
      [fieldName]: hasError
    }));
  };

  const hasFieldError = (fieldName: string): boolean => !!errors[fieldName];

  const hasAnyErrors = (): boolean =>
    Object.values(errors).some(error => error);

  return {
    setFieldError,
    hasFieldError,
    hasAnyErrors,
    errors
  };
}

export default useFormErrors;
