import SearchInput from './SearchInput';

type SearchBarProps = {
  hasActiveSearch?: boolean;
  searchStringInput?: string;
  handleClearSearch: React.KeyboardEventHandler<HTMLInputElement>;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleResetSearchString: () => void;
  placeholder?: string;
};

const SearchBar = ({
  hasActiveSearch = false,
  searchStringInput,
  handleClearSearch,
  handleSearchChange,
  handleResetSearchString,
  placeholder
}: SearchBarProps) => {
  return (
    <SearchInput
      hasActiveSearch={hasActiveSearch}
      value={searchStringInput}
      onChange={handleSearchChange}
      onKeyDown={handleClearSearch}
      placeholder={placeholder}
      onClear={handleResetSearchString}
    />
  );
};

export default SearchBar;
