import { clsx } from 'clsx';
import { PropsWithChildren } from 'react';

type Props = PropsWithChildren;

/**
 * @package
 */
export const Foot = ({ children }: Props) => {
  return (
    <thead
      className={clsx(
        'grid',
        'grid-cols-subgrid',
        'col-span-full',
        'border-y-thin',
        'border-y-black'
      )}
    >
      {children}
    </thead>
  );
};
