import type { ProductForSale } from 'types/Product';
import { track } from 'utils/mixpanel';
import Picker, { PickerVariant } from '_common/components/picker/Picker';
import {
  updateBasket,
  countUnitsOfProductInBasketForDeliveryDate,
  calculateMinimumOrderThreshold
} from '_consumer/reducers/basket';
import { useAppDispatch, useAppSelector } from '_common/hooks/reduxHooks';

type Props = {
  minValue?: number;
  product: ProductForSale;
  selectedDate: string;
  variant?: PickerVariant;
};

const EzPicker = ({ minValue, product, selectedDate, variant }: Props) => {
  const { nofUnits, unit } = useAppSelector(({ basket }) => {
    return {
      nofUnits: countUnitsOfProductInBasketForDeliveryDate(
        basket.items,
        selectedDate,
        product._id
      ),
      unit: product.pricing.orderedUnit
    };
  });
  const dispatch = useAppDispatch();

  const setNofUnits = (origin: string, nofUnits: number) => {
    track(origin, {
      units: nofUnits,
      productId: product._id,
      isCustomOffer: product.hasSpecialPricing
    });
    dispatch(
      updateBasket({
        deliveryDate: selectedDate,
        productId: product._id,
        units: nofUnits,
        hasSpecialPricing: product.hasSpecialPricing
      })
    );
    dispatch(calculateMinimumOrderThreshold());
  };

  return (
    <Picker
      minValue={minValue}
      variant={variant}
      nofUnits={nofUnits}
      unit={unit}
      setNofUnits={setNofUnits}
    />
  );
};

export default EzPicker;
