import { useTranslation } from 'react-i18next';
import Theme from '@dagensmat/carrot/Theme';
import Tag from '_common/components/utils/Tag';

type ProductRequestStatusTagProps = {
  opened?: boolean;
  hasNewMessage?: boolean;
};

const ProductRequestStatusTag = ({
  opened = false,
  hasNewMessage = false
}: ProductRequestStatusTagProps) => {
  const { t } = useTranslation();
  if (!opened) {
    return (
      <Tag bgColor={Theme.Colours.dagensPurple} text={t('common:NewRequest')} />
    );
  }
  if (hasNewMessage) {
    return (
      <Tag bgColor={Theme.Colours.dagensPurple} text={t('common:NewMessage')} />
    );
  }
  return null;
};

export default ProductRequestStatusTag;
