import styled from 'styled-components';
import { Typography } from '@dagensmat/carrot/Components';
import { useTranslation } from 'react-i18next';
import SectionHOC from '_common/components/section-hoc/SectionHOC';
import { NotificationSettings } from '../../../_common/components/notification-settings';

const MaxWidthContainer = styled.div`
  width: 100%;
  max-width: 490px;
`;

const ProfileNotificationsSection = () => {
  const { t } = useTranslation();
  return (
    <MaxWidthContainer>
      <Typography variant="paragraph" mb="m">
        {t('producer:NotificationsDescription')}
      </Typography>
      <NotificationSettings />
    </MaxWidthContainer>
  );
};

export default SectionHOC(ProfileNotificationsSection);
