import { ComboboxOption } from '@headlessui/react';
import clsx from 'clsx';
import { Typography } from '@dagensmat/carrot/Components';

type Props = {
  value: string;
  disabled?: boolean;
};

/**
 * @package
 */
export const DeliveryAddressResultElement = ({
  value,
  disabled = false
}: Props) => {
  return (
    <ComboboxOption
      key={value}
      value={value}
      disabled={disabled}
      className={clsx('data-[focus]:bg-brightPurple', 'py-xs', 'px-xs')}
    >
      <Typography>{value}</Typography>
    </ComboboxOption>
  );
};
