import { t } from 'i18next';
import { postDeliveryRouteRequest } from '../../../api';
import Logger from '../../../services/Logger';
import { DeliveryType, Hub } from '../../../types/Logistics';
import REQ, { ReqType } from '../../../utils/REQ';

type RequestData = {
  deliveryDays?: string[];
  deliveryType: DeliveryType;
  distributionAreaId?: string | null | undefined;
  hubsByCountry: Hub[];
  hub?: string | null | undefined;
  roleId: string | undefined;
  textValue?: string;
};

const getDistributionAreaById = (
  hub: Hub | undefined,
  id: string | null | undefined
) => {
  return hub?.routeOptions.find(routeOption => {
    return routeOption.distributionAreaId === id;
  });
};

const getHubAndDistributionById = (
  hubs: Hub[],
  id: string | null | undefined,
  distributionAreaId: string | null | undefined
): { hubName?: string; deadline?: string; distributionAreaName?: string } => {
  const hubFound = hubs.find(hub => {
    return hub.id === id;
  });

  const routeOption = getDistributionAreaById(hubFound, distributionAreaId);

  return {
    hubName: hubFound?.name,
    deadline: hubFound?.deadline,
    distributionAreaName: routeOption?.distributionAreaName
  };
};

const formatRequest = ({
  deliveryDays,
  deliveryType,
  distributionAreaId,
  hubsByCountry,
  hub,
  roleId,
  textValue
}: RequestData) => {
  if (deliveryType !== DeliveryType.DAGENS_DELIVERY) {
    return { producerId: roleId, details: textValue };
  }

  const { hubName, distributionAreaName, deadline } = getHubAndDistributionById(
    hubsByCountry,
    hub,
    distributionAreaId
  );
  const days = deliveryDays?.join(' and ');

  return {
    producerId: roleId,
    details: t(
      'producer:DeliveryRouteRequest.DagensDeliveryConfirmationSummary',
      { distributionAreaName, hubName, deliveryDays: days, deadline }
    ),
    distributionAreaId,
    fromHubId: hub,
    dropOffWeekdays: deliveryDays ?? []
  };
};

export const onSubmit = async (requestData: RequestData) => {
  let req = REQ.PENDING as ReqType;

  const data = formatRequest(requestData);
  await postDeliveryRouteRequest(data)
    .then(() => {
      req = REQ.SUCCESS;
    })
    .catch((e: Error) => {
      req = REQ.ERROR;
      Logger.error(e);
    });

  return { req, data };
};
