type Props = {
  children: React.ReactNode;
};

const TermsLink = ({ children }: Props) => {
  return (
    <a
      href="https://dagens.farm/terms-and-conditions"
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: 'underline' }}
    >
      {children}
    </a>
  );
};

export default TermsLink;
