import { MouseEvent } from 'react';
import { find } from 'lodash';
import { likedProduct, unlikedProduct } from '_common/reducers/auth';
import { postFavorite, deleteFavorite } from 'api';
import { useAppSelector, useAppDispatch } from '_common/hooks/reduxHooks';
import Icon from '_common/components/utils/Icon';
import Button from '_common/components/button/Button.style';
import { LikeButtonContainer } from '_common/components/product/Product.style';
import { isGuestConsumer } from 'utils/role';

type LikeButtonProps = {
  productId: string;
};

const LikeButton = ({ productId }: LikeButtonProps) => {
  const { consumerId, liked, isGuest } = useAppSelector(({ auth }) => {
    return {
      consumerId: auth._id,
      liked: Boolean(find(auth.favorites || [], { _ref: productId })),
      isGuest: Boolean(auth._id && isGuestConsumer(auth._id))
    };
  });

  if (isGuest) return null;

  const dispatch = useAppDispatch();

  const toggleLike = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const payload = { consumerId, favoriteId: productId };

    if (liked) {
      dispatch(unlikedProduct(productId));
      deleteFavorite(payload);
    } else {
      dispatch(likedProduct(productId));
      postFavorite(payload);
    }
  };

  return (
    <LikeButtonContainer>
      <Button onClick={toggleLike} variant="icon">
        <Icon icon={liked ? 'heartFilled' : 'heart'} $size="m" />
      </Button>
    </LikeButtonContainer>
  );
};

export default LikeButton;
