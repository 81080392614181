import {
  themeAnimations,
  themeAnimationSafelist,
  themeKeyframes
} from './animations';
import { themeColors } from './colors';
import {
  textLinkPlugin,
  themeFontFamily,
  themeFontSize,
  themeFontWeight,
  themeLetterSpacing,
  themeLineHeight
} from './typography';
import { themeMaxWidth } from './max-width';
import { themeScreens } from './screens';
import { themeSpacing } from './spacing';
import { themeBorderRadius } from './border-radius';
import { themeBorderWidth } from './border-width';
import {
  themeTransitionDelay,
  themeTransitionDuration,
  themeTransitionTimingFunction,
  transitionPlugin
} from './transition';

export const tailwindTheme = {
  animation: themeAnimations,
  borderRadius: themeBorderRadius,
  borderWidth: themeBorderWidth,
  colors: themeColors,
  fontFamily: themeFontFamily,
  fontSize: themeFontSize,
  fontWeight: themeFontWeight,
  keyframes: themeKeyframes,
  letterSpacing: themeLetterSpacing,
  lineHeight: themeLineHeight,
  maxWidth: themeMaxWidth,
  screens: themeScreens,
  spacing: themeSpacing,
  transitionDelay: themeTransitionDelay,
  transitionDuration: themeTransitionDuration,
  transitionTimingFunction: themeTransitionTimingFunction
};

export const tailwindPlugins = [textLinkPlugin, transitionPlugin];

export const tailwindSafelist = [...themeAnimationSafelist];
