import { Theme } from '@dagensmat/carrot';
import styled from 'styled-components';
import margins from 'utils/style';

export const SelectStyle = styled.select`
  --padding: ${Theme.Spacings.xs + Theme.Spacings.xxs / 2}px;

  cursor: pointer;
  display: inline-block;
  width: 100%;
  color: ${Theme.Colours.black};
  font-size: ${Theme.FontSizes.inputText}px;
  line-height: ${Theme.Spacings.m}px;
  border: 2px solid ${Theme.Colours.lightGrey};
  border-radius: ${Theme.BorderRadius.regular}px;
  margin: 0;
  padding: var(--padding);
  padding-right: calc((var(--chevron-size) * 3) - var(--padding));
  transition: border-color ${Theme.Animations.regular};

  appearance: none;
  outline: none;
  ${margins}

  &:focus,
  &:hover {
    border-color: ${Theme.Colours.black};
  }
`;
