import { clsx } from 'clsx';
import { PropsWithChildren } from 'react';

const style = clsx(
  'text-paragraphBold',
  'text-secondary',
  'py-s',
  'text-center',
  'border-b-thin',
  'border-lightGrey',
  'mb-xs'
);

type Props = PropsWithChildren;

export const ModalTitle = ({ children }: Props) => {
  return <div className={style}>{children}</div>;
};
