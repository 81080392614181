import React from 'react';
import { Navigate } from 'react-router-dom';
import { find, orderBy, get, filter, findIndex } from 'lodash';
import usePageView from '_common/hooks/usePageView';
import useOnboarding from '_common/hooks/useOnboarding';
import { PRODUCT_SEARCH_PAGE_VIEW } from 'utils/mixpanel';
import { useAppSelector } from '_common/hooks/reduxHooks';
import type { ProductForSale } from 'types/Product';
import { combineReqs } from 'utils/REQ';
import EmptySearch from '_common/components/search/EmptySearch';
import GroupedSearchResultContainer from './GroupedSearchResultContainer';
import { ContentLoader } from '../../../components/content-loader';

const GROUP_PAGE_SIZE = 10;

type GroupedSearchResultPageProps = {
  filteredProducts: ProductForSale[];
  clearFilters: () => void;
  noFiltersApplied: boolean;
};

const GroupedSearchResultPage = ({
  filteredProducts,
  clearFilters,
  noFiltersApplied
}: GroupedSearchResultPageProps) => {
  usePageView(PRODUCT_SEARCH_PAGE_VIEW);
  const { hasNotOnboarded, getOnboardingPath } = useOnboarding();

  if (hasNotOnboarded()) {
    return <Navigate to={getOnboardingPath()} />;
  }

  if (filteredProducts.length === 0) {
    return (
      <EmptySearch
        clearFilters={clearFilters}
        noFiltersApplied={noFiltersApplied}
      />
    );
  }

  const [statistics, orderedBefore] = useAppSelector(
    ({ statistics: { items = [], productsOrderedBefore } }) => {
      return [items, productsOrderedBefore];
    }
  );

  const sortedProducts = orderBy(
    filteredProducts,
    [
      product => {
        return product.newnessRank || 0;
      },
      product => {
        return findIndex(orderedBefore, id => {
          return id === product._id;
        });
      }
    ],
    ['desc', 'desc']
  );

  const [producerGroupsLoaded, setProducerGroupsLoaded] =
    React.useState(GROUP_PAGE_SIZE);
  const loadMore = () => {
    setProducerGroupsLoaded(prev => {
      return GROUP_PAGE_SIZE + prev;
    });
  };

  const producersWithStatistics = useAppSelector(
    ({ producers: { items: producers = [] } }) => {
      return producers.map(producer => {
        const withStatistics = find(statistics, { producerId: producer._id });
        return {
          producer,
          numberOfOrders: get(withStatistics, 'numberOfOrders', 0),
          products: filter(sortedProducts, ['producer._id', producer._id])
        };
      });
    }
  );

  const sortedProducerGroups = orderBy(
    producersWithStatistics,
    ['numberOfOrders', 'producer.rawRank'],
    ['desc', 'desc']
  );

  const producerGroupsToShow = sortedProducerGroups
    .filter(producerGroup => {
      return producerGroup.products.length > 0;
    })
    .slice(0, producerGroupsLoaded);

  return (
    <GroupedSearchResultContainer
      producerGroups={producerGroupsToShow}
      hasMore={sortedProducerGroups.length > producerGroupsLoaded}
      loadMore={loadMore}
    />
  );
};

const GroupedSearchResultPageFetcher = (
  props: GroupedSearchResultPageProps
) => {
  const req = useAppSelector(
    ({
      productsForSale: { req: productsReq },
      producers: { req: producersReq },
      statistics: { req: statisticsReq }
    }) => {
      return combineReqs([productsReq, producersReq, statisticsReq]);
    }
  );

  return (
    <ContentLoader req={req}>
      <GroupedSearchResultPage {...props} />
    </ContentLoader>
  );
};

export default GroupedSearchResultPageFetcher;
