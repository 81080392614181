import { Container } from '@dagensmat/carrot/Layout';
import { Typography } from '@dagensmat/carrot/Components';
import { ProducerProduct } from 'types/Product';
import ProductListElement from '_producer/pages/products/ProductListElement';

type Props = {
  header: string;
  products: ProducerProduct[];
};

const ProductGroup = ({ header, products = [] }: Props) => {
  return (
    <Container mt="xl" mb="jumbo">
      <Typography variant="secondaryHeading" mb="xs">
        {header}
      </Typography>
      <>
        {products.map(product => {
          return <ProductListElement key={product._id} product={product} />;
        })}
      </>
    </Container>
  );
};

export default ProductGroup;
