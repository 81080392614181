import React from 'react';
import { Typography } from '@dagensmat/carrot/Components';
import REQ, { ReqType } from 'utils/REQ';
import Button, { ButtonContainer } from './Button.style';

export type RequestButtonProps = {
  req: ReqType;
  onSubmit: () => void | Promise<void>;
  validationErrorMessage: React.ReactNode;
  buttonLabel: string;
  pendingMessage: React.ReactNode;
  successMessage: React.ReactNode;
  errorMessage: React.ReactNode;
};

const RequestButton = ({
  req,
  onSubmit,
  validationErrorMessage = null,
  buttonLabel,
  successMessage,
  pendingMessage,
  errorMessage
}: RequestButtonProps) => {
  return (
    <ButtonContainer>
      {req === REQ.ERROR && (
        <Typography variant="paragraph" color="error">
          {errorMessage}
        </Typography>
      )}
      {validationErrorMessage && (
        <Typography variant="paragraph" color="error">
          {validationErrorMessage}
        </Typography>
      )}
      {req === REQ.INIT && (
        <Button type="submit" onSubmit={onSubmit} variant="primary">
          {buttonLabel}
        </Button>
      )}
      {req === REQ.SUCCESS && (
        <Typography variant="paragraph">{successMessage}</Typography>
      )}
      {req === REQ.PENDING && (
        <Typography variant="paragraph" color="secondary">
          {pendingMessage}
        </Typography>
      )}
    </ButtonContainer>
  );
};

export default RequestButton;
