import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { getCountry } from '@dagensmat/core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { omit } from 'lodash';
import { AnyAction } from '@reduxjs/toolkit';
import {
  pricesComplete,
  convertPrices,
  isMeasureUnitValid,
  getNewProductInitialPricing,
  shouldPriceAllowZeroWeight
} from 'utils/pricing';
import REQ, { ReqType } from 'utils/REQ';
import { postProduct } from 'api';
import { clearProduct, updateProduct } from '_producer/reducers/newProduct';
import { fetchProducerProducts } from '_producer/reducers/products';
import ProductForm from '_producer/components/product-form/ProductForm';
import PageHeader from '@components/page-header/PageHeader';
import usePageView from '_common/hooks/usePageView';
import { ADD_PRODUCT_PAGE_VIEW } from 'utils/mixpanel';
import {
  Category,
  EditableProduct,
  EditableProductFieldChanger
} from 'types/Product';
import {
  useAppDispatch,
  useAppSelector
} from '../../../_common/hooks/reduxHooks';
import { RootState } from '../../../store';
import { Page } from '@components/page';
import { BottomActions } from '@components/bottom-actions';
import { ActionButton } from '@components/action-button';

const useCreateProductOnInit = (dispatch: Dispatch<AnyAction>) => {
  useEffect(() => {
    dispatch(
      updateProduct({
        prices: [getNewProductInitialPricing()],
        readyToEdit: true
      })
    );
    return () => {
      dispatch(clearProduct());
    };
  }, [dispatch]);
};

const useOnFieldChange = (
  setSaveReq: Dispatch<SetStateAction<ReqType>>,
  dispatch: Dispatch<AnyAction>
) => {
  const onFieldChanged: EditableProductFieldChanger = key => {
    return change => {
      setSaveReq(REQ.INIT);
      dispatch(updateProduct({ [key]: change }));
    };
  };

  return onFieldChanged;
};

const useAddProduct = (
  newProduct: Partial<EditableProduct>,
  producerId: string | undefined,
  setSaveReq: Dispatch<SetStateAction<ReqType>>,
  dispatch: (x: any) => void
) => {
  const navigate = useNavigate();

  const addProduct = async () => {
    try {
      setSaveReq(REQ.PENDING);
      await postProduct({
        ...omit(newProduct, 'readyToEdit'),
        prices: convertPrices(newProduct.prices ?? []),
        producerId
      });
      dispatch(fetchProducerProducts(producerId));
      dispatch(clearProduct());
      setSaveReq(REQ.SUCCESS);
      navigate('/my-products');
    } catch (error) {
      setSaveReq(REQ.ERROR);
    }
  };

  return addProduct;
};

const getIsValid = (
  newProduct: Partial<EditableProduct>,
  categories: Category[]
) => {
  const {
    name,
    forSaleStatus,
    prices = [],
    temperatureZone,
    image,
    processedState,
    categoryId,
    seasonCalendar
  } = newProduct;

  const allowZeroWeight = shouldPriceAllowZeroWeight(categoryId, categories);
  const allPricesHaveWeights = prices.every(pricing => {
    return isMeasureUnitValid(pricing);
  });

  const isValidImage =
    image &&
    image._type &&
    image.asset &&
    image.asset._ref &&
    image.asset._type;

  const isValid = [
    pricesComplete(prices),
    forSaleStatus,
    name,
    temperatureZone,
    isValidImage,
    processedState,
    categoryId,
    seasonCalendar && seasonCalendar.length > 0,
    allowZeroWeight || allPricesHaveWeights
  ].every(Boolean);
  return isValid;
};

const AddProductPage = () => {
  usePageView(ADD_PRODUCT_PAGE_VIEW);

  const { t } = useTranslation();
  const { producerName, producerId, newProduct, countryCode, categories } =
    useAppSelector(
      ({
        auth: { name: producerName, _id: producerId, organization },
        producerCategories,
        newProduct
      }: RootState) => {
        return {
          producerName,
          producerId,
          newProduct,
          countryCode: organization ? getCountry(organization) : undefined,
          categories: producerCategories.categories
        };
      }
    );

  const dispatch = useAppDispatch();
  const [saveReq, setSaveReq] = useState<ReqType>(REQ.INIT);

  useCreateProductOnInit(dispatch);
  const onFieldChange = useOnFieldChange(setSaveReq, dispatch);
  const addProduct = useAddProduct(
    newProduct,
    producerId,
    setSaveReq,
    dispatch
  );

  const isValid = getIsValid(newProduct, categories);
  return (
    <Page
      bottom={
        <BottomActions>
          <ActionButton.Save
            onClick={addProduct}
            disabled={!isValid}
            feedbackText={{
              [REQ.PENDING]: t('producer:ProductSavingButtonFeedback'),
              [REQ.SUCCESS]: t('producer:ProductSavedButtonFeedback')
            }}
            redirectTo="/my-products"
            saveReq={saveReq}
          >
            {isValid
              ? t('producer:ProductSaveButtonFeedback')
              : t('producer:ProductSaveButtonMissingFieldsFeedback')}
          </ActionButton.Save>
        </BottomActions>
      }
    >
      <PageHeader
        headerText={t('producer:productAddPageHeader')}
        subTitle={t('producer:productAddNewFromProducer', {
          producerName
        })}
      />
      <ProductForm
        fieldChanged={onFieldChange}
        product={newProduct}
        countryCode={countryCode}
      />
    </Page>
  );
};

export default AddProductPage;
