import { Typography } from '@dagensmat/carrot/Components';
import { Container } from '@dagensmat/carrot/Layout';
import { useTranslation } from 'react-i18next';
import OrderStatusTag from '_common/components/status-tag/OrderStatusTag';
import DeliveryTypeTag from '_common/components/tags/DeliveryTypeTag';
import { Order } from 'types/Order';
import { formatDate } from 'utils/date/format';

type OrderPageHeaderSectionProps = {
  order: Order;
  pointOfView?: 'consumer' | 'producer';
};

const OrderPageHeaderSection = ({
  order,
  pointOfView = 'producer'
}: OrderPageHeaderSectionProps) => {
  const { t } = useTranslation();
  const {
    deliveryType,
    deliveryDate,
    _id,
    statuses,
    orderNumberString,
    consumer,
    producer
  } = order;
  const producerPOV = pointOfView === 'producer';
  const consumerName = consumer.name;
  const producerName = producer.name;
  const orderDescriptionString = producerPOV
    ? 'producer:DeliveryToCustomerOnDate'
    : 'consumer:OrderFromProducerForDate';

  return (
    <>
      <Container flex>
        <DeliveryTypeTag
          deliveryType={deliveryType}
          pointOfView={pointOfView}
        />
        <OrderStatusTag
          orderId={_id}
          statuses={statuses}
          producer={producerPOV}
        />
      </Container>
      <Typography variant="secondaryHeading" as="h1" my="xs">
        {t('common:Order_one')} {orderNumberString}
      </Typography>

      <Typography variant="paragraph">
        {t(orderDescriptionString, {
          name: producerPOV ? consumerName : producerName,
          date: formatDate(deliveryDate)
        })}
      </Typography>
    </>
  );
};
export default OrderPageHeaderSection;
