import { useState, useEffect } from 'react';
import { useParams, Navigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { verifyUser } from 'api';
import { Page } from '@components/page';

const VerifyUserEmail = () => {
  const [isVerified, setIsVerified] = useState(false);
  const { token } = useParams<{ token: string }>();
  const { search } = useLocation();

  const { redirectLogin = '' } = queryString.parse(search) as {
    redirectLogin?: string;
  };

  useEffect(() => {
    (async () => {
      if (token) {
        await verifyUser({ token });
        setIsVerified(true);
      }
    })();
  }, [token]);

  if (isVerified) {
    return (
      <Navigate
        to={`/login?calloutType=emailVerified&redirectLogin=${redirectLogin}`}
      />
    );
  }

  return <Page.Loading />;
};

export default VerifyUserEmail;
