import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Callout, Typography } from '@dagensmat/carrot/Components';
import { Container as CarrotContainer } from '@dagensmat/carrot/Layout';
import { addMonths } from 'date-fns';
import PageHeader from '@components/page-header/PageHeader';
import { orderKindIsOrder, ordersBySettlement } from '_common/reducers/orders';
import { OrderKind } from 'types/Order';
import { useQueryParams } from '_common/hooks/useQueryParams';
import { getOrderHistory } from '../../../api';
import { useAppSelector } from '../../../_common/hooks/reduxHooks';
import REQ, { ReqType } from '../../../utils/REQ';
import SearchInput from '../../../_common/components/search/search-bar/SearchInput';
import { getDateShort } from '../../../utils/date/format';
import { SettlementContainer } from './SettlementContainer';
import { Page } from '@components/page';
import { CommonNavbar } from '@components/navbar';
import { Box } from '@carrot/layout/box';
import { ContentLoader } from '../../../components/content-loader';

type SettlementListProps = {
  searchQuery: string | null;
};

const SettlementsList = ({ searchQuery }: SettlementListProps) => {
  const { t } = useTranslation();
  const [req, setReq] = useState<ReqType>(REQ.INIT);
  const [orders, setOrders] = useState<OrderKind[]>([]);
  const { roleId } = useAppSelector(({ auth }) => {
    return {
      roleId: auth._id
    };
  });

  const fetchOrderHistory = async () => {
    setReq(REQ.PENDING);

    const FETCH_PERIOD = 24;
    const FETCH_INTERVAL = 3;

    const today = new Date();
    const history = await Promise.all(
      Array.from({ length: FETCH_PERIOD / FETCH_INTERVAL }).map(
        async (_, i) => {
          const startDate = addMonths(
            today,
            -(i + 1) * FETCH_INTERVAL
          ).toISOString();
          const endDate = addMonths(today, -i * FETCH_INTERVAL).toISOString();
          const orderHistory = await getOrderHistory({
            producerId: roleId,
            startDate,
            endDate
          });
          return orderHistory;
        }
      )
    );

    setOrders(history.flat());
    setReq(REQ.SUCCESS);
  };

  const settlements = useMemo(() => {
    const filteredOrders = orders.filter(order => {
      if (!searchQuery || searchQuery.length < 1) {
        return true;
      }
      const orderSearchableFields = [
        order.orderNumberString,
        order.settlement?.settlementNumber,
        order.consumer.name,
        orderKindIsOrder(order)
          ? getDateShort(order.deliveryDate)
          : getDateShort(order._createdAt)
      ].filter(Boolean);
      const searchKeywords = searchQuery.toLowerCase().split(' ');

      return searchKeywords.every(keyword => {
        return orderSearchableFields.some(field => {
          return field?.toLowerCase().includes(keyword);
        });
      });
    });

    return ordersBySettlement(filteredOrders);
  }, [orders, searchQuery]);

  useEffect(() => {
    fetchOrderHistory();
  }, []);

  return (
    <ContentLoader req={req}>
      <CarrotContainer mt="m">
        {settlements.length < 1 && (
          <Callout>
            <Typography variant="paragraph">
              {t('common:NoOrderHistoryYet')}
            </Typography>
          </Callout>
        )}
        {settlements.sort().map(settlement => {
          return (
            <SettlementContainer key={settlement.key} settlement={settlement} />
          );
        })}
      </CarrotContainer>
    </ContentLoader>
  );
};

const QUERY_KEY = 'query';

const ProducerOrdersHistoryPage = () => {
  const { getQueryValue, setQueryValue } = useQueryParams();
  const searchQuery = getQueryValue(QUERY_KEY);
  const { t } = useTranslation();

  return (
    <Page
      header={
        <>
          <CommonNavbar />
          <Box.FullWidth>
            <PageHeader
              headerText={t('common:OrderHistory')}
              subTitle={t('producer:OrderHistoryPageDescription')}
            />
            <SearchInput
              placeholder={t('common:SearchInOrderHistory')}
              value={searchQuery || ''}
              onChange={({ target: { value } }) => {
                return setQueryValue(QUERY_KEY, value);
              }}
              onClear={() => {
                return setQueryValue(QUERY_KEY, '');
              }}
            />
          </Box.FullWidth>
        </>
      }
    >
      <SettlementsList searchQuery={searchQuery} />
    </Page>
  );
};

export default ProducerOrdersHistoryPage;
