import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Callout, Typography } from '@dagensmat/carrot/Components';
import { Container } from '@dagensmat/carrot/Layout';
import { useTranslation } from 'react-i18next';
import {
  calculateActiveUnopenedValue,
  countActiveUnopenedOrders
} from '_common/reducers/orders';
import { useAppSelector } from '_common/hooks/reduxHooks';
import { getUserFirstName } from '_common/reducers/auth';
import { formatNok } from 'utils/texts';

const LinkWrapper = ({ to, children }: { to: string; children: ReactNode }) => {
  return to ? <Link to={to}>{children}</Link> : <>{children}</>;
};

const ProducerMessagesOrders = () => {
  const { t } = useTranslation();
  const { firstName, ordersCount, ordersValue } = useAppSelector(
    ({ auth, orders: { items: orderItems } }) => {
      return {
        firstName: getUserFirstName(auth),
        ordersCount: countActiveUnopenedOrders(orderItems),
        ordersValue: calculateActiveUnopenedValue(orderItems)
      };
    }
  );

  if (ordersCount === 0) return null;

  return (
    <Container flex flexBasis="48%" flexGrow={1}>
      <Callout colour="dagensPurple" width="full">
        <LinkWrapper to="/orders">
          <Typography variant="paragraphBold" as="p" mb="xs">
            {t('producer:HiName', {
              name: firstName
            })}
          </Typography>
          <Typography variant="paragraph" mb="xs">
            {t('producer:YouHaveOrders', {
              ordersCount,
              ordersText: t('producer:newOrder', {
                count: ordersCount
              }),
              ordersValue: formatNok(ordersValue)
            })}
          </Typography>
        </LinkWrapper>
      </Callout>
    </Container>
  );
};

export default ProducerMessagesOrders;
