import { PageBase, PageBaseProps } from '@components/page/base';
import { PageError } from './error';
import { PageLoading } from '@components/page/loading';
import { PageNotFound } from '@components/page/not-found';
import { PageStatus } from '@components/page/status';

const PageComponent = (props: Omit<PageBaseProps, 'req'>) => {
  return <PageBase {...props} />;
};

export const Page = Object.assign(PageComponent, {
  Error: PageError,
  Loading: PageLoading,
  NotFound: PageNotFound,
  Status: PageStatus
});
