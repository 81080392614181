import { clsx } from 'clsx';
import { ReactNode } from 'react';
import { Box } from '@carrot/layout/box';

const containerStyle = clsx('desktop:border-b-thin', 'border-b-lightGrey');
const navStyle = clsx(
  'grid',
  'grid-cols-[64px,1fr,64px]',
  'grid-rows-1',
  'gap-s',
  'py-xxs'
);

const leftStyle = clsx('flex', 'items-center', 'justify-start');

const rightStyle = clsx('flex', 'items-center', 'justify-end');

type Props = {
  left: ReactNode;
  center: ReactNode;
  right: ReactNode;
};

/**
 * @package
 */
export const NavBar = ({ left, center, right }: Props) => {
  return (
    <div className={containerStyle}>
      <Box.FullWidth>
        <nav className={navStyle}>
          <div className={leftStyle}>{left}</div>
          <div>{center}</div>
          <div className={rightStyle}>{right}</div>
        </nav>
      </Box.FullWidth>
    </div>
  );
};
