import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GetOrganizationFoundResponse } from '../../../../api';
import PageHeader from '@components/page-header/PageHeader';
import { Page } from '@components/page';
import { CommonNavbar } from '@components/navbar';
import { Box } from '@carrot/layout/box';
import { BottomActions } from '../../../../components/bottom-actions';
import { ButtonLink } from '../../../../_common/components/button/Button.style';
import { Typography } from '@dagensmat/carrot/Components';
import { Navigate, useLocation } from 'react-router-dom';
import { FormFieldType } from '../../../../carrot/form-field/types';
import { FormField } from '../../../../carrot/form-field';
import { isValidEan } from '../../../../utils/billingAccount';
import { validateEmail } from '../../../../utils/validation';
import Form from '../../../../carrot/style-components/form';
import { Button } from '../../../../carrot/style-components/button';
import { Icon } from '../../../../carrot/theme/icon';
import useFormErrors from '../../../../utils/use-form-errors';

type LocationState =
  | {
      organization: GetOrganizationFoundResponse;
    }
  | undefined;

const ReviewBillingInformation = () => {
  const [billingAccountName, setBillingAccountName] = useState<string>('');
  const [billingDepartmentName, setBillingDepartmentName] =
    useState<string>('');
  const [billingEanNumber, setBillingEanNumber] = useState<string>('');
  const [billingPrimaryInvoiceEmail, setBillingPrimaryInvoiceEmail] =
    useState<string>('');
  const [billingReminderInvoiceEmail, setBillingReminderInvoiceEmail] =
    useState<string>('');
  const { t } = useTranslation();
  const { setFieldError, hasFieldError, hasAnyErrors } = useFormErrors();
  const location = useLocation();
  const state = location.state as LocationState;
  const organization = state?.organization;

  if (!organization) {
    return <Navigate to="/customers/add/start" />;
  }

  useEffect(() => {
    setBillingAccountName(organization.name);
    if (organization.email) setBillingPrimaryInvoiceEmail(organization.email);
  }, [organization]);

  const canContinue = useMemo(() => {
    return (
      billingAccountName &&
      ((organization.country === 'dk' && isValidEan(billingEanNumber)) ||
        (organization.country === 'no' && !billingEanNumber)) &&
      billingPrimaryInvoiceEmail &&
      billingReminderInvoiceEmail &&
      !hasAnyErrors()
    );
  }, [
    billingAccountName,
    billingEanNumber,
    billingPrimaryInvoiceEmail,
    billingReminderInvoiceEmail
  ]);

  return (
    <Page
      header={
        <>
          <CommonNavbar />
          <Box.FullWidth>
            <PageHeader
              headerText={t(
                'producer:AddCustomer.reviewBillingInformationPageHeader'
              )}
              annotation={`${t('producer:AddCustomer.headerAnnotation')} (2/4)`}
            />
            <Typography>
              {t(
                'producer:AddCustomer.reviewBillingInformationPageDescription'
              )}
            </Typography>
          </Box.FullWidth>
        </>
      }
      bottom={
        <BottomActions border>
          <ButtonLink to="/customers/add/start">
            {t('common:BackWithArrow')}
          </ButtonLink>
          {canContinue ? (
            <ButtonLink
              to="/customers/add/review-contact-information"
              variant="primary"
              state={{
                organization,
                billingAccountName,
                billingDepartmentName,
                billingEanNumber,
                billingPrimaryInvoiceEmail,
                billingReminderInvoiceEmail
              }}
              color="secondary"
            >
              {t('common:NextWithArrow')}
            </ButtonLink>
          ) : (
            <Button
              styles={{
                variant: 'primary',
                size: 'small'
              }}
              disabled
            >
              {t('common:NextWithArrow')}
            </Button>
          )}
        </BottomActions>
      }
    >
      <Form>
        <FormField
          type={FormFieldType.TEXT}
          label={t('producer:AddCustomer.reviewBillingInformationFieldLabel')}
          helpText={t(
            'producer:AddCustomer:reviewBillingInformationFieldDescription'
          )}
          inputProps={{
            value: billingAccountName,
            onChange: ({ target: { value } }) => {
              setFieldError('billingAccountName', !value);
              return setBillingAccountName(value);
            }
          }}
        />
        <FormField
          type={FormFieldType.TEXT}
          label={t(
            'producer:AddCustomer.reviewBillingInformationFieldDepartmentNameLabel'
          )}
          helpText={t(
            'producer:AddCustomer:reviewBillingInformationFieldDepartmentNameDescription'
          )}
          optional
          inputProps={{
            value: billingDepartmentName,
            onChange: ({ target: { value } }) => {
              return setBillingDepartmentName(value);
            }
          }}
        />
        {organization.country === 'dk' && (
          <FormField
            label="EAN nummer"
            type={FormFieldType.TEXT}
            inputProps={{
              value: billingEanNumber,
              placeholder: t('common:EanNumberInputPlaceholder'),
              onChange: ({ target: { value } }) => {
                setFieldError('billingEanNumber', !isValidEan(value));
                return setBillingEanNumber(value);
              },
              invalid: hasFieldError('billingEanNumber')
            }}
            maxLength={13}
            valueLength={billingEanNumber ? billingEanNumber.length : 0}
            helpText={t('common:EanNumberExplanation')}
            errorMessage={t('common:EanNumberErrorMessage')}
            showError={!isValidEan(billingEanNumber)}
          />
        )}
        <FormField
          label={t(
            'producer:AddCustomer:reviewBillingInformationFieldPrimaryInvoiceLabel'
          )}
          inputProps={{
            value: billingPrimaryInvoiceEmail,
            onChange: ({ target: { value } }) => {
              setFieldError(
                'billingPrimaryInvoiceEmail',
                !validateEmail(value)
              );
              setBillingPrimaryInvoiceEmail(value);
            },
            invalid: hasFieldError('billingPrimaryInvoiceEmail')
          }}
          showError={hasFieldError('billingPrimaryInvoiceEmail')}
          type={FormFieldType.TEXT}
          feedback={
            organization.email && (
              <>
                <Icon color="warning" icon="alert" />
                {t('producer:AddCustomer:retrievedFromCvrRegistry')}
              </>
            )
          }
        />
        <FormField
          label={t(
            'producer:AddCustomer:reviewBillingInformationFieldReminderInvoiceLabel'
          )}
          inputProps={{
            value: billingReminderInvoiceEmail,
            onChange: ({ target: { value } }) => {
              setFieldError(
                'billingReminderInvoiceEmail',
                !validateEmail(value)
              );
              setBillingReminderInvoiceEmail(value);
            },
            invalid: hasFieldError('billingReminderInvoiceEmail')
          }}
          helpText={t(
            'producer:AddCustomer.reviewBillingInformationFieldReminderInvoiceDescription'
          )}
          showError={hasFieldError('billingReminderInvoiceEmail')}
          type={FormFieldType.TEXT}
        />
      </Form>
    </Page>
  );
};

export default ReviewBillingInformation;
