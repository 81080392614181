import React from 'react';

const useToggle = (initialValue: boolean): [boolean, () => void] => {
  const [isVisible, setIsVisible] = React.useState(initialValue);

  const onToggle = () => {
    setIsVisible(val => {
      return !val;
    });
  };

  return [isVisible, onToggle];
};

export default useToggle;
