import {
  forSaleStatuses,
  CountryCode,
  InputMeasureUnit,
  UnitType
} from '@dagensmat/core';
import type { SanityDoc, SanityImage, SanityRef } from 'types/Sanity';
import { DeliveryDate } from '_common/components/search/DeliveryDateOptions';
import type { Producer, ProducerProfile } from './Producer';

export type Pricing = {
  _key: string;
  _type: 'productPricing';
  availableTo: ['CONSUMERS'];
  pricedUnit: UnitType;
  nokPerPricedUnit: number;
  orderedUnit: UnitType;
  pricedUnitsPerOrderedUnit: number;
  inputMeasureUnit?: InputMeasureUnit;
  inputMeasureUnitValue?: number;
  unitSizeDescription?: string;
  specialConsumers?: SanityRef[];
};

export type EditablePricing = {
  nokPerPricedUnit: number | '';
  pricedUnitsPerOrderedUnit: number | '';
  inputMeasureUnitValue?: number | '';
  isSimplePricing: boolean;
} & Omit<
  Pricing,
  'nokPerPricedUnit' | 'pricedUnitsPerOrderedUnit' | 'inputMeasureUnitValue'
>;

export type Favorite = {
  _key: string;
  _ref: string;
  _type: 'products';
};

export type Category = {
  _id: string;
  name: string;
  translateKey: string;
  parent?: SanityRef;
  children?: Category[];
  path?: Category[];
  name_en?: string;
  name_nb?: string;
  name_da?: string;
  isNonFood?: boolean;
};

export type Period = {
  to?: string | Date;
  from: string | Date;
};

export type SeasonPeriod = {
  _key: string;
} & Required<Period>;

export type AlwaysInSeason = {
  _key: string;
  from: string;
};

export const isSeasonPeriod = (
  val: AlwaysInSeason | SeasonPeriod
): val is SeasonPeriod => {
  return val !== undefined && 'to' in val;
};

export enum ProcessedStateEnum {
  RAW = 'RAW',
  PROCESSED = 'PROCESSED'
}

export type ProcessedState = keyof typeof ProcessedStateEnum;

export enum TemperatureZone {
  FROZEN = 'FROZEN',
  REFRIGERATED = 'REFRIGERATED',
  DRY = 'DRY'
}

export type Capacity = {
  _key: string;
  _type: 'capacity';
  deliveryDate: string;
  units: number;
};

export type SoldOutCapacity = {
  _key: string;
  _type: 'capacity';
  deliveryDate: string;
  units: 0;
};

export type ProductCapacity = Capacity | SoldOutCapacity;
export const isSoldOutCapacity = (
  val: ProductCapacity
): val is SoldOutCapacity => {
  return val.units === 0;
};

export type ProducerProduct = {
  capacities: ProductCapacity[];
  categoryId: string;
  certificationIds: string[];
  certifications: Certification[];
  description: string;
  forSaleStatus: ForSaleStatus;
  image: SanityImage;
  name: string;
  prices: Pricing[];
  pricing: Pricing;
  processedState: ProcessedState;
  producer: ProducerProfile;
  seasonCalendar: SeasonCalendar;
  temperatureZone: TemperatureZone;
  type: string;
  deleted?: boolean;
} & SanityDoc<'products'>;

export type EditableProduct = {
  readyToEdit: boolean;
  prices: (Pricing | EditablePricing)[];
} & Omit<ProducerProduct, 'prices'>;

export type EditableProductFieldChanger = <Key extends keyof EditableProduct>(
  key: Key
) => (change?: EditableProduct[Key] | null) => void;

export type Certification = {
  _id: string;
  availableIn: CountryCode;
  displayNameKey: string;
  logo: SanityImage;
  parcelLabelAssetUrl: string;
  name: string;
  shortName: string;
  description: string;
} & SanityDoc<'certification'>;

export type ForSaleStatus = keyof typeof forSaleStatuses;

export type SeasonCalendar = (AlwaysInSeason | SeasonPeriod)[];

export enum AvailabilityStatus {
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE'
}

export type AvailabilityStatusType = keyof typeof AvailabilityStatus;

export type ProductForSale = {
  certifications: Certification[];
  deadlines: Record<string, string>;
  deliveryDates: DeliveryDate[];
  description: string;
  forSaleStatus: ForSaleStatus;
  image: SanityImage;
  name: string;
  newnessRank?: number;
  pricing: Pricing;
  processedState: ProcessedState;
  producer: Producer;
  seasonCalendar: SeasonCalendar;
  soldOutDates?: string[];
  tags: string[];
  temperatureZone: TemperatureZone;
  type: string;
  hasSpecialPricing?: boolean;
  availableIn: string[];
  prices?: Pricing[];
  justAdded: boolean;
  seasonStart: boolean;
} & SanityDoc<'products'>;

export type ProductRequest = {
  _id: string;
  _createdAt: string;
  hasNewMessage: boolean;
  isOpened: boolean;
  counterPartName: string;
  pathname: string;
};
