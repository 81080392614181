import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from '@dagensmat/carrot/Layout';
import { Icon, Typography } from '@dagensmat/carrot/Components';
import { isAfter, isBefore, subDays } from 'date-fns';
import { sortBy, random, capitalize } from 'lodash';
import ProducerOrderGroup from '_producer/pages/orders/ProducerOrderGroup';
import {
  groupOrdersByPickupType,
  splitActiveAndPastOrders
} from '_common/reducers/orders';
import { useAppSelector } from '_common/hooks/reduxHooks';
import { ButtonLink } from '_common/components/button/Button.style';
import { formatDate, parseDate } from 'utils/date/format';
import { Order } from 'types/Order';
import SalesModule from '_producer/pages/landing-page/producer-sales-section/SalesModule';
import { hasAllOrdersLeftProducer } from 'utils/delivery';
import { PRODUCER_HOME_TOP_UP_ALL_ORDERS, track } from 'utils/mixpanel';

const ProducerSalesModuleTopUp = () => {
  const { t } = useTranslation();

  const { potentialTopUpOrders, key, day } = useAppSelector(
    ({ auth, orders: { items } }) => {
      const { activeOrders } = splitActiveAndPastOrders(items);
      const groups = groupOrdersByPickupType(activeOrders);
      const groupsFiltered = groups.filter(group => {
        const parsedDeliveryDate = parseDate(group.deliveryDate);
        const orderDeadline = auth.minDaysBeforeOrder
          ? auth.minDaysBeforeOrder
          : 2;
        const parsedProducerDeliveryDate = parseDate(
          group.producerDeliveryDate
        );
        const today = new Date();
        return (
          isAfter(today, subDays(parsedDeliveryDate, orderDeadline + 1)) &&
          isBefore(today, parsedProducerDeliveryDate.setHours(14, 0, 0, 0)) &&
          !hasAllOrdersLeftProducer(group.orders)
        );
      });

      if (groupsFiltered.length === 0) {
        return {
          potentialTopUpOrders: []
        };
      }

      const groupsSorted = sortBy(groupsFiltered, group => {
        const producerDeliveryDate = parseDate(group.producerDeliveryDate);
        const deliveryTypePriority =
          group.orders[0]?.deliveryType === 'DAGENS_DELIVERY' ? 0 : 1;

        return producerDeliveryDate.getTime() + deliveryTypePriority;
      });

      const deliveryDateKey = groupsSorted[0].key;
      const deliveryDateDay = formatDate(groupsSorted[0].deliveryDate, 'EEEE');

      return {
        potentialTopUpOrders:
          groupsSorted[0].orders.length > 0 ? groupsSorted[0].orders : [],
        key: deliveryDateKey,
        day: deliveryDateDay
      };
    }
  );

  const pickTopUpOrders = (): Order[] => {
    const ordersToDisplay: Order[] = [];
    while (ordersToDisplay.length < 2 && potentialTopUpOrders.length > 0) {
      if (potentialTopUpOrders.length === 1) {
        ordersToDisplay.push((potentialTopUpOrders as Order[])[0]);
        break;
      }
      const randomOrder = (potentialTopUpOrders as Order[]).splice(
        random(potentialTopUpOrders.length - 1),
        1
      );
      ordersToDisplay.push(randomOrder[0]);
    }
    return ordersToDisplay;
  };

  const orders = useMemo(() => {
    return pickTopUpOrders();
  }, [JSON.stringify(potentialTopUpOrders)]);

  if (potentialTopUpOrders.length === 0) return null;

  return (
    <SalesModule>
      <Container>
        <Typography variant="paragraphBold">
          {t('producer:TopUpFridayDeliveries', {
            day: capitalize(day)
          })}
        </Typography>
        <Typography variant="paragraphSmall" color="secondary">
          {t('producer:TopUpFridayDeliveriesDescription')}
        </Typography>
      </Container>
      <Container>
        <ProducerOrderGroup orders={orders} showTags={false} />
      </Container>
      <ButtonLink
        onClick={() => {
          track(PRODUCER_HOME_TOP_UP_ALL_ORDERS);
        }}
        variant="borderless"
        to={`/orders/by-delivery/${key}`}
      >
        {t('producer:ViewAllOrdersFor', {
          day: capitalize(day)
        })}{' '}
        <Icon name="externalLink" fill="deepPurple" />
      </ButtonLink>
    </SalesModule>
  );
};
export default ProducerSalesModuleTopUp;
