import { capitalize } from '@dagensmat/core';
import Theme from '@dagensmat/carrot/Theme';
import { Container } from '@dagensmat/carrot/Layout';
import { TagButton } from '_common/components/button/Button.style';
import SelectedButton from '_common/components/button/SelectedButton';
import { mapOptions } from '_common/components/radio-buttons/RadioButtons';
import { KeyValue } from 'types/Utils';

type TagsProps = {
  tags?: KeyValue[];
  selectedTags?: KeyValue[];
  deselectTag: (key: string | null) => void;
  selectTag: (val: string | null) => void;
};

const Tags = ({
  tags = [],
  selectedTags = [],
  deselectTag,
  selectTag
}: TagsProps) => {
  return (
    <Container>
      <>
        {mapOptions(selectedTags).map(t => {
          return (
            <SelectedButton
              key={t.key}
              mr={Theme.Spacings.xxs}
              mb={Theme.Spacings.xxs}
              onClick={() => {
                deselectTag(t.key);
              }}
            >
              {capitalize(t.value)}
            </SelectedButton>
          );
        })}
        {mapOptions(tags).map(t => {
          return (
            <TagButton
              key={t.key}
              onClick={() => {
                selectTag(t.key);
              }}
            >
              {capitalize(t.value)}
            </TagButton>
          );
        })}
      </>
    </Container>
  );
};

export default Tags;
