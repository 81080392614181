import { useAppSelector } from '_common/hooks/reduxHooks';

export const useProductResults = (query: string) => {
  const { req, products } = useAppSelector(({ producerProducts }) => {
    return {
      req: producerProducts.req,
      products: producerProducts.items
    };
  });

  if (query === '') {
    return { productsReq: req, productResults: products };
  }

  const keywords = query.toLowerCase().split(' ');

  const matches = products.filter(product => {
    const title = product.name.toLowerCase();
    return keywords.some(keyword => title?.includes(keyword));
  });

  const sortedMatches = matches.sort((a, b) => a.name.localeCompare(b.name));
  return { productsReq: req, productResults: sortedMatches };
};
