import { useMemo } from 'react';
import { Option } from '../../../carrot/select-multi/types';

type Args = {
  value: readonly string[];
  options: readonly Option[];
};

export const useGetOptionsFromValue = ({ value, options }: Args) => {
  const selectedOptions = useMemo(() => {
    return options.filter(option => {
      return value.includes(option.value);
    });
  }, [value, options]);
  return selectedOptions;
};
