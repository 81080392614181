import { Button } from '@carrot/style-components/button';
import { Icon, IconType } from '@carrot/theme/icon';
import { ForwardedRef, forwardRef } from 'react';

type Props = {
  text?: string;
  icon?: IconType;
  to?: string;
  focused: boolean;
};

const Component = (
  { text, icon, to, focused }: Props,
  ref: ForwardedRef<HTMLAnchorElement>
) => {
  if (!to) {
    return null;
  }

  return (
    <Button
      linkRef={ref}
      styles={{
        size: 'regular',
        variant: 'borderless',
        alignment: 'left',
        focused
      }}
      as="link"
      to={to}
    >
      {icon && <Icon icon={icon} size="medium" />}
      {text}
    </Button>
  );
};

/**
 * @package
 */
export const CommandPaletteResult = forwardRef(Component);
