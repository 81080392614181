import { Suspense, useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { User, checkAuth } from '_common/reducers/auth';
import './i18n';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from '_common/hooks/reduxHooks';
import { authRouter } from './_authenticated/router';
import { consumerRouter } from './_consumer/router';
import { producerRouter } from './_producer/router';
import { unauthRouter } from './_unauthenticated/router';

const getRouter = (role?: string, options?: User[]) => {
  if (role === 'producers') {
    return producerRouter();
  }

  if (role === 'consumers') {
    return consumerRouter();
  }

  if (options && options.length === 0) {
    return authRouter();
  }

  return unauthRouter();
};

const App = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(checkAuth());
  }, []);

  const { authenticated, role, options } = useAppSelector(
    ({ auth }: RootState) => {
      return {
        authenticated: auth.authenticated,
        role: auth._type,
        options: auth.options
      };
    }
  );

  if (authenticated === undefined) return null;

  const router = getRouter(role, options);

  return (
    <Suspense fallback={null}>
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default App;
