import Theme from '@dagensmat/carrot/Theme';
import { UnstyledButton } from '_common/components/button/Button.style';
import { ReactComponent as QuestionCircleIcon } from '../../../../public/assets/question-circle-0x000000.svg';
import { ReactComponent as CloseCircleIcon } from '../../../../public/assets/close-circle-0x000000.svg';

type ToggleHelpCalloutButtonProps = {
  onToggle: () => void;
  isToggled: boolean;
};

const ToggleHelpCalloutButton = ({
  onToggle,
  isToggled
}: ToggleHelpCalloutButtonProps) => {
  return (
    <UnstyledButton
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: Theme.Spacings.xxs,
        width: Theme.Spacings.m,
        height: Theme.Spacings.m
      }}
      type="button"
      onClick={onToggle}
    >
      {isToggled ? <CloseCircleIcon /> : <QuestionCircleIcon />}
    </UnstyledButton>
  );
};

export default ToggleHelpCalloutButton;
