import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import { clsx } from 'clsx';
import { PropsWithChildren } from 'react';

const dialogStyles = clsx('relative', 'z-20');

const backdropStyles = clsx(
  'bg-black/75',
  'fixed',
  'inset-0',
  'transition-opacity'
);
// eslint-disable-next-line tailwindcss/no-custom-classname
const backdropDataStyles = clsx(
  'data-[enter]:duration-300',
  'data-[leave]:duration-200',
  'data-[enter]:ease-out',
  'data-[leave]:ease-in',
  'data-[closed]:opacity-0'
);

const contentStyles = clsx(
  'fixed',
  'inset-0',
  'z-10',
  'w-screen',
  'px-s',
  'py-xl',
  'flex',
  'justify-center',
  'items-end',
  'desktop:items-center'
);

const panelStyles = clsx(
  'bg-white',
  'rounded',
  'flex',
  'flex-col',
  'w-full',
  'max-w-[480px]'
);

type Props = PropsWithChildren<{
  open: boolean;
  onClose: () => void;
}>;

/**
 * @package
 */
export const Modal = ({ open, children, onClose }: Props) => {
  return (
    <Dialog
      transition
      className={dialogStyles}
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <DialogBackdrop
        transition
        className={clsx(backdropStyles, backdropDataStyles)}
      />
      <div className={contentStyles}>
        <DialogPanel className={panelStyles}>{children}</DialogPanel>
      </div>
    </Dialog>
  );
};
