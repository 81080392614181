import {
  ConsumerOnboarding,
  ConsumerOnboardingStepValue
} from '@dagensmat/core';
import { User } from '_common/reducers/auth';

export const isGuestConsumer = (userId?: string): boolean => {
  return userId === 'guest-consumer';
};

export type Onboarding = {
  [key in ConsumerOnboardingStepValue]?: string;
};

export const isConsumerOnboarding = (
  onboarding: User['onboarding']
): onboarding is Onboarding => {
  return !Array.isArray(onboarding);
};

export const hasConsumerOnboarded = (onboarding: User['onboarding']) => {
  if (isConsumerOnboarding(onboarding)) {
    return onboarding[ConsumerOnboarding.HAS_SEEN_INTRO_SCREEN];
  }
  return false;
};
