import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type UtilsState = {
  redirectLogin?: string;
  hideHeader?: boolean;
};

const initialState: UtilsState = {};

const utilsSlice = createSlice({
  name: 'utils',
  initialState,
  reducers: {
    updateUtils(state, action: PayloadAction<Partial<UtilsState>>) {
      return { ...state, ...action.payload };
    }
  }
});

export const { updateUtils } = utilsSlice.actions;

export default utilsSlice.reducer;
