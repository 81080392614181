import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from '@dagensmat/carrot/Layout';
import { Typography } from '@dagensmat/carrot/Components';
import PageHeader from '@components/page-header/PageHeader';
import Tabs from '_common/components/tabs/Tabs';
import ExpandableCallout from '_common/components/callout/ExpandableCallout';
import TradeReportLink from '_common/pages/profile/TradeReportLink';
import { Page } from '@components/page';
import { CommonNavbar } from '@components/navbar';

type Props = PropsWithChildren;

export const ConsumerOrdersPageHOC = ({ children }: Props) => {
  const { t } = useTranslation();
  return (
    <Page header={<CommonNavbar showLogo />}>
      <PageHeader headerText={t('common:MyOrders')} />
      <Container mb="m">
        <ExpandableCallout
          expandedContent={<TradeReportLink variant="callout" />}
        >
          {' '}
          <Typography variant="paragraphBold">
            {t('consumer:TradeReportHype')}
          </Typography>
        </ExpandableCallout>
      </Container>
      <Tabs
        tabs={[
          {
            text: t('consumer:Requests'),
            to: '/orders/product-requests'
          },
          { text: t('consumer:Products'), to: '/orders/overview' },
          { text: t('common:Orders'), to: '/orders' }
        ]}
      />
      {children}
    </Page>
  );
};
