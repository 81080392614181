import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import './index.css';
import './tailwind.css';
import setupAirbrake from 'utils/errorHandling';
import { store } from 'store';
import ErrorBoundary from '_common/components/error-boundary/ErrorBoundary';
import { initiateCorrelationId } from './services/CookieService';
import App from './App';
import theme from './theme';

const airbrake = setupAirbrake(store);

/** Remove the fallback loader element */
const loaderEl = document.getElementById('loader');
if (loaderEl) {
  loaderEl.remove();
}

/** Refresh page every 2nd hour. Some users stay on Dagens for days and never refresh the page... */
const REFRESH_INTERVAL = 2 * 60 * 60 * 1000;
setInterval(() => {
  window.location.reload();
}, REFRESH_INTERVAL);

initiateCorrelationId();

const container = document.getElementById('root');
if (!container) {
  throw new Error('Could not find root element');
}

const root = createRoot(container);
root.render(
  <Suspense fallback={null}>
    <Provider store={store}>
      <ErrorBoundary airbrake={airbrake}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </ErrorBoundary>
    </Provider>
  </Suspense>
);
