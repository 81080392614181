import { cellStyles } from '@carrot/style-components/table/cell';
import { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = PropsWithChildren;

/**
 * @package
 */
export const FooterCell = ({ children }: Props) => {
  return (
    <td
      className={twMerge(
        cellStyles(),
        'text-start',
        'text-paragraphSmallBold',
        'text-black'
      )}
    >
      {children}
    </td>
  );
};
