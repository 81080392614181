import {
  NavLinkComponent,
  NavLinkComponentProps
} from '@carrot/style-components/button/components/as-nav-link';
import { tv } from 'tailwind-variants';

const style = tv({
  base: [
    'p-xs',
    'h-full',
    'w-full',
    '!text-microText',
    'rounded-none',
    'text-secondary'
  ],
  variants: {
    isActive: {
      true: ['text-black']
    }
  }
});

const verticalContent = tv({
  base: ['w-full', 'h-full', 'flex', 'flex-col', 'items-center']
});

type Props = Omit<NavLinkComponentProps, 'styles' | 'className' | 'as'>;

export const NavButtonMobile = (props: Props) => {
  return (
    <NavLinkComponent
      {...props}
      as="nav-link"
      styles={{
        variant: 'borderless',
        size: 'regular'
      }}
      className={({ isActive }) => style({ isActive })}
    >
      <div className={verticalContent()}>{props.children}</div>
    </NavLinkComponent>
  );
};
