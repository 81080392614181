import Theme from '@dagensmat/carrot/Theme';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const tabStyle = css`
  flex: 1;
  text-align: center;
  margin-right: -1px;
  padding: ${Theme.Spacings.s - 2}px ${Theme.Spacings.xs}px;
  font-size: ${Theme.FontSizes.button}px;
  background: ${Theme.Colours.transparent};
  color: ${Theme.Colours.black};
  border: 2px solid ${Theme.Colours.black};

  &.active {
    background: ${Theme.Colours.black};
    color: ${Theme.Colours.white};
  }

  &:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &:last-child {
    margin: 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

const NavTab = styled(NavLink)`
  ${tabStyle}
`;

const ButtonTab = styled.button`
  ${tabStyle}
`;

type NavTabType = {
  text: string;
  to: string;
};

type NavTabsProps = {
  tabs: NavTabType[];
};

const NavTabs = ({ tabs = [] }: NavTabsProps) => {
  return (
    <TabsContainer>
      {tabs.map(({ text, to }) => {
        return (
          <NavTab key={to} to={to} end>
            {text}
          </NavTab>
        );
      })}
    </TabsContainer>
  );
};

type ButtonTabType = {
  text: string;
  onClick: () => void;
};

type ButtonTabsProps = {
  active: number;
  tabs: ButtonTabType[];
};

export const ButtonTabs = ({ tabs = [], active }: ButtonTabsProps) => {
  return (
    <TabsContainer>
      {tabs.map(({ text, onClick }, index) => {
        return (
          <ButtonTab
            key={text}
            onClick={onClick}
            // eslint-disable-next-line tailwindcss/no-custom-classname
            className={active === index ? 'active' : ''}
          >
            {text}
          </ButtonTab>
        );
      })}
    </TabsContainer>
  );
};

export default NavTabs;
