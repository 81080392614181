import { PropsWithChildren, Ref } from 'react';
import { buttonStyle, ButtonStyles } from '../styles';
import {
  NavLink as ReactRouterNavLink,
  NavLinkProps as ReactRouterNavLinkProps
} from 'react-router-dom';
import { tv } from 'tailwind-variants';
import { twMerge } from 'tailwind-merge';

const NAV_LINK_ACTIVE_CUSTOM_CLASS = 'active';

const contentStyle = tv({
  base: [
    'h-full',
    'w-full',
    'relative',
    'p-xxs',
    'inline-flex',
    'items-center',
    'justify-center',
    'gap-x-xxs'
  ]
});

type NavLinkProps = Omit<ReactRouterNavLinkProps, 'children'>;

export type NavLinkComponentProps = PropsWithChildren<
  NavLinkProps & {
    as: 'nav-link';
    navLinkRef?: Ref<HTMLAnchorElement>;
    styles: ButtonStyles;
    active: boolean;
  }
>;

export const NavLinkComponent = ({
  navLinkRef,
  styles,
  active,
  className,
  children,
  ...props
}: NavLinkComponentProps) => {
  return (
    <ReactRouterNavLink
      ref={navLinkRef}
      className={({ isActive, isPending, isTransitioning }) => {
        const navLinkActive = (isActive || active) ?? false;
        return twMerge(
          buttonStyle(styles),
          navLinkActive && NAV_LINK_ACTIVE_CUSTOM_CLASS,
          typeof className === 'function'
            ? className({ isActive: navLinkActive, isPending, isTransitioning })
            : className
        );
      }}
      {...props}
    >
      <div className={contentStyle()}>{children}</div>
    </ReactRouterNavLink>
  );
};
