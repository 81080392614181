import { useEffect, useState } from 'react';
import { Icon, Typography } from '@dagensmat/carrot/Components';
import { Container } from '@dagensmat/carrot/Layout';
import { subDays } from 'date-fns';
import { useTranslation } from 'react-i18next';
import REQ, { ReqType } from 'utils/REQ';
import {
  getFirstDayOfWeek,
  getLastDayOfWeek,
  slugifyDate
} from 'utils/date/format';
import { useAppSelector } from '_common/hooks/reduxHooks';
import { getProducerSalesStatistics } from 'api';
import TradeReportLink from '_common/pages/profile/TradeReportLink';
import { formatWithXDecimals, formatNok2Decimals } from 'utils/texts';
import SalesModule from '_producer/pages/landing-page/producer-sales-section/SalesModule';

type SalesStatistics = {
  id: string;
  startDate: string;
  endDate: string;
  turnover: number;
  averageOrder: number;
};

const generateTradesColumnContent = (
  title: string,
  amount: string,
  percentage: number | null
) => {
  return (
    <>
      <Typography variant="paragraphSmall" color="secondary" mb="xxs">
        {title}
      </Typography>
      <Typography variant="leadTextBold" mb="xxs">
        {amount}
      </Typography>
      {percentage && (
        <Container flex alignItems="center" gap="xxs" wrap="nowrap">
          <Icon
            name={percentage > 0 ? 'arrowUp' : 'arrowDown'}
            fill={percentage > 0 ? 'success' : 'error'}
          />
          <Typography
            variant="paragraphSmall"
            color={percentage > 0 ? 'success' : 'error'}
          >
            {percentage} %
          </Typography>
        </Container>
      )}
    </>
  );
};

const ProducerSalesModuleStatistics = () => {
  const [req, setReq] = useState<ReqType>(REQ.INIT);
  const [statisticsLastWeek, setStatisticsLastWeek] =
    useState<SalesStatistics>();
  const [statisticsCurrentWeek, setStatisticsCurrentWeek] =
    useState<SalesStatistics>();
  const { t } = useTranslation();
  const { producerId } = useAppSelector(({ auth }) => {
    return {
      producerId: auth._id
    };
  });

  const getSalesSatistics = async () => {
    setReq(REQ.PENDING);
    const mondayThisWeek = getFirstDayOfWeek(new Date());
    const sundayThisWeek = getLastDayOfWeek(new Date());
    const sundayLastWeek = subDays(mondayThisWeek, 1);
    const mondayLastWeek = getFirstDayOfWeek(sundayLastWeek);
    await getProducerSalesStatistics({
      producerId,
      startDate: slugifyDate(mondayLastWeek),
      endDate: slugifyDate(sundayLastWeek)
    })
      .then((data: SalesStatistics) => {
        setStatisticsLastWeek(data);
      })
      .catch(() => {
        setReq(REQ.ERROR);
      });

    await getProducerSalesStatistics({
      producerId,
      startDate: slugifyDate(mondayThisWeek),
      endDate: slugifyDate(sundayThisWeek)
    })
      .then((data: SalesStatistics) => {
        setReq(REQ.SUCCESS);
        setStatisticsCurrentWeek(data);
      })
      .catch(() => {
        setReq(REQ.ERROR);
      });
  };

  useEffect(() => {
    getSalesSatistics();
  }, []);

  if (req !== REQ.SUCCESS) return null;

  const lastWeekTurnover = statisticsLastWeek?.turnover ?? 0;
  const currentWeekTurnover = statisticsCurrentWeek?.turnover ?? 0;
  const turnoverRelativeChange =
    lastWeekTurnover === 0
      ? null
      : formatWithXDecimals(
          ((currentWeekTurnover - lastWeekTurnover) / lastWeekTurnover) * 100,
          1
        );

  const lastWeekAverageOrder = statisticsLastWeek?.averageOrder ?? 0;
  const currentWeekAverageOrder = statisticsCurrentWeek?.averageOrder ?? 0;
  const averageOrderPercentage =
    lastWeekAverageOrder === 0
      ? null
      : formatWithXDecimals(
          ((currentWeekAverageOrder - lastWeekAverageOrder) /
            lastWeekAverageOrder) *
            100,
          1
        );

  return (
    <SalesModule>
      <Container>
        <Typography variant="paragraphBold">
          {t('common:yourTrades')}
        </Typography>
        <Container flex gap="xxs" wrap="nowrap" mb="xxs">
          <Typography variant="paragraphSmall" color="secondary">
            {t('common:thisWeek')}
          </Typography>
          {turnoverRelativeChange && (
            <Typography variant="paragraphSmall" color="secondary">
              {t('common:comparedTo')} {t('common:lastWeek').toLowerCase()}
            </Typography>
          )}
        </Container>
      </Container>
      <Container
        flex
        wrap="nowrap"
        justify="flex-start"
        gap="s"
        mb="xxs"
        pt="xs"
      >
        <Container flex direction="column" pr="m">
          {generateTradesColumnContent(
            t('producer:turnover'),
            formatNok2Decimals(currentWeekTurnover),
            turnoverRelativeChange
          )}
        </Container>
        <Container flex direction="column">
          {generateTradesColumnContent(
            t('producer:averageOrder'),
            formatNok2Decimals(currentWeekAverageOrder),
            averageOrderPercentage
          )}
        </Container>
      </Container>

      <TradeReportLink variant="minimal" context="producerHome" />
    </SalesModule>
  );
};
export default ProducerSalesModuleStatistics;
