import { ButtonUnderline } from '@carrot/style-components/button/parts/underline';
import { ButtonBadgeContainer } from '@carrot/style-components/button/parts/badge-container';
import { ButtonWrapper } from '@carrot/style-components/button/wrapper';
import { MenuItemPrimary } from '@carrot/style-components/button/presets/menu-item-primary';
import { MenuItemSecondary } from '@carrot/style-components/button/presets/menu-item-secondary';
import { NavButton } from '@carrot/style-components/button/presets/nav-button';
import { NavButtonMobile } from '@carrot/style-components/button/presets/nav-button-mobile';
import { NavIconButton } from '@carrot/style-components/button/presets/nav-icon-button';
import { ModalAction } from '@carrot/style-components/button/presets/modal-action';

export const Button = Object.assign(ButtonWrapper, {
  BadgeContainer: ButtonBadgeContainer,
  Underline: ButtonUnderline,

  MenuItemPrimary: MenuItemPrimary,
  MenuItemSecondary: MenuItemSecondary,
  ModalAction,
  Nav: NavButton,
  NavMobile: NavButtonMobile,
  NavIcon: NavIconButton
});
