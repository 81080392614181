import { CountryCode } from '@dagensmat/core';
import type { SanityRef } from './Sanity';
import { TemperatureZone } from './Product';
import { Address } from './BillingAccount';

export type Route = {
  _key: string;
  _type: 'logisticsRoute' | 'extraDeliveryRoute';
  distributionArea: SanityRef;
  route: SanityRef[];
};

export type StructuredAddress = {
  addressLine1: string;
  addressLine2?: string;
  postalCode: string;
  city: string;
  country: CountryCode;
};

export type ToFromOrPartner = {
  _type:
    | 'logisticsPartner'
    | 'transportHub'
    | 'producers'
    | 'consumers'
    | 'distributionArea';
  _id: string;
  name: string;
  phone?: string;
  contactPerson?: string;
  address?: string;
  structuredAddress?: StructuredAddress;
  deadline?: string;
  infoLink?: string;
};

export type BookedLogisticsLeg = {
  type: 'transport' | 'distribution';
  bookingNumber: string;
  startDate: string;
  endDate: string;
  from: ToFromOrPartner;
  partner: ToFromOrPartner;
  to: ToFromOrPartner;
};

export type DeliveryRouteLeg = {
  _key: string;
  _type: 'bookedLogistics';
  pickupTime?: string;
  deliveryTime?: string;
  logisticsLeg: BookedLogisticsLeg;
};

export enum DeliveryType {
  DAGENS_DELIVERY = 'DAGENS_DELIVERY',
  SELF_DELIVERY = 'SELF_DELIVERY'
}

export enum ParcelStatus {
  READY_FOR_PRINT = 'READY_FOR_PRINT',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export type Parcel = {
  id: string;
  created: string;
  orderId: string;
  orderNumberString: string;
  temperatureZone: TemperatureZone;
  status: ParcelStatus;
};
export enum HubRole {
  HUB = 'HUB',
  DC = 'DC'
}

export type Hub = {
  id: string;
  name: string;
  address: Address;
  contactPerson: string;
  deadline: string;
  hubArea: string;
  limited: boolean;
  live: boolean;
  public: boolean;
  phone: string;
  role: HubRole;
  temperatureZones: TemperatureZone[];
  infoLink?: string;
  routeOptions: {
    distributionAreaId: string;
    distributionAreaName: string;
    pickupDays: string[];
  }[];
};
