import { Container } from '@dagensmat/carrot/Layout';
import { SpacingNames } from '@dagensmat/carrot/Theme/Spacings';
import FormLabel from '_common/components/input/FormLabel';
import TextArea from './TextArea.style';

type TextAreaWithLabelProps = {
  label: string;
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  mb?: SpacingNames;
  optional?: boolean;
  explanationText?: string;
  helpText?: string;
};

const TextAreaWithLabel = ({
  label,
  placeholder,
  value,
  onChange,
  mb,
  optional = false,
  explanationText = '',
  helpText = '',
  ...rest
}: TextAreaWithLabelProps) => {
  return (
    <Container mb={mb}>
      <FormLabel
        id={label}
        labelText={label}
        value={value}
        explanationText={explanationText}
        helpText={helpText}
        optional={optional}
      />
      <TextArea
        id={label}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        {...rest}
      />
    </Container>
  );
};

export default TextAreaWithLabel;
