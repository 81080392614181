import { UnitType } from '@dagensmat/core';
import { t } from 'i18next';
import { FormFieldType } from 'carrot/form-field/types';
import NewRadioButtons from '../../../_common/components/radio-buttons/NewRadioButtons';
import { booleanToYesNoUndecided } from '../../../utils/texts';
import { PACKED_UNIT_VALUES } from '../../../utils/units';
import { FormField } from '../../../carrot/form-field';
import { unitTypesToOptions } from './unit-types-to-options';

type OrderedUnitInputProps = {
  orderedUnit: UnitType;
  pricedUnitsPerOrderedUnit: number | '';
  orderedUnitOptions: UnitType[];
  pricedUnit: UnitType;
  isSimplePricing: boolean;
  showError?: boolean;
  onOrderedUnitChange: (value: UnitType) => void;
  onPricedUnitsPerOrderedUnitChange: (value: number | string) => void;
  onIsSimplePricingChange: (value: boolean) => void;
};
const OrderedUnitInput = ({
  orderedUnit,
  pricedUnitsPerOrderedUnit,
  orderedUnitOptions,
  pricedUnit,
  isSimplePricing,
  showError,
  onOrderedUnitChange,
  onPricedUnitsPerOrderedUnitChange,
  onIsSimplePricingChange
}: OrderedUnitInputProps) => {
  return (
    <>
      {!(PACKED_UNIT_VALUES as UnitType[]).includes(pricedUnit) && (
        <NewRadioButtons
          labelText={t('producer:productOrderingUnitsHeader', { pricedUnit })}
          options={[
            {
              key: t('common:no'),
              value: t('common:No')
            },
            {
              key: t('common:yes'),
              value: t('common:Yes')
            }
          ]}
          currentValue={booleanToYesNoUndecided(isSimplePricing)}
          radioGroupName="orderingUnitsSame"
          onChange={option => {
            onIsSimplePricingChange(option === t('common:yes'));
          }}
        />
      )}
      {isSimplePricing === false && (
        <FormField
          type={FormFieldType.NUMBER_WITH_SELECT}
          inputProps={{
            unit: t(`units:${pricedUnit}`, { count: 1 }),
            value: pricedUnitsPerOrderedUnit,
            onInputChange: onPricedUnitsPerOrderedUnitChange,
            selectValue: orderedUnit,
            selectOptions: unitTypesToOptions(orderedUnitOptions),
            onSelectChange: onOrderedUnitChange
          }}
          label={t('producer:productListOrderingUnitsLabelText')}
          errorMessage={t('common:requiredField')}
          showError={showError}
        />
      )}
    </>
  );
};

export default OrderedUnitInput;
