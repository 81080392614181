import { useAppSelector, useAppDispatch } from '_common/hooks/reduxHooks';
import { updateUtils } from '_common/reducers/utils';

const useHideHeader = (): {
  get: () => boolean;
  set: () => void;
  unset: () => void;
} => {
  const dispatch = useAppDispatch();

  const hideHeader = useAppSelector(({ utils }) => {
    return utils.hideHeader;
  });

  const get = (): boolean => {
    return Boolean(hideHeader);
  };

  const set = () => {
    dispatch(
      updateUtils({
        hideHeader: true
      })
    );
  };

  const unset = () => {
    dispatch(
      updateUtils({
        hideHeader: false
      })
    );
  };

  return { get, set, unset };
};

export default useHideHeader;
