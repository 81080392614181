import { clsx } from 'clsx';
import { PropsWithChildren } from 'react';

/**
 * @package
 */
export const fullWidthStyle = clsx(
  'm-auto',
  'max-w-desktop',
  'justify-center',
  'px-s',
  'print:p-xxs',
  'print:max-w-none'
);

type Props = PropsWithChildren;

/**
 * @package
 */
export const BoxFullWidth = ({ children }: Props) => {
  return <div className={fullWidthStyle}>{children}</div>;
};
