const REQ = {
  INIT: 'INIT',
  PENDING: 'PENDING',
  ERROR: 'ERROR',
  NOT_FOUND: 'NOT_FOUND',
  SUCCESS: 'SUCCESS'
} as const;

export type ReqType = keyof typeof REQ;

export const combineReqs = (reqs: ReqType[]): ReqType => {
  if (
    reqs.some(req => {
      return req === REQ.ERROR;
    })
  )
    return REQ.ERROR;
  if (
    reqs.some(req => {
      return req === REQ.PENDING;
    })
  )
    return REQ.PENDING;
  if (
    reqs.some(req => {
      return req === REQ.NOT_FOUND;
    })
  )
    return REQ.NOT_FOUND;
  if (
    reqs.every(req => {
      return req === REQ.SUCCESS;
    })
  )
    return REQ.SUCCESS;
  return REQ.INIT;
};

export default REQ;
