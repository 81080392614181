import { getOrderLineAggregates, capitalize } from '@dagensmat/core';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import queryString from 'query-string';
import { map } from 'lodash';
import Theme from '@dagensmat/carrot/Theme';
import { Container as CarrotContainer } from '@dagensmat/carrot/Layout';
import { Typography } from '@dagensmat/carrot/Components';
import { Order } from 'types/Order';
import {
  splitActiveAndPastOrders,
  getOrderGroup,
  isDeliveredToConsumer
} from '_common/reducers/orders';
import TextLink from '_common/components/text-link/TextLink.style';
import Tag from '_common/components/utils/Tag';
import { formatDate } from 'utils/date/format';
import {
  ButtonContainer,
  ButtonAnchor,
  ButtonLink
} from '_common/components/button/Button.style';
import { hasAllOrdersLeftProducer, PRODUCER_DELIVERS } from 'utils/delivery';
import ExpandableCallout from '_common/components/callout/ExpandableCallout';
import InContextOnboardingMessage from '_common/components/in-context-onboarding-message/InContextOnboardingMessage';
import OverviewTable from '_common/components/order-overview/OverviewTable';
import { baseUrl } from 'config';
import { track, DOWNLOAD_DELIVERY_NOTES } from 'utils/mixpanel';
import { DeliveryType } from 'types/Logistics';
import DeliveryTypeTag from '_common/components/tags/DeliveryTypeTag';
import { OnboardingHistoryKeys } from '../../../types/Producer';
import { useAppSelector } from '../../../_common/hooks/reduxHooks';
import PackingAndLabelingInfo from './PackingAndLabelingInfo';
import ProducerActions from './ProducerActions';
import ProducerOrderGroup from './ProducerOrderGroup';
import { Page } from '@components/page';

const EmptyPage = () => {
  const { t } = useTranslation();
  return (
    <Typography variant="paragraph" color="secondary" my="m">
      {t('producer:NothingLeftToDoWithThisOrder')}
      <TextLink
        to="/orders"
        mt={Theme.Spacings.xs}
        color={Theme.Colours.black}
        align="left"
      >
        {t('producer:BackToMyOrdersWithArrow')}
      </TextLink>
    </Typography>
  );
};

const DeliveredToConsumer = ({
  producerDeliveryDate
}: {
  producerDeliveryDate: string;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Tag
        bgColor={Theme.Colours.availability}
        text={t('producer:DeliveredToConsumer')}
      />
      <Typography variant="primaryHeading" mt="s">
        {capitalize(formatDate(producerDeliveryDate))}
      </Typography>
      <Typography variant="paragraph" mt="s">
        {t('producer:YouAreDoneWithYourPart')}.
      </Typography>
      <Typography variant="paragraph" mt="s">
        <Trans
          t={t}
          i18nKey="producer:OrderFoundUnderInvoicing"
          components={{
            TypographyLink: (
              <Typography variant="routerLink" to="/orders/invoice" />
            )
          }}
        />
      </Typography>
    </>
  );
};

export type IOrderGroup = {
  key: string;
  deliveryDate: Order['deliveryDate'];
  orders: Order[];
  producerDeliveryDate: string;
  type: string;
  toName?: string;
  toDeadline?: string;
};

const OrdersByDeliveryPage = () => {
  const { onboardingHistory } = useAppSelector(
    ({ auth: { onboardingHistory } }) => ({ onboardingHistory })
  );
  const { t } = useTranslation();
  const { key } = useParams<{ key: string }>();
  const { orderGroup, pastOrderGroup } = useAppSelector(({ orders }) => {
    const { activeOrders, pastOrders } = splitActiveAndPastOrders(orders.items);
    const group = getOrderGroup(activeOrders, key);
    const pastGroup = getOrderGroup(pastOrders, key);
    return {
      orderGroup: group,
      pastOrderGroup: pastGroup
    };
  });
  const isOnboarding =
    !onboardingHistory?.[OnboardingHistoryKeys.deliveredFirstOrder];

  const hasBeenDeliveredToConsumer =
    !orderGroup &&
    pastOrderGroup &&
    pastOrderGroup?.orders &&
    pastOrderGroup?.orders.some(isDeliveredToConsumer);

  if (hasBeenDeliveredToConsumer) {
    return (
      <Page>
        <DeliveredToConsumer
          producerDeliveryDate={pastOrderGroup.producerDeliveryDate}
        />
      </Page>
    );
  }

  const { orders = [], key: pickupKey, type, deliveryDate } = orderGroup || {};

  return (
    <Page>
      {!orderGroup ? (
        <EmptyPage />
      ) : (
        <>
          <DeliveryTypeTag
            deliveryType={
              type === PRODUCER_DELIVERS
                ? DeliveryType.SELF_DELIVERY
                : DeliveryType.DAGENS_DELIVERY
            }
          />
          <Typography variant="primaryHeading" mt="s">
            {capitalize(formatDate(deliveryDate))}
          </Typography>
          <InContextOnboardingMessage
            message={t('producer:orders.getReadyForDelivery')}
            showStep={isOnboarding}
            mt="m"
          />
          <ProducerActions orderGroup={orderGroup} />
          <CarrotContainer mt="xl" mb="s">
            <Typography variant="secondaryHeading" my="s">
              {t('common:OrderedProducts')}
            </Typography>
            <OverviewTable
              orderLineAggregates={getOrderLineAggregates(orders)}
            />
          </CarrotContainer>
          <ButtonContainer
            verticalSpacer={Theme.Spacings.xs}
            horizontalSpacer={Theme.Spacings.xxs}
            paddingY={0}
            textAlign="left"
          >
            {!hasAllOrdersLeftProducer(orders) && (
              <ButtonLink
                to={{
                  pathname: `/orders/by-delivery/${pickupKey}/parcels`
                }}
                state={{ returnPath: true }}
                variant="primary"
              >
                {t('common:parcelLabelModal.labelDownload')}
              </ButtonLink>
            )}
            <ButtonAnchor
              onClick={() => {
                track(DOWNLOAD_DELIVERY_NOTES);
              }}
              target="_blank"
              rel="noopener noreferrer"
              href={`${baseUrl}/export/deliveryNote?${queryString.stringify(
                {
                  orderIds: map(orders, '_id')
                },
                { arrayFormat: 'bracket' }
              )}`}
              variant="secondary"
            >
              {t('producer:DownloadDeliveryNotes')}
            </ButtonAnchor>
            <ButtonLink
              to={{
                pathname: `/orders/by-delivery/${pickupKey}/packing-list`
              }}
              state={{ returnPath: true }}
            >
              {t('producer:ViewPackingList')}
            </ButtonLink>
          </ButtonContainer>
          <CarrotContainer mt="l" mb="m">
            <ProducerOrderGroup
              headerText={t('common:Order_other')}
              orders={orders}
            />
          </CarrotContainer>
          <ExpandableCallout expandedContent={<PackingAndLabelingInfo />}>
            {t('producer:HowToPackAndLabel')}
          </ExpandableCallout>
        </>
      )}
    </Page>
  );
};

export default OrdersByDeliveryPage;
