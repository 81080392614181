import { InputRenderProps } from './types';

type Args<SelectType extends string> = {
  optional?: boolean;
  value?: InputRenderProps<SelectType>['inputProps']['value'];
};

// For each input type, return true if the value is valid
// Checks for if the input is optional, and evaluates the input value
export const useRequiredValidation = <SelectType extends string>({
  value,
  optional
}: Args<SelectType>) => {
  if (optional) {
    return true;
  }

  switch (typeof value) {
    case 'undefined':
      return false;
    case 'string':
      return value.length > 0;
    case 'number':
      return true;
    case 'object':
      return Array.isArray(value) ? value.length > 0 : true;
    default:
      return false;
  }
};
