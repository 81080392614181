import { useEffect, useMemo, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { getProducerDeliveryRoutes } from 'api';
import REQ, { ReqType } from 'utils/REQ';
import { getDagensDeliveryDates } from 'utils/delivery';
import { useAppSelector } from '_common/hooks/reduxHooks';
import { Consumer } from 'types/Consumer';
import { SelectedDeliveryDate } from '../../../_common/components/delivery-date-picker/DeliveryDatePicker';
import AddOrderDeliveryDate from './AddOrderDeliveryDate';
import { Page } from '@components/page';

type LocationState =
  | {
      consumer: Consumer;
    }
  | undefined;

const AddOrderDeliveryDateFetcher = () => {
  const [selectedDeliveryDates, setSelectedDeliveryDates] = useState<
    SelectedDeliveryDate[]
  >([]);
  const [req, setReq] = useState<ReqType>(REQ.INIT);
  const [deliveryRoutes, setDeliveryRoutes] = useState([]);
  const location = useLocation();
  const state = location.state as LocationState;

  const { producerId } = useAppSelector(({ auth }) => {
    return {
      producerId: auth._id
    };
  });
  const consumer = useMemo(() => {
    return state?.consumer;
  }, [JSON.stringify(state)]);

  const getDeliveryRoutes = () => {
    setReq(REQ.PENDING);
    const distributionAreaId = consumer?.distributionArea?._ref;

    getProducerDeliveryRoutes({ producerId, distributionAreaId })
      .then(deliveryRoutesPayload => {
        setDeliveryRoutes(deliveryRoutesPayload);
        setReq(REQ.SUCCESS);
      })
      .catch(() => {
        setReq(REQ.ERROR);
      });
  };

  const handleDeliveryDateChange = (selected: SelectedDeliveryDate[]) => {
    const sortedSelected = [...selected].sort((a, b) => {
      return (
        new Date(a.deliveryDate).getTime() - new Date(b.deliveryDate).getTime()
      );
    });
    setSelectedDeliveryDates(sortedSelected);
  };

  useEffect(() => {
    if (consumer) getDeliveryRoutes();
  }, [JSON.stringify(consumer)]);

  if (!consumer) {
    return <Navigate to="/orders/add/start" />;
  }

  if (req !== REQ.SUCCESS) {
    return <Page.Status req={req} />;
  }

  return (
    <AddOrderDeliveryDate
      selectedConsumer={consumer}
      producerId={producerId}
      selectedDeliveryDates={selectedDeliveryDates}
      onSelectedDeliveryDateChange={handleDeliveryDateChange}
      deliveryDates={getDagensDeliveryDates(deliveryRoutes, producerId)}
      deliveryRoutes={deliveryRoutes}
      req={req}
    />
  );
};

export default AddOrderDeliveryDateFetcher;
