import { getOrder } from '../../../../api';

export const checkDeliveryRouteReady = async (
  orderId: string,
  attempt: number
) => {
  if (attempt === 10) return;

  const { data } = await getOrder(orderId);

  if (data.deliveryRoute && data.deliveryRoute.length > 0) {
    return;
  }

  await new Promise(resolve => {
    setTimeout(resolve, 1000);
  });
  await checkDeliveryRouteReady(orderId, attempt + 1);
};
