import { Link, LinkProps } from 'react-router-dom';
import styled from 'styled-components';
import Theme from '@dagensmat/carrot/Theme';
import { Typography } from '@dagensmat/carrot/Components';
import { Container } from '@dagensmat/carrot/Layout';
import { MediaQuery } from 'utils/mediaQueries';
import { getFirstConsumerEmail } from 'utils/order';
import Separator from '../../../../_common/components/utils/Separator.style';
import { Consumer } from '../../../../types/Consumer';

const LinkListElementContainer = ({ to, state, children }: LinkProps) => {
  return (
    <Link to={to} state={state}>
      <Container
        flex
        justify="flex-start"
        width="full"
        alignItems="center"
        wrap="nowrap"
        py="s"
      >
        <div style={{ width: '100%' }}>{children}</div>
        <span style={{ marginLeft: `${Theme.Spacings.xs}px`, fontSize: 20 }}>
          →
        </span>
      </Container>
      <Separator />
    </Link>
  );
};

const TableContainer = styled.div`
  display: flex;
  flexwrap: wrap;
  ${MediaQuery.mobileOnly} {
    display: block;
  }
`;

const FormatConsumerDetails = ({ consumer }: { consumer: Consumer }) => {
  const { contactPerson, emails, phone, name } = consumer;
  const email = getFirstConsumerEmail(emails);
  return (
    <TableContainer>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginRight: 24,
          width: 200
        }}
      >
        <Typography variant="paragraphBold" as="p">
          {name}
        </Typography>
        <Typography variant="paragraphSmall" color="secondary">
          {contactPerson}
        </Typography>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="paragraphSmall" color="secondary">
          {phone}
        </Typography>
        <Typography
          variant="paragraphSmall"
          color="secondary"
          style={{ wordBreak: 'break-all' }}
        >
          {email}
        </Typography>
      </div>
    </TableContainer>
  );
};

export const AddOrderConsumerListLinkElement = ({
  consumer
}: {
  consumer: Consumer;
}) => {
  return (
    <LinkListElementContainer
      to={{ pathname: '/orders/add/delivery-date' }}
      state={{ consumer }}
    >
      <FormatConsumerDetails consumer={consumer} />
    </LinkListElementContainer>
  );
};
