import { ReactNode } from 'react';
import styled from 'styled-components';
import { Container } from '@dagensmat/carrot/Layout';
import {
  BorderRadius,
  BorderWeights,
  Colours,
  Spacings
} from '@dagensmat/carrot/Theme';

const SalesModuleContainer = styled(Container)`
  border: ${Colours.lightGrey} ${BorderWeights.regular}px solid;
  border-radius: ${BorderRadius.regular}px;
  padding: ${Spacings.s}px;
  max-width: 488px;
`;

const SalesModule = ({ children }: { children: ReactNode }) => {
  return (
    <SalesModuleContainer
      flex
      direction="column"
      justify="space-between"
      flexBasis="48%"
      flexGrow={1}
    >
      {children}
    </SalesModuleContainer>
  );
};
export default SalesModule;
