import { Link } from 'react-router-dom';
import { Logo } from '../../theme/logo';
import { clsx } from 'clsx';

const style = clsx('[&:not(:empty)]:pt-xs');

/**
 * @package
 */
export const NavLogoHome = () => {
  return (
    <Link className={style} to="/">
      <Logo />
    </Link>
  );
};

/**
 * @package
 */
export const NavLogoLandingPage = () => {
  return (
    <a className={style} href="https://dagens.farm" rel="noopener noreferrer">
      <Logo />
    </a>
  );
};
