import React from 'react';
import {
  DeliveryDateContext,
  DELIVERY_DATE_CONTEXT_ISOLATED
} from '_common/context/DeliveryDateContext';
import { ProductForSale } from 'types/Product';
import { Producer } from 'types/Producer';
import ProducerView from '_consumer/components/producer/ProducerView';
import { isGuestConsumer } from 'utils/role';
import { useAppSelector } from '_common/hooks/reduxHooks';
import ProducerCallout from '_consumer/components/producer/ProducerCallout';
import { Page } from '@components/page';

type ProducerPageProps = {
  products: ProductForSale[];
  producer: Producer;
};

const ProducerPage = ({ products, producer }: ProducerPageProps) => {
  const context = React.useMemo(() => {
    return { pageContext: DELIVERY_DATE_CONTEXT_ISOLATED };
  }, []);

  const { isGuest, isInSameDA, roleType, userId } = useAppSelector(
    ({ auth: { _id, authenticated, distributionAreaName, _type } }) => {
      return {
        isGuest: isGuestConsumer(_id) || !authenticated,
        isInSameDA:
          distributionAreaName &&
          producer.distributionAreas?.includes(distributionAreaName),
        roleType: _type,
        userId: _id
      };
    }
  );

  return (
    <DeliveryDateContext.Provider value={context}>
      <Page>
        <ProducerCallout userId={userId} producer={producer} />
        <ProducerView
          producer={producer}
          products={products}
          roleType={roleType}
          isGuest={isGuest}
          isInSameDA={Boolean(isInSameDA)}
        />
      </Page>
    </DeliveryDateContext.Provider>
  );
};

export default ProducerPage;
