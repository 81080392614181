import styled from 'styled-components';
import Theme from '@dagensmat/carrot/Theme';

const getIllustrationFromInitial = (
  productName: string | undefined
): string => {
  if (!productName) return 'none';

  let illustration: string;
  if (/^[a-g]/i.test(productName)) {
    illustration = '/assets/Illu_1.svg';
  } else if (/^[h-n]/i.test(productName)) {
    illustration = '/assets/Illu_2.svg';
  } else if (/^[o-t]/i.test(productName)) {
    illustration = '/assets/Illu_3.svg';
  } else {
    illustration = '/assets/Illu_4.svg';
  }

  return `url(${illustration})`;
};

const CardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border: 2px solid ${Theme.Colours.lightGrey};
  border-radius: 5px;
  cursor: pointer;
  overflow-x: auto;

  top: 0px;
  transition: border ${Theme.Animations.regular};

  :hover {
    border-color: ${Theme.Colours.lightGrey};
  }

  :active {
    border-color: ${Theme.Colours.black};
  }
`;

export const RegularCardContainer = styled(CardContainer)`
  height: 500px;
`;

export const SmallCardContainer = styled(CardContainer)`
  height: 240px;
  width: 184px;

  margin-right: ${Theme.Spacings.xxs}px;
`;

const ImageContainer = styled.div<{
  name?: string;
  imageUrl?: string | null;
  roundedCorners?: string;
}>`
  background-image: ${({ imageUrl, name }) => {
    return imageUrl ? `url(${imageUrl});` : getIllustrationFromInitial(name);
  }};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  ${({ roundedCorners = 'top' }) => {
    const orientation = ['top', 'bottom'].includes(roundedCorners)
      ? roundedCorners
      : 'top';
    return `border-${orientation}-right-radius: ${Theme.BorderRadius.small}px;
  border-${orientation}-left-radius: ${Theme.BorderRadius.small}px;`;
  }}
`;

export const ModalImageContainer = styled(ImageContainer)`
  display: block;
  width: 100%;
  max-width: 720px;
  height: 100vw;
  max-height: 640px;
  cursor: pointer;
`;

export const CardImageContainer = styled(ImageContainer)`
  flex-grow: 2;
  width: 100%;
  height: auto;
`;

export const Content = styled.div`
  padding: ${Theme.Spacings.xs + Theme.Spacings.xxs}px;
`;

export const SmallCardContent = styled.div`
  padding: ${Theme.Spacings.xxs}px;
  > p {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const LikeButtonContainer = styled.div`
  position: absolute;
  right: 0;
`;
