import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Spacings } from '@dagensmat/carrot/Theme';
import PageHeader from '@components/page-header/PageHeader';
import Tabs from '_common/components/tabs/Tabs';
import { ButtonLink } from '_common/components/button/Button.style';
import Icon from '_common/components/utils/Icon';
import { Page } from '@components/page';
import { CommonNavbar } from '@components/navbar';

type Props = PropsWithChildren<{
  numUnopenedOrders?: number;
}>;

const OrdersPageHOC = ({ numUnopenedOrders = 0, children }: Props) => {
  const { t } = useTranslation();
  return (
    <Page header={<CommonNavbar showLogo />}>
      <PageHeader
        headerText={t('common:MyOrders')}
        subTitle={t('common:newOrders', {
          count: numUnopenedOrders
        })}
      />
      <ButtonLink to="/orders/add/start" mb={Spacings.m} variant="primary">
        <Icon icon="add" mr={Spacings.xs} />
        {t('common:MakeNewOrder')}
      </ButtonLink>
      <Tabs
        tabs={[
          {
            text: t('common:Product_other'),
            to: '/orders/overview'
          },
          { text: t('common:Delivery_other'), to: '/orders' },
          { text: t('producer:Invoicing'), to: '/orders/invoice' }
        ]}
      />
      {children}
    </Page>
  );
};

export default OrdersPageHOC;
