import { useAppSelector } from '../../_common/hooks/reduxHooks';
import { ConsumerNavbar, ConsumerNavTabs } from '@components/navbar/consumer';
import { ProducerNavbar, ProducerNavTabs } from '@components/navbar/producer';
import { AuthNavbar } from '@components/navbar/auth';
import { UnauthNavbar } from '@components/navbar/unauth';

type Props = {
  showLogo?: boolean;
  showActionsOnMobile?: boolean;
  contextName?: string;
};

/**
 * @package
 */
export const CommonNavbar = ({
  showLogo,
  showActionsOnMobile,
  contextName
}: Props) => {
  const { _type, authenticated } = useAppSelector(
    ({ auth: { _type, authenticated } }) => ({ _type, authenticated })
  );

  if (_type === 'consumers') {
    return (
      <ConsumerNavbar
        showLogo={showLogo ?? false}
        showActionsOnMobile={showActionsOnMobile ?? false}
        contextName={contextName}
      />
    );
  }

  if (_type === 'producers') {
    return (
      <ProducerNavbar
        showLogo={showLogo ?? false}
        showActionsOnMobile={showActionsOnMobile ?? false}
        contextName={contextName}
      />
    );
  }

  if (authenticated) {
    return <AuthNavbar />;
  }

  return <UnauthNavbar />;
};

/**
 * @package
 */
export const CommonNavTabs = () => {
  const { role } = useAppSelector(({ auth: { _type: role } }) => ({ role }));

  if (role === 'consumers') {
    return <ConsumerNavTabs />;
  }
  if (role === 'producers') {
    return <ProducerNavTabs />;
  }
  return null;
};
