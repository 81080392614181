import { Callout, Typography } from '@dagensmat/carrot/Components';
import { SpacingNames } from '@dagensmat/carrot/Theme/Spacings';

type OnboardingMessageProps = {
  message: string;
  showStep: boolean;
  mt?: SpacingNames;
  mb?: SpacingNames;
};

const InContextOnboardingMessage = ({
  message,
  showStep,
  mt,
  mb = 's'
}: OnboardingMessageProps) => {
  if (!showStep) {
    return null;
  }

  return (
    <Callout colour="noteworthy" mb={mb} mt={mt}>
      <Typography variant="paragraph">{message}</Typography>
    </Callout>
  );
};
export default InContextOnboardingMessage;
