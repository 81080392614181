const heartbeatKeyframes = {
  '0%, 80%': {
    opacity: '1'
  },
  '0%, 60%': {
    transform: 'scale(1)'
  },
  '30%': {
    transform: 'scale(1.3)'
  },
  '100%': {
    opacity: '0'
  }
};

const heartbeat = 'heartbeatKeyframes ease-in-out 0.5s;';

/**
 * @package
 */
export const themeKeyframes = {
  heartbeatKeyframes
} as const;

/**
 * @package
 */
export const themeAnimations = {
  heartbeat
} as const;

/**
 * @package
 */
export const themeAnimationSafelist = [
  {
    pattern: /animate-.+/
  }
];

export type ThemeAnimation = keyof typeof themeAnimations;
