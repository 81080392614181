import {
  buttonStyle,
  ButtonStyles
} from '@carrot/style-components/button/styles';
import { PropsWithChildren, Ref } from 'react';
import { Link, LinkProps as ReactRouterLinkProps } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

type LinkProps = Omit<ReactRouterLinkProps, 'children'>;

export type LinkComponentProps = PropsWithChildren<
  LinkProps & {
    as: 'link';
    linkRef?: Ref<HTMLAnchorElement>;
    styles: ButtonStyles;
  }
>;

export const LinkComponent = ({
  linkRef,
  styles,
  className,
  children,
  ...props
}: LinkComponentProps) => {
  return (
    <Link
      ref={linkRef}
      className={twMerge(buttonStyle(styles), className)}
      {...props}
    >
      {children}
    </Link>
  );
};
