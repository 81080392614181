import React from 'react';
import { useTranslation } from 'react-i18next';
import Theme from '@dagensmat/carrot/Theme';
import { Container } from '@dagensmat/carrot/Layout';
import { parseDate } from 'utils/date/format';
import {
  getAlwaysInSeason,
  isAlwaysInSeason,
  validatePeriod,
  updateCalendar,
  addToSeasonCalendar,
  formatPeriod
} from 'utils/season';
import SingleProducerSeason from '_common/components/season/SingleProductSeason';
import Button from '_common/components/button/Button.style';
import SelectedButton from '_common/components/button/SelectedButton';
import DateRangePickerWithButtons from '_common/components/date-range-picker/DateRangePickerWithButtons';
import FormLabel from '_common/components/input/FormLabel';
import FormValidationMessage from '_common/components/input/FormValidationMessage';
import useWindowWidth from '_common/hooks/useWindowWidth';
import { initialRange } from '_common/components/date-range-picker/DateRangePicker';
import { numberOfMonths } from 'utils/dayPicker';
import {
  AlwaysInSeason,
  isSeasonPeriod,
  Period,
  SeasonCalendar
} from 'types/Product';
import {
  periodToRangeModifier,
  rangeModifierToPeriod
} from 'utils/date/convert';

const buttonMargins = { mr: Theme.Spacings.xxs, mb: Theme.Spacings.xs };

type EmptySeasonCalendarButtonProps = {
  seasonCalendar: SeasonCalendar;
  onChange: (val: AlwaysInSeason[]) => void;
};

const EmptySeasonCalendarButton = ({
  seasonCalendar = [],
  onChange
}: EmptySeasonCalendarButtonProps) => {
  const { t } = useTranslation();

  if (seasonCalendar.length !== 0) {
    return null;
  }

  return (
    <Button
      {...buttonMargins}
      onClick={() => {
        onChange(getAlwaysInSeason());
      }}
    >
      {t('producer:productSeasonCalendarButton1')}
    </Button>
  );
};

type AlwaysAvailableButtonProps = {
  seasonCalendar: SeasonCalendar;
  onChange: (val: SeasonCalendar) => void;
};

const AlwaysAvailableButton = ({
  seasonCalendar = [],
  onChange
}: AlwaysAvailableButtonProps) => {
  const { t } = useTranslation();

  if (!isAlwaysInSeason(seasonCalendar)) {
    return null;
  }

  return (
    <SelectedButton
      {...buttonMargins}
      onClick={() => {
        return onChange([]);
      }}
    >
      {t('producer:productSeasonCalendarButton1')}
    </SelectedButton>
  );
};

type SeasonPeriodButtonsProps = {
  seasonCalendar: SeasonCalendar;
  selectedIndex?: number;
  setSelectedIndex: (val: number | undefined) => void;
};

const SeasonPeriodButtons = ({
  seasonCalendar = [],
  selectedIndex,
  setSelectedIndex
}: SeasonPeriodButtonsProps) => {
  const { t } = useTranslation();
  if (isAlwaysInSeason(seasonCalendar)) {
    return null;
  }

  return (
    <>
      {seasonCalendar.map((period, i) => {
        return (
          <Button
            key={period._key}
            {...buttonMargins}
            variant={selectedIndex === i ? 'secondary' : 'ghost'}
            onClick={() => {
              return selectedIndex === i
                ? setSelectedIndex(undefined)
                : setSelectedIndex(i);
            }}
          >
            {formatPeriod(period)}
          </Button>
        );
      })}

      <Button
        {...buttonMargins}
        variant={selectedIndex === -1 ? 'secondary' : 'ghost'}
        onClick={() => {
          return selectedIndex === -1
            ? setSelectedIndex(undefined)
            : setSelectedIndex(-1);
        }}
      >
        {t('producer:productSeasonCalendarAddPeriod')}
      </Button>
    </>
  );
};

type AddOrEditPeriodProps = {
  selectedIndex?: number;
  seasonCalendar: SeasonCalendar;
  onSave: (val: Partial<Period>) => void;
};

const AddOrEditPeriod = ({
  selectedIndex,
  seasonCalendar = [],
  onSave
}: AddOrEditPeriodProps) => {
  const { t } = useTranslation();
  const [dates, setDates] = React.useState<
    Period | Partial<Period> | undefined
  >();

  const windowWidth = useWindowWidth();

  React.useEffect(() => {
    if (selectedIndex === undefined) {
      setDates(undefined);
      return;
    }

    const selected = seasonCalendar[selectedIndex];
    if (selected && isSeasonPeriod(selected)) {
      setDates({
        from: parseDate(selected.from),
        to: parseDate(selected.to)
      });
    } else {
      setDates({ ...initialRange });
    }
  }, [selectedIndex]);

  if (selectedIndex === undefined || selectedIndex < -1) {
    return null;
  }

  return (
    <Container my="xl">
      <DateRangePickerWithButtons
        key={selectedIndex}
        saveButtonText={
          selectedIndex === -1
            ? t('producer:productSeasonCalendarSaveButton1', {
                period: formatPeriod(dates)
              })
            : t('producer:productSeasonCalendarSaveButton2', {
                period: formatPeriod(dates)
              })
        }
        resetButtonText={
          selectedIndex === -1
            ? t('common:reset')
            : t('producer:productSeasonCalendarResetButton2')
        }
        onSave={newDates => {
          setDates(initialRange);
          onSave(rangeModifierToPeriod(newDates));
        }}
        value={periodToRangeModifier(dates)}
        onChange={range => {
          setDates(rangeModifierToPeriod(range));
        }}
        monthsToShow={numberOfMonths({ width: windowWidth ?? 0 })}
      />
    </Container>
  );
};

type ProductSeasonInputProps = {
  onChange: (val: SeasonCalendar) => void;
  seasonCalendar: SeasonCalendar;
};

const ProductSeasonInput = ({
  onChange,
  seasonCalendar = []
}: ProductSeasonInputProps) => {
  const [selectedIndex, setSelectedIndex] = React.useState<
    number | undefined
  >();

  const savePeriod = (period: Partial<Period>) => {
    if (
      selectedIndex !== undefined &&
      selectedIndex > -1 &&
      validatePeriod(period)
    ) {
      onChange(updateCalendar(seasonCalendar, period, selectedIndex));
    }

    if (
      selectedIndex !== undefined &&
      selectedIndex > -1 &&
      !validatePeriod(period)
    ) {
      onChange([
        ...seasonCalendar.slice(0, selectedIndex),
        ...seasonCalendar.slice(selectedIndex + 1)
      ]);
    }

    if (selectedIndex === -1 && validatePeriod(period)) {
      onChange(addToSeasonCalendar(seasonCalendar, period));
    }

    setSelectedIndex(undefined);
  };
  const { t } = useTranslation();
  return (
    <>
      <FormLabel labelText={t('producer:productSeasonCalendarLabel')} />
      <div>
        <EmptySeasonCalendarButton
          seasonCalendar={seasonCalendar}
          onChange={onChange}
        />
        <AlwaysAvailableButton
          seasonCalendar={seasonCalendar}
          onChange={onChange}
        />
        <SeasonPeriodButtons
          seasonCalendar={seasonCalendar}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
        />
      </div>
      {seasonCalendar.length === 0 && (
        <Container mb="xs">
          <FormValidationMessage
            message={t('producer:SeasonCalendarEmptyValidationMessage')}
          />
        </Container>
      )}
      <AddOrEditPeriod
        onSave={savePeriod}
        seasonCalendar={seasonCalendar}
        selectedIndex={selectedIndex}
      />
      <SingleProducerSeason seasonCalendar={seasonCalendar} />
    </>
  );
};

export default ProductSeasonInput;
