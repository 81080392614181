import { useTranslation } from 'react-i18next';
import { Navbar } from '@components/navbar/navbar';
import { Nav } from '@carrot/style-components/navbar';
import { NavMenuLogout, NavMenuSecondaryItems } from '@components/navbar/menu';

/**
 * @package
 */
export const AuthNavbar = () => {
  const { t } = useTranslation();
  return (
    <Navbar
      showLogo
      showActionsOnMobile
      actions={
        <Nav.Menu heading={t('common:Menu')}>
          <NavMenuSecondaryItems />
          <Nav.MenuDivider />
          <NavMenuLogout />
        </Nav.Menu>
      }
    />
  );
};
