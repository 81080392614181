import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Theme from '@dagensmat/carrot/Theme';
import Input from '_common/components/input/Input.style';
import Button from '_common/components/button/Button.style';

const MessageInputContainer = styled.div`
  display: flex;
  background: ${Theme.Colours.white};

  padding: ${Theme.Spacings.xs}px 0;
  margin: auto;
`;

const InputField = styled(Input)`
  width: 100%;
  margin: 0;
  margin-right: 5px;
`;

const MessageInput = ({ submit }: { submit: (val: string) => void }) => {
  const { t } = useTranslation();
  const [message, setMessage] = React.useState('');

  const sendMessage = () => {
    if (message.length < 1) return;

    submit(message);
    setMessage('');
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      sendMessage();
    }
  };

  return (
    <MessageInputContainer>
      <InputField
        onKeyPress={handleKeyPress}
        value={message}
        placeholder={t('common:writeMessage')}
        onChange={({ target: { value } }) => {
          return setMessage(value);
        }}
      />
      <Button onClick={sendMessage} variant="secondary">
        {t('common:send')}
      </Button>
    </MessageInputContainer>
  );
};

export default MessageInput;
