import { useMemo, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container as CarrotContainer } from '@dagensmat/carrot/Layout';
import { findRightConsumerPrice } from 'utils/pricing';
import Button, { ButtonLink } from '_common/components/button/Button.style';
import PageHeader from '@components/page-header/PageHeader';
import { formatDate } from 'utils/date/format';
import { useAppSelector } from '_common/hooks/reduxHooks';
import { Consumer } from 'types/Consumer';
import { SelectedDeliveryDate } from '../../../_common/components/delivery-date-picker/DeliveryDatePicker';
import { ProductList, ProductWithUnit } from '../add-orderline/ProductList';
import { Page } from '@components/page';
import { BottomActions } from '@components/bottom-actions';
import Input from '../../../_common/components/input/Input.style';
import { CommonNavbar } from '../../../components/navbar';
import { Box } from '../../../carrot/layout/box';

type LocationState =
  | {
      consumer: Consumer;
      deliveryDates: SelectedDeliveryDate[];
      products: ProductWithUnit[];
    }
  | undefined;

const AddOrderProducts = () => {
  const location = useLocation();
  const state = location.state as LocationState;
  const selectedConsumer = state?.consumer;

  const { t } = useTranslation();
  const [selectedProducts, setSelectedProducts] = useState<ProductWithUnit[]>(
    state?.products ?? []
  );
  const [filterString, setFilterString] = useState('');
  const selectedDeliveryDates = state?.deliveryDates ?? [];

  const { products } = useAppSelector(({ producerProducts: { items } }) => {
    return {
      products: items
    };
  });

  if (!selectedConsumer || selectedDeliveryDates.length === 0) {
    return <Navigate to="/orders/add/start" />;
  }

  const validSelectedProducts = useMemo(() => {
    return selectedProducts.filter(({ units }) => {
      return typeof units === 'number' && units > 0;
    });
  }, [selectedProducts]);

  const handleProductsChange = (selected: ProductWithUnit[]) => {
    setSelectedProducts(selected);
  };

  const filteredProducts = products
    .filter(({ prices = [] }) => {
      return !!findRightConsumerPrice(prices, state.consumer._id);
    })
    .filter(({ name, type = '' }) => {
      return (
        !filterString ||
        name.toLowerCase().includes(filterString.toLowerCase()) ||
        type.toLowerCase().includes(filterString.toLowerCase())
      );
    });

  const handleProductChange = (productId: string, newUnits: number) => {
    const newProducts = [...selectedProducts];
    const index = newProducts.findIndex(product => {
      return product.productId === productId;
    });
    if (index === -1) {
      newProducts.push({
        productId,
        units: newUnits
      });
    } else {
      newProducts[index] = {
        productId,
        units: newUnits
      };
    }
    handleProductsChange(newProducts);
  };

  return (
    <Page
      header={
        <>
          <CommonNavbar />
          <Box.FullWidth>
            <PageHeader
              headerText={t('producer:ChooseProducts')}
              subTitle={`${t('common:to')} ${selectedConsumer.name}, ${
                selectedDeliveryDates.length > 1
                  ? `${selectedDeliveryDates.length} ${t('common:order', {
                      count: selectedDeliveryDates.length
                    })}`
                  : formatDate(selectedDeliveryDates[0].deliveryDate)
              }`}
              annotation={`${t('common:MakeNewOrder')} (3/4)`}
            />
            <Input
              placeholder={t('producer:SearchInProducts')}
              value={filterString}
              onChange={({ target: { value } }) => {
                return setFilterString(value);
              }}
            />
          </Box.FullWidth>
        </>
      }
      bottom={
        <BottomActions border>
          <ButtonLink
            to="/orders/add/delivery-date"
            state={{ consumer: selectedConsumer }}
          >
            {t('common:BackWithArrow')}
          </ButtonLink>
          {validSelectedProducts.length === 0 ? (
            <Button disabled>{t('common:ToCheckoutWithArrow')}</Button>
          ) : (
            <ButtonLink
              to="/orders/add/checkout"
              variant="primary"
              state={{
                consumer: selectedConsumer,
                deliveryDates: selectedDeliveryDates,
                products: validSelectedProducts
              }}
            >
              {t('common:ToCheckoutWithArrow')}
            </ButtonLink>
          )}
        </BottomActions>
      }
    >
      <CarrotContainer mb="xl">
        <ProductList
          items={filteredProducts}
          productsWithUnit={selectedProducts}
          onPick={handleProductChange}
          consumerId={selectedConsumer._id}
        />
      </CarrotContainer>
    </Page>
  );
};
export default AddOrderProducts;
