import { dinero, toSnapshot } from 'dinero.js';
import { Typography } from '@dagensmat/carrot/Components';
import { Container } from '@dagensmat/carrot/Layout';
import { useTranslation } from 'react-i18next';
import { fmtMoney } from 'utils/texts';
import { Money, zeroDinero } from 'utils/money';

type DeliveryLineProps = {
  deliveryFee: Money;
  underThreshold: boolean;
};

const DeliveryLine = ({ deliveryFee, underThreshold }: DeliveryLineProps) => {
  const { t } = useTranslation();
  const constructedDeliveryFee = dinero(deliveryFee);

  return (
    <Container flex mt="xxs" justify="space-between">
      <Typography variant="paragraphSmall">
        {t('consumer:DagensDeliveryFee')}
      </Typography>
      <Typography variant="paragraphSmall">
        {underThreshold
          ? fmtMoney(constructedDeliveryFee)
          : fmtMoney(zeroDinero(toSnapshot(constructedDeliveryFee).currency))}
      </Typography>
    </Container>
  );
};

export default DeliveryLine;
