import {
  tailwindTheme,
  tailwindPlugins,
  tailwindSafelist
} from './src/carrot/theme';
import plugin from 'tailwindcss/plugin';
import { Config } from 'tailwindcss/types/config';
import formsPlugin from '@tailwindcss/forms';

export default {
  content: [
    '.storybook/**/*.{html,js,jsx,ts,tsx}',
    './index.html',
    './src/**/*.{html,js,jsx,ts,tsx}'
  ],
  theme: tailwindTheme,
  safelist: tailwindSafelist,
  plugins: [
    ...tailwindPlugins,
    formsPlugin({
      strategy: 'class'
    }),
    plugin(({ addVariant }) => {
      addVariant('media-hover', '@media (hover: hover)');
    })
  ]
} satisfies Config;
