import {
  format,
  getISOWeek,
  getDayOfYear,
  startOfYear,
  getMonth,
  getYear,
  endOfYear,
  isBefore,
  isAfter,
  differenceInCalendarDays,
  isToday as dateFnsIsToday,
  parseISO,
  min,
  max,
  add,
  isSameWeek,
  isThisYear,
  startOfWeek,
  endOfWeek
} from 'date-fns';
import { nb } from 'date-fns/locale/nb';
import { enUS } from 'date-fns/locale/en-US';
import { da } from 'date-fns/locale/da';
import { AvailableLanguages } from '@dagensmat/core';
import i18n from 'i18n';
import { MonthNumber } from 'types/Utils';

const lng = { nb, en: enUS, da };

type DateLike = string | Date | undefined;
type ParsedDate<T extends DateLike> = T extends undefined ? undefined : Date;

export const parseDate = <T extends DateLike>(date: T): ParsedDate<T> => {
  return (typeof date === 'string' ? parseISO(date) : date) as ParsedDate<T>;
};

export const isBeforeMonthIndex = (index: MonthNumber) => {
  return index < getMonth(new Date());
};

export const getStartOfYear = (date: string | Date) => {
  return startOfYear(parseDate(date));
};

export const getEndOfYear = (date: string | Date) => {
  return endOfYear(parseDate(date));
};

export const minCurrentYear = (date: string | Date) => {
  return max([parseDate(date), getStartOfYear(new Date())]);
};

export const isBeforeThisYear = (date: string | Date) => {
  return getYear(parseDate(date)) < getYear(new Date());
};

export const isAfterThisYear = (date: string | Date) => {
  return getYear(parseDate(date)) > getYear(new Date());
};

export const maxCurrentYear = (date: string | Date) => {
  return min([parseDate(date), getEndOfYear(new Date())]);
};

export const isToday = (date: string | Date) => {
  return dateFnsIsToday(parseDate(date));
};

export const isCurrentYear = (date: string | Date) => {
  return isThisYear(parseDate(date));
};

export const isTodayOrBefore = (date: string | Date) => {
  return isToday(date) || isBefore(parseDate(date), new Date());
};

export const isTodayOrAfter = (date: string | Date) => {
  return isToday(date) || isAfter(parseDate(date), new Date());
};

export const daysUntil = (date: string | Date) => {
  return differenceInCalendarDays(parseDate(date), new Date());
};

export const dayOfYear = (date: string | Date) => {
  return getDayOfYear(parseDate(date));
};

export const getYearProgressPercentage = () => {
  return (getDayOfYear(Date.now()) / 365) * 100;
};

export const getFirstDayOfWeek = (date: string | Date) => {
  return startOfWeek(parseDate(date), { weekStartsOn: 1 });
};

export const getLastDayOfWeek = (date: string | Date) => {
  return endOfWeek(parseDate(date), { weekStartsOn: 1 });
};

export const formatDate = (date: DateLike, pattern = 'iiii d. MMMM') => {
  if (!date) {
    return '';
  }
  return format(parseDate(date), pattern, {
    locale: lng[i18n.language as AvailableLanguages]
  });
};

export const formatDateWithWeek = (date: DateLike) => {
  if (!date) {
    return '';
  }

  return `${i18n.t('calendar:Week')} ${getISOWeek(
    parseDate(date)
  )} - ${formatDate(date)}`;
};

export const formatDateWithTime = (date: string | Date) => {
  return formatDate(date, 'iiii d. MMMM HH:mm');
};

export const slugifyDate = (date: DateLike) => {
  return formatDate(date, 'yyyy-MM-dd');
};

export const getDay = (date: string | Date) => {
  return formatDate(date, 'iiii');
};

export const getDate = (date: string | Date) => {
  return formatDate(date, 'd. MMMM');
};

export const getDateShort = (date: string | Date) => {
  return formatDate(date, 'd. MMM');
};

export const formatDateWithLetters = (date: string | Date) => {
  return formatDate(date, 'MMMM do');
};

export const isWeek = (date: string | Date, weeksFromNow = 0) => {
  const currentDate = add(parseDate(new Date()), { weeks: weeksFromNow });
  return isSameWeek(parseDate(date), currentDate, { weekStartsOn: 1 });
};

export const getLocaleFromLanguage = (language: AvailableLanguages) => {
  switch (language) {
    case AvailableLanguages.NORWEGIAN:
      return lng.nb;
    case AvailableLanguages.DANISH:
      return lng.da;
    case AvailableLanguages.ENGLISH:
      return lng.en;
    default:
      return lng.en;
  }
};
