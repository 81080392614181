import { clsx } from 'clsx';
import { PropsWithChildren } from 'react';

const style = clsx('flex', 'flex-col', 'gap-xs');

type Props = PropsWithChildren;

export const ModalActions = ({ children }: Props) => {
  return <div className={style}>{children}</div>;
};
