import { clsx } from 'clsx';
import { actionIcons } from './action-icons';
import { dagensShapesIcons } from './dagens-shapes-icons';
import { informationIcons } from './information-icons';
import { navigationIcons } from './navigation-icons';
import { IconDefinition, IconSize } from './types';
import { ThemeAnimation } from '@carrot/theme/animations';
import { ThemeColor, themeColors } from '../colors';

const style = clsx(
  'relative',
  'inline-flex',
  'items-end',
  'justify-center',
  'align-bottom',
  'box-content',
  'pointer-events-none'
);

const iconStyle = clsx(
  'absolute',
  'top-auto',
  'left-auto',
  'bottom-0',
  'right-auto'
);

const smallStyle = clsx('h-s', 'w-s');
const mediumStyle = clsx('h-m', 'w-m');
const largeStyle = clsx('h-l', 'w-l');

const animationStyle = (animation?: ThemeAnimation) => {
  return animation && `animate-${animation}`;
};

/**
 * @package
 */
export const iconTypes = {
  ...navigationIcons,
  ...actionIcons,
  ...dagensShapesIcons,
  ...informationIcons
} as const;

export type IconType = keyof typeof iconTypes;

type Props = {
  className?: string;
  animation?: ThemeAnimation;
  icon: IconType;
  size?: IconSize;
  color?: ThemeColor;
};

export const Icon = ({
  animation,
  className,
  icon,
  size = 'medium',
  color
}: Props) => {
  const iconType = iconTypes[icon] as IconDefinition;
  const IconSVG = size in iconType ? iconType[size] : null;
  if (!IconSVG) {
    return null;
  }
  const sizeStyle =
    size === 'small' ? smallStyle : size === 'large' ? largeStyle : mediumStyle;
  return (
    <div className={clsx(style, sizeStyle, className)}>
      <IconSVG
        color={color && themeColors[color]}
        className={clsx(iconStyle, animationStyle(animation))}
      />
    </div>
  );
};
