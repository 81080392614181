import React from 'react';
import { UnstyledButton } from '_common/components/button/Button.style';
import Icon from '_common/components/utils/Icon';

type CloseButtonProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
} & React.HTMLAttributes<HTMLButtonElement>;

const CloseButton = ({ onClick, ...rest }: CloseButtonProps) => {
  return (
    <UnstyledButton onClick={onClick} {...rest}>
      <Icon icon="close" $size="m" />
    </UnstyledButton>
  );
};

export default CloseButton;
