import { FC, useContext, useRef, Context } from 'react';
import { DefaultTheme, ThemeContext } from 'styled-components';
import { FormFieldInputProps } from 'carrot/form-field/types';
import theme from 'theme';
import { NumberInputContainer } from './container';
import { FloatInput } from './float';
import { IntegerInput } from './integer';

type Props = FormFieldInputProps & {
  value?: string | number;
  onChange: (value: number | string) => void;
  decimal?: boolean;
  disabled?: boolean;
  placeholder?: string;
  unit?: string | null;
  id?: string;
  width?: number;
};

export const NumberInput: FC<Props> = ({
  value,
  onChange,
  decimal = false,
  disabled = false,
  placeholder = '#',
  unit,
  id,
  width = 110,
  optional,
  showError,
  invalid
}) => {
  const inputEl = useRef<HTMLInputElement>(null);
  const themeContext = useContext(
    ThemeContext as Context<DefaultTheme & typeof theme>
  );

  const Input = decimal ? FloatInput : IntegerInput;
  return (
    <NumberInputContainer
      disabled={disabled}
      hasUnit={Boolean(unit)}
      onClick={e => {
        e.stopPropagation();
        inputEl.current?.focus();
      }}
      showError={invalid && showError}
      width={width}
    >
      <Input
        id={id}
        value={value}
        onChange={onChange}
        disabled={disabled}
        ref={inputEl}
        placeholder={placeholder}
        style={{ textAlign: 'right' }}
        optional={optional}
      />
      {unit && (
        <span
          role="presentation"
          style={{
            fontWeight: themeContext.typography.fontWeight.medium,
            color: themeContext.palette.secondary
          }}
        >
          {unit}
        </span>
      )}
    </NumberInputContainer>
  );
};

export type NumberInputProps = Props;
