import { Link, LinkProps } from 'react-router-dom';
import styled from 'styled-components';
import Theme from '@dagensmat/carrot/Theme';
import { Typography } from '@dagensmat/carrot/Components';
import Separator from '_common/components/utils/Separator.style';
import { MediaQuery } from 'utils/mediaQueries';

const ListElementContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${Theme.Spacings.s}px 0;

  :hover {
    background-color: ${Theme.Colours.brightPurple};
  }

  ${MediaQuery.tabletUp} {
    padding-left: ${Theme.Spacings.s}px;
    padding-right: ${Theme.Spacings.s}px;
  }
`;

const FlexReverseWrapper = styled.div`
  display: flex;

  ${MediaQuery.mobileOnly} {
    flex-direction: column-reverse;
  }
`;

const TagsWrapper = styled.div`
  ${MediaQuery.mobileOnly} {
    > div {
      margin-bottom: ${Theme.Spacings.xxs}px;
    }
  }
`;

const ArrowContainer = styled.span`
  margin-left: ${Theme.Spacings.xs}px;
  font-size: 20px;
`;

type LinkListElementProps = LinkProps & {
  header?: string;
  subTitle: string;
  onClick?: () => void;
  children?: React.ReactNode;
};

const LinkListElement = ({
  to,
  state,
  header,
  subTitle,
  onClick,
  children
}: LinkListElementProps) => {
  return (
    <Link to={to} state={state} onClick={onClick}>
      <ListElementContainer>
        <div>
          <FlexReverseWrapper>
            <Typography variant="paragraph" as="span" mr="xs">
              {header}
            </Typography>
            <TagsWrapper>{children}</TagsWrapper>
          </FlexReverseWrapper>
          <Typography variant="paragraphSmall" color="secondary">
            {subTitle}
            &nbsp;
          </Typography>
        </div>
        <ArrowContainer>→</ArrowContainer>
      </ListElementContainer>
      <Separator />
    </Link>
  );
};

export default LinkListElement;
