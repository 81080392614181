import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Theme from '@dagensmat/carrot/Theme';
import { Typography } from '@dagensmat/carrot/Components';
import { Container } from '@dagensmat/carrot/Layout';
import {
  isAlwaysInSeason,
  isValidThisYear,
  calculatePeriodPosition
} from 'utils/season';
import { SeasonCalendar } from 'types/Product';
import CalendarMonths from './CalendarMonths';

const CalendarContainer = styled.div`
  position: relative;
  width: 100%;
  height: ${Theme.Spacings.m}px;
`;

const InSeasonBlock = styled.div<{ width: string; left: string }>`
  position: absolute;
  left: ${({ left }) => {
    return left;
  }};
  width: ${({ width }) => {
    return width;
  }};
  height: inherit;
  background-color: ${Theme.Colours.availability};
  border-radius: ${Theme.BorderRadius.regular}px;
`;

type SingleProductSeasonProps = {
  seasonCalendar: SeasonCalendar;
};

const SingleProductSeason = ({
  seasonCalendar = []
}: SingleProductSeasonProps) => {
  const { t } = useTranslation();
  return (
    <Container mt="s">
      <Typography variant="paragraphSmall" mb="s">
        {t('producer:productSeasonCalendarYear')}
      </Typography>
      <CalendarContainer>
        {isAlwaysInSeason(seasonCalendar) ? (
          <InSeasonBlock left="0%" width="100%" />
        ) : (
          seasonCalendar.filter(isValidThisYear).map(period => {
            const { left, width } = calculatePeriodPosition(period);
            return (
              <InSeasonBlock key={period._key} left={left} width={width} />
            );
          })
        )}
        <CalendarMonths />
      </CalendarContainer>
    </Container>
  );
};

export default SingleProductSeason;
