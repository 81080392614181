import { Button } from '@carrot/style-components/button';
import { Icon } from '@carrot/theme/icon';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@carrot/utils/media-query';
import { useNavigate } from 'react-router-dom';

/**
 * @package
 */
export const AddCustomerButtonLink = () => {
  const { t } = useTranslation();
  const { desktopUp } = useMediaQuery();
  const navigate = useNavigate();

  if (desktopUp) {
    return (
      <Button
        as="link"
        styles={{
          size: 'regular',
          variant: 'borderless'
        }}
        to={'/customers/add/start'}
      >
        <Icon icon="add" /> {t('producer:AddCustomer.headerAnnotation')}
      </Button>
    );
  }

  return (
    <Button
      styles={{
        size: 'regular',
        variant: 'borderless'
      }}
      onClick={() => {
        navigate('/customers/add/start');
      }}
    >
      <Icon icon="add" />
    </Button>
  );
};
