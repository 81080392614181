/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type CheckoutState = {
  messages?: Record<string, string>;
};

const checkoutInitialState: CheckoutState = {
  messages: {}
};

const checkoutSlice = createSlice({
  name: 'checkout',
  initialState: checkoutInitialState,
  reducers: {
    updateMessage(
      state,
      action: PayloadAction<{ messageKey: string; value: string }>
    ) {
      const { messageKey, value } = action.payload;
      if (state.messages) {
        state.messages[messageKey] = value;
      }
    },
    clearCheckout() {
      return checkoutInitialState;
    }
  }
});

export const { updateMessage, clearCheckout } = checkoutSlice.actions;
export default checkoutSlice.reducer;
