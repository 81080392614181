import 'react-day-picker/dist/style.css';
import { addToRange, DateRange } from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import { AvailableLanguages } from '@dagensmat/core';
import { Label } from 'utils/fonts';
import { getLocaleFromLanguage } from 'utils/date/format';
import { StyledDateRangePicker } from './DateRangePicker.style';

export const initialRange = {
  from: undefined,
  to: undefined
};

type DateRangePickerProps = {
  dates: DateRange;
  onChange: (newRange: DateRange) => void;
  label?: string;
  monthsToShow?: number;
};

const DateRangePicker = ({
  dates,
  onChange,
  label,
  monthsToShow = 3
}: DateRangePickerProps) => {
  const handleDayClick = (day: Date) => {
    let newRange: DateRange = initialRange;
    if (
      day.toDateString() === dates.from?.toDateString() ||
      day.toDateString() === dates.to?.toDateString()
    ) {
      newRange = {
        from: day,
        to: day
      };
    } else {
      newRange = addToRange(day, dates) ?? {
        from: undefined,
        to: undefined
      };
    }
    onChange(newRange);
  };

  const { i18n } = useTranslation();
  return (
    <>
      {label && <Label htmlFor={label}>{label}</Label>}
      <StyledDateRangePicker
        numberOfMonths={monthsToShow}
        pagedNavigation
        weekStartsOn={1}
        mode="range"
        onDayClick={handleDayClick}
        selected={dates}
        locale={getLocaleFromLanguage(
          i18n.resolvedLanguage as AvailableLanguages
        )}
      />
    </>
  );
};

export default DateRangePicker;
