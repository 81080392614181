import { FC } from 'react';
import { components, ClearIndicatorProps } from 'react-select';
import Icon from '../../_common/components/utils/Icon';
import { Option } from './types';

type Props = ClearIndicatorProps<Option, true>;

export const SelectMultiClearIndicator: FC<Props> = props => {
  return (
    <components.ClearIndicator {...props}>
      <Icon icon="clear" fill="black" />
    </components.ClearIndicator>
  );
};
