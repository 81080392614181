import { CommandPaletteResultsOrders } from '@components/command-palette/results-orders';
import { CommandPaletteResultsPages } from '@components/command-palette/results-pages';
import { CommandPaletteResultsProducts } from '@components/command-palette/results-products';
import { useOrderResults } from '@components/command-palette/use-order-results';
import { usePageResults } from '@components/command-palette/use-page-results';
import { useProductResults } from '@components/command-palette/use-product-results';
import { ComboboxOptions } from '@headlessui/react';
import { clsx } from 'clsx';
import { useTranslation } from 'react-i18next';

type Props = {
  query: string;
};

/**
 * @package
 */
export const CommandPaletteResults = ({ query }: Props) => {
  const { t } = useTranslation();
  const { pageResults } = usePageResults(query);
  const { ordersReq, orderResults } = useOrderResults(query);
  const { productsReq, productResults } = useProductResults(query);
  const resultCount =
    pageResults.length + orderResults.length + productResults.length;

  if (resultCount === 0 && query !== '') {
    return (
      <div
        className={clsx(
          'text-paragraphSmallBold',
          'text-secondary',
          'p-xs',
          'mt-xs'
        )}
      >
        {t('common:pageNotFound')}
      </div>
    );
  }

  const page = (
    <CommandPaletteResultsPages key="pages" pageResults={pageResults} />
  );
  const other = [
    <CommandPaletteResultsOrders
      key="orders"
      req={ordersReq}
      results={orderResults}
    />,
    <CommandPaletteResultsProducts
      key="products"
      req={productsReq}
      results={productResults}
    />
  ];

  const results = [
    ...(query === '' ? [] : [other]),
    page,
    ...(query === '' ? [other] : [])
  ];

  return (
    <ComboboxOptions
      static
      as="ul"
      className={clsx(
        'max-h-[300px]',
        'mt-s',
        'scroll-pb-xxs',
        'scroll-pt-s',
        'space-y-xxs',
        'pb-xxs',
        'overflow-y-auto'
      )}
    >
      {results}
    </ComboboxOptions>
  );
};

// Search
// - orders
// - delivery
// - product
