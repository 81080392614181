import { Container } from '@dagensmat/carrot/Layout';
import { Typography } from '@dagensmat/carrot/Components';
import { useTranslation } from 'react-i18next';
import { track, PRODUCER_HOME_CLICK_SHOP_LINK } from 'utils/mixpanel';
import { useAppSelector } from '_common/hooks/reduxHooks';
import ProducerSalesModules from './ProducerSalesModules';

const ProducerSalesSection = () => {
  const { t } = useTranslation();
  const { handle, hasAddedWebshopLink, hasReceivedFirstOrder } = useAppSelector(
    ({ auth }) => {
      return {
        handle: auth.handle,
        hasAddedWebshopLink: auth.onboardingHistory?.addedWebshopLink,
        hasReceivedFirstOrder: auth.onboardingHistory?.receivedFirstOrder
      };
    }
  );
  const activeLink = `${window.location.host}/${handle}`;

  if (!hasAddedWebshopLink && !hasReceivedFirstOrder) return null;

  return (
    <Container mt="xl">
      <Container mb="s">
        <Typography variant="tertiaryHeading">
          {t('producer:increaseYourSalesHeading')}
        </Typography>
        <Typography variant="paragraph" mb="xs">
          {t('producer:increaseYourSalesDescription')}
        </Typography>
        <Typography variant="paragraph">
          {t('producer:ViewAndShareYourShop')}{' '}
          <Typography
            variant="routerLink"
            to={`/${handle}`}
            onClick={() => {
              return track(PRODUCER_HOME_CLICK_SHOP_LINK);
            }}
          >
            {activeLink}
          </Typography>
        </Typography>
      </Container>

      <ProducerSalesModules />
    </Container>
  );
};

export default ProducerSalesSection;
