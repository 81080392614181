import { useState } from 'react';
import { Combobox } from '@headlessui/react';
import { DeliveryAddressInput } from './DeliveryAddressInput';
import { DeliveryAddressResults } from './DeliveryAddressResults';
import Typography from '@dagensmat/carrot/Components/Typography';
import { useTranslation } from 'react-i18next';
import { Icon } from '@carrot/theme/icon';

type Props = {
  deliveryAddress: string;
  onDeliveryAddressChange: (address: string) => void;
  onErrorChange?: (hasError: boolean) => void;
};

export const DeliveryAddressField = ({
  deliveryAddress,
  onDeliveryAddressChange,
  onErrorChange
}: Props) => {
  const [query, setQuery] = useState('');
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();

  const handleDeliveryAddressChange = (address: string | null) => {
    if (!address || address === '') {
      if (!deliveryAddress) {
        setError(
          t(
            'common:deliveryInformationFormFieldDeliveryAddressErrorRequiredField'
          )
        );
        onErrorChange && onErrorChange(true);
      }
    } else {
      onErrorChange && onErrorChange(false);
      setError(null);
      onDeliveryAddressChange(address);
      setQuery(address);
    }
  };

  return (
    <div>
      <Typography variant="inputLabel">
        {t('common:deliveryInformationFormFieldDeliveryAddressLabel')}
      </Typography>
      <Typography variant="paragraphSmall" color="secondary">
        {t('common:deliveryInformationFormFieldDeliveryAddressDescription')}
      </Typography>
      <Combobox
        value={deliveryAddress}
        onChange={handleDeliveryAddressChange}
        onClose={() => {
          setQuery('');
        }}
      >
        <DeliveryAddressInput setQuery={setQuery} />
        <DeliveryAddressResults query={query} />
      </Combobox>
      {/* TODO: Create this into feedback comp */}
      {error && (
        <Typography variant="paragraph" mt="s">
          <Icon color="error" icon="alert" />
          {error}
        </Typography>
      )}
    </div>
  );
};
