import React from 'react';
import { Container } from '@dagensmat/carrot/Layout';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import NewRadioButtons from '_common/components/radio-buttons/NewRadioButtons';
import PageHeader from '@components/page-header/PageHeader';
import { LabelFormat, userUpdated } from '_common/reducers/auth';
import { useAppDispatch, useAppSelector } from '_common/hooks/reduxHooks';
import Logger from 'services/Logger';
import REQ, { ReqType } from 'utils/REQ';
import { postProducerUpdate } from 'api';
import { Page } from '@components/page';
import { ActionButton } from '@components/action-button';
import { BottomActions } from '@components/bottom-actions';

const LabelFormatSelectorPage = () => {
  const DEFAULT_LABEL_FORMAT: LabelFormat = '1';
  const { labelFormat, roleId } = useAppSelector(({ auth }) => {
    return {
      labelFormat: auth.labelFormat || DEFAULT_LABEL_FORMAT,
      roleId: auth._id
    };
  });

  const [req, setReq] = React.useState<ReqType>(REQ.INIT);
  const [disabled, setDisabled] = React.useState<boolean>(true);
  const [value, setValue] = React.useState<LabelFormat>(labelFormat);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    setValue(labelFormat);
  }, [labelFormat]);

  const onChange = (newValue: LabelFormat) => {
    setValue(newValue);
    setDisabled(false);
  };

  const save = async () => {
    setReq(REQ.PENDING);
    dispatch(userUpdated({ labelFormat: value }));
    await postProducerUpdate({
      userId: roleId,
      labelFormat: value
    }).catch(e => {
      setReq(REQ.ERROR);
      Logger.error(e);
    });
    setReq(REQ.SUCCESS);
  };

  if (req === REQ.SUCCESS) {
    navigate(-1);
    return null;
  }

  return (
    <Page
      bottom={
        !disabled && (
          <BottomActions>
            <ActionButton.Save saveReq={req} onClick={save} redirectTo={-1}>
              {t('producer:SaveParcelLabelSettings')}
            </ActionButton.Save>
          </BottomActions>
        )
      }
    >
      <PageHeader headerText={t('producer:ParcelLabelSettings')} />
      <Container my="m">
        <NewRadioButtons
          radioGroupName="labelFormats"
          labelText={t('producer:LabelFormatHeader')}
          currentValue={value}
          onChange={newValue => {
            return onChange(newValue as LabelFormat);
          }}
          options={[
            {
              key: '1',
              value: t('producer:LabelFormat1'),
              explanationText: t('producer:LabelFormat1ExplanationText')
            },
            {
              key: '2',
              value: t('producer:LabelFormat2'),
              explanationText: t('producer:LabelFormat2ExplanationText')
            }
          ]}
        />
      </Container>
    </Page>
  );
};

export default LabelFormatSelectorPage;
