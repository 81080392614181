import { roundNumber, UnitType } from '@dagensmat/core';
import { Typography } from '@dagensmat/carrot/Components';
import { ColourNames } from '@dagensmat/carrot/Theme/Colours';
import { useTranslation } from 'react-i18next';

type PricePerUnitProps = {
  price: number;
  unit: UnitType;
  color: ColourNames;
};

const PricePerUnit = ({ price, unit, color }: PricePerUnitProps) => {
  const { t } = useTranslation();
  return (
    <Typography variant="paragraphSmall" color={color}>
      {roundNumber(price)} kr/{t(`units:${unit}`, { count: 1 })}
    </Typography>
  );
};
export default PricePerUnit;
