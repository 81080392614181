import { FC } from 'react';
import { Typography } from '@dagensmat/carrot/Components';
import { Container } from '@dagensmat/carrot/Layout';
import Icon from '../utils/Icon';

type Props = {
  value: {
    message: string;
    isError: boolean;
  } | null;
};

export const Feedback: FC<Props> = ({ value }) => {
  const { message, isError } = value ?? {};
  if (!message) {
    return null;
  }

  return (
    <Container flex wrap="nowrap" gap="xs" alignItems="start" mt="s">
      <Icon
        icon={isError ? 'alert' : 'success'}
        fill={isError ? 'error' : 'success'}
        $size="m"
      />
      <Typography variant="paragraph" color={isError ? 'error' : undefined}>
        {message}
      </Typography>
    </Container>
  );
};
