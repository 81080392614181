import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Typography } from '@dagensmat/carrot/Components';
import { t } from 'i18next';
import { Container } from '@dagensmat/carrot/Layout';
import { formatDate } from 'utils/date/format';
import {
  postOrderAdjustment,
  UpdateOrderLinePayload,
  CreateOrderAdjustmentPayload
} from 'api';
import REQ, { ReqType } from 'utils/REQ';
import { Order, OrderLine } from 'types/Order';
import OrderLineTable from '_common/components/orderline-table/OrderLineTable';
import {
  hasOrderLineBeenUpdated,
  getTotalOrderPrice
} from '_common/reducers/orders';
import ConsumerContactInfo from '_producer/pages/order/ConsumerContactInfo';
import OrderAdjustmentSummaryTable from '_common/pages/order/OrderAdjustmentSummaryTable';
import { formatNok2Decimals } from 'utils/texts';
import Logger from '../../../services/Logger';
import { Page } from '@components/page';
import { BottomActions } from '@components/bottom-actions';
import { ActionButton } from '@components/action-button';

type CreateOrderAdjustmentProps = {
  order: Order;
};

const getOrderAdjustmentOverviewRows = (
  order: Order,
  adjustedLines: OrderLine[]
) => {
  const initialOrderTotal = getTotalOrderPrice(order.orderLines);
  const adjustedOrderTotal = getTotalOrderPrice(adjustedLines);
  const diff = adjustedOrderTotal - initialOrderTotal;
  return [
    {
      description: `${t('common:InitialOrder')} (${order.orderNumberString})`,
      amount: formatNok2Decimals(initialOrderTotal)
    },
    {
      description: `${t('common:Adjustment')}`,
      amount: formatNok2Decimals(diff)
    },
    {
      description: t('common:AdjustedOrderTotal'),
      amount: formatNok2Decimals(adjustedOrderTotal)
    }
  ];
};

type LocationState = { returnPath: string } | undefined;

// TODO support some feedback in the very unlikely event that producer ends up on this page for an order that has already been adjusted
export const CreateOrderAdjustment = ({
  order
}: CreateOrderAdjustmentProps) => {
  const location = useLocation();
  const state = location.state as LocationState;
  const [req, setReq] = useState<ReqType>(REQ.INIT);
  const [updatedOrderLines, setUpdatedOrderLines] = useState<
    UpdateOrderLinePayload[]
  >([]);

  const { consumer, deliveryDate } = order;

  const { name } = consumer;

  const mergeLine = (
    orderLine: OrderLine,
    updatedOrderLine: UpdateOrderLinePayload
  ) => {
    return {
      ...orderLine,
      nrOfOrderedUnitsDelivered: updatedOrderLine.nrOfOrderedUnitsDelivered,
      nrOfPricedUnitsDelivered: updatedOrderLine.nrOfPricedUnitsDelivered,
      pricingAtTimeOfOrder: {
        ...orderLine.pricingAtTimeOfOrder,
        nokPerPricedUnit: updatedOrderLine.nokPerPricedUnit
      }
    };
  };

  const saveOrderLine = (payload: UpdateOrderLinePayload) => {
    const filteredUpdatedOrderLines = updatedOrderLines.filter(ol => {
      return ol.lineKey !== payload.lineKey;
    });
    const originalOrderLine = order.orderLines.find(ol => {
      return ol._key === payload.lineKey;
    });
    if (!originalOrderLine) {
      Logger.error(
        new Error(
          `Trying to save order line that does not exist in order: ${payload.lineKey}`
        )
      );
      return;
    }

    const mergedLine = mergeLine(originalOrderLine, payload);
    const hasChanged =
      hasOrderLineBeenUpdated(mergedLine) ||
      originalOrderLine.pricingAtTimeOfOrder.nokPerPricedUnit !==
        payload.nokPerPricedUnit;

    if (hasChanged) {
      setUpdatedOrderLines([...filteredUpdatedOrderLines, payload]);
    } else {
      setUpdatedOrderLines([...filteredUpdatedOrderLines]);
    }
  };

  const mergeLines = () => {
    const lines = order.orderLines.map(ol => {
      const updatedOrderLine = updatedOrderLines.find(uol => {
        return uol.lineKey === ol._key;
      });
      if (updatedOrderLine) {
        return mergeLine(ol, updatedOrderLine);
      }
      return ol;
    });
    return lines;
  };

  const onSubmit = async () => {
    setReq(REQ.PENDING);
    const payload: CreateOrderAdjustmentPayload = {
      orderLines: updatedOrderLines.map(ol => {
        return {
          lineKey: ol.lineKey,
          nrOfOrderedUnitsDelivered: ol.nrOfOrderedUnitsDelivered,
          nrOfPricedUnitsDelivered: ol.nrOfPricedUnitsDelivered,
          nokPerPricedUnit: ol.nokPerPricedUnit
        };
      })
    };
    try {
      await postOrderAdjustment(order._id, payload);
      setReq(REQ.SUCCESS);
    } catch (e) {
      setReq(REQ.ERROR);
    }
  };

  const isAdjusted = updatedOrderLines.length > 0;

  return (
    <Page
      bottom={
        <BottomActions>
          <ActionButton.Save
            saveReq={req}
            onClick={onSubmit}
            disabled={!isAdjusted}
            redirectTo={state?.returnPath ? -1 : `/orders/${order.secret}`}
          >
            {t('producer:OrderAdjustments.saveButtonLabel')}
          </ActionButton.Save>
        </BottomActions>
      }
    >
      <Typography variant="secondaryHeading" as="h1">
        {t('producer:OrderAdjustments.pageTitle', {
          orderNr: order.orderNumberString
        })}
      </Typography>
      <Typography variant="paragraph">
        {t('producer:DeliveryToCustomerOnDate', {
          name,
          date: formatDate(deliveryDate)
        })}
      </Typography>
      <ConsumerContactInfo consumer={order.consumer} />
      <Typography variant="paragraphBold" as="p">
        {t('producer:OrderAdjustments.adjustOrderLabel')}
      </Typography>
      <Typography variant="paragraph" mb="s">
        {t('producer:OrderAdjustments.adjustmentSettlementInformaiton')}
      </Typography>
      <OrderLineTable
        orderId={order._id}
        orderLines={mergeLines()}
        originalOrderLines={order.orderLines}
        isReadOnly={false}
        isOrderAdjustment
        onSaveOrderLine={saveOrderLine}
      />
      {isAdjusted && (
        <Container my="xl">
          <OrderAdjustmentSummaryTable
            rows={getOrderAdjustmentOverviewRows(order, mergeLines())}
          />
        </Container>
      )}
    </Page>
  );
};
