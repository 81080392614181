import { useTranslation } from 'react-i18next';
import { Typography } from '@dagensmat/carrot/Components';
import { updateSelectedDeliveryDate } from '_consumer/reducers/productsForSale';
import { track, SELECT_DELIVERY_DAY_PRODUCT_SEARCH } from 'utils/mixpanel';
import { useAppDispatch, useAppSelector } from '_common/hooks/reduxHooks';
import DeliveryDateOptions, { DeliveryDate } from './DeliveryDateOptions';

const DeliveryDateSelection = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { availableDeliveryDates } = useAppSelector(({ productsForSale }) => {
    return productsForSale;
  });

  const setDeliveryDate = (date: DeliveryDate) => {
    dispatch(updateSelectedDeliveryDate(date));
    track(SELECT_DELIVERY_DAY_PRODUCT_SEARCH);
  };

  return (
    <>
      <Typography variant="inputLabel" mt="m" mb="xxs">
        {t('consumer:WhenAreYouOrderingFor')}
      </Typography>
      <DeliveryDateOptions
        availableDeliveryDates={availableDeliveryDates}
        setDeliveryDate={setDeliveryDate}
      />
    </>
  );
};

export default DeliveryDateSelection;
