import { clsx } from 'clsx';
import { PropsWithChildren } from 'react';

const style = clsx('flex', 'items-center', 'gap-xxs');

type Props = PropsWithChildren;

/**
 * @package
 */
export const ButtonGroupNoWrap = ({ children }: Props) => {
  return <div className={clsx(style)}>{children}</div>;
};
