import { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Typography } from '@dagensmat/carrot/Components';
import { Container } from '@dagensmat/carrot/Layout';
import { B } from 'utils/fonts';
import { MINIMUM_ORDER_MESSAGE_SHOWN, track } from 'utils/mixpanel';
import ExpandCollapseAnimationContainer from '../container/ExpandCollapseAnimationContainer';
import Icon from '../utils/Icon';

type MinimumOrderAmountCalloutProps = {
  producerName?: string;
  minimumOrderAmount?: number;
  underMinimum: boolean;
};

const MinimumOrderAmountCallout = ({
  producerName,
  minimumOrderAmount,
  underMinimum
}: MinimumOrderAmountCalloutProps) => {
  const [eventSent, setEventSent] = useState(false);
  if (!eventSent && underMinimum) {
    track(MINIMUM_ORDER_MESSAGE_SHOWN, { producerName });
    setEventSent(true);
  }
  const { t } = useTranslation();

  return (
    <ExpandCollapseAnimationContainer shown={underMinimum}>
      <Container flex direction="row" wrap="nowrap" gap="xxs" my="xs">
        <Icon icon="alert" $size="m" fill="warning" />
        <Typography variant="paragraphSmall" color="secondary">
          <Trans
            t={t}
            i18nKey="consumer:ProducerMinimumOrderAmountThresholdMessage"
            values={{ producerName, amount: minimumOrderAmount }}
            components={{ B: <B /> }}
          />
        </Typography>
      </Container>
    </ExpandCollapseAnimationContainer>
  );
};

export default MinimumOrderAmountCallout;
