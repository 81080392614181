import { useTranslation } from 'react-i18next';
import { Typography } from '@dagensmat/carrot/Components';
import {
  countActiveUnopenedOrders,
  splitActiveAndPastOrders
} from '_common/reducers/orders';
import OrderOverview from '_common/components/order-overview/OrderOverview';
import OrdersPageHOC from './OrdersPageHOC';
import { useAppSelector } from '../../../_common/hooks/reduxHooks';
import REQ from '../../../utils/REQ';
import { Page } from '../../../components/page';
import { Order } from '../../../types/Order';

type Props = {
  activeOrders: Order[];
};

const OrdersOverviewPage = ({ activeOrders = [] }: Props) => {
  const { t } = useTranslation();
  return activeOrders.length < 1 ? (
    <Typography variant="paragraph" color="secondary" my="s">
      {t('producer:NoActiveOrders')}
    </Typography>
  ) : (
    <OrderOverview orders={activeOrders} />
  );
};

const OrdersOverviewPageFetcher = () => {
  const { activeOrders, numUnopenedOrders, req } = useAppSelector(
    ({ orders: { items, req } }) => {
      const { activeOrders } = splitActiveAndPastOrders(items);
      const numUnopenedOrders = countActiveUnopenedOrders(items);
      return {
        req,
        numUnopenedOrders,
        activeOrders
      };
    }
  );

  if (req !== REQ.SUCCESS) {
    return <Page.Status req={req} />;
  }

  return (
    <OrdersPageHOC numUnopenedOrders={numUnopenedOrders}>
      <OrdersOverviewPage activeOrders={activeOrders} />
    </OrdersPageHOC>
  );
};

export default OrdersOverviewPageFetcher;
