import { FC } from 'react';
import { Typography } from '@dagensmat/carrot/Components';
import styled from 'styled-components';

const UppercaseTypography = styled(Typography)`
  text-transform: uppercase;
  letter-spacing: 1.25px;
  font-size: 12px;
`;

type Props = {
  text?: string;
};

export const Annotation: FC<Props> = ({ text }) => {
  if (!text) {
    return null;
  }

  return (
    <UppercaseTypography variant="paragraphSmallBold" color="secondary" mb="xs">
      {text}
    </UppercaseTypography>
  );
};
