import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography } from '@dagensmat/carrot/Components';
import { find, get } from 'lodash';
import Theme from '@dagensmat/carrot/Theme';
import { Container } from '@dagensmat/carrot/Layout';
import type { ProducerProduct } from 'types/Product';
import Separator from '_common/components/utils/Separator.style';
import {
  getUniqueConsumers,
  isRegularPrice,
  hasCustomOffer
} from 'utils/pricing';
import Tag from '_common/components/utils/Tag';
import PricingDisplay from '_producer/components/pricing/PricingDisplay';
import { truncate } from 'utils/array';
import { useAppSelector } from '_common/hooks/reduxHooks';
import ProductReference from '_common/components/product-reference/ProductReference';

const ProductListElement = ({ product }: { product: ProducerProduct }) => {
  const { t } = useTranslation();

  const { prices = [] } = product;

  const hasAllConsumers = prices.some(isRegularPrice);

  const customOfferConsumerNames = useAppSelector(
    ({ consumers: { items } }) => {
      const uniqueConsumerRefs = getUniqueConsumers(prices);
      return hasAllConsumers
        ? [t('producer:AllBuyers')]
        : [
            ...uniqueConsumerRefs.map(({ _ref }) => {
              return get(find(items, { _id: _ref }), 'name');
            })
          ].filter(Boolean);
    }
  );

  return (
    <Link
      to={{
        pathname: `/my-products/${product._id}`
      }}
      state={{ returnPath: true }}
    >
      <Container flex justify="space-between" alignItems="center" py="s">
        <div>
          <Container flex justify="flex-start" wrap="wrap-reverse">
            <ProductReference
              product={product}
              variant="regular"
              mr={Theme.Spacings.xxs}
              mt={Theme.Spacings.xxs}
            />
            {hasCustomOffer(prices) && (
              <Tag
                bgColor={Theme.Colours.noteworthy}
                text={t('common:CustomOffer')}
              />
            )}
          </Container>
          <Container flex justify="left">
            <Typography variant="paragraphSmall" color="secondary">
              {t('common:VisibleTo')}
            </Typography>
            <Typography variant="paragraphSmall" as="p" color="black">
              &nbsp;
              {truncate({
                list: customOfferConsumerNames,
                addendum: t('common:more')
              })}
            </Typography>
          </Container>
          <Container flex alignItems="baseline" justify="left">
            <Typography variant="paragraphSmall" color="secondary">
              {t('common:PriceInList')}
            </Typography>
            &nbsp;
            {prices.length === 1 ? (
              <PricingDisplay price={prices[0]} />
            ) : (
              <Typography variant="paragraphSmall">
                {t('producer:productGroupSeveralPrices', {
                  count: prices.length
                })}
              </Typography>
            )}
          </Container>
        </div>
        <span
          style={{
            fontSize: 20
          }}
        >
          →
        </span>
      </Container>
      <Separator />
    </Link>
  );
};

export default ProductListElement;
