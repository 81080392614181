import { clsx } from 'clsx';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';

const containerStyles = clsx(
  'relative',
  'overflow-hidden',
  'w-screen',
  'left-1/2',
  '-translate-x-1/2',
  'desktop:w-full'
);

const contentStyles = clsx(
  'flex',
  'gap-xxs',
  'w-full',
  'overflow-x-auto',
  'px-s',
  'desktop:px-0',
  'pb-[12px]'
);

const gradientStyles = clsx(
  'absolute',
  'inset-0',
  'pointer-events-none',
  'desktop:from-white',
  'desktop:to-transparent',
  'desktop:from-0%',
  'desktop:to-[48px]'
);

type Props = PropsWithChildren;

/**
 * @package
 */
export const ButtonGroupHorizontalScroll = ({ children }: Props) => {
  const [atStart, setAtStart] = useState(true);
  const [atEnd, setAtEnd] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const scrollHandler = () => {
      if (ref.current) {
        setAtStart(ref.current.scrollLeft === 0);
        const scrollEnd = ref.current.scrollWidth - ref.current.clientWidth;
        setAtEnd(Math.abs(ref.current.scrollLeft - scrollEnd) <= 1); // some weirdness can occur with rounding
      }
    };

    if (ref.current) {
      ref.current.addEventListener('scroll', scrollHandler);
    }

    return () => {
      if (ref.current) {
        ref.current.removeEventListener('scroll', scrollHandler);
      }
    };
  }, []);

  return (
    <div className={containerStyles}>
      <div className={contentStyles} ref={ref}>
        {children}
      </div>
      {!atEnd && (
        <div className={clsx(gradientStyles, 'desktop:bg-gradient-to-l')}></div>
      )}
      {!atStart && (
        <div className={clsx(gradientStyles, 'desktop:bg-gradient-to-r')}></div>
      )}
    </div>
  );
};
