import { useMemo } from 'react';
import { AvailableLanguages } from '@dagensmat/core';
import {
  DayMouseEventHandler,
  DayPickerBase,
  DayPickerMultipleProps,
  DayPickerSingleProps
} from 'react-day-picker';
import { getLocaleFromLanguage } from '../../../utils/date/format';

type Args = {
  mode: 'single' | 'multiple';
  selectedDeliveryDates: string[];
  deliveryDates: string[];
  handleDateClick: DayMouseEventHandler;
  language: AvailableLanguages;
};
export const useGetPickerProps = ({
  mode,
  selectedDeliveryDates,
  deliveryDates,
  handleDateClick,
  language
}: Args) => {
  const onModeChange = useMemo(() => {
    const modifiers = {
      hasDagensLogistics: deliveryDates.map(date => {
        return new Date(date);
      }),
      selected: selectedDeliveryDates.map(deliveryDate => {
        return new Date(deliveryDate);
      })
    };
    const commonPickerProps: DayPickerBase = {
      pagedNavigation: true,
      numberOfMonths: 1,
      weekStartsOn: 1,
      onDayClick: handleDateClick,
      modifiers,
      modifiersClassNames: {
        hasDagensLogistics: 'rdp-day_hasDagensLogistics'
      },
      locale: getLocaleFromLanguage(language)
    };
    const singlePickerProps: DayPickerSingleProps = {
      ...commonPickerProps,
      mode: 'single',
      selected:
        selectedDeliveryDates.length > 0
          ? new Date(selectedDeliveryDates[0])
          : undefined
    };
    const multiplePickerProps: DayPickerMultipleProps = {
      ...commonPickerProps,
      mode: 'multiple',
      selected:
        selectedDeliveryDates.length > 0
          ? selectedDeliveryDates.map(deliveryDate => {
              return new Date(deliveryDate);
            })
          : undefined
    };
    const pickerProps =
      mode === 'multiple' ? multiplePickerProps : singlePickerProps;
    return pickerProps;
  }, [mode, selectedDeliveryDates, deliveryDates]);
  return onModeChange;
};
