import { PropsWithChildren } from 'react';
import { tv } from 'tailwind-variants';

const style = tv({
  base: ['absolute', 'left-full', '-top-xxs'],
  variants: {
    variant: {
      desktop: ['-ml-xs', '-mt-xxs'],
      mobile: ['ml-[-10px]']
    }
  }
});

type BadgeContainerVariant = keyof typeof style.variants.variant;

type Props = PropsWithChildren<{
  variant: BadgeContainerVariant;
}>;

export const ButtonBadgeContainer = ({ variant, children }: Props) => {
  return <div className={style({ variant })}>{children}</div>;
};
