import { Typography } from '@dagensmat/carrot/Components';
import { UnitType } from '@dagensmat/core';
import { t } from 'i18next';
import { FormFieldType } from 'carrot/form-field/types';
import { useAppSelector } from '../../../_common/hooks/reduxHooks';
import { FormField } from '../../../carrot/form-field';
import DisplayPricingStructure from './DisplayPricingStructure';
import { unitTypesToOptions } from './unit-types-to-options';

type PricedUnitInputProps = {
  pricedUnit: UnitType;
  nokPerPricedUnit: number | '';
  pricedUnitOptions: UnitType[];
  hasDagensLogistics: boolean;
  showError?: boolean;
  onPricedUnitChange: (pricedUnit: UnitType) => void;
  onNokPerPricedUnitChange: (nokPerPricedUnit: number | string) => void;
};

const PricedUnitInput = ({
  pricedUnit,
  nokPerPricedUnit,
  pricedUnitOptions,
  hasDagensLogistics,
  showError,
  onPricedUnitChange,
  onNokPerPricedUnitChange
}: PricedUnitInputProps) => {
  const { producerTransactionCut, producerSelfDeliveryFee } = useAppSelector(
    ({ auth: { transactionCut, transactionCutForSelfDelivery } }) => {
      return {
        producerTransactionCut: transactionCut,
        producerSelfDeliveryFee: transactionCutForSelfDelivery
      };
    }
  );

  return (
    <FormField
      type={FormFieldType.NUMBER_WITH_SELECT}
      inputProps={{
        unit: 'kr',
        value: nokPerPricedUnit,
        onInputChange: onNokPerPricedUnitChange,
        selectValue: pricedUnit,
        selectOptions: unitTypesToOptions(pricedUnitOptions),
        onSelectChange: onPricedUnitChange
      }}
      helpText={t('producer:productPriceExplanationText')}
      label={t('producer:productProductPriceLabelText')}
      errorMessage={t('common:requiredField')}
      feedback={
        nokPerPricedUnit ? (
          <DisplayPricingStructure
            transactionCut={
              (hasDagensLogistics
                ? producerTransactionCut
                : producerSelfDeliveryFee) ?? 0
            }
            nokPerPricedUnit={nokPerPricedUnit}
          />
        ) : (
          <Typography variant="paragraphSmall" mt="xxs">
            {t('producer:productPriceFee')}
          </Typography>
        )
      }
      showError={showError}
    />
  );
};

export default PricedUnitInput;
