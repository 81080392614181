import { useEffect, useState } from 'react';
import { getConsumers } from 'api';
import { useAppSelector } from '../../../_common/hooks/reduxHooks';
import { Consumer } from '../../../types/Consumer';

export const useLoadConsumers = () => {
  const [consumers, setConsumers] = useState<Consumer[]>([]);
  const [loading, setLoading] = useState(true);
  const { producerId } = useAppSelector(({ auth: { _id } }) => {
    return { producerId: _id };
  });

  useEffect(() => {
    getConsumers({ producerId }).then(data => {
      setConsumers(data);
      setLoading(false);
    });
  }, [producerId]);

  return { consumers, loading };
};
