import { useTranslation } from 'react-i18next';
import { Callout, Typography } from '@dagensmat/carrot/Components';
import { EditablePricing, Pricing } from 'types/Product';
import SpacedContainer from '_common/components/container/FormContainer.style';
import { useAppDispatch } from '_common/hooks/reduxHooks';
import { ButtonLink } from '_common/components/button/Button.style';
import { convertPrices, isRegularPrice } from 'utils/pricing';
import { productEdited as updateProductInStore } from '_producer/reducers/products';
import { updateProduct as updateNewProductInStore } from '_producer/reducers/newProduct';
import { postProductFieldsUpdate } from 'api';
import { EDIT_PRODUCT_CLICK_ADD_NEW_OFFER, track } from 'utils/mixpanel';
import PricingPreview, { OfferType } from './PricingPreview';

type PricingPreviewsProps = {
  allowZeroWeight: boolean;
  productId: string | undefined;
  prices?: (EditablePricing | Pricing)[];
};

export const getOfferType = (pricing: EditablePricing | Pricing): OfferType => {
  return isRegularPrice(pricing) ? 'regular' : 'custom';
};

const PricingPreviews = ({
  allowZeroWeight,
  productId,
  prices = []
}: PricingPreviewsProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const onDelete = (key: string) => {
    return async () => {
      const newPrices = convertPrices(
        prices.filter(pricing => {
          return pricing._key !== key;
        })
      );
      await postProductFieldsUpdate({ productId, prices: newPrices });
      dispatch(
        updateNewProductInStore({
          prices: newPrices
        })
      );
      dispatch(
        updateProductInStore({
          _id: productId,
          prices: newPrices
        })
      );
    };
  };

  return (
    <SpacedContainer space="xs">
      {Boolean(
        !prices.find(p => {
          return isRegularPrice(p);
        })
      ) && (
        <Callout>
          <Typography variant="paragraphSmall">
            {t('producer:SelectedCustomersHelpText')}
          </Typography>
        </Callout>
      )}
      {prices.map(pricing => {
        return (
          <PricingPreview
            key={pricing._key}
            productId={productId}
            pricing={pricing}
            numberOfPrices={prices.length}
            onDelete={onDelete(pricing._key)}
            offerType={getOfferType(pricing)}
            allowZeroWeight={allowZeroWeight}
            showError
          />
        );
      })}
      <ButtonLink
        to={`/my-products/${productId}/add-pricing`}
        onClick={() => {
          track(EDIT_PRODUCT_CLICK_ADD_NEW_OFFER);
        }}
      >
        {t('producer:AddNewPricing')}
      </ButtonLink>
    </SpacedContainer>
  );
};

export default PricingPreviews;
