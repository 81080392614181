import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import Theme from '@dagensmat/carrot/Theme';
import { ButtonAnchor } from '_common/components/button/Button.style';
import margins, { MarginProps } from 'utils/style';
import { MediaQuery } from 'utils/mediaQueries';
import Icon from '_common/components/utils/Icon';
import { Illustrations } from '../../../utils/Illustrations';

const FlexContainer = styled.div<MarginProps>`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  > img {
    align-self: flex-end;
    width: 100%;
    height: auto;
    max-height: 180px;
    mix-blend-mode: multiply;
    object-fit: contain;
    margin: ${Theme.Spacings.xs}px 0;
  }

  ol {
    margin-left: -${Theme.Spacings.s}px;
  }

  li {
    margin-top: ${Theme.Spacings.xs}px;
  }
  ${margins}

  ${MediaQuery.tabletUp} {
    flex-direction: row-reverse;
    align-items: center;

    > img {
      width: 20%;
    }
  }
`;

const PackingAndLabelingInfo = ({ mt }: MarginProps) => {
  const { t } = useTranslation();
  const url = 'https://dagens.farm/how-to/pack-and-label';

  return (
    <FlexContainer mt={mt}>
      <img src={Illustrations.PRODUCER_PLACING_PRODUCE_IN_IFCO} alt="" />
      <div>
        <Trans
          t={t}
          i18nKey="producer:PackingAndLabelingSteps"
          components={{ ol: <ol />, li: <li /> }}
        />
        <ButtonAnchor
          mt={Theme.Spacings.s}
          variant="secondary"
          target="_blank"
          rel="noopener noreferrer"
          href={url}
        >
          {t('producer:ReadGuideLines')}
          <Icon icon="externalLinkSmall" fill="white" />{' '}
        </ButtonAnchor>
      </div>
    </FlexContainer>
  );
};

export default PackingAndLabelingInfo;
