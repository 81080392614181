import styled from 'styled-components';
import OrderContactDetails from '_common/components/order-contact-details/OrderContactDetails';
import { Order } from 'types/Order';

const OrderDetailsContainer = styled.div`
  margin-bottom: 40px;
`;

type Props = {
  order: Order;
};

const OrderDetailsForConsumer = ({
  order: {
    producer: { name, contactPerson, phone }
  }
}: Props) => {
  return (
    <OrderDetailsContainer>
      <OrderContactDetails
        name={name}
        contactPerson={contactPerson}
        phone={phone}
      />
    </OrderDetailsContainer>
  );
};

export default OrderDetailsForConsumer;
