import { VatDecimal } from '@dagensmat/core';
import type { SanityDoc, SanityImage } from './Sanity';
import type { Route, StructuredAddress } from './Logistics';
import type { Period } from './Product';

export type Producer = {
  _updatedAt: string;
  name: string;
  contactPerson: string;
  phone: string;
  instagram?: string;
  profileArea?: string;
  profileBio?: string;
  image?: SanityImage;
  rawRank?: number;
  distributionAreas?: string[];
  handle: string;
  minimumOrderAmount?: number;
} & SanityDoc<'producers'>;

export type ProducerProfile = {} & Producer & ProducerFields;

export type ProducerFields = {
  unsubscribeFavoriteNotification?: boolean;
  unsubscribeWeeklyReminderNotification?: boolean;
  profileArea?: string;
  profileBio?: string;
  address?: string;
  structuredAddress?: StructuredAddress;
  dagensId?: string;
  instagram?: string;
  image?: SanityImage;
  minDaysBeforeOrder?: number;
  logisticsRoutes?: Route[];
  extraRoutes?: Route[];
  accountingId?: number;
  transactionCut?: number;
  transactionCutForSelfDelivery?: number;
  tripletexId?: number;
  vatDecimal?: VatDecimal;
  temporarilyUnavailable?: Period;
  ecologicNumber?: string;
  ecologicCodeNumber?: string;
  inactive?: boolean;
  paymentDetails?: any;
  minimumOrderAmount?: number;
  handle: string;
  shopUrl: string;
  hideShop: boolean;
};

export type ProducerWithNumProductsForSale = {
  numProductsForSale: number;
} & Producer;

export enum OnboardingHistoryKeys {
  filledOutAboutInfo = 'filledOutAboutInfo',
  addedFirstProduct = 'addedFirstProduct',
  setUpInitialDeliveryRoute = 'setUpInitialDeliveryRoute',
  addedWebshopLink = 'addedWebshopLink',
  receivedFirstOrder = 'receivedFirstOrder',
  deliveredFirstOrder = 'deliveredFirstOrder',
  addedBankAccount = 'addedBankAccount',
  receivedFirstPayment = 'receivedFirstPayment'
}

export type ProducerOnboarding = Record<
  keyof typeof OnboardingHistoryKeys,
  string | null
> & { accountType: 'producers' };
