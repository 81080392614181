import { clsx } from 'clsx';
import { PropsWithChildren } from 'react';
import { boxStyles } from '@carrot/layout/box';

const bottomActionsStyle = clsx('flex', 'justify-between', 'py-s', 'pb-m');

const bottomActionsBorderStyle = clsx('border-t', 'border-t-secondary');

type Props = PropsWithChildren<{
  border?: boolean;
}>;

export const BottomActions = ({ border, children }: Props) => {
  return (
    <div
      className={clsx(
        boxStyles.fullWidth,
        bottomActionsStyle,
        border && bottomActionsBorderStyle
      )}
    >
      {children}
    </div>
  );
};
