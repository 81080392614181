import { clsx } from 'clsx';
import { PropsWithChildren } from 'react';

type Props = PropsWithChildren;

/**
 * @package
 */
export const Head = ({ children }: Props) => {
  return (
    <thead
      className={clsx(
        'grid',
        'grid-cols-subgrid',
        'col-span-full',
        'sticky',
        'bg-white',
        'top-0',
        'z-10'
      )}
    >
      {children}
    </thead>
  );
};
