import { useTranslation } from 'react-i18next';
import { Typography } from '@dagensmat/carrot/Components';
import DeliveryDateOptions, {
  DeliveryDate
} from '_common/components/search/DeliveryDateOptions';

type ProductDeliveryDateSelectionProps = {
  product: any;
  availableDeliveryDates?: DeliveryDate[];
  setDeliveryDate: (deliveryDate: DeliveryDate) => void;
};

const getUnavailableDeliveryDates = (
  product: any,
  availableDeliveryDates?: string[]
) => {
  const { deliveryDates = [], soldOutDates = [] } = product;
  return Array.from(
    new Set<string>(
      availableDeliveryDates?.concat(deliveryDates).filter(deliveryDate => {
        return (
          !deliveryDates.includes(deliveryDate) ||
          soldOutDates.includes(deliveryDate)
        );
      })
    )
  );
};

const ProductDeliveryDateSelection = ({
  product,
  availableDeliveryDates,
  setDeliveryDate
}: ProductDeliveryDateSelectionProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="inputLabel" mt="m" mb="xxs">
        {t('consumer:WhenAreYouOrderingFor')}
      </Typography>
      <DeliveryDateOptions
        availableDeliveryDates={availableDeliveryDates}
        unavailableDeliveryDates={getUnavailableDeliveryDates(
          product,
          availableDeliveryDates
        )}
        setDeliveryDate={setDeliveryDate}
      />
    </>
  );
};

export default ProductDeliveryDateSelection;
