import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalize } from '@dagensmat/core';
import Theme from '@dagensmat/carrot/Theme';
import { Typography } from '@dagensmat/carrot/Components';
import { Container } from '@dagensmat/carrot/Layout';
import { formatDate, getDate, isTodayOrBefore } from 'utils/date/format';
import { hasAllOrdersLeftProducer, PRODUCER_DELIVERS } from 'utils/delivery';
import { useAppDispatch, useAppSelector } from '_common/hooks/reduxHooks';
import {
  splitActiveAndPastOrders,
  groupOrdersByPickupType,
  countActiveUnopenedOrders,
  fetchOrders
} from '_common/reducers/orders';
import InContextOnboardingMessage from '_common/components/in-context-onboarding-message/InContextOnboardingMessage';
import LinkListElement from '_common/components/link-list-element/LinkListElement';
import Tag from '_common/components/utils/Tag';
import { OnboardingHistoryKeys } from '../../../types/Producer';
import { Order } from '../../../types/Order';
import OrdersPageHOC from './OrdersPageHOC';
import { Page } from '../../../components/page';

const DeliveryStatusTag = ({
  orderGroup
}: {
  orderGroup: {
    producerDeliveryDate: string;
    orders: Order[];
  };
}) => {
  const { t } = useTranslation();
  const { producerDeliveryDate, orders } = orderGroup;
  if (
    isTodayOrBefore(producerDeliveryDate) &&
    !hasAllOrdersLeftProducer(orders)
  )
    return (
      <Tag bgColor={Theme.Colours.warning} text={t('producer:ActionNeeded')} />
    );

  const numActiveUnopenedOrders = countActiveUnopenedOrders(orders);
  if (numActiveUnopenedOrders) {
    return (
      <Tag
        bgColor={Theme.Colours.dagensPurple}
        text={t('common:NewOrder', {
          count: numActiveUnopenedOrders
        })}
      />
    );
  }

  return null;
};

type Props = {
  roleId: string | undefined;
  orderGroups: ReturnType<typeof groupOrdersByPickupType>;
  activeOrders: Order[];
  onboardingStep: {
    messageKey: string;
    isOnboarding: boolean;
  };
};

const OrdersPage = ({
  roleId,
  orderGroups = [],
  activeOrders,
  onboardingStep = {
    messageKey: 'producer:orders.receivedFirstOrder',
    isOnboarding: false
  }
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchOrders({ producerId: roleId }, { clearStore: false }));
  }, []);

  return (
    <>
      <InContextOnboardingMessage
        message={t(onboardingStep.messageKey)}
        showStep={onboardingStep.isOnboarding}
        mt="s"
      />
      {activeOrders.length === 0 && (
        <Typography variant="paragraph" color="secondary" my="m">
          {t('producer:NoActiveOrdersRightNow')}
        </Typography>
      )}
      {activeOrders.length > 0 && (
        <Container my="xl">
          {orderGroups.map(orderGroup => {
            const { key, type, orders, deliveryDate, producerDeliveryDate } =
              orderGroup;
            const header = capitalize(formatDate(deliveryDate));

            const deliveryTextKey =
              type === PRODUCER_DELIVERS
                ? 'common:SelfDelivery'
                : `${t('common:DagensDelivery')} (${t(
                    'producer:DropOff'
                  )} ${getDate(producerDeliveryDate)})`;
            const subTitle = `${t('common:numOrders', {
              count: orders.length
            })} → ${t(deliveryTextKey)}`;
            return (
              <LinkListElement
                key={key}
                header={header}
                subTitle={subTitle}
                to={`/orders/by-delivery/${key}`}
                state={{ returnPath: true }}
              >
                <DeliveryStatusTag orderGroup={orderGroup} />
              </LinkListElement>
            );
          })}
        </Container>
      )}
    </>
  );
};

const OrdersPageFetcher = () => {
  const { req, numUnopenedOrders, ...props } = useAppSelector(
    ({ auth: { _id: roleId, onboardingHistory }, orders: { items, req } }) => {
      const { activeOrders } = splitActiveAndPastOrders(items);
      const numUnopenedOrders = countActiveUnopenedOrders(items);
      const orderGroups = groupOrdersByPickupType(activeOrders);

      return {
        req,
        roleId,
        numUnopenedOrders,
        activeOrders,
        orderGroups,
        onboardingStep: {
          messageKey: 'producer:orders.receivedFirstOrder',
          isOnboarding: Boolean(
            onboardingHistory?.[OnboardingHistoryKeys.receivedFirstOrder]
          )
        }
      };
    }
  );

  if (req !== 'SUCCESS') {
    return <Page.Status req={req} />;
  }

  return (
    <OrdersPageHOC numUnopenedOrders={numUnopenedOrders}>
      <OrdersPage {...props} />
    </OrdersPageHOC>
  );
};

export default OrdersPageFetcher;
