import { createBrowserRouter, redirect } from 'react-router-dom';
import OrderPageFetcher from '_common/pages/order/OrderPageFetcher';
import BillingAccountPage from '_common/pages/billing-account/BillingAccountPage';
import RootResolver from '_common/pages/RootResolver';
import LogoutPage from '_common/pages/LogoutPage';
import CreateConsumerPage from '_common/pages/create-role/CreateConsumerPage';
import CreateProducerPage from '_common/pages/create-role/CreateProducerPage';
import CookiesInfoPage from '_common/pages/CookiesInfoPage';
import VerifyUserEmail from '_common/pages/VerifyUserEmail';
import LoginPage from '_unauthenticated/pages/login/Login';
import SwitchUserPage from '_common/pages/switch-role-page/SwitchRolePage';
import LabelFormatSelector from '_producer/pages/parcels/LabelFormatSelectorPage';
import ProducerProductsPage from '_producer/pages/products/ProducerProductsPage';
import EditProductPage from '_producer/pages/edit-product/EditProductPage';
import EditPricingPage from '_producer/pages/edit-product/EditPricingPage';
import AddPricingPage from '_producer/pages/edit-product/AddPricingPage';
import AddProductPage from '_producer/pages/add-product/AddProductPage';
import ProducerLandingPage from './pages/landing-page/ProducerLandingPage';
import ProfilePage from './pages/profile/ProfilePage';
import OrdersPage from './pages/orders/OrdersPage';
import OrdersOverviewPage from './pages/orders/OrdersOverviewPage';
import OrdersInvoicePage from './pages/orders/OrdersInvoicePage';
import OrdersByDeliveryPage from './pages/orders/OrdersByDeliveryPage';
import PackingListPage from './pages/orders/PackingListPage';
import CreateOrderAdjustmentPage from './pages/order-adjustment/CreateOrderAdjustmentPage';
import ProductRequestsPage from './pages/product-requests/ProductRequestsPage';
import ProductRequestPage from './pages/product-request/ProductRequestPage';
import AddOrderLinePage from './pages/add-orderline/AddOrderLinePage';
import ChangeDeliveryDateFetcher from './pages/change-delivery-date/ChangeDeliveryDateFetcher';
import MinimumOrderPage from './pages/profile/MinimumOrder/MinimumOrderPage';
import ParcelsPage from './pages/parcels/ParcelsPage';
import DeliveryRouteRequestPageHub from './pages/logistics-request/DeliveryRouteRequestPageHub';
import DeliveryRouteRequestPageStart from './pages/logistics-request/DeliveryRouteRequestPageStart';
import DeliveryRouteRequestSelfDelivery from './pages/logistics-request/DeliveryRouteRequestSelfDelivery';
import DeliveryRouteRequestPageConfirmation from './pages/logistics-request/DeliveryRouteRequestPageConfirmation';
import GeneratingParcelLabelsPage from './pages/parcels/GeneratingParcelLabelsPage';
import ProducerOrdersHistoryPage from './pages/order-history/ProducerOrdersHistoryPage';
import { ProducerLayout } from './layout';
import AddOrderStart from './pages/add-order/add-order-start/AddOrderStart';
import AddOrderDeliveryDateFetcher from './pages/add-order/AddOrderDeliveryDateFetcher';
import AddOrderProducts from './pages/add-order/AddOrderProducts';
import AddOrderCheckoutPage from './pages/add-order/add-order-checkout/AddOrderCheckout';
import { t } from 'i18next';
import { ExtendedRouteObject } from 'types/routes';
import { CustomerListPage } from '_producer/pages/customer-list-page';
import Start from '../pages/producer/add-customer/start/Start';
import ReviewBillingInformation from '../pages/producer/add-customer/review-billing-information/ReviewBillingInformation';
import ReviewContactInformation from '../pages/producer/add-customer/review-contact-information/ReviewContactInformation';
import ReviewDeliveryInformation from '../pages/producer/add-customer/review-delivery-information/ReviewDeliveryInformation';

export const producerRoutes = () => [
  {
    path: '/',
    element: <ProducerLayout />,
    children: [
      {
        index: true,
        title: t('common:Home'),
        icon: 'home',
        element: <ProducerLandingPage />
      },
      {
        path: '/profile',
        title: t('producer:MyPage'),
        icon: 'profile',
        element: <ProfilePage />
      },
      {
        path: '/profile/minimum-order',
        title: t('producer:MinimumOrderPageHeader'),
        icon: 'profile',
        element: <MinimumOrderPage />
      },
      {
        path: '/profile/packing',
        title: t('producer:ParcelLabelSettings'),
        icon: 'profile',
        element: <LabelFormatSelector />
      },
      {
        path: '/profile/delivery-route-request/start',
        title: t('producer:DeliveryRouteRequest.PageHeader'),
        icon: 'profile',
        element: <DeliveryRouteRequestPageStart />
      },
      {
        path: '/profile/delivery-route-request/hubs',
        element: <DeliveryRouteRequestPageHub />
      },
      {
        path: '/profile/delivery-route-request/self-delivery-form',
        element: <DeliveryRouteRequestSelfDelivery />
      },
      {
        path: '/profile/delivery-route-request/complete',
        element: <DeliveryRouteRequestPageConfirmation />
      },
      {
        path: '/billing-account',
        title: t('common:BillingAccountHeader'),
        icon: 'profile',
        element: <BillingAccountPage />
      },
      {
        path: '/orders',
        title: `${t('common:MyOrders')}, ${t('common:Delivery_other')}`,
        icon: 'orders',
        element: <OrdersPage />
      },
      {
        path: '/orders/overview',
        title: `${t('common:MyOrders')}, ${t('common:Product_other')}`,
        icon: 'orders',
        element: <OrdersOverviewPage />
      },
      {
        path: '/orders/invoice',
        title: `${t('common:MyOrders')}, ${t('common:Invoicing')}`,
        icon: 'orders',
        element: <OrdersInvoicePage />
      },
      { path: '/orders/by-delivery/:key', element: <OrdersByDeliveryPage /> },
      { path: '/orders/by-delivery/:key/parcels', element: <ParcelsPage /> },
      {
        path: '/generating-parcel-labels',
        element: <GeneratingParcelLabelsPage />
      },
      {
        path: '/orders/by-delivery/:key/packing-list',
        element: <PackingListPage />
      },
      {
        path: '/orders/history',
        title: t('common:OrderHistory'),
        icon: 'orders',
        element: <ProducerOrdersHistoryPage />
      },
      {
        path: '/orders/add',
        loader: () => {
          return redirect('/orders/add/start');
        }
      },
      {
        path: '/orders/add/start',
        title: t('common:MakeNewOrder'),
        icon: 'orders',
        element: <AddOrderStart />
      },
      {
        path: '/orders/add/delivery-date',
        element: <AddOrderDeliveryDateFetcher />
      },
      { path: '/orders/add/products', element: <AddOrderProducts /> },
      { path: '/orders/add/checkout', element: <AddOrderCheckoutPage /> },
      { path: '/orders/:id/adjust', element: <CreateOrderAdjustmentPage /> },
      { path: '/orders/:secret', element: <OrderPageFetcher /> },
      {
        path: '/orders/:secret/add-orderline/*',
        element: <AddOrderLinePage />
      },
      {
        path: '/customers/add',
        loader: () => {
          return redirect('/customers/add/start');
        }
      },
      {
        path: '/customers/add/start',
        title: t('common:MakeNewOrder'),
        icon: 'profile',
        element: <Start />
      },
      {
        path: '/customers/add/review-billing-information',
        title: t('common:MakeNewOrder'),
        icon: 'profile',
        element: <ReviewBillingInformation />
      },
      {
        path: '/customers/add/review-contact-information',
        title: t('common:MakeNewOrder'),
        icon: 'profile',
        element: <ReviewContactInformation />
      },
      {
        path: '/customers/add/review-delivery-information',
        title: t('common:MakeNewOrder'),
        icon: 'profile',
        element: <ReviewDeliveryInformation />
      },
      {
        path: '/orders/:secret/change-delivery-date/*',
        element: <ChangeDeliveryDateFetcher />
      },
      {
        path: '/product-requests',
        title: t('producer:MyRequests'),
        icon: 'messages',
        element: <ProductRequestsPage />
      },
      { path: '/product-requests/:id/*', element: <ProductRequestPage /> },
      {
        path: '/my-products',
        title: t('producer:MyProducts'),
        icon: 'products',
        element: <ProducerProductsPage />
      },
      {
        path: '/my-products/add',
        title: t('producer:productAddPageHeader'),
        icon: 'products',
        element: <AddProductPage />
      },
      { path: '/my-products/:id', element: <EditProductPage /> },
      { path: '/my-products/:id/add-pricing', element: <AddPricingPage /> },
      {
        path: '/my-products/:id/edit-pricing/:key',
        element: <EditPricingPage />
      },
      {
        path: '/customers',
        element: <CustomerListPage />,
        icon: 'customers',
        title: t('producer:Customers')
      },
      {
        path: '/switch-role',
        title: t('common:header.switchRole'),
        icon: 'profile',
        element: <SwitchUserPage />
      },
      { path: '/login', element: <LoginPage /> },
      { path: '/logout', element: <LogoutPage /> },
      { path: '/cookies', element: <CookiesInfoPage /> },
      { path: '/user/verify/:token', element: <VerifyUserEmail /> },
      { path: '/create-consumer', element: <CreateConsumerPage /> },
      { path: '/create-producer', element: <CreateProducerPage /> },
      { path: '/:handle', element: <RootResolver /> },

      {
        path: '/producers/:handle',
        loader: ({ params }) => {
          return redirect(`/${params.handle}`);
        }
      },
      {
        path: '*',
        loader: () => {
          return redirect('/');
        }
      }
    ] satisfies ExtendedRouteObject[]
  }
];

export const producerRouter = () => {
  return createBrowserRouter(producerRoutes());
};
