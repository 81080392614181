import { Typography } from '@dagensmat/carrot/Components';
import { Container } from '@dagensmat/carrot/Layout';
import { DayTotal, DayTotalContainer } from './OrdersForDayTotal.style';

type OrdersForDayTotalProps = {
  label: string;
  subtotal: string;
};

const OrdersForDayTotal = ({ label, subtotal }: OrdersForDayTotalProps) => {
  return (
    <DayTotalContainer>
      <Container flex>
        <Typography
          variant="paragraphSmallBold"
          style={{ order: 0, flex: 'none' }}
        >
          {label}
        </Typography>
        <DayTotal variant="paragraphSmallBold">{`${subtotal}`}</DayTotal>
      </Container>
    </DayTotalContainer>
  );
};

export default OrdersForDayTotal;
