import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@dagensmat/carrot/Components';
import { capitalize } from '@dagensmat/core';
import { formatDate } from 'utils/date/format';
import {
  splitActiveAndPastOrders,
  groupOrdersByDeliveryDate
} from '_common/reducers/orders';
import TextLink from '_common/components/text-link/TextLink.style';
import OrderGroup from '_common/components/order-group/OrderGroup';
import { Order } from 'types/Order';
import { getOrders } from 'api';
import Loader from '_common/components/loader/Loader';
import REQ, { ReqType } from 'utils/REQ';
import { ConsumerOrdersPageHOC } from './ConsumerOrdersPageHOC';
import { useAppSelector } from '../../../_common/hooks/reduxHooks';

type Props = {
  roleId: string | undefined;
};

const ConsumerOrdersPage = ({ roleId }: Props) => {
  const { t } = useTranslation();
  const [orderGroups, setOrderGroups] = useState<
    {
      key: string;
      deliveryDate: Order['deliveryDate'];
      orders: Order[];
    }[]
  >([]);
  const [req, setReq] = useState<ReqType>(REQ.INIT);

  const getConsumerOrders = async () => {
    setReq(REQ.PENDING);
    await getOrders({ consumerId: roleId })
      .then(orders => {
        if (orders.length > 0) {
          const { activeOrders } = splitActiveAndPastOrders(orders);
          const groups = groupOrdersByDeliveryDate(activeOrders);
          setOrderGroups(groups);
        }
        setReq(REQ.SUCCESS);
      })
      .catch(() => {
        setReq(REQ.ERROR);
        throw Error(`Could not fetch orders for consumer id ${roleId}`);
      });
  };

  useEffect(() => {
    getConsumerOrders();
  }, []);

  if (req === REQ.PENDING) return <Loader />;
  return (
    <>
      {orderGroups.length === 0 && (
        <Typography variant="paragraph" color="secondary" my="s">
          {t('common:NoActiveOrder')}
        </Typography>
      )}
      <TextLink to="/orders/history" mt={20}>
        {t('consumer:ViewOrderHistory')}
      </TextLink>
      {orderGroups.map(({ deliveryDate, orders }) => {
        return (
          <OrderGroup
            key={deliveryDate}
            header={capitalize(formatDate(deliveryDate))}
            orders={orders}
            roleType="consumers"
            showDeliveryType
          />
        );
      })}
    </>
  );
};

const ConsumerOrdersPageFetcher = () => {
  const { roleId } = useAppSelector(({ auth: { _id: roleId } }) => {
    return {
      roleId
    };
  });

  return (
    <ConsumerOrdersPageHOC>
      <ConsumerOrdersPage roleId={roleId} />
    </ConsumerOrdersPageHOC>
  );
};

export default ConsumerOrdersPageFetcher;
