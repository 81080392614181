import { Fragment, PropsWithChildren, useEffect, useState } from 'react';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { clsx } from 'clsx';
import { Button } from '../button';
import { Icon, IconType } from '../../theme/icon';
import { NavIconButton } from '@carrot/style-components/navbar/icon-button';

const style = clsx(
  'flex',
  'flex-col',
  'gap-s',
  'border-secondary',
  'p-m',
  'absolute',
  'z-10',
  'origin-top-right',
  'bg-white',
  'focus:outline-none',
  'desktop:pr-xl',
  'desktop:rounded',
  'desktop:border',
  'desktop:inset-auto'
);

const mobileStyle = clsx('inset-0');

// eslint-disable-next-line tailwindcss/no-custom-classname
const animationStyle = clsx(
  'transition',
  'data-[enter]:duration-100',
  'data-[leave]:duration-75',
  'data-[enter]:ease-out',
  'data-[leave]:ease-in',
  'data-[closed]:scale-95',
  'data-[closed]:opacity-0'
);

type Props = PropsWithChildren<{
  heading: string;
}>;

/**
 * @package
 */
export const NavMenu = ({ heading, children }: Props) => {
  const [containerWidth, setContainerWidth] = useState(0);
  useEffect(() => {
    const onResize = () => {
      setContainerWidth(window.innerWidth);
    };
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [close]);

  return (
    <Menu as={Fragment} key={containerWidth}>
      <MenuButton as={Fragment}>
        {({ open }) => <NavIconButton icon={open ? 'close' : 'menu'} />}
      </MenuButton>

      <MenuItems
        anchor="bottom end"
        transition
        className={clsx(style, mobileStyle, animationStyle)}
      >
        <div className={clsx('text-primaryHeading', 'desktop:hidden', 'mb-xs')}>
          {heading}
        </div>
        {children}
      </MenuItems>
    </Menu>
  );
};

type MenuGroupProps = PropsWithChildren<{
  title?: string;
}>;

/**
 * @package
 */
export const NavMenuGroup = ({ title, children }: MenuGroupProps) => {
  return (
    <div className={clsx('flex', 'flex-col', 'gap-xxs')}>
      {title && (
        <div className={clsx('text-paragraphSmall', 'text-secondary')}>
          {title}
        </div>
      )}
      {children}
    </div>
  );
};

/**
 * @package
 */
export const NavMenuDivider = () => {
  return <hr className={clsx('border-lightGrey')} />;
};

type PrimaryItemProps = {
  text: string;
  to: string;
};

/**
 * @package
 */
export const NavMenuPrimaryItem = ({ text, to }: PrimaryItemProps) => {
  return (
    <MenuItem>
      {({ close }) => (
        <div className={clsx('contents')}>
          <Button.MenuItemPrimary to={to} onClick={close}>
            {text}
          </Button.MenuItemPrimary>
        </div>
      )}
    </MenuItem>
  );
};

type SecondaryItemProps = {
  icon?: IconType;
  text: string;
  to: string;
};

/**
 * @package
 */
export const NavMenuSecondaryItem = ({
  icon,
  text,
  to
}: SecondaryItemProps) => {
  return (
    <MenuItem>
      {({ close }) => (
        <div className={clsx('contents')}>
          <Button.MenuItemSecondary to={to} onClick={close}>
            {icon && <Icon icon={icon} size="small" />}
            {text}
          </Button.MenuItemSecondary>
        </div>
      )}
    </MenuItem>
  );
};
