import { LinkProps } from 'react-router-dom';
import { ButtonLink } from './Button.style';

type AddButtonLinkProps = LinkProps & {
  buttonText: string;
  style?: { marginBottom?: string };
};

const AddButtonLink = ({
  to,
  state,
  buttonText,
  style
}: AddButtonLinkProps) => {
  return (
    <ButtonLink to={to} state={state} style={style}>
      {buttonText}
      &nbsp;
      <span style={{ fontSize: '16px', lineHeight: '14px' }}>+</span>
    </ButtonLink>
  );
};

export default AddButtonLink;
