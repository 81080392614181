import { useTranslation } from 'react-i18next';
import ImageFormField from '_common/components/image-uploader/ImageFormField';
import { postAsset } from 'api';
import { SanityImage } from 'types/Sanity';

type ProductImageInputProps = {
  productImage: SanityImage | undefined;
  onChange: (change?: SanityImage) => void;
};

const ProductImageInput = ({
  productImage,
  onChange
}: ProductImageInputProps) => {
  const saveImage = async (file: File) => {
    if (file === null) {
      onChange();
      return;
    }
    const formData = new FormData();
    formData.append('image', file, file.name);
    try {
      const { image } = await postAsset(formData);
      onChange(image);
      formData.delete('image');
    } catch {
      throw new Error('Failed to upload image.');
    }
  };
  const { t } = useTranslation();
  return (
    <ImageFormField
      image={productImage}
      onSave={saveImage}
      label={t('producer:productImageLabel')}
      helpText={t('producer:ImageHelperText')}
      explanationText={t('producer:productImageText')}
    />
  );
};

export default ProductImageInput;
