import { IconDefinitions } from './types';
import { ReactComponent as ginger } from './dagens-shapes/ginger.svg';
import { ReactComponent as onion } from './dagens-shapes/onion.svg';

/**
 * @package
 */
export const dagensShapesIcons = {
  ginger: {
    medium: ginger
  },
  onion: {
    medium: onion
  }
} as const satisfies IconDefinitions;

/**
 * @package
 */
export type DagensShapesIcon = keyof typeof dagensShapesIcons;
