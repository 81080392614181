import { HTMLProps, Ref, forwardRef } from 'react';
import { definedNumber } from '@dagensmat/core';
import { FormFieldInputProps } from 'carrot/form-field/types';

type Props = Omit<HTMLProps<HTMLInputElement>, 'onChange'> &
  FormFieldInputProps & {
    onChange: (value: number | string) => void;
  };

const Component = (
  { onChange, ...rest }: Props,
  ref: Ref<HTMLInputElement>
) => {
  return (
    <input
      type="number"
      min="0"
      onKeyDown={e => {
        if (['-', '+', 'e'].includes(e.key)) {
          e.preventDefault();
        }
      }}
      onChange={({ target: { value: newValue } }) => {
        const newValueFloat = parseFloat(newValue);
        const value = definedNumber(newValueFloat) ? newValueFloat : newValue;
        onChange(value);
      }}
      ref={ref}
      onClick={e => {
        return e.stopPropagation();
      }}
      {...rest}
    />
  );
};

export const FloatInput = forwardRef(Component);
