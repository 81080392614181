import { clsx } from 'clsx';
import { NavLinkMobile, NavLinkProps } from './link';

const style = clsx(
  'grid',
  'grid-flow-col',
  'auto-cols-fr',
  'gap-xs',
  'border-t-thin',
  'border-t-lightGrey',
  'px-s',
  'pb-m'
);

type Props = {
  links: readonly NavLinkProps[];
};

/**
 * @package
 */
export const NavTabs = ({ links }: Props) => {
  return (
    <div className={style}>
      {links.map(link => (
        <div key={link.label}>
          <NavLinkMobile {...link} />
        </div>
      ))}
    </div>
  );
};
