import React from 'react';
import { Container } from '@dagensmat/carrot/Layout';
import REQ, { ReqType } from 'utils/REQ';
import { getNews, postSeenNews } from 'api';
import { News } from 'types/News';
import CloseableCallout from '_common/components/callout/CloseableCallout';

const ProducerMessagesNews = ({
  producerId
}: {
  producerId: string | undefined;
}) => {
  const [req, setReq] = React.useState<ReqType>(REQ.INIT);
  const [news, setNews] = React.useState<News[]>([]);

  React.useEffect(() => {
    async function fetchNews() {
      if (req === REQ.SUCCESS) return;

      setReq(REQ.PENDING);

      const params = { producerId };

      setNews(await getNews(params));
      setReq(REQ.SUCCESS);
    }

    fetchNews();
  }, [req, producerId]);

  const onSeen = async (newsId: any) => {
    const unseenNews = news.filter(({ _id }: { _id: any }) => {
      return _id !== newsId;
    });
    setNews(unseenNews);

    await postSeenNews({ newsId, producerId });
  };

  if (news.length < 1) return null;

  return (
    <>
      {news.map(({ _id, header, message }) => {
        return (
          <Container key={_id} flex flexBasis="48%" flexGrow={1}>
            <CloseableCallout
              header={<strong>{header}</strong>}
              message={message}
              onClose={() => {
                return onSeen(_id);
              }}
            />
          </Container>
        );
      })}
    </>
  );
};

export default ProducerMessagesNews;
