import React from 'react';
import { forSaleStatuses } from '@dagensmat/core';
import Theme from '@dagensmat/carrot/Theme';
import { Typography } from '@dagensmat/carrot/Components';
import { getImageUrl } from 'utils/imageUrls';
import CloseButton from '_common/components/button/CloseButton';
import ProductInfo from '_common/components/product/ProductInfo';
import {
  Content,
  ModalImageContainer as ImageContainer
} from '_common/components/product/Product.style';
import {
  DeliveryDateContext,
  chooseDeliveryDateFromContext
} from '_common/context/DeliveryDateContext';
import SendRequest from '_common/components/product/request-product/SendRequest';
import SingleProductSeason from '_common/components/season/SingleProductSeason';
import ProductTag from '_common/components/product/ProductTag';
import ContactProducerText from '_common/components/product/ContactProducerText';
import LikeButton from '_common/components/button/LikeButton';
import SignUpToTradeButton from '_common/components/product-card/SignUpToTradeButton';
import { useAppSelector } from '_common/hooks/reduxHooks';
import { ProductForSale } from 'types/Product';
import ModalBuyerWrapper from './ModalBuyerWrapper';

type ProductViewProps = {
  product: ProductForSale;
  closeModal: () => void;
  isGuest?: boolean;
  isInSameDA?: boolean;
  isAuthenticated?: boolean;
  isProducer?: boolean;
  userId?: string;
};

const ProductView = ({
  product,
  closeModal,
  isGuest = false,
  isInSameDA = false,
  isAuthenticated = false,
  isProducer,
  userId
}: ProductViewProps) => {
  const selectedDeliveryDate = useAppSelector(({ productsForSale }) => {
    return productsForSale.selectedDeliveryDate;
  });
  const context = React.useContext(DeliveryDateContext);

  const {
    _id: productId,
    description,
    image,
    forSaleStatus,
    seasonCalendar = [],
    name,
    producer
  } = product;

  const isForSale = forSaleStatus === forSaleStatuses.FOR_SALE;
  const isOnRequest = forSaleStatus === forSaleStatuses.ON_REQUEST;
  const imageUrl = getImageUrl(image, { width: 1000 });
  const deliveryDate = chooseDeliveryDateFromContext(
    context,
    selectedDeliveryDate
  );

  const canBuy = isAuthenticated && isInSameDA && !isGuest;

  return (
    <div style={{ position: 'relative' }}>
      <ProductTag product={product} deliveryDate={deliveryDate} />
      <LikeButton productId={productId} />
      <ImageContainer imageUrl={imageUrl} name={name} onClick={closeModal} />
      <div style={{ position: 'relative' }}>
        <CloseButton
          onClick={closeModal}
          style={{
            position: 'absolute',
            right: 0,
            top: 0,
            padding: Theme.Spacings.xxs + Theme.Spacings.xs
          }}
        />
        <Content>
          <ProductInfo
            product={product}
            isAuthenticated={isAuthenticated}
            isProducer={isProducer}
            isGuest={isGuest}
            isInSameDA={isInSameDA}
            isOwnShop={userId === producer._id}
          />
          {(() => {
            if (!isAuthenticated) {
              return <SignUpToTradeButton />;
            }

            if (canBuy && isOnRequest) {
              return <SendRequest productId={productId} />;
            }

            if (canBuy && isForSale) {
              return <ModalBuyerWrapper product={product} />;
            }

            return null;
          })()}
          <Typography
            variant="paragraph"
            mt="xs"
            mb="xs"
            style={{ whiteSpace: 'pre-line' }}
          >
            {description}
          </Typography>
          <SingleProductSeason seasonCalendar={seasonCalendar} />
          {(() => {
            if (canBuy && isForSale) {
              return <ContactProducerText producerId={producer._id} />;
            }

            return null;
          })()}
        </Content>
      </div>
    </div>
  );
};

export default ProductView;
