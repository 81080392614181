import React, { ChangeEvent } from 'react';
import { AUTO_REQUEST_TIMEOUT_MS } from 'config';

type Timeout = ReturnType<typeof setTimeout>;

type DeferChangeProps<T extends string> = {
  value?: T;
  onChange?: (value: T) => void;
  validationFunction?: (value: T) => boolean;
};

const DeferChangeHOC = <InputProps,>(
  InputComponent: React.ComponentType<InputProps>
) => {
  return function withDeferChange<T extends string>({
    value: initialValue,
    onChange,
    validationFunction,
    ...rest
  }: Omit<InputProps, 'onChange'> &
    JSX.IntrinsicAttributes &
    DeferChangeProps<T>) {
    const [pendingTimeout, setPendingTimeout] = React.useState<Timeout | null>(
      null
    );

    const [value, setValue] = React.useState<string | undefined>('');
    const [saved, setSaved] = React.useState(false);

    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value: newValue }
      } = event;
      setSaved(false);
      setValue(newValue);

      if (validationFunction && !validationFunction(newValue as T)) return;

      if (pendingTimeout) {
        clearTimeout(pendingTimeout);
      }

      const timeout = setTimeout(() => {
        onChange?.(newValue as T);
        setSaved(true);
      }, AUTO_REQUEST_TIMEOUT_MS);

      setPendingTimeout(timeout);
    };

    return (
      <InputComponent
        {...(rest as InputProps)}
        value={value}
        onChange={handleChange}
        saved={saved}
      />
    );
  };
};

export default DeferChangeHOC;
