import { Input } from '@carrot/style-components/input';
import { ComboboxInput } from '@headlessui/react';
import { useTranslation } from 'react-i18next';

type Props = {
  query: string;
  setQuery: (query: string) => void;
};

/**
 * @package
 */
export const CommandPaletteInput = ({ query, setQuery }: Props) => {
  const { t } = useTranslation();
  return (
    <ComboboxInput
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus
      as={Input}
      value={query}
      onChange={event => setQuery(event.target.value)}
      placeholder={t('common:Search')}
    />
  );
};
