import { clsx } from 'clsx';
import { PropsWithChildren } from 'react';

type Props = PropsWithChildren;

/**
 * @package
 */
export const Body = ({ children }: Props) => {
  return (
    <tbody
      className={clsx('contents', 'text-paragraphSmall', 'text-secondary')}
    >
      {children}
    </tbody>
  );
};
