import SearchPage from '_common/components/search/SearchPage';
import DeliveryDateBanner from '_common/components/search/DeliveryDateBanner';
import { useAppSelector } from '_common/hooks/reduxHooks';
import { Box } from '@carrot/layout/box';
import { Page } from '@components/page';
import { CommonNavbar } from '@components/navbar';

const CustomerLandingPage = () => {
  const { selectedDeliveryDate } = useAppSelector(
    ({ productsForSale: { selectedDeliveryDate: deliveryDate } }) => {
      return {
        selectedDeliveryDate: deliveryDate
      };
    }
  );

  return (
    <Page
      header={
        <>
          <CommonNavbar showLogo showActionsOnMobile />
          <Box.FullWidth>
            {selectedDeliveryDate && <DeliveryDateBanner />}
          </Box.FullWidth>
        </>
      }
    >
      <SearchPage />
    </Page>
  );
};
export default CustomerLandingPage;
