import { slugifyDate } from 'utils/date/format';
import {
  DeliveryRouteLeg,
  ToFromOrPartner,
  DeliveryType,
  Route
} from 'types/Logistics';
import { Order } from 'types/Order';

/** Pickup types */
export const PRODUCER_DELIVERS = 'PRODUCER_DELIVERS';
export const PRODUCER_TRANSPORTS = 'PRODUCER_TRANSPORTS';
export type PickupType = typeof PRODUCER_DELIVERS | typeof PRODUCER_TRANSPORTS;

const refIsDefined = (ref: ToFromOrPartner): boolean => {
  return Boolean(ref && ref._id && ref.name);
};

const isLegInvalid = (deliveryRouteLeg: DeliveryRouteLeg): boolean => {
  const { from, to, partner, startDate, endDate } =
    deliveryRouteLeg.logisticsLeg;
  return (
    [from, to, partner].some(ref => {
      return !refIsDefined(ref);
    }) ||
    !startDate ||
    !endDate
  );
};

const isRouteInvalid = (deliveryRoute: DeliveryRouteLeg[] = []): boolean => {
  return deliveryRoute.some(isLegInvalid);
};

export const isSelfDelivery = ({ deliveryType }: Order): boolean => {
  return deliveryType === DeliveryType.SELF_DELIVERY;
};

const getTransportStartedByProducerData = (order: Order) => {
  const { deliveryRoute = [], deliveryDate } = order;
  const {
    logisticsLeg: {
      to: { _id: toId, name: toName, deadline: toDeadline },
      endDate
    }
  } = deliveryRoute[0];
  return {
    key: `${endDate}${toId}${deliveryDate}-producer-start`,
    toName,
    producerDeliveryDate: endDate,
    toDeadline
  };
};

type PickupTypeDetails = {
  key: string;
  type: PickupType;
  producerDeliveryDate: string;
  toName?: string;
  toDeadline?: string;
};

export const getPickupType = (order: Order): PickupTypeDetails => {
  const { deliveryRoute = [], deliveryDate } = order;

  if (
    deliveryRoute.length < 1 ||
    isRouteInvalid(deliveryRoute) ||
    isSelfDelivery(order)
  ) {
    return {
      key: `${deliveryDate}-delivered-by-producer`,
      type: PRODUCER_DELIVERS,
      producerDeliveryDate: deliveryDate
    };
  }

  return {
    type: PRODUCER_TRANSPORTS,
    ...getTransportStartedByProducerData(order)
  };
};

const hasLeftProducer = ({
  producer: { _id: producerId },
  deliveryRoute = []
}: Order): boolean => {
  const producerLegIndex = deliveryRoute.findIndex(
    ({
      logisticsLeg: {
        partner: { _id: partnerId }
      }
    }) => {
      return partnerId === producerId;
    }
  );

  const producerCheckPoint =
    producerLegIndex > -1 && deliveryRoute[producerLegIndex].deliveryTime;

  const deliveryRouteAfterProducer =
    producerLegIndex > -1
      ? deliveryRoute.slice(0, producerLegIndex)
      : deliveryRoute;

  const checkPointsAfterProducer = deliveryRouteAfterProducer.find(
    ({ pickupTime, deliveryTime }) => {
      return pickupTime || deliveryTime;
    }
  );

  return [producerCheckPoint, checkPointsAfterProducer].some(Boolean);
};

export const hasSomeOrderNotLeftProducer = (orders: Order[] = []): boolean => {
  return orders.some(order => {
    return !hasLeftProducer(order);
  });
};

export const hasAllOrdersLeftProducer = (orders: Order[] = []): boolean => {
  return orders.every(order => {
    return hasLeftProducer(order);
  });
};

export const isProducerSelfDelivery = ({
  logisticsRoutes = [],
  producer: { _id: producerId }
}: any): boolean => {
  return (
    logisticsRoutes.length === 0 ||
    (logisticsRoutes.length === 1 &&
      (logisticsRoutes[0].partner?._ref || logisticsRoutes[0].partner?._id) ===
        producerId)
  );
};

export const getDagensDeliveryDates = (
  logisticsRoutes: Route[],
  producerId: string | undefined
): string[] => {
  return (
    logisticsRoutes
      .filter(({ route }) => {
        return !isProducerSelfDelivery({
          logisticsRoutes: route,
          producer: { _id: producerId }
        });
      })
      // @ts-expect-error: TODO(strict)
      .map(({ deliveryDate }) => {
        return slugifyDate(deliveryDate);
      })
  );
};

export const isOnlyProducerDeliveryRoutes = (
  logisticsRoutes: Route[],
  producerId: string | undefined
): boolean => {
  return logisticsRoutes.every(({ route }) => {
    return isProducerSelfDelivery({
      logisticsRoutes: route,
      producer: { _id: producerId }
    });
  });
};
