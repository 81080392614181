import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Theme from '@dagensmat/carrot/Theme';
import { Typography } from '@dagensmat/carrot/Components';
import { Container } from '@dagensmat/carrot/Layout';
import { DeliveryDate } from '_common/components/search/DeliveryDateOptions';
import { ButtonLink } from '_common/components/button/Button.style';
import { Order } from 'types/Order';
import { DeliveryType } from 'types/Logistics';
import DeliveryTypeTag from '_common/components/tags/DeliveryTypeTag';
import {
  getPickupType,
  isSelfDelivery,
  PickupType,
  PRODUCER_DELIVERS,
  PRODUCER_TRANSPORTS
} from 'utils/delivery';
import { formatDate } from 'utils/date/format';
import i18n from 'i18n';
import { DeliveryWindow } from 'types/Consumer';

const StyledContainer = styled.div`
  border-top: 1px solid ${Theme.Colours.lightGrey};
`;

type ConsumerDeliveryDetails = {
  name: string;
  deliveryAddress: string;
  deliveryInfo?: string;
  deliveryWindow: DeliveryWindow;
};

type GetProducerDeliveryInfoProps = {
  type: PickupType;
  producerDeliveryDate: DeliveryDate;
  name: string;
  toName?: string;
  toDeadline?: string;
};

const getProducerDeliveryInfo = ({
  type,
  producerDeliveryDate,
  name,
  toName,
  toDeadline
}: GetProducerDeliveryInfoProps): string => {
  if (type === PRODUCER_DELIVERS) {
    return i18n.t('producer:DeliveryToCustomerOnDate', {
      name,
      date: formatDate(producerDeliveryDate)
    });
  }
  if (type === PRODUCER_TRANSPORTS) {
    return toDeadline
      ? i18n.t('producer:DropOffAtWithDeadline', {
          toName,
          date: formatDate(producerDeliveryDate),
          deadline: toDeadline
        })
      : i18n.t('producer:DropOffAt', {
          toName,
          date: formatDate(producerDeliveryDate)
        });
  }
  return '';
};

const SelfDeliveryInformation = ({
  name,
  deliveryAddress,
  deliveryInfo,
  deliveryWindow
}: ConsumerDeliveryDetails) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="paragraphBold" as="p" mt="m">
        {t('producer:DeliverTo')}
      </Typography>
      <Typography variant="paragraphBold" as="p" mt="xs">
        {name}
      </Typography>
      <Typography variant="paragraph">{deliveryAddress}</Typography>
      <Typography variant="paragraphBold" as="p" mt="m">
        {t('producer:Instructions')}
      </Typography>
      <Typography variant="paragraph">
        {deliveryWindow.start} - {deliveryWindow.end}
      </Typography>
      {deliveryInfo && (
        <Typography variant="paragraph" mb="m">
          {deliveryInfo}
        </Typography>
      )}
    </>
  );
};

const OrderDeliveryInformation = ({ order }: { order: Order }) => {
  const {
    deliveryDate,
    secret,
    consumer: { deliveryAddress, deliveryInfo, name, deliveryWindow }
  } = order;

  const { t } = useTranslation();
  const { toName, type, producerDeliveryDate, toDeadline } =
    getPickupType(order);

  return (
    <StyledContainer>
      <Container mt="s">
        <DeliveryTypeTag
          deliveryType={
            isSelfDelivery(order)
              ? DeliveryType.SELF_DELIVERY
              : DeliveryType.DAGENS_DELIVERY
          }
        />
      </Container>
      <Typography variant="tertiaryHeading" mt="xs">
        {t('common:DateOfDelivery', { date: formatDate(deliveryDate) })}
      </Typography>
      <ButtonLink to={`/orders/${secret}/change-delivery-date`}>
        {t('producer:ChangeDeliveryDate')}
      </ButtonLink>
      {type === PRODUCER_DELIVERS ? (
        <SelfDeliveryInformation
          name={name}
          deliveryAddress={deliveryAddress}
          deliveryInfo={deliveryInfo}
          deliveryWindow={deliveryWindow}
        />
      ) : (
        <Typography variant="paragraph" mt="m" mb="xl">
          {getProducerDeliveryInfo({
            type,
            producerDeliveryDate,
            name,
            toName,
            toDeadline
          })}
        </Typography>
      )}
    </StyledContainer>
  );
};

export default OrderDeliveryInformation;
