import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { baseUrl } from 'config';
import PageHeader from '@components/page-header/PageHeader';
import Logger from '../../../services/Logger';
import { Page } from '@components/page';

const GeneratingParcelLabelsPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [query, setQuery] = useState('');

  const getParcelExportPdf = async (exportQuery: string) => {
    Logger.info(
      `Exporting parcel labels for iPhone with query: ${exportQuery}`
    );
    window.location.href = `${baseUrl}/export/parcelLabel${exportQuery}`;
  };

  useEffect(() => {
    const urlQuery = location.search;
    if (urlQuery) {
      setQuery(urlQuery);
    }
  }, [location]);

  useEffect(() => {
    if (query) {
      getParcelExportPdf(query);
    }
  }, [query]);

  return (
    <Page header={null} bottom={null}>
      <PageHeader
        headerText={t('producer:parcelLabelsGenerating')}
        subTitle={t('producer:parcelLabelsDownloadStartingSoon')}
      />
    </Page>
  );
};

export default GeneratingParcelLabelsPage;
