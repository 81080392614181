import { FC, PropsWithChildren } from 'react';
import { Typography } from '@dagensmat/carrot/Components';

export const FormFieldHelpText: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Typography variant="paragraphSmall" color="secondary">
      {children}
    </Typography>
  );
};
