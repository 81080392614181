import { Dialog, DialogBackdrop } from '@headlessui/react';
import { clsx } from 'clsx';
import { PropsWithChildren, useEffect } from 'react';

const dialogStyles = clsx('relative', 'z-20');

const backdropStyles = clsx(
  'bg-lightGrey/25',
  'fixed',
  'inset-0',
  'transition-opacity'
);
// eslint-disable-next-line tailwindcss/no-custom-classname
const backdropDataStyles = clsx(
  'data-[enter]:duration-300',
  'data-[leave]:duration-200',
  'data-[enter]:ease-out',
  'data-[leave]:ease-in',
  'data-[closed]:opacity-0'
);

const contentStyles = clsx(
  'p-m',
  'fixed',
  'inset-0',
  'z-10',
  'w-screen',
  'overflow-y-auto'
);

type Props = PropsWithChildren<{
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
}>;

/**
 * @package
 */
export const CommandPaletteModal = ({
  open,
  children,
  onOpen,
  onClose
}: Props) => {
  useEffect(() => {
    const attachShortcut = (event: KeyboardEvent) => {
      if (
        (event.key === 'k' && event.metaKey) ||
        (event.key === 'k' && event.ctrlKey)
      ) {
        event.preventDefault();
        onOpen();
      }
    };
    document.addEventListener('keydown', attachShortcut);
    return () => {
      document.removeEventListener('keydown', attachShortcut);
    };
  }, []);

  return (
    <Dialog
      transition
      className={dialogStyles}
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <DialogBackdrop
        transition
        className={clsx(backdropStyles, backdropDataStyles)}
      />
      <div className={contentStyles}>{children}</div>
    </Dialog>
  );
};
