import { Container } from '@dagensmat/carrot/Layout';
import { useAppSelector } from '_common/hooks/reduxHooks';
import ProducerMessagesNews from './ProducerMessagesNews';
import ProducerMessagesOrders from './ProducerMessagesOrders';
import ProducerMessagesOnboarding from './ProducerMessagesOnboarding';
import ProducerMessagesWeightVolume from './ProducerMessagesWeightVolume';

const ProducerMessagesSection = () => {
  const { producerId } = useAppSelector(({ auth }) => {
    return {
      producerId: auth._id,
      billingAccount: auth.billingAccount
    };
  });

  return (
    <Container
      mb="xs"
      flex
      wrap="wrap"
      width="full"
      gap="s"
      alignItems="stretch"
    >
      <ProducerMessagesOnboarding />
      <ProducerMessagesOrders />
      <ProducerMessagesNews producerId={producerId} />
      <ProducerMessagesWeightVolume />
    </Container>
  );
};

export default ProducerMessagesSection;
