import { useEffect, useState } from 'react';
import REQ, { ReqType } from 'utils/REQ';
import { getAddress } from '../../api';
import { formatStructuredAddress } from '../../utils/address';
import { AUTO_REQUEST_TIMEOUT_MS } from 'config'; // Import timeout duration
import { useTranslation } from 'react-i18next';

export const useAddressResults = (query: string) => {
  const [req, setReq] = useState<ReqType>(REQ.INIT);
  const [addressesResults, setAddressesResults] = useState<string[]>([]);
  const { t } = useTranslation();
  let timeout: NodeJS.Timeout;

  useEffect(() => {
    if (query === '') {
      setReq(REQ.SUCCESS);
      setAddressesResults([]);
      return;
    }

    setReq(REQ.PENDING);
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      getAddress({ address: query })
        .then(address => {
          setReq(REQ.SUCCESS);
          setAddressesResults([
            formatStructuredAddress({
              ...address,
              country:
                address.country === 'no'
                  ? t('common:Norway')
                  : t('common:Denmark') // ⚠️ ⚠️ Improve this
            })
          ]);
        })
        .catch(() => {
          setReq(REQ.ERROR);
        });
    }, AUTO_REQUEST_TIMEOUT_MS);

    return () => clearTimeout(timeout);
  }, [query]);

  return { addressesReq: req, addressesResults };
};
