import { FC, PropsWithChildren } from 'react';
import { Callout } from '@dagensmat/carrot/Components';
import { Container } from '@dagensmat/carrot/Layout';
import useToggle from '../../_common/hooks/useToggle';
import ToggleHelpCalloutButton from '../../_common/components/callout/ToggleHelpCalloutButton';

export const FormFieldExplanation: FC<PropsWithChildren> = ({ children }) => {
  const [show, toggle] = useToggle(false);
  if (!children) {
    return null;
  }

  return (
    <>
      <Container flexGrow={1}>
        <ToggleHelpCalloutButton onToggle={toggle} isToggled={show} />
      </Container>
      {show && (
        <Callout colour="lightGrey" mb="xxs" mt="xxs">
          {children}
        </Callout>
      )}
    </>
  );
};
