import { FC } from 'react';
import ProductPickerListElement from '_common/components/product-picker-list-element/ProductPickerListElement';
import { ProducerProduct } from 'types/Product';

export type ProductWithUnit = {
  productId: string;
  units: number;
};

type Props = {
  items: ProducerProduct[];
  productsWithUnit: ProductWithUnit[];
  onPick: (id: string, units: number) => void;
  consumerId?: string;
};

export const ProductList: FC<Props> = ({
  items = [],
  productsWithUnit,
  onPick,
  consumerId
}) => {
  return (
    <>
      {items.map(product => {
        const { units = 0 } =
          productsWithUnit.find(({ productId }) => {
            return product._id === productId;
          }) || {};

        return (
          <ProductPickerListElement
            key={product._id}
            product={product}
            units={units}
            onPick={onPick}
            consumerId={consumerId}
          />
        );
      })}
    </>
  );
};
