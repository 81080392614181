import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { keyframes } from 'styled-components';
import { Container } from '@dagensmat/carrot/Layout';
import { MarginProps } from '@dagensmat/carrot/utils/MarginProps';
import { Typography } from '@dagensmat/carrot/Components';
import useResetAnimation from '_common/hooks/useResetAnimation';
import { InputStyles } from '../../../carrot/input-text/styles';
import FormLabel from './FormLabel';
import FormValidationMessage from './FormValidationMessage';

const fadeOut = keyframes`
0%, 90%{
  opacity:1;
}

100% {
  opacity: 0;
}
`;
export const FadeOut = styled.span<{ saved: boolean }>`
  display: inline-block;
  visibility: ${({ saved }) => {
    return saved ? 'visible' : 'hidden';
  }};
  opacity: 0;
  animation: ${fadeOut} ease-in ${3000 / 0.9}ms;
`;

type FormFieldProps = {
  labelText: string;
  helpText?: string;
  placeholder?: string;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type: string;
  value?: string | number;
  savedText?: string;
  autoSave?: boolean;
  saved?: boolean;
  id?: string;
  success?: boolean;
  error?: boolean;
  errorFeedback?: string;
  warning?: boolean;
  margin?: MarginProps;
  maxLength?: number;
  optional?: boolean;
  explanationText?: string;
  autoComplete?: string;
  tabIndex?: number;
  required?: boolean;
  style?: CSSProperties;
};

const FormField = ({
  labelText,
  savedText,
  helpText,
  placeholder = '',
  name,
  onChange,
  type,
  value,
  saved = false,
  id = name,
  success = false,
  error = false,
  errorFeedback,
  margin = { mb: 'xl', mt: 'xl' },
  maxLength,
  autoSave = false,
  optional = false,
  explanationText,
  ...rest
}: FormFieldProps) => {
  const { t } = useTranslation();

  const ref = React.useRef<HTMLElement>(null);
  useResetAnimation(saved, ref);

  return (
    <Container {...margin}>
      <FormLabel
        labelText={labelText}
        helpText={helpText}
        id={id}
        maxLength={maxLength}
        optional={optional}
        explanationText={explanationText}
        value={value}
      />
      <InputStyles
        id={id}
        placeholder={placeholder}
        onChange={onChange}
        name={name}
        success={success}
        error={error}
        type={type}
        value={value}
        maxLength={maxLength}
        {...rest}
      />
      <>
        {error && errorFeedback && (
          <FormValidationMessage message={errorFeedback} />
        )}
        {autoSave && (
          <FadeOut ref={ref} saved={saved}>
            <Typography variant="paragraphSmall" color="secondary">
              {savedText || t('common:Saved')}
            </Typography>
          </FadeOut>
        )}
      </>
    </Container>
  );
};

export default FormField;
