import { useTranslation, Trans } from 'react-i18next';
import Theme from '@dagensmat/carrot/Theme';
import { Callout, Typography } from '@dagensmat/carrot/Components';
import { Container } from '@dagensmat/carrot/Layout';
import { B } from 'utils/fonts';
import { Producer } from 'types/Producer';
import { ButtonLink } from '_common/components/button/Button.style';
import { useAppSelector } from '_common/hooks/reduxHooks';

type ProducerCalloutProps = {
  userId?: string;
  producer: Producer;
};

const ProducerCallout = ({ userId, producer }: ProducerCalloutProps) => {
  const { t } = useTranslation();
  const { dagensId } = useAppSelector(({ auth: { dagensId } }) => {
    return {
      dagensId
    };
  });

  if (userId !== producer._id) return null;

  const producerUrl = `${window.location.host}/${producer.handle}`;

  return (
    <Callout mb="s">
      <>
        <Typography variant="paragraphBold" as="p">
          {t('common:OnlyVisibleForProducerCalloutHeader')}
        </Typography>
        <Typography variant="paragraph" my="s">
          {t('common:OnlyVisibleForProducerCalloutText')}
        </Typography>
        <Typography variant="paragraph" my="s">
          <Trans
            t={t}
            i18nKey="OnlyVisibleForProducerCalloutTextWithProducerUrl"
            values={{ producerUrl }}
            components={{ B: <B /> }}
          />
        </Typography>
        <Container flex justify="flex-start" alignItems="baseline">
          {producer.handle.toLowerCase() === dagensId?.toLowerCase() ? (
            <>
              <ButtonLink
                to="/profile"
                variant="secondary"
                mt={Theme.Spacings.xs}
                mr={Theme.Spacings.xs}
              >
                {t('common:SetCustomShopLink')}
              </ButtonLink>
              <ButtonLink
                to="/my-products"
                variant="borderless"
                mt={Theme.Spacings.xs}
              >
                {t('common:UpdateProducts')}
              </ButtonLink>
            </>
          ) : (
            <>
              <ButtonLink
                to="/my-products"
                variant="secondary"
                mt={Theme.Spacings.xs}
              >
                {t('common:UpdateProducts')}
              </ButtonLink>
              <ButtonLink
                to="/profile"
                variant="borderless"
                mt={Theme.Spacings.xs}
              >
                {t('common:EditInformation')}
              </ButtonLink>
            </>
          )}
        </Container>
      </>
    </Callout>
  );
};

export default ProducerCallout;
