import { getTotalOrderPrice } from '_common/reducers/orders';
import { OrderLine } from 'types/Order';
import { UpdateOrderLinePayload } from 'api';
import { Table } from '_common/components/table/CommonTable.style';
import OrderLineTableHeader from './OrderLineTableHeader';
import OrderLineTableBody from './OrderLineTableBody';
import OrderLineTableFooter from './OrderLineTableFooter';

type Props = {
  orderId: string;
  orderLines: OrderLine[];
  originalOrderLines?: OrderLine[];
  isReadOnly?: boolean;
  hideTableHeader?: boolean;
  isOrderAdjustment?: boolean;
  onUpdateOrder?: () => void;
  onSaveOrderLine?: (payload: UpdateOrderLinePayload) => Promise<void> | void;
};

const OrderLineTable = ({
  orderId,
  orderLines,
  originalOrderLines,
  isReadOnly = false,
  hideTableHeader = false,
  isOrderAdjustment,
  onUpdateOrder,
  onSaveOrderLine
}: Props) => {
  return (
    <Table>
      {!hideTableHeader && <OrderLineTableHeader />}
      <OrderLineTableBody
        orderId={orderId}
        orderLines={orderLines}
        isReadOnly={isReadOnly}
        isOrderAdjustment={isOrderAdjustment}
        onUpdateOrder={onUpdateOrder}
        onSaveOrderLine={onSaveOrderLine}
      />
      <OrderLineTableFooter
        originalTotalPrice={getTotalOrderPrice(
          originalOrderLines || orderLines
        )}
        totalPrice={getTotalOrderPrice(orderLines)}
      />
    </Table>
  );
};

export default OrderLineTable;
