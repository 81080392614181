import { useTranslation } from 'react-i18next';
import { FlattenedOrderLine, getCountAndPriceAndUnit } from '@dagensmat/core';
import { Typography } from '@dagensmat/carrot/Components';
import { Table } from '_common/components/table/CommonTable.style';
import { formatTextWithUnits } from 'utils/texts';
import { isSimplePricing } from 'utils/pricing';
import { Pricing } from 'types/Product';
import ProductReference from '../product-reference/ProductReference';

type OverviewTableProps = {
  orderLineAggregates: FlattenedOrderLine[];
};

const OverviewTable = ({ orderLineAggregates = [] }: OverviewTableProps) => {
  const { t } = useTranslation();
  return (
    <Table>
      <thead>
        <tr>
          <th>
            <Typography variant="paragraphSmallBold" color="secondary">
              {t('common:product_one')}
            </Typography>
          </th>
          <th>
            <Typography variant="paragraphSmallBold" color="secondary">
              {t('common:Quantity')}
            </Typography>
          </th>
        </tr>
      </thead>
      <tbody>
        {orderLineAggregates.map(aggregate => {
          const { unit, count } = getCountAndPriceAndUnit(aggregate);
          return (
            <tr key={aggregate.key}>
              <td>
                <ProductReference product={aggregate.product} variant="small" />
              </td>
              <td>
                <Typography variant="paragraphSmall">
                  {formatTextWithUnits(unit, count)}
                </Typography>
                {!isSimplePricing(
                  aggregate.pricingAtTimeOfOrder as Pricing
                ) && (
                  <Typography
                    variant="paragraphSmall"
                    color="secondary"
                    as="span"
                  >
                    {aggregate.pricingAtTimeOfOrder.pricedUnitsPerOrderedUnit}{' '}
                    {t(`units:${aggregate.pricingAtTimeOfOrder.pricedUnit}`, {
                      count: 1
                    })}{' '}
                    per{' '}
                    {t(`units:${aggregate.pricingAtTimeOfOrder.orderedUnit}`, {
                      count: 1
                    })}
                  </Typography>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
export default OverviewTable;
