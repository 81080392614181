import styled from 'styled-components';
import { definedNumber } from '@dagensmat/core';
import Theme from '@dagensmat/carrot/Theme';
import margins from 'utils/style';

export const InputContainer = styled.div<{
  margin?: number;
  marginRight?: number;
}>`
  margin: ${({ margin = 40 }) => {
    return `${margin}px 0`;
  }};
  margin-right: ${({ marginRight = 0 }) => {
    return `${marginRight}px`;
  }};
`;

const borderColor = ({
  success,
  warning,
  error
}: {
  success?: boolean;
  warning?: boolean;
  error?: boolean;
}) => {
  if (error) return Theme.Colours.error;
  if (warning) return Theme.Colours.warning;
  if (success) return Theme.Colours.black;
  return Theme.Colours.lightGrey;
};

type InputProps = {
  maxWidth?: number;
  block?: boolean;
  textAlign?: string;
  success?: boolean;
  warning?: boolean;
  error?: boolean;
};

const Input = styled.input<InputProps>`
  padding: ${Theme.Spacings.xxs + Theme.Spacings.xs}px;
  margin: 0;
  margin-bottom: ${Theme.Spacings.xxs}px;
  outline: none;
  border: 2px solid
    ${(props: InputProps) => {
      return borderColor(props);
    }};
  border-radius: 5px;
  background: ${Theme.Colours.white};
  font-size: ${Theme.FontSizes.inputText}px;
  font-weight: ${Theme.FontWeights.medium};
  ${margins}

  width: ${({ width = '100%' }) => {
    return width;
  }};
  max-width: ${({ maxWidth }: InputProps) => {
    return definedNumber(maxWidth) && `${maxWidth}px`;
  }};

  display: ${({ block }: InputProps) => {
    return block && 'block';
  }};
  text-align: ${({ textAlign = 'left' }: InputProps) => {
    return textAlign;
  }};

  ::placeholder {
    color: ${Theme.Colours.secondary};
  }

  &:hover {
    border-color: ${Theme.Colours.secondary};
  }

  &:focus:enabled {
    color: ${Theme.Colours.black};
    border-color: ${Theme.Colours.black};
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  &[type='search']::-webkit-search-decoration,
  &[type='search']::-webkit-search-cancel-button,
  &[type='search']::-webkit-search-results-button,
  &[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  transition: border-color ${Theme.Animations.regular};
`;

export default Input;
