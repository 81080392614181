import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Theme from '@dagensmat/carrot/Theme';
import Tag from '_common/components/utils/Tag';
import { OrderStatuses } from 'types/Order';

type StatusTagProps = {
  orderId: string;
  statuses?: OrderStatuses;
  messageFromConsumerToProducer?: string;
  producer?: boolean;
  isSmallOrder?: boolean;
};

const isNewWithMessage = (
  openedByProducer: string | undefined,
  producer: boolean,
  messageFromConsumerToProducer: string | undefined
) => {
  return (
    !openedByProducer && producer && Boolean(messageFromConsumerToProducer)
  );
};

const OrderStatusTag = ({
  orderId,
  statuses: {
    cancelled,
    deliveredToConsumer,
    openedByProducer,
    invoiceSentToDagens,
    invoiceSentToConsumer
  } = { _type: 'orderStatuses' },
  messageFromConsumerToProducer,
  producer = false,
  isSmallOrder
}: StatusTagProps) => {
  const { t } = useTranslation();

  const buffer: ReactNode[] = [];

  if (cancelled)
    return <Tag bgColor={Theme.Colours.error} text={t('common:Cancelled')} />;

  if (invoiceSentToDagens && producer)
    return <Tag bgColor={Theme.Colours.success} text={t('common:Settled')} />;

  if (invoiceSentToConsumer && !producer)
    return <Tag bgColor={Theme.Colours.success} text={t('common:Invoiced')} />;

  if (deliveredToConsumer)
    return (
      <Tag bgColor={Theme.Colours.availability} text={t('common:Delivered')} />
    );

  if (
    isNewWithMessage(openedByProducer, producer, messageFromConsumerToProducer)
  ) {
    buffer.push(
      <Tag
        key={`${orderId}_nwm`}
        bgColor={Theme.Colours.dagensPurple}
        text={t('common:NewOrderWithMessage')}
        mr={Theme.Spacings.xxs}
      />
    );
  } else if (!openedByProducer && producer) {
    buffer.push(
      <Tag
        key={`${orderId}_n`}
        bgColor={Theme.Colours.dagensPurple}
        text={t('common:NewOrder_one')}
        mr={Theme.Spacings.xxs}
      />
    );
  } else if (producer && Boolean(messageFromConsumerToProducer)) {
    buffer.push(
      <Tag
        key={`${orderId}_nm`}
        bgColor={Theme.Colours.messages}
        text={t('common:WithMessage')}
        mr={Theme.Spacings.xxs}
      />
    );
  }

  if (isSmallOrder)
    buffer.push(
      <Tag
        key={`${orderId}_s`}
        bgColor={Theme.Colours.warning}
        text={t('common:SmallOrder')}
        mr={Theme.Spacings.xxs}
      />
    );

  return <>{buffer}</>;
};

export default OrderStatusTag;
