import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container as CarrotContainer } from '@dagensmat/carrot/Layout';
import { useNavigate } from 'react-router-dom';
import PageHeader from '@components/page-header/PageHeader';
import { useAppSelector } from '_common/hooks/reduxHooks';
import NewRadioButtons from '../../../_common/components/radio-buttons/NewRadioButtons';
import { DeliveryType, Hub } from '../../../types/Logistics';
import REQ, { ReqType } from '../../../utils/REQ';
import Logger from '../../../services/Logger';
import { useThrowError } from '../../../utils/errorHandling';
import { getHubsByCountry } from '../../../api';
import DeliveryOption from './DeliveryOption';
import { Page } from '@components/page';
import { BottomActions } from '@components/bottom-actions';
import { Button } from '@carrot/style-components/button';

const getAvailableDistributionAreasFromHubs = (hubsByCountry: Hub[]) => {
  const distributionAreas: { id: string; name: string }[] = [];
  hubsByCountry.forEach(hub => {
    hub.routeOptions.forEach(routeOption => {
      if (
        !distributionAreas.some(area => {
          return area.id === routeOption.distributionAreaId;
        })
      ) {
        distributionAreas.push({
          id: routeOption.distributionAreaId,
          name: routeOption.distributionAreaName
        });
      }
    });
  });
  return distributionAreas;
};

const DeliveryRouteRequestPageStart = () => {
  const [hubsByCountry, setHubsByCountry] = useState<Hub[]>([]);
  const [fetchHubsReq, setFetchHubsReq] = useState<ReqType>(REQ.INIT);
  const { t } = useTranslation();
  const throwAsyncError = useThrowError();
  const navigate = useNavigate();
  const [deliveryType, setDeliveryType] = useState<DeliveryType>();
  const [distributionAreaId, setDistributionAreaId] = useState<
    string | undefined | null
  >();
  const availableDistributionAreas =
    getAvailableDistributionAreasFromHubs(hubsByCountry);

  const { country, producerName } = useAppSelector(({ auth }) => {
    return {
      country: auth.organization?.address.country,
      producerName: auth.name
    };
  });

  const fetchHubsByCountry = async () => {
    if (!country) {
      return;
    }

    setFetchHubsReq(REQ.PENDING);
    await getHubsByCountry({ country })
      .then(res => {
        setFetchHubsReq(REQ.SUCCESS);
        setHubsByCountry(res);
      })
      .catch(e => {
        setFetchHubsReq(REQ.ERROR);
        Logger.error(e);
        throwAsyncError(e);
      });
  };

  const handleDeliveryTypeChange = (typeOrId: string | null | undefined) => {
    if (typeOrId === DeliveryType.SELF_DELIVERY) {
      setDeliveryType(DeliveryType.SELF_DELIVERY);
      setDistributionAreaId(undefined);
    } else {
      setDeliveryType(DeliveryType.DAGENS_DELIVERY);
      setDistributionAreaId(typeOrId);
    }
  };

  useEffect(() => {
    fetchHubsByCountry();
  }, []);

  useEffect(() => {
    if (!deliveryType && availableDistributionAreas.length > 0) {
      handleDeliveryTypeChange(availableDistributionAreas[0].id);
    }
  }, [availableDistributionAreas]);

  if (fetchHubsReq !== REQ.SUCCESS) {
    return <Page.Status req={fetchHubsReq} />;
  }

  if (!availableDistributionAreas) {
    return <Page.NotFound />;
  }

  return (
    <Page
      bottom={
        <BottomActions>
          <Button
            styles={{
              size: 'fullWidth',
              variant: 'primary'
            }}
            onClick={() => {
              navigate(
                `/profile/delivery-route-request${
                  deliveryType === DeliveryType.DAGENS_DELIVERY
                    ? '/hubs'
                    : '/self-delivery-form'
                }`,
                {
                  state: {
                    returnPath: true,
                    deliveryType,
                    distributionAreaId,
                    hubsByCountry
                  }
                }
              );
            }}
          >
            {t('producer:DeliveryRouteRequest.NextButtonStart')}
          </Button>
        </BottomActions>
      }
    >
      <PageHeader
        headerText={t('producer:DeliveryRouteRequest.PageHeader')}
        subTitle={producerName}
      />
      <CarrotContainer mb="l">
        <NewRadioButtons
          labelText={t(
            'producer:DeliveryRouteRequest.RadioButtonHowAndWhereLabelText'
          )}
          radioGroupName="selectDeliveryType"
          currentValue={distributionAreaId || deliveryType}
          onChange={daId => {
            return handleDeliveryTypeChange(daId);
          }}
          options={availableDistributionAreas
            .map(da => {
              return {
                key: da.id,
                value: t(
                  'producer:DeliveryRouteRequest.RadioButtonDeliveryTypeOptionTo',
                  { distributionAreaName: da.name }
                ),
                explanationText: t(
                  'producer:DeliveryRouteRequest.RadioButtonDeliveryTypeDagensExplanationText'
                )
              };
            })
            .concat({
              key: DeliveryType.SELF_DELIVERY,
              value: t(
                'producer:DeliveryRouteRequest.RadioButtonDeliveryTypeOptionSelfDelivery'
              ),
              explanationText: t(
                'producer:DeliveryRouteRequest.RadioButtonDeliveryTypeSelfExplanationText'
              )
            })}
        />
      </CarrotContainer>
      <CarrotContainer gap="xs" mb="m">
        {deliveryType === DeliveryType.DAGENS_DELIVERY ? (
          <DeliveryOption
            deliveryTypesToShow={[DeliveryType.DAGENS_DELIVERY]}
          />
        ) : (
          <DeliveryOption deliveryTypesToShow={[DeliveryType.SELF_DELIVERY]} />
        )}
      </CarrotContainer>
    </Page>
  );
};

export default DeliveryRouteRequestPageStart;
