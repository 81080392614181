import { clsx } from 'clsx';
import { NavLink, NavLinkProps } from './link';

const style = clsx(
  'h-full',
  'w-full',
  'flex',
  'items-center',
  'justify-center',
  'gap-xxs'
);

type Props = {
  links: readonly NavLinkProps[];
};

/**
 * @package
 */
export const NavLinks = ({ links }: Props) => {
  return (
    <div className={style}>
      {links.map(link => (
        <NavLink key={link.label} {...link} />
      ))}
    </div>
  );
};
