import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { postUserInvite, postUserRemove } from '../../../api';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { userUpdated } from '../../reducers/auth';

export const useUsers = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { accountId, loginEmails } = useAppSelector(({ auth }) => {
    return {
      accountId: auth._id,
      loginEmails: auth.loginEmails ? auth.loginEmails : []
    };
  });
  const [feedback, setFeedback] = useState<{
    message: string;
    isError: boolean;
  } | null>(null);

  const [removing, setRemoving] = useState<string[]>([]);
  const hideRemoveButton = loginEmails.length - removing.length <= 1;

  const onInvite = async (value: string) => {
    try {
      await postUserInvite({ accountId, email: value });
      dispatch(userUpdated({ loginEmails: [...loginEmails, value] }));

      setFeedback({
        message: t('common:InviteUserSuccessText', { email: value }),
        isError: false
      });
    } catch (e) {
      setFeedback({
        message: t('common:InviteUserErrorText', { email: value }),
        isError: true
      });
      throw e;
    }
  };

  const onInviteInputChange = () => {
    setFeedback(null);
  };

  const onRemove = async (email: string) => {
    setFeedback(null);
    setRemoving(prev => {
      return [...prev, email];
    });

    await postUserRemove({ accountId, email });
  };

  const onRemoveSuccess = (email: string) => {
    setRemoving(prev => {
      return prev.filter(e => {
        return e !== email;
      });
    });

    const newEmails = loginEmails.filter(e => {
      return e !== email && !removing.includes(e);
    });
    dispatch(userUpdated({ loginEmails: newEmails }));
  };

  const onRemoveError = (email: string) => {
    setRemoving(prev => {
      return prev.filter(e => {
        return e !== email;
      });
    });
  };

  return {
    loginEmails,
    hideRemoveButton,
    feedback,
    onInvite,
    onInviteInputChange,
    onRemove,
    onRemoveSuccess,
    onRemoveError
  };
};
