import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@dagensmat/carrot/Components';
import { Container } from '@dagensmat/carrot/Layout';
import { useEmails } from './use-emails';
import { Email } from './email';
import { AddEmail } from './add-email';
import { Feedback } from './feedback';

export const NotificationSettings: FC = () => {
  const { t } = useTranslation();
  const {
    emails,
    hideRemoveButton,
    feedback,
    onAdd,
    onAddInputChange,
    onRemove,
    onRemoveSuccess,
    onRemoveError
  } = useEmails();

  return (
    <Container mb="xl">
      <Typography variant="paragraphBold" as="p" mb="xs">
        {t('consumer:EmailAddressAlerts')}
      </Typography>
      <ul>
        {emails?.map(email => {
          return (
            <Email
              key={email}
              email={email}
              hideRemoveButton={hideRemoveButton}
              onRemove={onRemove}
              onRemoveSuccess={onRemoveSuccess}
              onRemoveError={onRemoveError}
            />
          );
        })}
      </ul>
      <AddEmail onAdd={onAdd} onAddInputChange={onAddInputChange} />
      <Feedback value={feedback} />
    </Container>
  );
};
