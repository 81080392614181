import { tv } from 'tailwind-variants';

const style = tv({
  base: [
    'absolute',
    'top-0',
    'right-0',
    'bottom-[-2px]',
    'left-0',
    'group-[.active]:border-b',
    'group-[.active]:border-currentColor'
  ]
});

export const ButtonUnderline = () => {
  return <div className={style()} />;
};
