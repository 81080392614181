import {
  NavLinkComponent,
  NavLinkComponentProps
} from '@carrot/style-components/button/components/as-nav-link';
import { ButtonComponent, ButtonComponentProps } from './components/as-button';
import { LinkComponent, LinkComponentProps } from './components/as-link';

type Props = LinkComponentProps | NavLinkComponentProps | ButtonComponentProps;

/**
 * @package
 */
export const ButtonWrapper = (props: Props) => {
  if (props.as === 'link') {
    return <LinkComponent {...props} />;
  }

  if (props.as === 'nav-link') {
    return <NavLinkComponent {...props} />;
  }

  return <ButtonComponent {...props} />;
};
