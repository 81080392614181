import { Link } from 'react-router-dom';
import { Callout, Typography } from '@dagensmat/carrot/Components';
import { ColourNames } from '@dagensmat/carrot/Theme/Colours';

type CalloutLinkListElementProps = {
  to: string;
  background: ColourNames;
  header: string;
  subTitle: string;
  symbol?: string;
};

const CalloutLinkListElement = ({
  to,
  background,
  header,
  subTitle,
  symbol = '→'
}: CalloutLinkListElementProps) => {
  return (
    <Link to={to}>
      <Callout colour={background} my="l">
        <Typography variant="paragraphBold" as="p">
          {header}
        </Typography>
        <Typography variant="paragraph">
          {subTitle}
          &nbsp;
        </Typography>
        <span
          style={{
            position: 'absolute',
            right: 10,
            top: 22,
            fontSize: 20
          }}
        >
          {symbol}
        </span>
      </Callout>
    </Link>
  );
};

export default CalloutLinkListElement;
