import { useMemo, useState } from 'react';
import { Translation, Trans } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { formatPhoneNumber } from '@dagensmat/core';
import { subDays } from 'date-fns';
import { Callout, Typography } from '@dagensmat/carrot/Components';
import REQ, { ReqType } from 'utils/REQ';
import { postOrderUpdate } from 'api';
import { fetchOrders } from '_common/reducers/orders';
import PageHeader from '@components/page-header/PageHeader';
import Button, { ButtonLink } from '_common/components/button/Button.style';
import DeliveryDatePicker, {
  SelectedDeliveryDate
} from '_common/components/delivery-date-picker/DeliveryDatePicker';
import {
  slugifyDate,
  formatDateWithLetters,
  parseDate
} from 'utils/date/format';
import { getDagensDeliveryDates, isSelfDelivery } from 'utils/delivery';
import { hasDeliveryChanged } from 'utils/order';
import { useAppDispatch } from '_common/hooks/reduxHooks';
import { Order } from 'types/Order';
import { Route } from '../../../types/Logistics';
import { Page } from '@components/page';
import { BottomActions } from '@components/bottom-actions';

type ChangeDeliveryDatePageProps = {
  // We need to fix a standardized HOC solution

  req: ReqType;
  roleId?: string;
  order: Order;
  deliveryDates: string[];
  deliveryRoutes: Route[];
};

const ChangeDeliveryDatePage = ({
  roleId,
  order,
  deliveryDates,
  deliveryRoutes
}: ChangeDeliveryDatePageProps) => {
  const [submitReq, setSubmitReq] = useState<ReqType>(REQ.INIT);
  const [deliveryDate, setDeliveryDate] = useState<string>(order.deliveryDate);
  const [withLogistics, setWithLogistics] = useState<boolean>(
    !isSelfDelivery(order)
  );
  const [isDeliveryDateDifferent, setIsDeliveryDateDifferent] =
    useState<boolean>(false);
  const [isWithLogisticsDifferent, setIsWithLogisticsDifferent] =
    useState<boolean>(false);
  const dispatch = useAppDispatch();

  const dagensDeliveryDates = useMemo(() => {
    return getDagensDeliveryDates(deliveryRoutes, order.producer._id);
  }, [JSON.stringify(deliveryRoutes), JSON.stringify(order)]);

  const onSubmit = () => {
    setSubmitReq(REQ.PENDING);

    const payload = {
      orderId: order._id,
      deliveryDate: deliveryDate || order.deliveryDate,
      withLogistics
    };

    postOrderUpdate(payload)
      .then(() => {
        dispatch(fetchOrders({ producerId: roleId }, { clearStore: false }));
        setSubmitReq(REQ.SUCCESS);
      })
      .catch(() => {
        setSubmitReq(REQ.ERROR);
      });
  };

  const deliveryDateChanged = (slugifiedDate: string) => {
    const hasItChanged = hasDeliveryChanged({
      partialOrder: {
        deliveryDate: order.deliveryDate,
        deliveryRoute: order.deliveryRoute,
        producer: order.producer
      },
      newDate: slugifiedDate,
      withLogistics: !isSelfDelivery(order)
    });
    setIsDeliveryDateDifferent(hasItChanged);
    setDeliveryDate(slugifiedDate);
  };

  const withLogisticsChanged = (value: boolean) => {
    const hasItChanged = hasDeliveryChanged({
      partialOrder: {
        deliveryDate: order.deliveryDate,
        deliveryRoute: order.deliveryRoute,
        producer: order.producer
      },
      newDate: slugifyDate(order.deliveryDate),
      withLogistics: value
    });

    setIsWithLogisticsDifferent(hasItChanged);
    setWithLogistics(value);
  };

  const isDagensDeliveryDate = (slugifiedDate: string) => {
    return dagensDeliveryDates.includes(slugifiedDate);
  };

  const backToCurrentOrderUrl = `/orders/${order.secret}`;
  if (submitReq === REQ.SUCCESS) {
    return <Navigate to={backToCurrentOrderUrl} />;
  }

  const deliveryRoute = deliveryRoutes.find(
    // @ts-expect-error: TODO(strict)
    ({ deliveryDate: eDeliveryDate }) => {
      if (deliveryDate) {
        return deliveryDate === slugifyDate(eDeliveryDate);
      }
      return order.deliveryDate === eDeliveryDate;
    }
  );
  const deliveryHubInfo =
    deliveryRoute && deliveryRoute.route.length > 1
      ? // @ts-expect-error: TODO(strict)
        deliveryRoute.route[0].toName
      : '';

  return (
    <Translation>
      {t => {
        return (
          <Page
            bottom={
              <BottomActions>
                <ButtonLink to={backToCurrentOrderUrl}>
                  {t('common:BackWithArrow')}
                </ButtonLink>
                {(submitReq === REQ.INIT || submitReq === REQ.ERROR) && (
                  <Button
                    disabled={
                      !isDeliveryDateDifferent && !isWithLogisticsDifferent
                    }
                    onClick={onSubmit}
                    variant="primary"
                  >
                    {t('producer:ConfirmNewDeliveryDate')}
                  </Button>
                )}
                {submitReq === REQ.PENDING && (
                  <Typography variant="paragraph" color="secondary" mr="s">
                    {t('producer:ChangingDeliveryDate')}{' '}
                    <i className="loading-dots">...</i>
                  </Typography>
                )}
              </BottomActions>
            }
          >
            <PageHeader
              headerText={t('producer:ChangeDeliveryDate')}
              subTitle={t('common:OrderWithNumber', {
                orderNumberString: order.orderNumberString
              })}
            />
            <Typography variant="paragraphBold" as="p" color="secondary">
              {t('producer:ToConsumerName', { name: order.consumer.name })}
            </Typography>
            <Typography variant="paragraph">
              <Trans
                t={t}
                i18nKey="producer:ConsumerContactInfo"
                values={{
                  phone: formatPhoneNumber(order.consumer.phone),
                  contactPerson: order.consumer.contactPerson
                }}
                components={{
                  TypographyLink: (
                    <Typography
                      variant="link"
                      href={`tel:${order.consumer.phone}`}
                    />
                  )
                }}
              />
            </Typography>
            <Callout colour="lightGrey" mt="m" mb="xl">
              {t('producer:NotifyConsumerOnDeliveryChangeCallout', {
                date: formatDateWithLetters(
                  subDays(parseDate(order.deliveryDate), 2)
                )
              })}
            </Callout>
            <DeliveryDatePicker
              mode="single"
              selectedDeliveryDates={
                deliveryDate
                  ? [
                      {
                        deliveryDate,
                        withLogistics
                      }
                    ]
                  : [
                      {
                        deliveryDate: slugifyDate(order.deliveryDate),
                        withLogistics: !isSelfDelivery(order)
                      }
                    ]
              }
              onSelectedDeliveryDateChange={(
                selectedDeliveryDates: SelectedDeliveryDate[]
              ) => {
                const selectedDeliveryDate = selectedDeliveryDates[0];
                deliveryDateChanged(selectedDeliveryDate.deliveryDate);
                withLogisticsChanged(selectedDeliveryDate.withLogistics);
              }}
              isDagensDeliveryDate={isDagensDeliveryDate}
              deliveryDates={deliveryDates}
              deliveryHubInfo={
                deliveryHubInfo
                  ? t('common:DagensDeliveryExplanationText', {
                      deliveryHubInfo
                    })
                  : ''
              }
            />
          </Page>
        );
      }}
    </Translation>
  );
};

export default ChangeDeliveryDatePage;
