import { ButtonComponentProps } from '@carrot/style-components/button/components/as-button';
import { LinkComponentProps } from '@carrot/style-components/button/components/as-link';
import { ButtonWrapper } from '@carrot/style-components/button/wrapper';
import { tv } from 'tailwind-variants';

const style = tv({
  base: ['p-xs', 'text-black']
});

type Props =
  | Omit<ButtonComponentProps, 'styles' | 'className'>
  | Omit<LinkComponentProps, 'styles' | 'className'>;

export const NavIconButton = (props: Props) => {
  return (
    <ButtonWrapper
      {...props}
      styles={{
        variant: 'borderless',
        size: 'regular'
      }}
      className={style()}
    />
  );
};
