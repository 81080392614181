import { ReactNode } from 'react';
import { clsx } from 'clsx';
import { tv } from 'tailwind-variants';

const style = clsx(
  'relative',
  'h-full',
  'overflow-hidden',
  'grid',
  'grid-cols-1',
  'grid-rows-[auto,1fr,auto]',
  'print:overflow-visible'
);

const contentStyle = tv({
  base: ['overflow-y-auto'],
  variants: {
    withPadding: {
      true: 'py-s'
    }
  }
});

type Props = {
  header: ReactNode;
  content: ReactNode;
  bottom?: ReactNode;
  modal?: ReactNode;
  noContentPadding?: boolean;
};

/**
 * @package
 */
export const LayoutPage = ({
  header,
  content,
  bottom,
  modal,
  noContentPadding
}: Props) => {
  return (
    <div className={style}>
      <header>{header}</header>
      <main className={contentStyle({ withPadding: !noContentPadding })}>
        {content}
      </main>
      {bottom && <nav>{bottom}</nav>}
      {modal}
    </div>
  );
};
