import Theme from '@dagensmat/carrot/Theme';
import styled from 'styled-components';
import { Spacing } from 'theme';

const SpacedContainer = styled.div<{ space?: Spacing }>`
  > * {
    margin-bottom: ${({ space = 'xl' }) => {
      return `${Theme.Spacings[space as keyof typeof Theme.Spacings]}px`;
    }};
  }
`;

export default SpacedContainer;
