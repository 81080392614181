import { useState } from 'react';
import { Notifier } from 'airbrake-browser-esm';
import type { ReduxStore } from 'store';
import { environment, gitCommitRef } from 'config';

const setupAirbrake = (reduxStore: ReduxStore) => {
  const airbrake = new Notifier({
    projectId: 280625,
    projectKey: 'b1c61b64fb5887be60fc4b3f5a3f2fd0',
    environment
  });

  airbrake.addFilter(notice => {
    const { auth: { uid, loginEmail } = {} } = reduxStore.getState();

    return {
      ...notice,
      context: {
        ...notice.context,
        version: gitCommitRef,
        user: { id: uid, email: loginEmail }
      }
    };
  });

  return airbrake;
};

export default setupAirbrake;

export const useThrowError = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, setState] = useState();
  return (error: any) => {
    setState(() => {
      throw error;
    });
  };
};
