import styled from 'styled-components';
import Theme from '@dagensmat/carrot/Theme';
import { MediaQuery } from 'utils/mediaQueries';
import ResponsiveImage from './ResponsiveImage.style';

const ImageToTheRightContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  margin-bottom: ${Theme.Spacings.l}px;
  align-items: start;

  > ${ResponsiveImage} {
    width: 50%;
  }

  ${MediaQuery.tabletUp} {
    flex-direction: row;

    > ${ResponsiveImage} {
      width: 30%;
    }

    > div {
      width: 50%;
    }
  }
`;

export default ImageToTheRightContainer;
