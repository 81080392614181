import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from '@components/page-header/PageHeader';
import {
  useAppDispatch,
  useAppSelector
} from '../../../../_common/hooks/reduxHooks';
import { clearBasket } from '../../../../_consumer/reducers/basket';
import { AddOrderConsumerList } from './AddOrderConsumerList';
import { Page } from '@components/page';
import Input from '../../../../_common/components/input/Input.style';
import { CommonNavbar } from '../../../../components/navbar';
import { Box } from '../../../../carrot/layout/box';

const AddOrderStart = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [filterString, setFilterString] = React.useState('');

  const { consumers } = useAppSelector(({ consumers: { items } }) => {
    return { consumers: items };
  });

  useEffect(() => {
    dispatch(clearBasket());
  }, []);

  return (
    <Page
      header={
        <>
          <CommonNavbar />
          <Box.FullWidth>
            <PageHeader
              headerText={t('producer:ChooseCustomer')}
              annotation={`${t('common:MakeNewOrder')} (1/4)`}
            />
            <Input
              placeholder={t('common:SearchInCustomers')}
              value={filterString}
              onChange={({ target: { value } }) => {
                return setFilterString(value);
              }}
            />
          </Box.FullWidth>
        </>
      }
      bottom={null}
    >
      <AddOrderConsumerList
        items={consumers.filter(({ name }) => {
          return (
            !filterString ||
            name.toLowerCase().includes(filterString.toLowerCase())
          );
        })}
      />
    </Page>
  );
};

export default AddOrderStart;
