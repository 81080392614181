import { useState } from 'react';
import { CountryCode } from '@dagensmat/core';
import REQ, { ReqType } from 'utils/REQ';
import { track } from 'utils/mixpanel';
import {
  validateSignup,
  validateConsumer,
  validateProducer,
  validateOrganization,
  ValidationMessage,
  DefaultFields,
  ConsumerFields,
  ProducerFields,
  SignupFields,
  ConsumerSignupFields,
  ProducerSignupFields
} from 'utils/validation';

const defaultValidationState: ValidationMessage<''> = {
  field: '',
  message: ''
};

const defaultFields: DefaultFields = {
  name: '',
  contactPerson: '',
  phone: '',
  orgNumber: '',
  acceptTerms: false
};

const consumerFields: ConsumerFields = {
  deliveryAddress: ''
};

const producerFields: ProducerFields = {
  address: ''
};

const signupFields: SignupFields = {
  email: '',
  password: '',
  passwordRepeat: '',
  howDidYouHearAboutUs: ''
};

type Fields = ConsumerSignupFields | ProducerSignupFields;

export type OrganizationAPIResponse = {
  address?: string;
  country?: CountryCode;
  email?: string;
  exists?: boolean;
  isNotVatRegistered?: boolean;
  name?: string;
  orgNumber?: string;
  phone?: string;
  status: 'ORGANIZATION_FOUND' | 'ORGANIZATION_NOT_FOUND';
};

type CreateRoleOptions<TFields> = {
  fields: TFields;
  validationFunction: (fields: TFields) => void;
  trackFilloutStartedEvent: string;
};

const useCreateRole = <TFields extends Fields>(
  options: CreateRoleOptions<TFields> = {} as CreateRoleOptions<TFields>
): [
  ReqType,
  typeof setReq,
  TFields,
  typeof onFieldChange,
  ValidationMessage,
  typeof validate,
  OrganizationAPIResponse,
  (org: OrganizationAPIResponse) => void
] => {
  const [req, setReq] = useState<ReqType>(REQ.INIT);
  const [formFilloutStarted, setFormFilloutStarted] = useState(false);
  const [validation, setValidation] = useState<ValidationMessage>(
    defaultValidationState
  );
  const [fields, setFields] = useState(options.fields);
  const [organization, setOrganization] = useState<OrganizationAPIResponse>(
    {} as OrganizationAPIResponse
  );

  const validate = () => {
    const validationError =
      validateOrganization(organization) || options.validationFunction(fields);

    if (validationError) {
      setValidation(validationError);
      return false;
    }
    return true;
  };

  const onFieldChange = ({
    target: { name, type, checked, value }
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (!formFilloutStarted) {
      track(options.trackFilloutStartedEvent);
    }

    setReq(REQ.INIT);
    setFormFilloutStarted(true);
    setValidation(defaultValidationState);

    setFields(prevState => {
      return {
        ...prevState,
        [name]: type === 'checkbox' ? checked : value
      };
    });
  };

  const onOrganizationChanged = (org: OrganizationAPIResponse) => {
    setOrganization(org);
    setFields(prevState => {
      return {
        ...prevState,
        ...(org.name && { name: org.name }),
        ...(org.phone && { phone: org.phone }),
        ...(org.address && { deliveryAddress: org.address }),
        ...(org.address && { address: org.address }),
        ...(org.email && { email: org.email })
      };
    });
  };

  return [
    req,
    setReq,
    fields,
    onFieldChange,
    validation,
    validate,
    organization,
    onOrganizationChanged
  ];
};

export const useSignupConsumer = (existingUser = false) => {
  const fields: ConsumerSignupFields = {
    ...defaultFields,
    ...consumerFields,
    ...signupFields
  };
  return useCreateRole({
    fields,
    validationFunction: (fieldsToValidate: ConsumerSignupFields) => {
      return (
        validateConsumer(fieldsToValidate) ||
        (!existingUser && validateSignup(fieldsToValidate))
      );
    },
    trackFilloutStartedEvent: 'Fillout Consumer Signup Started'
  });
};

export const useSignupProducer = (existingUser = false) => {
  const fields: ProducerSignupFields = {
    ...defaultFields,
    ...producerFields,
    ...signupFields
  };
  return useCreateRole({
    fields,
    validationFunction: (fieldsToValidate: ProducerSignupFields) => {
      return (
        validateProducer(fieldsToValidate) ||
        (!existingUser && validateSignup(fieldsToValidate))
      );
    },
    trackFilloutStartedEvent: 'Fillout Producer Signup Started'
  });
};
