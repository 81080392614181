import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Theme from '@dagensmat/carrot/Theme';
import { Typography } from '@dagensmat/carrot/Components';
import { isBeforeMonthIndex } from 'utils/date/format';
import { MonthNumber, Months } from 'types/Utils';

const LetterContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: baseline;
  width: 100%;
`;

const SingleView = styled(Typography)`
  flex-grow: 1;
  margin-top: ${Theme.Spacings.xxs}px;
  text-align: center;
`;

const MultiView = styled(SingleView)`
  height: 100%;
  border-right: 1px dashed ${Theme.Colours.lightGrey};

  :last-child {
    border: none;
  }
`;

type Props = {
  multi?: boolean;
};

const CalendarMonths = ({ multi = false }: Props) => {
  const Letter = multi ? MultiView : SingleView;
  const { t } = useTranslation();
  const months: Months = t('calendar:months', { returnObjects: true });

  return (
    <LetterContainer>
      {months.map((month, i) => {
        return (
          <Letter
            variant="allCapsTitle"
            key={month}
            color={
              isBeforeMonthIndex(i as MonthNumber)
                ? Theme.Colours.secondary
                : Theme.Colours.black
            }
          >
            {month.slice(0, 1)}
          </Letter>
        );
      })}
    </LetterContainer>
  );
};

export default CalendarMonths;
