import { DateRange } from 'react-day-picker';
import Theme from '@dagensmat/carrot/Theme';
import { Container } from '@dagensmat/carrot/Layout';
import { parseDate } from 'utils/date/format';
import Button from '_common/components/button/Button.style';
import DateRangePicker, { initialRange } from './DateRangePicker';

type DateRangePickerWithButtonProps = {
  onSave: (newRange: DateRange) => void | Promise<void>;
  resetButtonText: string;
  saveButtonText: string;
  isSaving?: boolean;
  monthsToShow?: number;
  onChange: (newRange: DateRange) => void;
  value: DateRange;
};

const DateRangePickerWithButtons = ({
  onSave,
  resetButtonText,
  saveButtonText,
  isSaving = false,
  monthsToShow = 3,
  value,
  onChange
}: DateRangePickerWithButtonProps) => {
  return (
    <>
      <DateRangePicker
        dates={value}
        onChange={onChange}
        monthsToShow={monthsToShow}
      />
      <Container flex justify="flex-start">
        <Button
          onClick={() => {
            onChange(initialRange);
            onSave(initialRange);
          }}
          mr={Theme.Spacings.xs}
          disabled={isSaving}
        >
          {resetButtonText}
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            onSave({
              from: parseDate(value.from),
              to: parseDate(value.to)
            });
          }}
          disabled={isSaving || !value.from || !value.to}
        >
          {saveButtonText}
        </Button>
      </Container>
    </>
  );
};

export default DateRangePickerWithButtons;
