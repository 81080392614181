import { useAppSelector } from '_common/hooks/reduxHooks';

export const useOrderResults = (query: string) => {
  const { req, orders } = useAppSelector(({ orders }) => {
    return {
      req: orders.req,
      orders: orders.items
    };
  });

  if (query === '') {
    return { ordersReq: req, orderResults: orders };
  }

  const keywords = query.toLowerCase().split(' ');

  const matches = orders.filter(order => {
    const title = order.orderNumberString.toLowerCase();
    return keywords.some(keyword => title?.includes(keyword));
  });

  const sortedMatches = matches.sort((a, b) =>
    a.orderNumberString.localeCompare(b.orderNumberString)
  );
  return { ordersReq: req, orderResults: sortedMatches };
};
