import { ReactComponent as back } from './navigation/back.svg';
import { ReactComponent as backSmall } from './navigation/back-small.svg';
import { ReactComponent as basket } from './navigation/basket.svg';
import { ReactComponent as basketEmpty } from './navigation/basket-empty.svg';
import { ReactComponent as close } from './navigation/close.svg';
import { ReactComponent as customers } from './navigation/customers.svg';
import { ReactComponent as externalLink } from './navigation/external-link.svg';
import { ReactComponent as externalLinkSmall } from './navigation/external-link-small.svg';
import { ReactComponent as forward } from './navigation/forward.svg';
import { ReactComponent as forwardSmall } from './navigation/forward-small.svg';
import { ReactComponent as home } from './navigation/home.svg';
import { ReactComponent as menu } from './navigation/menu.svg';
import { ReactComponent as messages } from './navigation/messages.svg';
import { ReactComponent as orderNote } from './navigation/order-note.svg';
import { ReactComponent as orderNoteAdded } from './navigation/order-note-added.svg';
import { ReactComponent as orders } from './navigation/orders.svg';
import { ReactComponent as producer } from './navigation/producer.svg';
import { ReactComponent as products } from './navigation/products.svg';
import { ReactComponent as profile } from './navigation/profile.svg';
import { ReactComponent as search } from './navigation/search.svg';
import { ReactComponent as settings } from './navigation/settings.svg';
import { IconDefinitions } from './types';

/**
 * @package
 */
export const navigationIcons = {
  back: {
    small: backSmall,
    medium: back
  },
  basket: {
    medium: basket
  },
  basketEmpty: {
    medium: basketEmpty
  },
  close: {
    medium: close
  },
  customers: {
    medium: customers
  },
  externalLink: {
    small: externalLinkSmall,
    medium: externalLink
  },
  forward: {
    small: forwardSmall,
    medium: forward
  },
  home: {
    medium: home
  },
  menu: {
    medium: menu
  },
  messages: {
    medium: messages
  },
  orderNote: {
    medium: orderNote
  },
  orderNoteAdded: {
    medium: orderNoteAdded
  },
  orders: {
    medium: orders
  },
  producer: {
    medium: producer
  },
  products: {
    medium: products
  },
  profile: {
    medium: profile
  },
  search: {
    medium: search
  },
  settings: {
    medium: settings
  }
} as const satisfies IconDefinitions;

/**
 * @package
 */
export type NavigationIcon = keyof typeof navigationIcons;
