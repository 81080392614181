import { Layout } from '@carrot/layout';
import { Box } from '@carrot/layout/box';
import { CommonNavbar, CommonNavTabs } from '@components/navbar';
import ModalContainer from '../../_common/components/modal/ModalContainer';
import { ReqType } from '../../utils/REQ';
import { ContentLoader } from '@components/content-loader';

/**
 * @package
 */
export type PageBaseProps = {
  header?: React.ReactNode;
  content?: React.ReactNode;
  bottom?: React.ReactNode;
  modal?: React.ReactNode;
  children?: React.ReactNode;
  req?: ReqType | ReqType[];
  noContentPadding?: boolean;
};

/**
 * @package
 */
export const PageBase = ({
  header,
  content,
  bottom,
  modal,
  children,
  req,
  noContentPadding
}: PageBaseProps) => {
  if (req !== undefined && req !== 'SUCCESS') {
    return (
      <Layout.Page
        header={header !== undefined ? header : <CommonNavbar />}
        content={
          <Box.FullWidth>
            <ContentLoader req={req} />
          </Box.FullWidth>
        }
        bottom={bottom !== undefined ? bottom : <CommonNavTabs />}
        noContentPadding={noContentPadding}
      />
    );
  }

  return (
    <Layout.Page
      header={header !== undefined ? header : <CommonNavbar />}
      content={
        content !== undefined ? (
          content
        ) : (
          <Box.FullWidth>{children}</Box.FullWidth>
        )
      }
      bottom={bottom !== undefined ? bottom : <CommonNavTabs />}
      modal={modal !== undefined ? modal : <ModalContainer />}
      noContentPadding={noContentPadding}
    />
  );
};
