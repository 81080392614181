import { useEffect, useRef } from 'react';
import { Container } from '@dagensmat/carrot/Layout';
import {
  markAsSeen,
  getUnseenMessageIds,
  startInterval,
  Message as IMessage
} from '_common/reducers/messages';
import { postMessage, postSeenMessages } from 'api';
import MessageInput from './MessageInput';
import Message from './Message';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';

type Props = {
  thread: IMessage[];
  threadId?: string;
  userId?: string;
  counterPartId?: string;
};

const MessageThread = ({ threadId, thread, userId, counterPartId }: Props) => {
  const dispatch = useAppDispatch();
  const lastMessage = useRef<HTMLDivElement>(null);
  const { roleId } = useAppSelector(({ auth: { _id: roleId } }) => {
    return {
      roleId
    };
  });

  const messagesRendered = (scrollOptions: ScrollIntoViewOptions = {}) => {
    lastMessage.current?.scrollIntoView(scrollOptions);

    const messageIds = getUnseenMessageIds(thread, threadId, userId);

    if (messageIds.length > 0) {
      dispatch(markAsSeen(messageIds));
      postSeenMessages({ messageIds });
    }
  };

  useEffect(() => {
    startInterval(roleId, 2000, dispatch);
    messagesRendered();
    return () => {
      startInterval(roleId, undefined, dispatch);
    };
  }, []);

  useEffect(() => {
    messagesRendered({ behavior: 'smooth' });
  }, [thread.length]);

  return (
    <Container mt="xl">
      <>
        {thread.map(({ _id, fromId, message }) => {
          return (
            <Message key={_id} fromMe={userId === fromId} message={message} />
          );
        })}
      </>
      <div ref={lastMessage} />
      <MessageInput
        submit={message => {
          postMessage({
            fromId: userId,
            toId: counterPartId,
            message,
            threadId
          });
        }}
      />
    </Container>
  );
};

export default MessageThread;
