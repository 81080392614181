import { useTranslation } from 'react-i18next';
import { Typography } from '@dagensmat/carrot/Components';
import { Table } from '_common/components/table/CommonTable.style';
import { formatProductNameAndType } from 'utils/texts';

const RequestedProductLine = ({
  product
}: {
  product?: { name: string; _id: string };
}) => {
  const { t } = useTranslation();
  return (
    <Table>
      <thead>
        <tr>
          <th>
            <Typography variant="paragraphBold" as="p">
              {t('common:product_one')}
            </Typography>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <Typography variant="paragraph">
              {formatProductNameAndType(product ?? {})}
            </Typography>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};
export default RequestedProductLine;
