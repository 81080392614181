import { GetOrganizationFoundResponse } from '../../../../../api';
import { Consumer } from '../../../../../types/Consumer';

type Props = {
  foundOrganization: GetOrganizationFoundResponse | null;
  matchingCustomers: Consumer[];
};

export const BillingCompaniesList = ({
  foundOrganization,
  matchingCustomers
}: Props) => {
  return (
    <>
      {foundOrganization && (
        <ul>
          <li>{foundOrganization.name}</li>
        </ul>
      )}
      {matchingCustomers.length > 0 && (
        <ul>
          {matchingCustomers.map(customer => (
            <li key={customer._id}>Existing consumer: {customer.name}</li>
          ))}
        </ul>
      )}
    </>
  );
};
