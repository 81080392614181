import { Body } from '@carrot/style-components/table/body';
import { Cell } from '@carrot/style-components/table/cell';
import { Foot } from '@carrot/style-components/table/foot';
import { FooterCell } from '@carrot/style-components/table/footer-cell';
import { Head } from '@carrot/style-components/table/head';
import { HeaderCell } from '@carrot/style-components/table/header-cell';
import { Row } from '@carrot/style-components/table/row';
import { Table as TableContainer } from '@carrot/style-components/table/table';

export const Table = Object.assign(TableContainer, {
  Body,
  Cell,
  Foot,
  FooterCell,
  Head,
  HeaderCell,
  Row
});
