import { ChangeEvent, Component } from 'react';
import { Container } from '@dagensmat/carrot/Layout';
import { Typography } from '@dagensmat/carrot/Components';
import REQ from 'utils/REQ';
import CharactersRemaining from './CharactersRemaining';
import TextArea from './TextArea.style';
import { ActionButton } from '@components/action-button';
import { t } from 'i18next';

type TextAreaWithButtonProps = {
  labelText: string;
  placeholder: string;
  maxLength: number;
  value: string;
  submitText: (value: string) => Promise<void>;
};

class TextAreaWithButton extends Component<TextAreaWithButtonProps> {
  state = {
    submitReq: undefined,
    value: ''
  };

  componentDidMount() {
    const { value } = this.props;
    this.setState({ value });
  }

  handleChange = ({ target: { value } }: ChangeEvent<HTMLTextAreaElement>) => {
    const { value: currentValue } = this.state;
    const { maxLength = 150 } = this.props;
    if (
      value.length <= maxLength ||
      (currentValue.length > maxLength && value.length <= currentValue.length)
    ) {
      this.setState({ value, submitReq: REQ.INIT });
    }
  };

  submit = () => {
    const { value } = this.state;
    const { submitText } = this.props;

    this.setState({ submitReq: REQ.PENDING });

    submitText(value)
      .then(() => {
        this.setState({ submitReq: REQ.SUCCESS });
      })
      .catch(() => {
        this.setState({ submitReq: REQ.ERROR });
      });
  };

  render() {
    const { value, submitReq } = this.state;
    const { labelText, placeholder, maxLength = 150 } = this.props;

    return (
      <Container my="m">
        <Typography variant="inputLabel" id={labelText} mb="xxs">
          {labelText}
        </Typography>
        <TextArea
          id={labelText}
          height="15em"
          value={value}
          placeholder={placeholder}
          onChange={this.handleChange}
        />
        {submitReq && (
          <CharactersRemaining
            maxLength={maxLength}
            textLength={value.length}
          />
        )}
        {submitReq && (
          <Container py="s">
            <ActionButton.Save
              size="regular"
              saveReq={submitReq}
              onClick={this.submit}
            >
              {t('common:SaveChanges')}
            </ActionButton.Save>
          </Container>
        )}
      </Container>
    );
  }
}

export default TextAreaWithButton;
