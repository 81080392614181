import { useCallback } from 'react';
import { ProductForSale } from '../../../../types/Product';
import { ProductWithUnit } from '../../add-orderline/ProductList';
import { OrderLine } from '../../../../types/Order';
import { findRightConsumerPrice } from '../../../../utils/pricing';
import Logger from '../../../../services/Logger';

type Args = {
  products: ProductForSale[];
};

export const useGenerateOrderLinesFromProducts = ({ products }: Args) => {
  const generateOrderLines = useCallback(
    (selectedProducts: ProductWithUnit[], consumerId?: string): OrderLine[] => {
      return selectedProducts
        .map(selectedProduct => {
          const product = products.find(p => {
            return p._id === selectedProduct.productId;
          });
          const pricing = findRightConsumerPrice(
            product?.prices ?? [],
            consumerId
          );
          if (!pricing) {
            Logger.error(
              new Error(
                `Missing pricing for product ${selectedProduct.productId} for consumer ${consumerId} when generating order lines.`
              )
            );
            return null;
          }

          return {
            _key: selectedProduct.productId,
            nrOfUnits: selectedProduct.units,
            pricingAtTimeOfOrder: pricing,
            product: {
              _id: selectedProduct.productId,
              name: product?.name,
              type: product?.type,
              temperatureZone: product?.temperatureZone
            }
          };
        })
        .filter(Boolean) as OrderLine[];
    },
    [products]
  );
  return generateOrderLines;
};
