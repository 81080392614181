import React from 'react';
import { Typography } from '@dagensmat/carrot/Components';
import { Container } from '@dagensmat/carrot/Layout';
import { Annotation } from './Annotation';
import { useMediaQuery } from '../../_common/hooks/useMediaQueries';

type PageHeaderProps = {
  headerText?: string;
  subTitle?: React.ReactNode | string;
  annotation?: string;
};

const SubTitle = ({ subTitle }: { subTitle: PageHeaderProps['subTitle'] }) => {
  return React.isValidElement(subTitle) ? (
    subTitle
  ) : (
    <Typography variant="paragraphSmall" color="secondary">
      {subTitle}
    </Typography>
  );
};

const PageHeader = ({
  headerText = '',
  subTitle = '',
  annotation
}: PageHeaderProps) => {
  const { tabletUp } = useMediaQuery();
  return (
    <Container mt={tabletUp ? 'm' : undefined} mb="m">
      <Annotation text={annotation} />
      <Typography variant="primaryHeading" mb="xs">
        {headerText}
      </Typography>
      <SubTitle subTitle={subTitle} />
    </Container>
  );
};

export default PageHeader;
