import { useTranslation } from 'react-i18next';
import Theme from '@dagensmat/carrot/Theme';
import { Typography } from '@dagensmat/carrot/Components';

const getCounterTextColor = (remainingCharacters: number) => {
  if (remainingCharacters > 30) return Theme.Colours.secondary;

  return [
    Theme.Colours.error,
    '#f34e1f',
    '#e8552c',
    '#dd5c38',
    '#d26445',
    '#c76b51',
    '#bc725e',
    '#b17a6a',
    '#a68177',
    '#9b8883',
    Theme.Colours.secondary
  ][Math.floor(remainingCharacters / 3)];
};

type CharactersRemainingProps = {
  textLength: number;
  maxLength: number;
};

const CharactersRemaining = ({
  textLength,
  maxLength
}: CharactersRemainingProps) => {
  const remainingCharacters = Math.max(maxLength - textLength, 0);
  const { t } = useTranslation();

  if (remainingCharacters >= 50) {
    return null;
  }

  return (
    <Typography
      variant="paragraph"
      style={{ color: getCounterTextColor(remainingCharacters) }}
    >
      {t('common:RemainingCharacters', { remainingCharacters })}
    </Typography>
  );
};

export default CharactersRemaining;
