import React from 'react';
import styled from 'styled-components';
import {
  getOrderLineAggregates,
  getCountAndPriceAndUnit,
  defaultKeyFunction as aggregateKeyFunction,
  FlattenedOrderLine
} from '@dagensmat/core';
import { useTranslation } from 'react-i18next';
import Theme from '@dagensmat/carrot/Theme';
import { Typography } from '@dagensmat/carrot/Components';
import { useParams } from 'react-router-dom';
import {
  splitActiveAndPastOrders,
  postAndUpdateOrderStatuses,
  getOrderGroup
} from '_common/reducers/orders';
import { formatProductNameAndType, formatTextWithUnits } from 'utils/texts';
import { formatDateWithWeek } from 'utils/date/format';
import Button from '_common/components/button/Button.style';
import { TOGGLE_PACKING_LIST_AXES, track } from 'utils/mixpanel';
import REQ from 'utils/REQ';
import { MediaQuery } from 'utils/mediaQueries';
import { useAppDispatch, useAppSelector } from '_common/hooks/reduxHooks';
import { Order } from '../../../types/Order';
import { Page } from '@components/page';

const ScrollableTable = styled.div`
  ${MediaQuery.print} {
    @page {
      margin: 0;
      size: auto;
    }
  }

  position: relative;
  overflow: auto;
  width: 100%;
  z-index: 1;

  table {
    font-variant-numeric: lining-nums tabular-nums;
    font-feature-settings:
      'tnum' 1,
      'lnum' 1;
    width: 100%;
    margin: 10px 0;
    min-width: 320px;
    border-spacing: 0;
  }

  th,
  td {
    border-bottom: 1px solid ${Theme.Colours.black};
    border-right: 1px solid ${Theme.Colours.black};
    background-color: ${Theme.Colours.white};
    min-width: 120px;
    width: 120px;

    ${MediaQuery.print} {
      font-size: 10px;
      min-width: 0px;
      border-bottom: 1pt solid ${Theme.Colours.black};
      border-right: 1pt solid ${Theme.Colours.black};

      :first-child {
        position: static;
        min-width: 80px;
        width: 80px;
      }
    }
  }

  p {
    ${MediaQuery.print} {
      font-size: 10px;
    }
  }

  thead {
    border-bottom: 2px solid ${Theme.Colours.black};
  }

  th {
    padding: 8px 4px;
    word-break: break-word;
    vertical-align: bottom;
  }

  td {
    padding: 8px 12px;
  }

  .numeral {
    text-align: center;
    font-weight: 600;
  }

  tbody > tr:nth-child(even) td,
  tbody > tr:nth-child(even) th {
    background-color: ${Theme.Colours.lightGrey};
  }

  tbody > tr:nth-child(odd) td,
  tbody > tr:nth-child(odd) th {
    background-color: ${Theme.Colours.white};
  }

  tr:first-child td,
  tr:first-child th {
    border-top: 1px solid ${Theme.Colours.black};
  }

  tr > :first-child {
    position: sticky;
    left: 0;
    padding-right: 6px;
    z-index: 2;
    border-left: 1px solid ${Theme.Colours.black};
    border-right-width: 2px;
    text-align: right;
    ${MediaQuery.print} {
      position: static;
    }
  }
`;

const TOGGLES = {
  XProductsYOrders: Symbol('X-axis products, Y-axis orders'),
  XOrdersYProducts: Symbol('X-axis orders, Y-axis products')
};

const Th = ({ text, subText }: { text: string; subText: string }) => {
  return (
    <th>
      <Typography variant="paragraphSmall">{text}</Typography>
      {subText}
    </th>
  );
};

const Td = ({ aggregatedLine }: { aggregatedLine?: FlattenedOrderLine }) => {
  if (!aggregatedLine) return <td>&nbsp;</td>;

  const { count } = getCountAndPriceAndUnit(aggregatedLine);

  // eslint-disable-next-line tailwindcss/no-custom-classname
  return <td className="numeral">{count}</td>;
};

const Thead = ({
  theadRow
}: {
  theadRow: { key: string | undefined; text: string; subText: string }[];
}) => {
  return (
    <thead>
      <tr>
        <th>&nbsp;</th>
        {theadRow.map(({ key, text, subText }) => {
          return <Th key={key} text={text} subText={subText} />;
        })}
      </tr>
    </thead>
  );
};

const getCellLine = (order: Order, group: FlattenedOrderLine) => {
  const aggregates = getOrderLineAggregates([order]);
  const found = aggregates.find(orderLine => {
    return aggregateKeyFunction(orderLine) === group.key;
  });
  return found;
};

type Props = {
  orderGroup: {
    orders: Order[];
    producerDeliveryDate: string;
  };
};

const PackingListPage = ({ orderGroup }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { orders = [], producerDeliveryDate } = orderGroup ?? {};

  React.useState(() => {
    dispatch(
      postAndUpdateOrderStatuses(
        orders.map(({ _id }) => {
          return _id;
        }),
        'hasDownloadedPackingList'
      )
    );
  });

  const [displayMode, setDisplayMode] = React.useState(
    TOGGLES.XOrdersYProducts
  );

  const toggle = () => {
    track(TOGGLE_PACKING_LIST_AXES);
    setDisplayMode(mode => {
      return mode === TOGGLES.XProductsYOrders
        ? TOGGLES.XOrdersYProducts
        : TOGGLES.XProductsYOrders;
    });
  };

  React.useEffect(() => {
    const trackPrintOrientation = () => {
      track(`Print Packing List`, { orientation: displayMode.description });
    };

    window.addEventListener('beforeprint', trackPrintOrientation);

    return () => {
      window.removeEventListener('beforeprint', trackPrintOrientation);
    };
  }, [displayMode]);

  const grouped = getOrderLineAggregates(orders);

  const theadRow =
    displayMode === TOGGLES.XOrdersYProducts
      ? orders.map(order => {
          return {
            key: order._id,
            text: order.consumer.name,
            subText: order.orderNumberString
          };
        })
      : grouped.map(line => {
          const { count, unit } = getCountAndPriceAndUnit(line);
          return {
            key: line.key,
            text: formatProductNameAndType(line.product),
            subText: formatTextWithUnits(unit, count)
          };
        });
  return (
    <Page>
      <Button onClick={window.print}>{t('producer:PrintPackingList')}</Button>
      <Typography variant="paragraphSmall" color="secondary" my="s">
        {formatDateWithWeek(producerDeliveryDate)}
      </Typography>
      <Button onClick={toggle}>{t('producer:SwapAxes')}</Button>
      {displayMode === TOGGLES.XOrdersYProducts ? (
        <ScrollableTable>
          <table>
            <Thead theadRow={theadRow} />
            <tbody>
              {grouped.map(group => {
                const { count: groupCount, unit } =
                  getCountAndPriceAndUnit(group);
                return (
                  <tr key={group.key}>
                    <Th
                      text={formatProductNameAndType(group.product)}
                      subText={formatTextWithUnits(unit, groupCount)}
                    />
                    {orders.map(order => {
                      return (
                        <Td
                          key={order._id}
                          aggregatedLine={getCellLine(order, group)}
                        />
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </ScrollableTable>
      ) : (
        <ScrollableTable>
          <table>
            <Thead theadRow={theadRow} />
            <tbody>
              {orders.map(order => {
                return (
                  <tr key={order._id}>
                    <Th
                      text={order.consumer.name}
                      subText={order.orderNumberString}
                    />
                    {grouped.map(group => {
                      return (
                        <Td
                          key={`${order.orderNumberString}-${group.key}`}
                          aggregatedLine={getCellLine(order, group)}
                        />
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </ScrollableTable>
      )}
    </Page>
  );
};

const PackingListPageFetcher = () => {
  const { key } = useParams();
  const { req, orderGroup } = useAppSelector(({ orders: { items, req } }) => {
    const { activeOrders } = splitActiveAndPastOrders(items);
    const orderGroup = getOrderGroup(activeOrders, key);

    return {
      req: req === REQ.SUCCESS && !orderGroup ? REQ.NOT_FOUND : req,
      orderGroup
    };
  });

  if (req !== REQ.SUCCESS) {
    return <Page.Status req={req} />;
  }
  if (!orderGroup) {
    return <Page.NotFound />;
  }

  return <PackingListPage orderGroup={orderGroup} />;
};

export default PackingListPageFetcher;
