export type Color = keyof (typeof dagensTheme)['palette'];
export type ColorValue =
  | (typeof dagensTheme)['palette'][Color]
  | (typeof dagensTheme)['hover'][keyof (typeof dagensTheme)['hover']];
export type Spacing = keyof (typeof dagensTheme)['spacing'];
export type SpacingValue = (typeof dagensTheme)['spacing'][Spacing];

const dagensTheme = {
  palette: {
    white: '#FFFFFF',
    black: '#000000',
    secondary: '#767676',
    lightGrey: '#E6EBE9',
    messages: '#CCBFA3',
    new: '#DDCCFF',
    deepPurple: '#322172',
    noteworthy: '#FFE1BA',
    warning: '#FFAC59',
    darkWarning: '#F27900',
    availability: '#DEE5CF',
    available: '#9BC193',
    season: '#77B36B',
    error: '#FF4713',
    lightError: '#FF9779',
    transparent: 'transparent',
    brightPurple: '#F1EBFF',
    currentColor: 'currentColor'
  } as const,
  hover: { new: '#E7DBFF' },
  borderRadius: { base: 5, small: 3 },
  spacing: {
    border: 2,
    xxs: 4,
    xs: 8,
    s: 16,
    m: 24,
    l: 32,
    xl: 48,
    jumbo: 96
  },
  typography: {
    fontSize: {
      displayTitle: 40,
      primaryHeading: 34,
      secondaryHeading: 28,
      leadText: 20,
      paragraph: 16,
      inputText: 16,
      buttonText: 14,
      paragraphSmall: 14,
      xs: 12
    },
    fontWeight: {
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700
    }
  },
  animationTransition: '240ms ease-in-out'
} as const;

export default dagensTheme;
export type DagensTheme = typeof dagensTheme;
