import Theme from '@dagensmat/carrot/Theme';
import { ProductWithBasket } from '_consumer/reducers/productsForSale';
import EzPicker from '_common/components/picker/EzPicker';
import { DeliveryDate } from '_common/components/search/DeliveryDateOptions';
import ProductReference from '../product-reference/ProductReference';
import { ProductLine } from './ProductsInBasket.style';

type ProductProps = {
  product: ProductWithBasket;
  date: DeliveryDate;
};

const Product = ({ product, date }: ProductProps) => {
  return (
    <ProductLine>
      <ProductReference
        product={product}
        variant="small"
        mr={Theme.Spacings.xs}
      />
      <EzPicker
        product={product}
        selectedDate={date}
        minValue={0}
        variant="small"
      />
    </ProductLine>
  );
};

type ProductsInBasketProps = {
  products: ProductWithBasket[];
  deliveryDate: DeliveryDate;
};

const ProductsInBasket = ({
  products,
  deliveryDate
}: ProductsInBasketProps) => {
  return (
    <>
      {products.map(product => {
        return (
          <Product
            key={`${product._id}`}
            product={product}
            date={deliveryDate}
          />
        );
      })}
    </>
  );
};

export default ProductsInBasket;
