import { ReactNode } from 'react';
import type { NumberInputProps } from '../input-number';
import type { TextInputProps } from '../input-text';
import type { InputWithSelectProps } from '../input-with-select';
import type { SelectMultiProps } from '../select-multi';

export enum FormFieldType {
  NUMBER = 'number',
  NUMBER_WITH_SELECT = 'number-with-select',
  SELECT_MULTI = 'select-multi',
  TEXT = 'text'
}

export type FormFieldInputProps = {
  invalid?: boolean;
  optional?: boolean;
  showError?: boolean;
};

type OmitFormFieldProps<T extends FormFieldBaseProps> = Omit<
  T,
  'optional' | 'showError'
>;

type NumberInputType = {
  type: FormFieldType.NUMBER;
  inputProps: OmitFormFieldProps<NumberInputProps>;
};

type InputWithSelectType<SelectType extends string> = {
  type: FormFieldType.NUMBER_WITH_SELECT;
  inputProps: OmitFormFieldProps<InputWithSelectProps<SelectType>>;
};

type SelectMultiType = {
  type: FormFieldType.SELECT_MULTI;
  inputProps: OmitFormFieldProps<SelectMultiProps>;
};

type TextInputType = {
  type: FormFieldType.TEXT;
  inputProps: OmitFormFieldProps<TextInputProps>;
};

export type FormFieldBaseProps = {
  label?: string | null;
  optional?: boolean;
  explanation?: string | null;
  helpText?: string | null;
  maxLength?: number;
  feedback?: ReactNode;
  valueLength?: number;
  errorMessage?: string | null;
  showError?: boolean;
};

export type InputRenderProps<SelectType extends string> =
  | NumberInputType
  | InputWithSelectType<SelectType>
  | SelectMultiType
  | TextInputType;
