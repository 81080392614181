import { Button } from '@carrot/style-components/button';
import { Table } from '@carrot/style-components/table';
import { Icon } from '@carrot/theme/icon';
import { CustomerActionsModal } from '_producer/pages/customer-list-page/customer-actions-modal';
import { clsx } from 'clsx';
import { useTranslation } from 'react-i18next';
import { Consumer } from 'types/Consumer';

type Props = {
  consumers: Consumer[];
  activeConsumer: Consumer | null;
  onConsumerClick: (consumer: Consumer) => void;
  onModalClose: () => void;
};

/**
 * @package
 */
export const CustomerTable = ({
  consumers,
  activeConsumer,
  onConsumerClick,
  onModalClose
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Table
        className={clsx(
          'grid-cols-[1fr,max-content,1fr,max-content,max-content]'
        )}
      >
        <Table.Head>
          <Table.Row noBorder noHover>
            <Table.HeaderCell>{t('common:Consumer')}</Table.HeaderCell>
            <Table.HeaderCell>{t('common:Contact')}</Table.HeaderCell>
            <Table.HeaderCell>{t('common:DeliveryAddress')}</Table.HeaderCell>
            {/* <Table.HeaderCell>{t('common:LastOrder')}</Table.HeaderCell> */}
            <Table.HeaderCell hidden>{t('common:Actions')}</Table.HeaderCell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {consumers.map(consumer => {
            const {
              _id,
              name,
              contactPerson,
              emails,
              phone,
              structuredDeliveryAddress: {
                addressLine1,
                addressLine2,
                postalCode,
                city
              }
            } = consumer;

            return (
              <Table.Row key={_id}>
                <Table.Cell>
                  <div className={clsx('text-paragraphBold', 'text-black')}>
                    {name}
                  </div>
                  <div>{contactPerson}</div>
                </Table.Cell>
                <Table.Cell>
                  <div>{phone}</div>
                  {emails.map(email => (
                    <div key={email}>{email}</div>
                  ))}
                </Table.Cell>
                <Table.Cell>
                  {addressLine1 && (
                    <>
                      {addressLine1}
                      <br />
                    </>
                  )}
                  {addressLine2 && (
                    <>
                      {addressLine2}
                      <br />
                    </>
                  )}
                  {(postalCode || city) && (
                    <>
                      {postalCode} {city}
                    </>
                  )}
                </Table.Cell>
                {/* <Table.Cell>???</Table.Cell> */}
                <Table.Cell verticalAlign="middle">
                  <Button
                    styles={{
                      variant: 'borderless',
                      size: 'regular'
                    }}
                    onClick={() => onConsumerClick(consumer)}
                  >
                    <Icon icon="meatballs" size="small" />
                  </Button>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      <CustomerActionsModal
        activeConsumer={activeConsumer}
        onClose={onModalClose}
      />
    </>
  );
};
