import { useTranslation } from 'react-i18next';
import { Typography, Callout } from '@dagensmat/carrot/Components';
import { useAppSelector } from '_common/hooks/reduxHooks';

const EmptyBasket = () => {
  const { t } = useTranslation();
  const numProducts = useAppSelector(({ productsForSale: { items = [] } }) => {
    return items.length;
  });

  return (
    <Callout my="xs" colour="lightGrey">
      <Typography variant="paragraph" mb="xs">
        {t('consumer:emptyBasket', { count: numProducts })}
      </Typography>
      <Typography variant="paragraph">
        {t('consumer:moneyToProducer')}
      </Typography>
    </Callout>
  );
};

export default EmptyBasket;
