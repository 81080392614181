import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../tailwind.config';
import { useEffect, useState } from 'react';

const getWindowSize = () => {
  return {
    width: window?.innerWidth,
    height: window?.innerHeight
  };
};

const isIPhone = (): boolean => {
  return /iPhone/.test(navigator.userAgent);
};

export const useMediaQuery = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const config = resolveConfig(tailwindConfig);
  const breakpoints = config.theme.screens;

  useEffect(() => {
    if (!window) {
      return;
    }

    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => {
      return window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    width: windowSize.width,
    height: windowSize.height,
    mobileOnly: windowSize.width < Number(breakpoints.tablet.replace('px', '')),
    tabletUp: windowSize.width >= Number(breakpoints.tablet.replace('px', '')),
    desktopUp:
      windowSize.width >= Number(breakpoints.desktop.replace('px', '')),
    isIphone: isIPhone()
  };
};
