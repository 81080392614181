import { CommandPaletteResult } from '@components/command-palette/result';
import { ComboboxOption } from '@headlessui/react';
import Loader from '_common/components/loader/Loader';
import clsx from 'clsx';
import { t } from 'i18next';
import { Fragment } from 'react/jsx-runtime';
import { ProducerProduct } from 'types/Product';
import { ReqType } from 'utils/REQ';

type Props = {
  req: ReqType;
  results: ProducerProduct[];
};

/**
 * @package
 */
export const CommandPaletteResultsProducts = ({ req, results }: Props) => {
  if (results.length === 0 && req !== 'PENDING') {
    return null;
  }

  return (
    <li key="pages">
      <h2 className={clsx('text-microText', 'px-xs', 'mb-xxs')}>
        {t('common:Products')}
      </h2>
      {req === 'PENDING' ? (
        <Loader />
      ) : (
        <ul className={clsx('flex', 'flex-col', 'justify-start')}>
          {results.map(item => {
            const to = `/my-products/${item._id}`;
            return (
              <ComboboxOption as={Fragment} key={item._id} value={to}>
                {({ focus }) => (
                  <CommandPaletteResult
                    icon="products"
                    text={item.name}
                    to={to}
                    focused={focus}
                  />
                )}
              </ComboboxOption>
            );
          })}
        </ul>
      )}
    </li>
  );
};
