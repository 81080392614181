import {
  track,
  PRODUCT_SEARCH_ADD_FILTER,
  PRODUCT_SEARCH_REMOVE_FILTER
} from 'utils/mixpanel';
import { TagButton } from '_common/components/button/Button.style';
import { mapOptions } from '_common/components/radio-buttons/RadioButtons';
import { KeyValue } from 'types/Utils';

type InclusiveTagFilterProps = {
  tags: KeyValue[];
  selectedTags: string[];
  toggleTag: (id: string | null) => void;
};

const InclusiveTagFilter = ({
  tags = [],
  selectedTags = [],
  toggleTag
}: InclusiveTagFilterProps) => {
  const handleToggleFilter = (t: KeyValue) => {
    toggleTag(t.key);
    track(
      selectedTags.includes(t.value)
        ? PRODUCT_SEARCH_REMOVE_FILTER
        : PRODUCT_SEARCH_ADD_FILTER,
      {
        filter: t.value,
        filterCategory: 'Product Category'
      }
    );
  };

  return (
    <div>
      {mapOptions(tags).map(t => {
        return (
          <TagButton
            key={t.key}
            onClick={() => {
              handleToggleFilter(t);
            }}
            variant={
              t.key && selectedTags.includes(t.key) ? 'secondary' : 'ghost'
            }
          >
            {t.value}
          </TagButton>
        );
      })}
    </div>
  );
};

export default InclusiveTagFilter;
