import { clsx } from 'clsx';

const style = clsx(
  'flex',
  'items-center',
  'justify-center',
  'text-paragraphBold',
  'py-[12px]'
);

type Props = {
  text?: string;
};

/**
 * @package
 */
export const NavContext = ({ text }: Props) => {
  return <div className={style}>{text}</div>;
};
