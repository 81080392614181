import Theme from '@dagensmat/carrot/Theme';
import styled from 'styled-components';
import margins from 'utils/style';

const BASE_FONT_SIZE = 16;
export const FontSizes = {
  xs: BASE_FONT_SIZE * 0.625,
  small: BASE_FONT_SIZE * 0.6875,
  medium: BASE_FONT_SIZE * 0.875,
  large: BASE_FONT_SIZE,
  xl: BASE_FONT_SIZE * 1.125
};

export const Label = styled.label`
  display: block;
  line-height: 1;
  margin-bottom: 9px;
  color: ${({ color = 'inherit' }) => {
    return color;
  }};
  font-weight: 800;
  font-size: ${`${FontSizes.small}px`};
  text-transform: uppercase;
  letter-spacing: 0.09em;
  ${margins}
`;

export const B = styled.strong<{ decoration?: string }>`
  font-weight: ${Theme.FontWeights.semiBold};
  text-decoration: ${({ decoration }) => {
    return decoration || undefined;
  }};
`;
