import React from 'react';
import { Callout, Typography } from '@dagensmat/carrot/Components';
import { Container } from '@dagensmat/carrot/Layout';
import { WidthNames } from '@dagensmat/carrot/utils/WidthProps';
import CloseButton from '_common/components/button/CloseButton';

type CloseableCalloutProps = {
  header: React.ReactNode;
  message: string;
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  width?: WidthNames;
};

const CloseableCallout = ({
  header,
  message,
  onClose,
  width = 'full'
}: CloseableCalloutProps) => {
  return (
    <Callout width={width}>
      <Container
        flex
        wrap="nowrap"
        justify="space-between"
        alignItems="flex-start"
        mb="xxs"
      >
        <Typography variant="paragraph">{header}</Typography>
        <CloseButton onClick={onClose} />
      </Container>
      <Typography variant="paragraph">{message}</Typography>
    </Callout>
  );
};

export default CloseableCallout;
