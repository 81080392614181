import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Theme from '@dagensmat/carrot/Theme';
import { Typography } from '@dagensmat/carrot/Components';
import ConfirmButton from '_common/components/confirm-button/ConfirmButton';
import { MediaQuery } from 'utils/mediaQueries';
import { Order } from 'types/Order';
import { postOrderStatuses } from '../../../api';

const Container = styled.div`
  border-top: 1px solid ${Theme.Colours.lightGrey};
  padding: ${Theme.Spacings.m}px 0;

  ${MediaQuery.print} {
    display: none;
  }
`;

type CancelOrderProps = {
  order: Order;
  onCancel: () => void;
};

const CancelOrder = ({ order, onCancel }: CancelOrderProps) => {
  const { t } = useTranslation();
  const { _id: orderId, statuses: { cancelled, deliveredToConsumer } = {} } =
    order;

  const onConfirm = async () => {
    await postOrderStatuses({ orderIds: [orderId], status: 'cancelled' });
    onCancel();
  };

  if (cancelled || deliveredToConsumer) return null;
  return (
    <Container>
      <Typography variant="paragraphBold" as="p">
        {t('producer:CancelOrder')}
      </Typography>
      <Typography variant="paragraphSmall" my="s" color="secondary">
        {t('producer:IfYouHaveAgreedWithCustomerToCancelDoSoAsSoonAsPossible')}
      </Typography>
      <ConfirmButton
        confirmText={t('producer:SureYouWantToCancelThisOrder')}
        confirmedText={t('producer:OrderWasCancelled')}
        buttonText={t('producer:CancelTheOrder')}
        rejectButtonText={t('common:NoWait')}
        confirmButtonText={t('common:YesIAmSure')}
        buttonVariant="error"
        onConfirm={onConfirm}
      />
    </Container>
  );
};

export default CancelOrder;
