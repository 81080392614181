import { AvailableLanguages } from '@dagensmat/core';
import { useTranslation } from 'react-i18next';
import { Typography } from '@dagensmat/carrot/Components';
import { Container } from '@dagensmat/carrot/Layout';
import styled from 'styled-components';
import Tags from '_common/components/tags/Tags';
import FormLabel from '_common/components/input/FormLabel';
import Icon from '../../../_common/components/utils/Icon';
import { Category } from '../../../types/Product';
import { useAppSelector } from '_common/hooks/reduxHooks';

const WarningMessageTypography = styled(Typography)`
  flex: 1;
`;

const toKeyValue = (
  category: Category,
  roleLang = AvailableLanguages.ENGLISH
) => {
  return {
    key: category._id,
    value: category[`name_${roleLang}`] || category.name
  };
};

type CategoryPickerProps = {
  categoryId: string | undefined;
  onChange: (change: string | null) => void;
  roleLang?: AvailableLanguages;
  categories: Category[];
};

const CategoryPicker = ({
  categoryId,
  onChange,
  categories = []
}: CategoryPickerProps) => {
  const { roleLang } = useAppSelector(({ auth: { roleLang } }) => {
    return {
      roleLang
    };
  });

  const topLevelTags = () => {
    return (
      categories
        .filter(({ parent }) => {
          return !parent;
        })
        // non food categories should be last
        .sort((a, b) => {
          if (a.isNonFood) {
            return 1;
          }
          if (b.isNonFood) {
            return -1;
          }
          return 0;
        })
    );
  };

  const findCategory = (id?: string | null) => {
    return categories.find(({ _id }) => {
      return _id === id;
    });
  };

  const getCurrent = () => {
    return findCategory(categoryId);
  };

  const getChildren = () => {
    const category = getCurrent();
    return category ? category.children : topLevelTags();
  };

  const getCurrentPath = () => {
    const category = getCurrent();
    return category ? category.path : [];
  };

  const deselectTag = (id: string | null) => {
    const selectedTag = findCategory(id);
    const parentId = selectedTag?.parent ? selectedTag.parent._ref : undefined;
    onChange(parentId ?? null);
  };
  const { t } = useTranslation();

  const warningMessage = getCurrent()?.isNonFood
    ? t('producer:productPickCategoryNonFoodWarning')
    : null;

  return (
    <>
      <FormLabel labelText={t('producer:productPickCategoryHeader')} />
      <Tags
        selectedTags={getCurrentPath()?.map(cat => {
          return toKeyValue(cat, roleLang);
        })}
        tags={getChildren()?.map(cat => {
          return toKeyValue(cat, roleLang);
        })}
        selectTag={onChange}
        deselectTag={deselectTag}
      />
      {warningMessage && (
        <Container flex gap="xs" my="s">
          <Icon icon="alert" $size="m" fill="warning" />
          <WarningMessageTypography variant="paragraph">
            {warningMessage}
          </WarningMessageTypography>
        </Container>
      )}
    </>
  );
};

export default CategoryPicker;
