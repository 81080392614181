import { useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useQueryParams = () => {
  const location = useLocation();
  const [queryParams, setQueryParams] = useState(
    new URLSearchParams(location.search)
  );

  const getQueryValue = (key: string) => {
    return queryParams.get(key);
  };

  const setQueryValue = (key: string, value: string) => {
    if (value && value.length > 0) {
      queryParams.set(key, value);
    } else {
      queryParams.delete(key);
    }

    const query = queryParams.toString();
    setQueryParams(new URLSearchParams(query));
    window.history.replaceState({}, '', `${window.location.pathname}?${query}`);
  };
  return {
    getQueryValue,
    setQueryValue
  };
};
