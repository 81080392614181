import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: ['signup', 'producer', 'calendar', 'common', 'consumer', 'units'],
    initImmediate: false,
    fallbackLng: {
      nn: ['nb'],
      no: ['nb'],
      default: ['en']
    },
    defaultNS: 'common',
    nonExplicitSupportedLngs: true,
    interpolation: {
      escapeValue: false
    },
    backend: { loadPath: '/locales/{{lng}}/{{ns}}.json' }
  });

export default i18n;
