import { ButtonHTMLAttributes, forwardRef, Ref } from 'react';
import { Icon, IconType } from '../../theme/icon';
import { Button } from '@carrot/style-components/button';

type Props = Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  'children' | 'className'
> & {
  icon: IconType;
};

const Component = ({ icon, ...props }: Props, ref: Ref<HTMLButtonElement>) => {
  return (
    <Button.NavIcon buttonRef={ref} {...props}>
      <Icon icon={icon} size="medium" />
    </Button.NavIcon>
  );
};

/**
 * @package
 */
export const NavIconButton = forwardRef(Component);
