import { Button } from '@carrot/style-components/button';
import { Icon } from '@carrot/theme/icon';
import { CustomerActionsModal } from '_producer/pages/customer-list-page/customer-actions-modal';
import { clsx } from 'clsx';
import { useTranslation } from 'react-i18next';
import { Consumer } from 'types/Consumer';

type Props = {
  consumers: Consumer[];
  activeConsumer: Consumer | null;
  onConsumerClick: (consumer: Consumer) => void;
  onModalClose: () => void;
};

/**
 * @package
 */
export const CustomerList = ({
  consumers,
  activeConsumer,
  onConsumerClick,
  onModalClose
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <ul className={clsx('flex', 'flex-col', '-mt-s')}>
        {consumers.map(consumer => {
          const {
            _id,
            name,
            contactPerson,
            emails,
            phone,
            structuredDeliveryAddress: {
              addressLine1,
              addressLine2,
              postalCode,
              city
            }
          } = consumer;

          return (
            <li
              key={_id}
              className={clsx(
                'py-s',
                'px-xs',
                'relative',
                'transition-[color,background-color,border-color,opacity]',
                'transition-regular',
                'hover:bg-brightPurple',
                'border-y-thin',
                'border-y-lightGrey'
              )}
            >
              <div className={clsx('flex', 'justify-between', 'mb-xs')}>
                <div>
                  <div className={clsx('text-paragraphBold')}>{name}</div>
                  <div
                    className={clsx('text-paragraphSmall', 'text-secondary')}
                  >
                    {contactPerson}
                  </div>
                </div>
                <Button
                  styles={{
                    variant: 'borderless',
                    size: 'regular'
                  }}
                  className={clsx('before:absolute', 'before:inset-0')}
                  onClick={() => onConsumerClick(consumer)}
                >
                  <Icon icon="meatballs" size="small" />
                </Button>
              </div>

              <div
                className={clsx(
                  'grid',
                  'grid-cols-[max-content,1fr]',
                  'items-center',
                  'gap-x-s',
                  'gap-y-xs',
                  'text-paragraphSmall',
                  'w-full',
                  'overflow-hidden',
                  'text-ellipsis'
                )}
              >
                <div>{t('common:Email')}</div>
                <div
                  className={clsx(
                    'text-secondary',
                    'overflow-hidden',
                    'whitespace-nowrap',
                    'text-ellipsis'
                  )}
                >
                  {emails.map((email, index, array) => (
                    <>
                      <span key={email}>{email}</span>
                      {index < array.length - 1 && <br />}
                    </>
                  ))}
                </div>
                <div>{t('common:Phone')}</div>
                <div className={'text-secondary'}>{phone}</div>
                <div>{t('common:DeliveryAddress')}</div>
                <div className={'text-secondary'}>
                  {addressLine1 && (
                    <>
                      {addressLine1}
                      <br />
                    </>
                  )}
                  {addressLine2 && (
                    <>
                      {addressLine2}
                      <br />
                    </>
                  )}
                  {(postalCode || city) && (
                    <>
                      {postalCode} {city}
                    </>
                  )}
                </div>
                {/* <div>{t('common:LastOrder')}</div>
              <div className={'text-secondary'}>???</div> */}
              </div>
            </li>
          );
        })}
      </ul>
      <CustomerActionsModal
        activeConsumer={activeConsumer}
        onClose={onModalClose}
      />
    </>
  );
};
