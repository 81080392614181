import { ReactComponent as add } from './actions/add.svg';
import { ReactComponent as addSmall } from './actions/add-small.svg';
import { ReactComponent as chevronLeft } from './actions/chevron-left.svg';
import { ReactComponent as chevronRight } from './actions/chevron-right.svg';
import { ReactComponent as clear } from './actions/clear.svg';
import { ReactComponent as clearSmall } from './actions/clear-small.svg';
import { ReactComponent as collapse } from './actions/collapse.svg';
import { ReactComponent as collapseSmall } from './actions/collapse-small.svg';
import { ReactComponent as deleteSmall } from './actions/delete-small.svg';
import { ReactComponent as edit } from './actions/edit.svg';
import { ReactComponent as editSmall } from './actions/edit-small.svg';
import { ReactComponent as expand } from './actions/expand.svg';
import { ReactComponent as expandSmall } from './actions/expand-small.svg';
import { ReactComponent as favorite } from './actions/favorite.svg';
import { ReactComponent as favoriteFilled } from './actions/favorite-filled.svg';
import { ReactComponent as filterSmall } from './actions/filter-small.svg';
import { ReactComponent as link } from './actions/link.svg';
import { ReactComponent as linkLarge } from './actions/link-large.svg';
import { ReactComponent as meatballsSmall } from './actions/meatballs-small.svg';
import { ReactComponent as removeSmall } from './actions/remove-small.svg';
import { IconDefinitions } from './types';

/**
 * @package
 */
export const actionIcons = {
  add: {
    small: addSmall,
    medium: add
  },
  chevronLeft: {
    medium: chevronLeft
  },
  chevronRight: {
    medium: chevronRight
  },
  clear: {
    small: clearSmall,
    medium: clear
  },
  collapse: {
    small: collapseSmall,
    medium: collapse
  },
  delete: {
    small: deleteSmall
  },
  edit: {
    small: editSmall,
    medium: edit
  },
  expand: {
    small: expandSmall,
    medium: expand
  },
  favorite: {
    medium: favorite
  },
  favoriteFilled: {
    medium: favoriteFilled
  },
  filter: {
    small: filterSmall
  },
  link: {
    medium: link,
    large: linkLarge
  },
  meatballs: {
    small: meatballsSmall
  },
  remove: {
    small: removeSmall
  }
} as const satisfies IconDefinitions;

/**
 * @package
 */
export type ActionIcon = keyof typeof actionIcons;
