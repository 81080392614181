import { ReactNode } from 'react';
import { Icon, IconType } from '../../theme/icon';
import { clsx } from 'clsx';
import { To, useLocation } from 'react-router-dom';
import { ThemeAnimation } from '@carrot/theme/animations';
import { Button } from '@carrot/style-components/button';

const useIsActive = (activeOn: string[] = []) => {
  const location = useLocation();
  return activeOn.some(path => location.pathname === path);
};

/**
 * @package
 */
export type NavLinkProps = {
  badge?: ReactNode;
  icon: IconType;
  iconAnimation?: ThemeAnimation;
  label: string;
  to: To;
  activeOn?: string[];
};

/**
 * @package
 */
export const NavLink = ({
  badge,
  icon,
  iconAnimation,
  label,
  to,
  activeOn
}: NavLinkProps) => {
  const isOtherRoutesActive = useIsActive(activeOn);
  return (
    <Button.Nav active={isOtherRoutesActive} to={to}>
      <Icon icon={icon} animation={iconAnimation} size="medium" />
      {label}
      <Button.Underline />
      <Button.BadgeContainer variant="desktop">{badge}</Button.BadgeContainer>
    </Button.Nav>
  );
};

/**
 * @package
 */
export const NavLinkMobile = ({
  badge,
  icon,
  iconAnimation,
  label,
  to,
  activeOn
}: NavLinkProps) => {
  const isOtherRoutesActive = useIsActive(activeOn);
  return (
    <Button.NavMobile active={isOtherRoutesActive} to={to}>
      <div className={clsx('relative')}>
        <Icon icon={icon} animation={iconAnimation} size="medium" />
        <Button.BadgeContainer variant="mobile">{badge}</Button.BadgeContainer>
      </div>
      {label}
    </Button.NavMobile>
  );
};
