/**
 * @package
 */
export const themeColors = {
  currentColor: 'currentcolor',
  availability: '#DEE5CF',
  black: '#000000',
  brightPurple: '#F1EBFF',
  dagensPurple: '#DDCCFF',
  deepPurple: '#322172',
  error: '#FF4713',
  lightGrey: '#E6EBE9',
  messages: '#CCBFA3',
  noteworthy: '#FFE1BA',
  secondary: '#767676',
  success: '#77B36B',
  transparent: 'transparent',
  warning: '#FFAC59',
  white: '#FFFFFF'
} as const;

/**
 * @package
 */
export type ThemeColor = keyof typeof themeColors;
