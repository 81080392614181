import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Theme from '@dagensmat/carrot/Theme';
import { Callout, Typography } from '@dagensmat/carrot/Components';
import { Container as CarrotContainer } from '@dagensmat/carrot/Layout';
import { useAppDispatch } from '_common/hooks/reduxHooks';
import { getUserFirstName } from '_common/reducers/auth';
import {
  hasOrderLinesBeenUpdated,
  isOrderReadOnly,
  postAndUpdateOrderStatuses,
  isOpened,
  isDeliveredToConsumer,
  isActive,
  isOrderSmall,
  isOrderInvoiceSentToDagens,
  resetToOriginalOrderLines
} from '_common/reducers/orders';
import { postOrderLine, UpdateOrderLinePayload } from 'api';
import ExpandableCallout from '_common/components/callout/ExpandableCallout';
import OrderLineTable from '_common/components/orderline-table/OrderLineTable';
import { track, PRODUCER_ORDER_OVERVIEW_PAGE_VIEW } from 'utils/mixpanel';
import Icon from '_common/components/utils/Icon';
import { ButtonLink } from '_common/components/button/Button.style';
import { Order, OrderKind } from 'types/Order';
import OrderMessage from '_common/pages/order/OrderMessage';
import OrderPageHeaderSection from '_common/pages/order/OrderPageHeaderSection';
import OrderAdjustedFeedback from '_common/pages/order/OrderAdjustedFeedback';
import OrderReference from '_common/components/order-reference/OrderReference';
import ConsumerContactInfo from './ConsumerContactInfo';
import CancelOrder from './CancelOrder';
import OrderDeliveryInformation from './OrderDeliveryInformation';
import { Page } from '@components/page';

type IProducerOrderPage = {
  order: Order;
  orderAndAdjustments: OrderKind[];
  onUpdateOrder: () => Promise<void>;
};

const SmallOrderCallout = ({ order }: { order: Order }) => {
  const { t } = useTranslation();

  if (
    !isOrderReadOnly(order) &&
    !isDeliveredToConsumer(order) &&
    isOrderSmall(order)
  ) {
    return (
      <ExpandableCallout
        expandedContent={
          <Typography variant="paragraph">
            {t('producer:SmallOrderCalloutText', {
              firstName: getUserFirstName(order.consumer)
            })}
          </Typography>
        }
      >
        <Typography variant="paragraphBold">
          {t('producer:SmallOrderCalloutHeader')}
        </Typography>
      </ExpandableCallout>
    );
  }
  return null;
};

const ProducerOrderPage = ({
  order,
  orderAndAdjustments,
  onUpdateOrder
}: IProducerOrderPage) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const onUpdateOrderLine = async (payload: UpdateOrderLinePayload) => {
    await postOrderLine(payload);
    await onUpdateOrder();
  };

  const onCancel = () => {
    dispatch(postAndUpdateOrderStatuses([order._id], 'cancelled'));
    onUpdateOrder();
  };

  useEffect(() => {
    if (order) {
      track(PRODUCER_ORDER_OVERVIEW_PAGE_VIEW);
      if (!isOpened(order)) {
        dispatch(postAndUpdateOrderStatuses([order._id], 'openedByProducer'));
      }
    }
  }, [order]);

  const { _id: orderId, consumer, orderLines } = order;

  const isOrderDeliveredToConsumer = isDeliveredToConsumer(order);
  const isReadOnly = isOrderReadOnly(order);
  const isOrderActive = isActive(order);
  const showAddOrderLineButton = !isReadOnly && !isOrderDeliveredToConsumer;
  const showMadeSignificantChangesCallout =
    hasOrderLinesBeenUpdated(orderLines) && !isReadOnly;
  const orderIsAdjusted = orderAndAdjustments.length > 1;
  const showOrderAdjustmentLink =
    !orderIsAdjusted && isOrderInvoiceSentToDagens(order);

  return (
    <Page>
      <OrderPageHeaderSection order={order} />
      <ConsumerContactInfo consumer={consumer} />
      <CarrotContainer mb="s">
        <OrderMessage order={order} pointOfView="producer" />
        <SmallOrderCallout order={order} />
      </CarrotContainer>
      <OrderLineTable
        orderId={orderId}
        orderLines={orderLines}
        originalOrderLines={resetToOriginalOrderLines(orderLines)}
        isReadOnly={isReadOnly}
        onUpdateOrder={onUpdateOrder}
        onSaveOrderLine={onUpdateOrderLine}
      />
      {showAddOrderLineButton && (
        <ButtonLink
          to={`/orders/${order.secret}/add-orderline`}
          variant="secondary"
          mt={Theme.Spacings.s}
        >
          {t('producer:AddOrderLine')}
          <Icon icon="add" ml={Theme.Spacings.xxs} fill="white" />
        </ButtonLink>
      )}
      <OrderReference
        orderId={orderId}
        orderReference={order.orderReference}
        readOnly={isReadOnly}
        my="xl"
        onReferenceChange={onUpdateOrder}
      />
      {showMadeSignificantChangesCallout && (
        <Callout mt="s">
          <Typography variant="paragraph" className="no-print">
            {t(
              'producer:YouHaveMadeSignificantChangesToThisOrderRememberToInformCustomer',
              { firstName: getUserFirstName(consumer) }
            )}
          </Typography>
        </Callout>
      )}
      <OrderAdjustedFeedback
        order={order}
        orderAndAdjustments={orderAndAdjustments}
      />
      {isOrderActive && (
        <CarrotContainer mt="xl">
          <OrderDeliveryInformation order={order} />
        </CarrotContainer>
      )}

      {!isReadOnly && <CancelOrder order={order} onCancel={onCancel} />}
      {showOrderAdjustmentLink && (
        <ButtonLink
          variant="secondary"
          my={Theme.Spacings.xl}
          to={{
            pathname: `/orders/${order._id}/adjust`
          }}
          state={{ returnPath: true }}
        >
          {t('producer:OrderAdjustments.createOrderAdjustment')}
        </ButtonLink>
      )}
    </Page>
  );
};

export default ProducerOrderPage;
