import { PropsWithChildren } from 'react';
import { tv } from 'tailwind-variants';

/**
 * @package
 */
export const cellStyles = tv({
  base: [
    'flex',
    'flex-col',
    'not(:first-child):pl-xxs',
    'not(:last-child):pr-xxs',
    'desktop:px-xs'
  ],
  variants: {
    verticalAlign: {
      top: 'justify-start',
      middle: 'justify-center',
      bottom: 'justify-end'
    }
  },
  defaultVariants: {
    verticalAlign: 'top'
  }
});

type Props = PropsWithChildren & {
  verticalAlign?: keyof typeof cellStyles.variants.verticalAlign;
};

/**
 * @package
 */
export const Cell = ({ verticalAlign, children }: Props) => {
  return <td className={cellStyles({ verticalAlign })}>{children}</td>;
};
