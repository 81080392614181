import { clsx } from 'clsx';
import { tv } from 'tailwind-variants';

/**
 * @package
 */
export const buttonStyle = tv({
  base: [
    'group',
    'inline-flex',
    'items-center',
    'whitespace-nowrap',
    'rounded',
    '!text-button',
    'gap-xs',
    'transition-[color,background-color,border-color,opacity]',
    'transition-regular',
    'print:hidden'
  ],
  variants: {
    variant: {
      primary: [
        'text-black',
        'bg-dagensPurple',
        'hover:text-black',
        'hover:bg-brightPurple',
        'active:text-white',
        'active:bg-deepPurple',
        'disabled:text-secondary',
        'disabled:bg-lightGrey',
        'focus-visible:bg-brightPurple'
      ],
      secondary: [
        'text-white',
        'bg-black',
        'hover:bg-deepPurple',
        'active:text-black',
        'active:bg-brightPurple',
        'disabled:text-secondary',
        'disabled:bg-lightGrey',
        'focus-visible:bg-deepPurple'
      ],
      ghost: [
        'text-black',
        'bg-transparent',
        'ring-2',
        'ring-inset',
        'ring-black',
        'hover:bg-brightPurple',
        'active:text-white',
        'active:bg-black',
        'disabled:text-secondary',
        'disabled:bg-lightGrey',
        'focus-visible:bg-brightPurple'
      ],
      borderless: [
        'text-deepPurple',
        'bg-transparent',
        'hover:bg-brightPurple',
        'active:text-white',
        'active:bg-deepPurple',
        'disabled:text-secondary',
        'disabled:bg-lightGrey',
        'focus-visible:bg-brightPurple'
      ]
    },
    size: {
      small: 'p-xs',
      regular: 'p-s',
      fullWidth: [
        'w-full',
        'p-s',
        'tablet:w-auto',
        'tablet:min-w-[25%]',
        'tablet:max-w-max-content'
      ]
    },
    alignment: {
      left: 'justify-start',
      center: 'justify-center'
    },
    focused: {
      true: ''
    }
  },
  compoundVariants: [
    // Focused/variants styles
    {
      variant: 'primary',
      focused: true,
      className: clsx('bg-brightPurple')
    },
    {
      variant: 'secondary',
      focused: true,
      className: clsx('bg-deepPurple')
    },
    {
      variant: 'ghost',
      focused: true,
      className: clsx('bg-brightPurple')
    },
    {
      variant: 'borderless',
      focused: true,
      className: clsx('bg-brightPurple')
    }
  ],
  defaultVariants: {
    variant: 'primary',
    size: 'regular',
    alignment: 'center',
    focused: false
  }
});

type ButtonVariant = keyof typeof buttonStyle.variants.variant;
type ButtonSize = keyof typeof buttonStyle.variants.size;
type ButtonAlignment = keyof typeof buttonStyle.variants.alignment;

/**
 * @package
 */
export type ButtonStyles = {
  variant: ButtonVariant;
  size: ButtonSize;
  alignment?: ButtonAlignment;
  focused?: boolean;
};
