import styled from 'styled-components';
import Theme from '@dagensmat/carrot/Theme';

const Image = styled.img`
  border-radius: ${Theme.BorderRadius.regular}px;
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  position: relative;
  overflow: hidden;
  padding-bottom: 100%;
  max-width: 490px;
  max-height: 490px;
  margin-bottom: ${Theme.Spacings.xs}px;
`;

const ImagePreviewSimple = ({ imageUrl }: { imageUrl?: string | null }) => {
  if (!imageUrl) {
    return null;
  }

  return (
    <Container>
      <Image src={imageUrl} />
    </Container>
  );
};

export default ImagePreviewSimple;
