import clsx from 'clsx';
import { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  noBorder?: boolean;
  noHover?: boolean;
}>;

/**
 * @package
 */
export const Row = ({ noBorder, noHover, children }: Props) => {
  return (
    <tr
      className={clsx(
        'grid',
        'grid-cols-subgrid',
        'col-span-full',
        'transition-[color,background-color,border-color,opacity]',
        'transition-regular',
        'py-s',
        !noBorder && 'border-y-thin',
        !noBorder && 'border-y-lightGrey',
        !noHover && 'hover:bg-brightPurple'
      )}
    >
      {children}
    </tr>
  );
};
