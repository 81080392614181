import { Theme } from '@dagensmat/carrot';
import styled from 'styled-components';

export const SelectWrapper = styled.div<{ width?: string }>`
  --chevron-size: ${Theme.Spacings.s}px;
  display: inline-block;
  position: relative;
  backface-visibility: hidden;
  width: ${({ width }) => {
    return width;
  }};

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: var(--chevron-size);
    height: var(--chevron-size);
    top: 50%;
    right: var(--chevron-size);
    transform: translate3d(0, -50%, 0);
    background-image: url(/assets/chevron.svg);
    pointer-events: none;
  }
`;
