import { Container } from '@dagensmat/carrot/Layout';
import { Typography } from '@dagensmat/carrot/Components';
import { useTranslation } from 'react-i18next';
import OrderStatusTag from '_common/components/status-tag/OrderStatusTag';
import LinkListElement from '_common/components/link-list-element/LinkListElement';
import { Order } from 'types/Order';
import { formatDate } from 'utils/date/format';
import DeliveryTypeTag from '../tags/DeliveryTypeTag';

type OrderGroupProps = {
  header: string;
  orders?: Order[];
  roleType?: 'user' | 'consumers' | 'producers';
  showDeliveryType?: boolean;
};

const OrderGroup = ({
  header,
  orders = [],
  roleType = 'producers',
  showDeliveryType = false
}: OrderGroupProps) => {
  const { t } = useTranslation();

  return (
    <Container my="xl">
      <Typography variant="tertiaryHeading" as="h2" mb="none">
        {header}
      </Typography>
      <>
        {orders.map(
          ({
            _id,
            secret,
            consumer: { name: consumerName },
            producer: { name: producerName },
            orderNumberString,
            statuses,
            deliveryType,
            deliveryDate
          }) => {
            const { deliveredToConsumer } = statuses;
            const listHeader = `${
              roleType === 'producers' ? consumerName : producerName
            } (${orderNumberString})`;
            const listSubTitle = `${
              deliveredToConsumer
                ? t('common:Delivered')
                : t('common:Delivery_one')
            } ${formatDate(deliveryDate)}`;

            return (
              <LinkListElement
                key={_id}
                header={listHeader}
                subTitle={listSubTitle}
                to={`/orders/${secret}`}
                state={{ returnPath: true }}
              >
                {showDeliveryType && (
                  <DeliveryTypeTag
                    deliveryType={deliveryType}
                    pointOfView="consumer"
                  />
                )}
                <OrderStatusTag
                  orderId={_id}
                  statuses={statuses}
                  producer={roleType === 'producers'}
                />
              </LinkListElement>
            );
          }
        )}
      </>
    </Container>
  );
};

export default OrderGroup;
