import { DeliveryRouteLeg } from '../types/Logistics';
import { slugifyDate } from './date/format';
import { isProducerSelfDelivery } from './delivery';

export const getFirstConsumerEmail = (emails: string[]): string => {
  return (
    emails.filter(email => {
      return !email.includes('@dagens');
    })[0] || ''
  );
};

export const hasDeliveryChanged = ({
  partialOrder,
  newDate,
  withLogistics
}: {
  partialOrder: {
    deliveryDate: string;
    deliveryRoute: DeliveryRouteLeg[];
    producer: { _id: string };
  };
  newDate: string;
  withLogistics: boolean;
}): boolean => {
  const { deliveryDate: currentDeliveryDate } = partialOrder;
  const hasDeliveryDateChanged = slugifyDate(currentDeliveryDate) !== newDate;
  const isOrderProducerSelfDelivery = isProducerSelfDelivery({
    logisticsRoutes: partialOrder.deliveryRoute.map(dr => {
      return dr.logisticsLeg;
    }),
    producer: partialOrder.producer
  });
  const hasDeliveryMethodChanged =
    withLogistics === isOrderProducerSelfDelivery;

  return hasDeliveryDateChanged || hasDeliveryMethodChanged;
};
