import { useState, useEffect } from 'react';
import queryString from 'query-string';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isEqual, sortBy, indexOf } from 'lodash';
import Theme from '@dagensmat/carrot/Theme';
import { Typography } from '@dagensmat/carrot/Components';
import {
  Container as CarrotContainer,
  Container
} from '@dagensmat/carrot/Layout';
import { getOrders, getParcelsByOrderId, postParcelsCount } from 'api';
import REQ, { ReqType } from 'utils/REQ';
import { Parcel, ParcelStatus } from 'types/Logistics';
import { TemperatureZone } from 'types/Product';
import { track, DOWNLOAD_PARCEL_LABELS } from 'utils/mixpanel';
import { useThrowError } from 'utils/errorHandling';
import ParcelInput from '_producer/pages/parcels/ParcelInput';
import PageHeader from '@components/page-header/PageHeader';
import {
  getOrderGroup,
  splitActiveAndPastOrders
} from '_common/reducers/orders';
import { Order } from 'types/Order';
import { formatDate } from 'utils/date/format';
import { MediaQuery } from 'utils/mediaQueries';
import Logger from 'services/Logger';
import { useAppSelector } from '_common/hooks/reduxHooks';
import { ButtonLink } from '_common/components/button/Button.style';
import { LabelFormat } from '_common/reducers/auth';
import Icon from '_common/components/utils/Icon';
import CheckboxWithLabel from '../../../_common/components/checkbox/CheckboxWithLabel';
import { useMediaQuery } from '../../../_common/hooks/useMediaQueries';
import { baseUrl } from '../../../config';
import { Page } from '@components/page';
import { BottomActions } from '@components/bottom-actions';
import { ActionButton } from '@components/action-button';

const OrderRow = styled.div`
  padding: ${Theme.Spacings.s}px 0;
  border-top: solid 2px ${Theme.Colours.lightGrey};

  :last-child {
    border-bottom: solid 2px ${Theme.Colours.lightGrey};
  }
`;

const OrderListHeader = styled(Typography)`
  color: ${Theme.Colours.secondary};

  ${MediaQuery.tabletUp} {
    margin-left: ${Theme.Spacings.m + Theme.Spacings.xxs}px;
  }
`;

type IParcelCount = {
  orderId: string;
  count: number;
  temperatureZone: TemperatureZone;
};

const ALLOWED_TEMPERATURE_ZONES = [
  TemperatureZone.REFRIGERATED,
  TemperatureZone.FROZEN,
  TemperatureZone.DRY
];

type IFormattedPayloadParcel = {
  temperatureZone: string;
  packageType: string;
  count: number;
  weight: number;
};

type IFormattedPayload = {
  orderId: string;
  orderNumberString: string;
  status: ParcelStatus;
  parcels: IFormattedPayloadParcel[];
}[];

const showLabelFormatText = (
  labelFormat: LabelFormat
): { labelFormat: string; labelFormatExplanationText: string } => {
  const { t } = useTranslation();
  switch (labelFormat) {
    case '1':
      return {
        labelFormat: `${t('producer:LabelFormat1')}`,
        labelFormatExplanationText: `
      ${t('producer:LabelFormat1ExplanationText')}`
      };
    case '2':
      return {
        labelFormat: `${t('producer:LabelFormat2')}`,
        labelFormatExplanationText: ` ${t(
          'producer:LabelFormat2ExplanationText'
        )}`
      };
    default:
      return {
        labelFormat: `${t('producer:LabelFormat1')}`,
        labelFormatExplanationText: `
      ${t('producer:LabelFormat1ExplanationText')}`
      };
  }
};

const ParcelsPage = () => {
  const [req, setReq] = useState<ReqType>(REQ.INIT);
  const [reqSave, setReqSave] = useState<ReqType>(REQ.INIT);
  const [orders, setOrders] = useState<Order[]>([]);
  const [isCheckedForPrintStates, setIsCheckedForPrintStates] = useState<
    Record<string, boolean>
  >({});
  const [hasCountChangedStates, setHasCountChangedStates] = useState<
    Record<string, boolean>
  >({});
  const [parcelsCount, setParcelsCount] = useState<IParcelCount[]>([]);
  const [originalParcelsCount, setOriginalParcelsCount] = useState<
    IParcelCount[]
  >([]);
  const { key } = useParams<{ key: string }>();
  const { t } = useTranslation();
  const throwAsyncError = useThrowError();
  const mediaQuery = useMediaQuery();

  const { roleId, labelFormat } = useAppSelector(({ auth }) => {
    return {
      roleId: auth._id,
      labelFormat: auth.labelFormat || '1'
    };
  });

  const getParcelsSum = (parcels: IParcelCount[]): number => {
    return parcels.reduce((acc, parcelCount) => {
      return acc + parcelCount.count;
    }, 0);
  };

  const getParcelsSumForOrderId = (orderId: string): number => {
    return parcelsCount
      .filter(p => {
        return p.orderId === orderId;
      })
      .reduce((acc, parcelCount) => {
        return acc + parcelCount.count;
      }, 0);
  };

  const getParcelsSumForCheckedOrders = () => {
    const checkedOrderParcels = parcelsCount.filter(parcelCount => {
      return isCheckedForPrintStates[parcelCount.orderId];
    });
    const parcelsSumOnOrder = getParcelsSum(checkedOrderParcels);
    return parcelsSumOnOrder;
  };

  const getFormattedPayloadData = (): IFormattedPayload => {
    return orders
      .map(o => {
        if (
          (!hasCountChangedStates[o._id] && !isCheckedForPrintStates[o._id]) ||
          getParcelsSumForOrderId(o._id) === 0
        ) {
          return undefined;
        }

        const parcels: IFormattedPayloadParcel[] = parcelsCount
          .filter(pc => {
            return pc.orderId === o._id;
          })
          .map(pc => {
            return {
              temperatureZone: pc.temperatureZone,
              packageType: 'box',
              count: pc.count,
              weight: 20
            };
          });

        return {
          orderId: o._id,
          orderNumberString: o.orderNumberString,
          status: isCheckedForPrintStates[o._id]
            ? ParcelStatus.ACTIVE
            : ParcelStatus.READY_FOR_PRINT,
          parcels: sortBy(parcels, parcel => {
            return indexOf(ALLOWED_TEMPERATURE_ZONES, parcel.temperatureZone);
          })
        };
      })
      .filter(p => {
        return Boolean(p);
      }) as IFormattedPayload;
  };

  const setInitalParcelCount = (
    ordersWithParcels: {
      orderId: string;
      parcels: Parcel[];
    }[]
  ) => {
    const formattedFetchedParcels = ordersWithParcels
      .map(order => {
        const hasOrdersReadyForPrint = order.parcels.some(p => {
          return p.status === ParcelStatus.READY_FOR_PRINT;
        });
        const parcelStatusToUse = hasOrdersReadyForPrint
          ? ParcelStatus.READY_FOR_PRINT
          : ParcelStatus.ACTIVE;
        return ALLOWED_TEMPERATURE_ZONES.map(tz => {
          const nrOfParcelsWithTz = order.parcels
            .filter(p => {
              return p.status === parcelStatusToUse;
            })
            .filter(p => {
              return p.temperatureZone === tz;
            }).length;
          return {
            orderId: order.orderId,
            count: nrOfParcelsWithTz,
            temperatureZone: tz
          };
        }).flat();
      })
      .flat();
    setParcelsCount(formattedFetchedParcels);
    setOriginalParcelsCount(formattedFetchedParcels);
  };

  const handleOnCheckboxChange = (id: string, status?: boolean) => {
    setIsCheckedForPrintStates(prevState => {
      return {
        ...prevState,
        [id]: status ?? !isCheckedForPrintStates[id]
      };
    });
  };

  const handleOnParcelInputChange = (updatedParcelCount: IParcelCount) => {
    const originalParcelCount = originalParcelsCount.find(pc => {
      return (
        pc.orderId === updatedParcelCount.orderId &&
        pc.temperatureZone === updatedParcelCount.temperatureZone
      );
    });
    const isUpdatedParcelCountDifferentFromOriginal =
      originalParcelCount?.count !== updatedParcelCount.count;
    setHasCountChangedStates(prevState => {
      if (!originalParcelCount) {
        return prevState;
      }
      return {
        ...prevState,
        [originalParcelCount.orderId]: isUpdatedParcelCountDifferentFromOriginal
      };
    });

    setParcelsCount(prevState => {
      return [
        ...prevState.filter(pc => {
          return !(
            pc.orderId === updatedParcelCount.orderId &&
            pc.temperatureZone === updatedParcelCount.temperatureZone
          );
        }),
        updatedParcelCount
      ];
    });

    handleOnCheckboxChange(updatedParcelCount.orderId, true);
  };

  const isSomeChecked = () => {
    return Object.values(isCheckedForPrintStates).some(value => {
      return value;
    });
  };

  const showSaveButton = () => {
    return (
      (!isEqual(
        sortBy(originalParcelsCount, 'orderId', 'temperatureZone'),
        sortBy(parcelsCount, 'orderId', 'temperatureZone')
      ) &&
        getParcelsSum(parcelsCount) > 0) ||
      (isSomeChecked() && getParcelsSumForCheckedOrders() > 0)
    );
  };

  const fetchOrders = async () => {
    setReq(REQ.PENDING);
    await getOrders({ producerId: roleId })
      .then(async o => {
        if (o.length > 0) {
          const { activeOrders } = splitActiveAndPastOrders(o);
          const { orders: filteredOrders } =
            getOrderGroup(activeOrders, key) ?? {};
          setOrders(filteredOrders ?? []);
        }
      })
      .catch(() => {
        setReq(REQ.ERROR);
      });
  };

  const fetchParcels = async () => {
    await Promise.all(
      orders.map(async o => {
        return {
          orderId: o._id,
          parcels: (await getParcelsByOrderId(o._id)) as Parcel[]
        };
      })
    )
      .then(parcels => {
        setInitalParcelCount(parcels);
        setReq(REQ.SUCCESS);
      })
      .catch(e => {
        setReq(REQ.ERROR);
        throwAsyncError(e);
      });
  };

  const postParcels = async () => {
    setReqSave(REQ.PENDING);
    const payloadData = getFormattedPayloadData();

    // If there are no checked orders, or the checked orders have no parcels
    // they should only be saved, and not printed
    const activeOrders = payloadData.filter(o => {
      return o.status === ParcelStatus.ACTIVE;
    });
    const checkedParcelCount = getParcelsSumForCheckedOrders();

    // This is a workaround, read more in https://github.com/dagensmat/frontend/pull/2088
    const downloadExportByNewWindowDueToIPhone =
      activeOrders.length > 0 && mediaQuery.isIphone;

    const downloadWindow = downloadExportByNewWindowDueToIPhone
      ? window.open(`/generating-parcel-labels`)
      : undefined;

    try {
      await postParcelsCount({ ordersWithParcels: payloadData });

      if (activeOrders.length === 0 || checkedParcelCount === 0) {
        setReqSave(REQ.SUCCESS);
        return;
      }

      track(DOWNLOAD_PARCEL_LABELS);
      Logger.info(
        `Exporting parcel labels for producer id ${roleId}, for orders [${activeOrders
          .map(o => {
            return o.orderNumberString;
          })
          .join(',')}]`
      );

      const stringyfiedQuery = queryString.stringify(
        {
          orderIds: activeOrders.map(order => {
            return order.orderId;
          }),
          format: labelFormat
        },
        { arrayFormat: 'bracket' }
      );

      if (!downloadExportByNewWindowDueToIPhone) {
        window.open(
          `${baseUrl}/export/parcelLabel?${stringyfiedQuery}`,
          '_blank'
        );
      }

      if (downloadExportByNewWindowDueToIPhone && downloadWindow) {
        downloadWindow.location.href = `/generating-parcel-labels?${stringyfiedQuery}`;
      }

      setReqSave(REQ.SUCCESS);
    } catch (e) {
      setReq(REQ.ERROR);
      throwAsyncError(e);
    }
  };

  useEffect(() => {
    if (orders.length > 0) {
      fetchParcels();
      const initState = orders.reduce((acc, o) => {
        return { ...acc, [o._id]: false };
      }, {});
      setIsCheckedForPrintStates(initState);
      setHasCountChangedStates(initState);
    }
  }, [orders]);

  useEffect(() => {
    fetchOrders();
  }, []);

  const labelFormatStr = showLabelFormatText(labelFormat).labelFormat;
  const labelFormatExplanationTextStr =
    showLabelFormatText(labelFormat).labelFormatExplanationText;

  if (req !== REQ.SUCCESS) {
    return <Page.Status req={req} />;
  }

  return (
    <Page
      bottom={
        showSaveButton() && (
          <BottomActions border>
            <Container flex direction="column">
              <Typography variant="paragraphSmall" color="secondary">
                {t('common:parcelLabelPage.selectedForPrinting')}
              </Typography>
              <Typography variant="paragraphSmallBold" as="p" mb="xs">
                {t('common:parcelLabelPage.ordersSelected', {
                  count: Object.values(isCheckedForPrintStates).filter(
                    value => {
                      return value;
                    }
                  ).length
                })}{' '}
                ·{' '}
                {t('common:parcelLabelPage.labelsSelected', {
                  count: getParcelsSumForCheckedOrders()
                })}
              </Typography>
              <ActionButton.Save
                onClick={postParcels}
                redirectTo={-1}
                saveReq={reqSave}
              >
                {isSomeChecked()
                  ? t('common:parcelLabelPage.saveAndDownloadParcels')
                  : t('common:SaveChanges')}
              </ActionButton.Save>
            </Container>
          </BottomActions>
        )
      }
    >
      <PageHeader
        headerText={t('common:parcelLabelPage.enterParcels')}
        subTitle={t('common:parcelLabelPage.deliveryDate', {
          date: orders[0] ? formatDate(orders[0].deliveryDate) : ''
        })}
      />
      <Typography variant="paragraphSmall" mb="xl">
        {t('common:parcelLabelPage.description')}{' '}
        <Typography
          variant="link"
          mb="xl"
          href="https://dagens.farm/how-to/pack-and-label"
        >
          {t('common:parcelLabelPage.descriptionLink')}
        </Typography>
      </Typography>
      <Typography variant="paragraphSmallBold" color="secondary" mb="xs" as="p">
        {t('producer:YourParcelLabelSettings')}
      </Typography>
      <Typography variant="paragraphBold" as="p">
        {labelFormatStr}
      </Typography>
      <Typography variant="paragraphSmall" color="secondary">
        {labelFormatExplanationTextStr}
      </Typography>
      <Typography mb="l">
        <ButtonLink
          to={{
            pathname: '/profile/packing'
          }}
          state={{ returnPath: true }}
          variant="borderless"
        >
          <Icon icon="editSmall" mr={Theme.Spacings.xxs} fill="deepPurple" />
          {t('producer:ChangePrintAndLabelFormat')}
        </ButtonLink>
      </Typography>
      <OrderListHeader variant="paragraphSmallBold">
        {t('common:parcelLabelPage.orderAndParcels')}
      </OrderListHeader>

      <CarrotContainer>
        {req === REQ.SUCCESS &&
          parcelsCount.length > 0 &&
          orders.map(order => {
            return (
              <OrderRow key={order._id}>
                <CheckboxWithLabel
                  name={order._id}
                  label={
                    <>
                      <CarrotContainer mr="xxs">
                        <Typography variant="inputLabel" color="black">
                          {order.consumer.name}
                        </Typography>
                      </CarrotContainer>
                      {order.orderNumberString && (
                        <Typography variant="paragraph" color="secondary">
                          {order.orderNumberString}
                        </Typography>
                      )}
                    </>
                  }
                  checked={isCheckedForPrintStates[order._id]}
                  onChange={() => {
                    return handleOnCheckboxChange(order._id);
                  }}
                />

                <CarrotContainer
                  flex
                  alignItems="baseline"
                  justify="flex-start"
                  mt="xs"
                >
                  {ALLOWED_TEMPERATURE_ZONES.map(tz => {
                    const parcel = parcelsCount.find(pc => {
                      return (
                        pc.orderId === order._id && pc.temperatureZone === tz
                      );
                    });
                    if (!parcel) {
                      return null;
                    }

                    return (
                      <ParcelInput
                        orderId={order._id}
                        count={parcel?.count}
                        temperatureZone={parcel?.temperatureZone}
                        onChange={parcelCount => {
                          handleOnParcelInputChange(parcelCount);
                        }}
                        key={`${order._id}-${tz}`}
                      />
                    );
                  })}
                </CarrotContainer>
              </OrderRow>
            );
          })}
      </CarrotContainer>
    </Page>
  );
};

export default ParcelsPage;
