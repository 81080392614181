import React from 'react';

const useResetAnimation = (
  condition: boolean,
  ref: React.RefObject<HTMLElement>
): void => {
  const DOMnode = ref.current;

  if (condition) {
    if (DOMnode) {
      DOMnode.style.animation = 'none';
      DOMnode.getClientRects();
    }
  }
};

export default useResetAnimation;
