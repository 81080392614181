import styled from 'styled-components';
import { EditUsers } from '_common/components/edit-users';
import SectionHOC from '_common/components/section-hoc/SectionHOC';

const MaxWidthContainer = styled.div`
  width: 100%;
  max-width: 490px;
`;

const ProfileUsersSection = () => {
  return (
    <MaxWidthContainer>
      <EditUsers />
    </MaxWidthContainer>
  );
};

export default SectionHOC(ProfileUsersSection);
