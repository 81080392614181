import { Button } from '@carrot/style-components/button';
import { clsx } from 'clsx';
import { useTranslation } from 'react-i18next';
import { Illustrations } from 'utils/Illustrations';

type Props = {
  title: string;
  description: string;
};

export const CustomerListEmpty = ({ title, description }: Props) => {
  const { t } = useTranslation();
  return (
    <div
      className={clsx(
        'flex',
        'justify-between',
        'gap-x-jumbo',
        'gap-y-xl',
        'items-center',
        'flex-col-reverse',
        'desktop:flex-row',
        'mt-xl'
      )}
    >
      <div className={clsx('flex', 'flex-col', 'gap-m')}>
        <h1 className={clsx('text-tertiaryHeading')}>{title}</h1>
        <p className={clsx('text-paragraph')}>{description}</p>
        <div>
          <Button
            as="link"
            styles={{ variant: 'primary', size: 'regular' }}
            to="/profile/delivery-route-request/start"
          >
            {t('producer:DeliveryRouteRequestPage.ButtonText')}
          </Button>
        </div>
      </div>
      <img
        src={Illustrations.INTERESTED_CHEF}
        alt="Rootless user illustration"
        className={clsx('h-full', 'max-h-[160px]', 'aspect-auto')}
      />
    </div>
  );
};
