import { roundNumber } from '@dagensmat/core';
import { Typography } from '@dagensmat/carrot/Components';
import { ColourNames } from '@dagensmat/carrot/Theme/Colours';
import { Container } from '@dagensmat/carrot/Layout';
import { EditablePricing, Pricing } from 'types/Product';
import PricePerUnit from '_producer/components/pricing/PricePerUnit';
import {
  convertPricing,
  isEditablePricing,
  isSimplePricing,
  pricingComplete
} from 'utils/pricing';
import OrderedUnitContents from '_producer/components/pricing/OrderedUnitContents';

type PricingDisplayProps = {
  price?: Pricing | EditablePricing;
  color?: ColourNames;
};
const PricingDisplay = ({ price, color = 'black' }: PricingDisplayProps) => {
  if (!price || !pricingComplete(price)) return null;

  const parsedPrice: Pricing = isEditablePricing(price)
    ? convertPricing(price)
    : price;

  const {
    orderedUnit,
    nokPerPricedUnit,
    pricedUnitsPerOrderedUnit,
    pricedUnit
  } = parsedPrice;

  return !isSimplePricing(parsedPrice) ? (
    <Container flex justify="flex-start" alignItems="baseline">
      <PricePerUnit
        unit={orderedUnit}
        price={roundNumber(nokPerPricedUnit * pricedUnitsPerOrderedUnit)}
        color={color}
      />
      <Typography variant="paragraphSmall" color={color}>
        &nbsp;(
      </Typography>
      <OrderedUnitContents
        unit={pricedUnit}
        count={pricedUnitsPerOrderedUnit}
        color={color}
      />
      <Typography variant="paragraphSmall" color={color}>
        &nbsp;
      </Typography>
      <PricePerUnit unit={pricedUnit} price={nokPerPricedUnit} color={color} />
      <Typography variant="paragraphSmall" color={color}>
        )
      </Typography>
    </Container>
  ) : (
    <PricePerUnit unit={pricedUnit} price={nokPerPricedUnit} color={color} />
  );
};

export default PricingDisplay;
