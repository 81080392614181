import { Combobox, DialogPanel } from '@headlessui/react';
import { clsx } from 'clsx';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const styles = clsx(
  'max-w-[512px]',
  'rounded',
  'p-xs',
  'mx-auto',
  'overflow-hidden',
  'bg-white',
  'shadow-2xl',
  'ring-1',
  'ring-black/5',
  'transition-all'
);

// eslint-disable-next-line tailwindcss/no-custom-classname
const dataStyles = clsx(
  'data-[enter]:duration-300',
  'data-[leave]:duration-200',
  'data-[enter]:ease-out',
  'data-[leave]:ease-in',
  'data-[closed]:scale-95',
  'data-[closed]:opacity-0'
);

type Props = PropsWithChildren<{
  onValueChange: () => void;
}>;

/**
 * @package
 */
export const CommandPalettePanel = ({ onValueChange, children }: Props) => {
  const navigate = useNavigate();
  const [state, setState] = useState<string | null>(null);
  useEffect(() => {
    if (!state) {
      return;
    }
    if (state) {
      navigate(state);
      onValueChange();
    }
    setState(null);
  }, [state]);
  return (
    <DialogPanel transition className={clsx(styles, dataStyles)}>
      <Combobox value={state} onChange={setState}>
        {children}
      </Combobox>
    </DialogPanel>
  );
};
