import styled from 'styled-components';
import Theme from '@dagensmat/carrot/Theme';
import BaseButton from '_common/components/button/Button.style';

export const Picker = styled.div`
  display: flex;
  flex-direction: row;
  height: fit-content;
  align-items: center;

  > *:not(:first-child) {
    margin-left: ${Theme.Spacings.xxs}px;
  }

  margin-top: ${Theme.Spacings.xs}px;
`;

export const SmallPicker = styled.div`
  display: flex;
  flex-direction: row;
  height: fit-content;
  align-items: center;
`;

export const Button = styled(BaseButton)`
  width: ${Theme.Spacings.xl}px;
  touch-action: manipulation;
`;

export const SmallButton = styled(BaseButton)`
  padding: 10px 10px;
  touch-action: manipulation;
`;
