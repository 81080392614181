import { Button } from '@carrot/style-components/button';
import { ButtonComponentProps } from '@carrot/style-components/button/components/as-button';
import { Icon } from '@carrot/theme/icon';
import { FadeOut } from '_common/components/input/FormField';
import clsx from 'clsx';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { To, useNavigate } from 'react-router-dom';
import REQ, { ReqType } from 'utils/REQ';

type Props = Omit<ButtonComponentProps, 'styles'> & {
  redirectTo?: To | number;
  saveReq: ReqType;
  size?: 'regular' | 'fullWidth';
  feedbackText?: {
    [key in ReqType]?: string;
  };
};

/**
 * @package
 */
export const SaveButton = ({
  redirectTo,
  saveReq,
  size,
  feedbackText,
  ...props
}: Props) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  useEffect(() => {
    if (saveReq === REQ.SUCCESS && redirectTo) {
      setTimeout(() => {
        navigate(redirectTo as To);
      }, 3000); // lines up with fadeout animation
    }
  }, [saveReq, redirectTo]);

  if (saveReq === REQ.PENDING) {
    return (
      <div className={clsx('text-paragraph', 'py-[12px]')}>
        {feedbackText?.[saveReq] ?? t('common:SavingChanges')}
      </div>
    );
  }

  if (saveReq === REQ.SUCCESS) {
    return (
      <FadeOut saved>
        <div
          className={clsx(
            'flex',
            'items-center',
            'gap-xxs',
            'py-[12px]',
            'text-paragraph'
          )}
        >
          <Icon icon="success" size="medium" />
          <div>{feedbackText?.[saveReq] ?? t('common:ChangesSaved')}</div>
        </div>
      </FadeOut>
    );
  }

  if (saveReq === REQ.ERROR) {
    return (
      <div className={clsx('text-paragraph', 'py-[12px]', 'text-error')}>
        {feedbackText?.[saveReq] ?? t('common:errorOccured')}
      </div>
    );
  }

  // tbd if text should fallback to t('common:SaveChanges') or not
  return (
    <Button
      styles={{
        variant: 'primary',
        size: size ?? 'fullWidth'
      }}
      {...props}
    />
  );
};
