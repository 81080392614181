import plugin from 'tailwindcss/plugin';
import { Config } from 'tailwindcss/types/config';

/**
 * @package
 */
export const themeFontFamily = {
  inter: 'Inter, Helvetica, sans-serif'
} as const;

/**
 * @package
 */
export const themeFontSize = {
  // Headings
  primaryHeading: [
    '34px',
    {
      lineHeight: '40px',
      letterSpacing: '-0.4px',
      fontWeight: '400'
    }
  ],
  secondaryHeading: [
    '28px',
    {
      lineHeight: '32px',
      letterSpacing: '-0.4px',
      fontWeight: '400'
    }
  ],
  tertiaryHeading: [
    '20px',
    {
      lineHeight: '32px',
      letterSpacing: '0',
      fontWeight: '400'
    }
  ],

  // Running text
  paragraph: [
    '16px',
    {
      lineHeight: '24px',
      letterSpacing: '0.1px',
      fontWeight: '400'
    }
  ],
  paragraphBold: [
    '16px',
    {
      lineHeight: '24px',
      letterSpacing: '0.1px',
      fontWeight: '600'
    }
  ],
  paragraphSmall: [
    '14px',
    {
      lineHeight: '24px',
      letterSpacing: '0.1px',
      fontWeight: '500'
    }
  ],
  paragraphSmallBold: [
    '14px',
    {
      lineHeight: '24px',
      letterSpacing: '0.1px',
      fontWeight: '700'
    }
  ],
  leadText: [
    '20px',
    {
      lineHeight: '32px',
      letterSpacing: '0',
      fontWeight: '400'
    }
  ],
  leadTextBold: [
    '20px',
    {
      lineHeight: '32px',
      letterSpacing: '0',
      fontWeight: '600'
    }
  ],

  // Element texts
  inputLabel: [
    '16px',
    {
      lineHeight: '24px',
      letterSpacing: '0.1px',
      fontWeight: '600'
    }
  ],
  inputText: [
    '16px',
    {
      lineHeight: '24px',
      letterSpacing: '0.1px',
      fontWeight: '500'
    }
  ],
  button: [
    '14px',
    {
      lineHeight: '16px',
      letterSpacing: '0.25px',
      fontWeight: '500'
    }
  ],
  allCaps: [
    '12px',
    {
      lineHeight: '16px',
      letterSpacing: '1.25px',
      fontWeight: '700'
    }
  ],
  microText: [
    '11px',
    {
      lineHeight: '16px',
      letterSpacing: '0.25px',
      fontWeight: '500'
    }
  ]
} satisfies NonNullable<Config['theme']>['fontSize'];

/**
 * @package
 */
export const themeLineHeight = {};

/**
 * @package
 */
export const themeLetterSpacing = {};

/**
 * @package
 */
export const themeFontWeight = {};

/**
 * @package
 */
export const textLinkPlugin = plugin(({ addBase }) => {
  addBase({
    '.text-link': {
      fontSize: 'inherit',
      lineHeight: 'inherit',
      letterSpacing: 'inherit',
      fontWeight: '500',
      textDecoration: 'underline'
    }
  });
});
