/**
 * @package
 */
export const themeSpacing = {
  0: '0',
  xxs: '4px',
  xs: '8px',
  s: '16px',
  m: '24px',
  l: '32px',
  xl: '48px',
  jumbo: '96px'
} as const;
