import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { Typography } from '@dagensmat/carrot/Components';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { find, filter } from 'lodash';
import { AnyAction } from '@reduxjs/toolkit';
import {
  convertPrices,
  isMeasureUnitValid,
  makeEditable,
  pricesComplete,
  shouldPriceAllowZeroWeight
} from 'utils/pricing';
import REQ, { ReqType } from 'utils/REQ';
import PageHeader from '@components/page-header/PageHeader';
import { productEdited as updateProductInStore } from '_producer/reducers/products';
import EditPricingFlow from '_producer/components/edit-pricing-flow/EditPricingFlow';
import { FormWrapper } from '_producer/components/product-form/ProductForm';
import {
  clearProduct,
  InitialProductState,
  updateProduct as updateProductEdits
} from '_producer/reducers/newProduct';
import { formatProductNameAndType } from 'utils/texts';
import { postProductFieldsUpdate } from 'api';
import {
  Pricing,
  EditablePricing,
  ProducerProduct,
  Category
} from 'types/Product';
import PricingPreview from '_producer/components/product-form/PricingPreview';
import { getOfferType } from '_producer/components/product-form/PricingPreviews';
import usePageView from '_common/hooks/usePageView';
import {
  EDIT_OFFER_CLICK_SAVE_OFFER,
  EDIT_OFFER_PAGE_VIEW,
  getConsumersForDataTracking,
  track
} from 'utils/mixpanel';
import { useAppDispatch, useAppSelector } from '_common/hooks/reduxHooks';
import { ALL_CUSTOMERS } from '../../../config';
import { useThrowError } from '../../../utils/errorHandling';
import { Page } from '@components/page';
import { BottomActions } from '@components/bottom-actions';
import { ActionButton } from '@components/action-button';

const useUpdateProductOnChange = (
  product: ProducerProduct | undefined,
  dispatch: Dispatch<AnyAction>
) => {
  useEffect(() => {
    dispatch(updateProductEdits({ ...product, readyToEdit: true }));
    return () => {
      dispatch(clearProduct());
    };
  }, [product, dispatch]);
};

const useOnPricingChange = ({
  newProduct,
  pricingKey,
  dispatch,
  setSaveReq
}: {
  newProduct: InitialProductState;
  pricingKey?: string;
  dispatch: Dispatch<AnyAction>;
  setSaveReq: Dispatch<ReqType>;
}) => {
  const onPricingChange = (editedPricing: EditablePricing) => {
    setSaveReq(REQ.INIT);
    const newPrices: (Pricing | EditablePricing)[] = [
      ...filter(newProduct.prices, p => {
        return p._key !== pricingKey;
      }),
      editedPricing
    ];
    dispatch(
      updateProductEdits({
        prices: newPrices
      })
    );
  };

  return onPricingChange;
};

const useOnSave = ({
  isValid,
  newProduct,
  priceToEdit,
  productId,
  dispatch,
  setSaveReq
}: {
  isValid: boolean;
  newProduct: InitialProductState;
  priceToEdit?: EditablePricing | Pricing;
  productId?: string;
  dispatch: Dispatch<AnyAction>;
  setSaveReq: Dispatch<ReqType>;
}) => {
  const throwError = useThrowError();
  const [showError, setShowError] = useState(false);
  const { consumers } = useAppSelector(({ consumers: { items = [] } }) => {
    return { consumers: items };
  });

  const onSave = useCallback(async () => {
    if (!isValid || !priceToEdit || !productId || !newProduct.prices) {
      setShowError(true);
      return;
    }

    const consumersSelected = getConsumersForDataTracking(
      consumers,
      priceToEdit as Pricing
    );
    try {
      setSaveReq(REQ.PENDING);
      await postProductFieldsUpdate({
        productId,
        prices: convertPrices(newProduct.prices)
      });
      dispatch(updateProductInStore(newProduct as ProducerProduct));
      setSaveReq(REQ.SUCCESS);
      track(EDIT_OFFER_CLICK_SAVE_OFFER, { ...consumersSelected });
    } catch (error) {
      setSaveReq(REQ.ERROR);
      throwError(error);
    }
  }, [isValid, consumers, priceToEdit, productId, newProduct, dispatch]);

  return { onSave, showError };
};

const findCurrentPricing = (
  pricingKey: string,
  newProduct: InitialProductState
) => {
  const { readyToEdit, prices } = newProduct;
  const pricing = find(prices, { _key: pricingKey });
  if (!pricing || !readyToEdit || !prices) {
    return null;
  }
  return pricing;
};

const makePricingEditable = (pricing: Pricing | EditablePricing) => {
  const hasSpecialConsumers =
    pricing?.specialConsumers && pricing.specialConsumers.length > 0;
  const priceToEdit = {
    ...makeEditable(pricing),
    specialConsumers: hasSpecialConsumers
      ? pricing?.specialConsumers
      : [
          {
            _ref: ALL_CUSTOMERS,
            _key: ALL_CUSTOMERS,
            _type: 'reference'
          } as const
        ]
  };
  return priceToEdit;
};

const getIsValid = (
  saveReq: ReqType,
  prices?: (Pricing | EditablePricing)[],
  priceToEdit?: EditablePricing | Pricing,
  productCategoryId?: string,
  categories?: Category[]
) => {
  const allowZeroWeight = shouldPriceAllowZeroWeight(
    productCategoryId,
    categories
  );
  const priceHasWeight = priceToEdit && isMeasureUnitValid(priceToEdit);

  const isValid = [
    saveReq === REQ.INIT,
    prices && pricesComplete(prices),
    priceToEdit?.specialConsumers && priceToEdit.specialConsumers.length > 0,
    allowZeroWeight || priceHasWeight
  ].every(Boolean);

  return isValid;
};

type Props = {
  pricingKey: string | undefined;
  product: ProducerProduct | undefined;
  newProduct: InitialProductState;
};

const EditPricingPage = ({ pricingKey, product, newProduct }: Props) => {
  const dispatch = useAppDispatch();
  usePageView(EDIT_OFFER_PAGE_VIEW);

  const { t } = useTranslation();
  const [saveReq, setSaveReq] = React.useState<ReqType>(REQ.INIT);
  const { categories } = useAppSelector(({ producerCategories }) => {
    return { categories: producerCategories.categories };
  });

  const currentPricing = pricingKey
    ? findCurrentPricing(pricingKey, newProduct)
    : null;
  const priceToEdit = currentPricing
    ? makePricingEditable(currentPricing)
    : undefined;
  const isValid = getIsValid(
    saveReq,
    newProduct.prices,
    priceToEdit,
    product?.categoryId,
    categories
  );

  const onPricingChange = useOnPricingChange({
    newProduct,
    pricingKey,
    dispatch,
    setSaveReq
  });
  const { onSave, showError } = useOnSave({
    isValid,
    priceToEdit,
    productId: product?._id,
    newProduct,
    dispatch,
    setSaveReq
  });
  useUpdateProductOnChange(product, dispatch);

  if (!product || !priceToEdit || !newProduct.prices) {
    return null;
  }

  return (
    <Page
      bottom={
        <BottomActions>
          <ActionButton.Save
            saveReq={saveReq}
            redirectTo={-1}
            onClick={onSave}
            feedbackText={{
              [REQ.PENDING]: t('producer:PricingSavingChangesButtonFeedback'),
              [REQ.SUCCESS]: t('producer:PricingChangesSavedButtonFeedback')
            }}
          >
            {isValid || !showError
              ? t('producer:PricingSaveChangesButtonFeedback')
              : t('producer:PricingSaveChangesMissingFieldsFeedback')}
          </ActionButton.Save>
        </BottomActions>
      }
    >
      <PageHeader
        headerText={t('producer:EditPricingHeader')}
        subTitle={formatProductNameAndType(newProduct)}
      />
      <FormWrapper>
        <EditPricingFlow
          allowZeroWeight={shouldPriceAllowZeroWeight(
            product.categoryId,
            categories
          )}
          editablePricing={priceToEdit}
          onPricingChange={onPricingChange}
          prices={newProduct.prices}
          showError={showError}
        />
        {isValid && (
          <>
            <Typography variant="inputLabel">
              {t('producer:OfferSummarised')}
            </Typography>
            <PricingPreview
              offerType={getOfferType(priceToEdit)}
              numberOfPrices={1}
              productId={product._id}
              pricing={priceToEdit}
            />
          </>
        )}
      </FormWrapper>
    </Page>
  );
};

const EditPricingPageFetcher = () => {
  const { id, key } = useParams();
  const { pricingKey, newProduct, product, req } = useAppSelector(
    ({ producerProducts: { items, req }, newProduct }) => {
      const product = find(items, { _id: id });
      return {
        pricingKey: key,
        req: req === REQ.SUCCESS && !product ? REQ.NOT_FOUND : req,
        product,
        newProduct
      };
    }
  );

  if (req !== REQ.SUCCESS) {
    return <Page.Status req={req} />;
  }

  return (
    <EditPricingPage
      pricingKey={pricingKey}
      newProduct={newProduct}
      product={product}
    />
  );
};

export default EditPricingPageFetcher;
