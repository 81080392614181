import React from 'react';
import styled from 'styled-components';
import Theme from '@dagensmat/carrot/Theme';

const ExpandCollapseWrapper = styled.div<{ height: string }>`
  height: ${({ height }) => {
    return height;
  }};
  transition: all ${Theme.Animations.regular};
  overflow: hidden;
`;

type ExpandCollapseAnimationContainerProps = {
  shown: boolean;
  children: React.ReactNode;
};

const ExpandCollapseAnimationContainer = ({
  shown,
  children
}: ExpandCollapseAnimationContainerProps) => {
  const [calloutHeight, setCalloutHeight] = React.useState<number | undefined>(
    shown ? undefined : 0
  );

  const calloutRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    setCalloutHeight(
      shown ? (calloutRef.current?.offsetHeight ?? 0) + Theme.Spacings.xs : 0
    );
  }, [shown]);

  const height =
    typeof calloutHeight === 'undefined' ? `auto` : `${calloutHeight}px`;

  return (
    <ExpandCollapseWrapper height={height}>
      <div ref={calloutRef}>{children}</div>
    </ExpandCollapseWrapper>
  );
};

export default ExpandCollapseAnimationContainer;
