import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Theme from '@dagensmat/carrot/Theme';
import { Typography } from '@dagensmat/carrot/Components';
import { Container } from '@dagensmat/carrot/Layout';
import SectionHOC from '_common/components/section-hoc/SectionHOC';
import { ButtonLink } from '_common/components/button/Button.style';
import SupportEmailLink from '_common/components/text-link/SupportEmailLlink';

const PriceView = styled.div`
  padding: ${Theme.Spacings.s}px;
  margin-top: ${Theme.Spacings.xs}px;
  margin-right: ${Theme.Spacings.xs}px;
  background-color: ${Theme.Colours.lightGrey};
  border-radius: ${Theme.BorderRadius.regular}px;
`;

type ProfilePlanAndPaymentSectionProps = {
  transactionCut?: number;
  transactionCutForSelfDelivery?: number;
};

const ProfilePlanAndPaymentSection = ({
  transactionCut,
  transactionCutForSelfDelivery
}: ProfilePlanAndPaymentSectionProps) => {
  const { t } = useTranslation();

  return (
    <Container width="halfTabletUp">
      <Typography variant="inputLabel">{t('common:PricingPlan')}</Typography>
      <Container flex alignItems="baseline" justify="flex-start" mb="xs">
        <PriceView>
          <Typography variant="paragraphSmall" as="p">
            {t('producer:DagensDeliveryLogisticsFee')}
          </Typography>
          <Typography variant="paragraphBold" mr="xxs">
            {`${(transactionCut ?? 0) * 100}%`}
          </Typography>
          <Typography variant="paragraphSmall" as="span" color="secondary">
            {t('common:PerOrder')}
          </Typography>
        </PriceView>
        <PriceView>
          <Typography variant="paragraphSmall" as="p">
            {t('producer:SystemFee')}
          </Typography>
          <Typography variant="paragraphBold" mr="xxs">
            {`${(transactionCutForSelfDelivery ?? 0) * 100}%`}
          </Typography>
          <Typography variant="paragraphSmall" as="span" color="secondary">
            {t('common:PerOrder')}
          </Typography>
        </PriceView>
      </Container>
      <Typography variant="paragraphSmall" mb="l">
        <Trans
          t={t}
          i18nKey="producer:RequestPricingOptionsText"
          components={{
            SupportEmailLink: <SupportEmailLink />
          }}
        />
      </Typography>

      <Typography variant="inputLabel" mb="xxs">
        {t('common:BillingAccountHeader')}
      </Typography>
      <Typography variant="paragraphSmall" color="secondary" mb="xs">
        {t('common:BillingAccountLinkDescription')}
      </Typography>
      <ButtonLink to="/billing-account" variant="ghost">
        {t('common:BillingAccountLinkButton')}
      </ButtonLink>
    </Container>
  );
};
export default SectionHOC(ProfilePlanAndPaymentSection);
