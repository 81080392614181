import { InputMeasureUnit, UnitType } from '@dagensmat/core';
import { t } from 'i18next';
import { FormFieldType } from 'carrot/form-field/types';
import { FormField } from '../../../carrot/form-field';
import { isMeasureUnitValid } from '../../../utils/pricing';
import { isMeasureUnit } from '../../../utils/units';
import { unitTypesToOptions } from './unit-types-to-options';

type InputMeasureUnitInputProps = {
  inputMeasureUnit?: InputMeasureUnit;
  inputMeasureUnitValue?: number | '';
  inputMeasureUnitOptions: InputMeasureUnit[];
  pricedUnit: UnitType;
  allowZeroWeight?: boolean;
  showError?: boolean;
  onInputMeasureUnitChange: (inputMeasureUnit: InputMeasureUnit) => void;
  onInputMeasureUnitValueChange: (
    inputMeasureUnitValue: number | string
  ) => void;
};
const InputMeasureUnitInput = ({
  inputMeasureUnit,
  inputMeasureUnitValue,
  inputMeasureUnitOptions,
  pricedUnit,
  allowZeroWeight = false,
  showError,
  onInputMeasureUnitChange,
  onInputMeasureUnitValueChange
}: InputMeasureUnitInputProps) => {
  if (isMeasureUnit(pricedUnit)) {
    return null;
  }

  const isWeightValid =
    isMeasureUnitValid({ inputMeasureUnit, inputMeasureUnitValue }) ||
    allowZeroWeight;

  return (
    <FormField
      type={FormFieldType.NUMBER_WITH_SELECT}
      inputProps={{
        value: inputMeasureUnitValue,
        onInputChange: onInputMeasureUnitValueChange,
        selectValue: inputMeasureUnit,
        selectOptions: unitTypesToOptions(inputMeasureUnitOptions, false),
        onSelectChange: onInputMeasureUnitChange,
        invalid: !isWeightValid
      }}
      label={t('producer:WeightPerPricedUnit', {
        pricedUnit
      })}
      helpText={t('producer:productInputMeasureUnitHelperText')}
      errorMessage={t('producer:productInputMeasureUnitCalloutText')}
      showError={showError}
      optional={allowZeroWeight}
    />
  );
};

export default InputMeasureUnitInput;
