import {
  NavLinkComponent,
  NavLinkComponentProps
} from '@carrot/style-components/button/components/as-nav-link';
import { tv } from 'tailwind-variants';

const style = tv({
  base: ['p-xs', 'h-auto', 'w-auto', '!text-paragraph', 'text-secondary'],
  variants: {
    isActive: {
      true: ['text-black']
    }
  }
});

type Props = Omit<NavLinkComponentProps, 'styles' | 'className' | 'as'>;

export const NavButton = (props: Props) => {
  return (
    <NavLinkComponent
      {...props}
      as="nav-link"
      styles={{
        variant: 'borderless',
        size: 'regular'
      }}
      className={({ isActive }) => style({ isActive })}
    />
  );
};
