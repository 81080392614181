import { find } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Typography } from '@dagensmat/carrot/Components';
import { useParams } from 'react-router-dom';
import REQ, { combineReqs } from 'utils/REQ';
import { getMessagesInThread, Message } from '_common/reducers/messages';
import MessageThread from '_common/components/message-thread/MessageThread';
import RequestedProductLine from '_common/components/requested-product-line/RequestedProductLine';
import OrderContactDetails from '_common/components/order-contact-details/OrderContactDetails';
import { Page } from '@components/page';
import { useAppSelector } from '../../../_common/hooks/reduxHooks';
import { ProductRequestItem } from '../../../_common/reducers/productRequests';

type Props = {
  userId: string | undefined;
  productRequest: ProductRequestItem | undefined;
  messageThreadId: string | undefined;
  messages: Message[];
};

const ConsumerProductRequestPage = ({
  userId,
  productRequest,
  messageThreadId,
  messages
}: Props) => {
  const product = productRequest?.product;
  const producer = productRequest?.producer;
  const {
    _id: producerId,
    name: producerName,
    contactPerson,
    phone
  } = producer ?? {};
  const { t } = useTranslation();
  return (
    <Page>
      <Typography variant="primaryHeading" mt="m">
        {t('common:ProductRequest_one')}
      </Typography>
      <OrderContactDetails
        name={producerName}
        contactPerson={contactPerson}
        phone={phone}
      />
      <RequestedProductLine product={product} />
      <MessageThread
        threadId={messageThreadId}
        thread={messages}
        counterPartId={producerId}
        userId={userId}
      />
    </Page>
  );
};

const ConsumerProductRequestPageFetcher = () => {
  const { id } = useParams();
  const { req, userId, productRequest, messageThreadId, messages } =
    useAppSelector(
      ({
        auth: { _id: userId },
        productRequests: { items: productRequestItems, req: productRequestReq },
        messages: { items: messageItems, req: messagesReq }
      }) => {
        const productRequest = find(productRequestItems, { _id: id });
        const { messageThreadId } = productRequest || {};

        return {
          req: combineReqs([productRequestReq, messagesReq]),
          userId,
          productRequest,
          messageThreadId,
          messages: getMessagesInThread(messageItems, messageThreadId)
        };
      }
    );

  if (req !== REQ.SUCCESS) {
    return <Page.Status req={req} />;
  }

  return (
    <ConsumerProductRequestPage
      userId={userId}
      productRequest={productRequest}
      messageThreadId={messageThreadId}
      messages={messages}
    />
  );
};

export default ConsumerProductRequestPageFetcher;
