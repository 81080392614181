import { clsx } from 'clsx';
import { ForwardedRef, forwardRef, InputHTMLAttributes } from 'react';

type Props = InputHTMLAttributes<HTMLInputElement>;

const Component = (props: Props, ref: ForwardedRef<HTMLInputElement>) => {
  return (
    <input
      {...props}
      ref={ref}
      className={clsx(
        'form-input',
        'rounded',
        'p-[10px]',
        'w-full',
        'border',
        'border-lightGrey',
        'text-inputText',
        'text-black',
        'placeholder:text-secondary',
        'hover:border-secondary',
        'focus:border-black',
        'focus:outline-none',
        'focus:ring-0'
      )}
    />
  );
};

/**
 * @package
 */
export const BasicInput = forwardRef(Component);
