import { Typography } from '@dagensmat/carrot/Components';

const SupportEmailLink = () => {
  return (
    <Typography
      variant="link"
      href="mailto:support@dagens.farm"
      color="deepPurple"
    >
      support@dagens.farm
    </Typography>
  );
};

export default SupportEmailLink;
