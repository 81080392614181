import { Input } from '@carrot/style-components/input';
import { ComboboxInput } from '@headlessui/react';
import { useTranslation } from 'react-i18next';

type Props = {
  setQuery: (query: string) => void;
};

/**
 * @package
 */
export const DeliveryAddressInput = ({ setQuery }: Props) => {
  const { t } = useTranslation();
  return (
    <ComboboxInput
      as={Input}
      onChange={event => setQuery(event.target.value)}
      displayValue={(deliveryAddress: string) => deliveryAddress}
      placeholder={t(
        'common:deliveryInformationFormFieldDeliveryAddressPlaceholder'
      )}
    />
  );
};
