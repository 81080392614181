import {
  LinkComponent,
  LinkComponentProps
} from '@carrot/style-components/button/components/as-link';
import { tv } from 'tailwind-variants';

const style = tv({
  base: ['text-paragraphBold']
});

type Props = Omit<LinkComponentProps, 'styles' | 'className' | 'as'>;

export const MenuItemPrimary = (props: Props) => {
  return (
    <LinkComponent
      {...props}
      as="link"
      styles={{
        variant: 'borderless',
        size: 'small',
        alignment: 'left'
      }}
      className={style()}
    />
  );
};
