import { uuid } from '@sanity/uuid';
import { map, filter } from 'lodash';
import {
  isWithinInterval,
  closestTo,
  isBefore,
  differenceInDays,
  getMonth
} from 'date-fns';
import {
  daysUntil,
  minCurrentYear,
  maxCurrentYear,
  dayOfYear,
  slugifyDate,
  isBeforeThisYear,
  isAfterThisYear,
  parseDate,
  formatDate,
  isCurrentYear
} from 'utils/date/format';
import {
  AlwaysInSeason,
  isSeasonPeriod,
  Period,
  SeasonCalendar,
  SeasonPeriod,
  SoldOutCapacity
} from 'types/Product';

const toPeriodWithKey = ({
  from,
  to
}: Partial<Period>): Required<SeasonPeriod> => {
  return {
    _key: uuid(),
    from: slugifyDate(from),
    to: slugifyDate(to)
  };
};

export const getAlwaysInSeason = (): AlwaysInSeason[] => {
  return [{ _key: uuid(), from: slugifyDate(new Date()) }];
};

export const addToSeasonCalendar = (
  seasonCalendar: SeasonCalendar,
  period: Partial<Period>
): SeasonCalendar => {
  return [...seasonCalendar, toPeriodWithKey(period)];
};

export const updateCalendar = (
  seasonCalendar: SeasonCalendar,
  period: Partial<Period>,
  index: number
): SeasonCalendar => {
  const updatedCalendar = [...seasonCalendar];
  updatedCalendar[index] = toPeriodWithKey(period);
  return updatedCalendar;
};

export const isAlwaysInSeason = (
  seasonCalendar: SeasonCalendar = []
): boolean => {
  if (seasonCalendar.length !== 1) {
    return false;
  }
  const firstSeason = seasonCalendar[0];
  if (isSeasonPeriod(firstSeason)) {
    return false;
  }
  return daysUntil(firstSeason.from) <= 0;
};

export const validatePeriod = ({ from, to }: Partial<Period>): boolean => {
  return Boolean(from) && Boolean(to);
};

export const isValidThisYear = (period: Period): boolean => {
  return (
    validatePeriod(period) &&
    (!period.to || !isBeforeThisYear(period.to)) &&
    !isAfterThisYear(period.from)
  );
};

export const calculatePeriodPosition = ({ from, to = '' }: Period) => {
  const fromDayOfYear = dayOfYear(minCurrentYear(from));
  const toDayOfYear = dayOfYear(maxCurrentYear(to));
  const numDaysInPeriod = toDayOfYear - fromDayOfYear;

  return {
    left: `${(fromDayOfYear / 365) * 100}%`,
    width: `${(numDaysInPeriod / 365) * 100}%`
  };
};

export const toSoldOutCapacityDTO = (date: string): SoldOutCapacity => {
  return {
    _type: 'capacity',
    _key: uuid(),
    deliveryDate: date,
    units: 0
  };
};

export const isInSeason = (seasonCalendar: SeasonCalendar = []): boolean => {
  const today = new Date();
  return seasonCalendar.some(season => {
    if (!validatePeriod(season) || !isSeasonPeriod(season)) return false;
    return isWithinInterval(today, {
      start: parseDate(season.from),
      end: parseDate(season.to)
    });
  });
};

const getFirstSeasonStart = (
  seasonCalendar: SeasonCalendar = [],
  today: Date
) => {
  const futureSeasonStarts = filter(
    map(seasonCalendar, ({ from }) => {
      return parseDate(from);
    }),
    fromDate => {
      return !isBefore(fromDate, today);
    }
  );

  return closestTo(today, futureSeasonStarts);
};

export const getUpcomingSeason = (
  seasonCalendar: SeasonCalendar = []
): { diff: number; month: string } | false => {
  if (
    seasonCalendar.length === 0 ||
    isInSeason(seasonCalendar) ||
    isAlwaysInSeason(seasonCalendar)
  )
    return false;

  const today = new Date();

  const firstSeasonStart = getFirstSeasonStart(seasonCalendar, today);

  if (!firstSeasonStart || differenceInDays(firstSeasonStart, today) < 14)
    return false;

  return {
    diff: Math.abs(getMonth(today) - getMonth(firstSeasonStart)),
    month: formatDate(firstSeasonStart, 'MMMM')
  };
};

export const daysUntilAvailabilityChanges = (today = new Date()) => {
  return ({
    seasonCalendar = []
  }: {
    seasonCalendar: SeasonCalendar;
  }): number => {
    if (isAlwaysInSeason(seasonCalendar)) {
      return Infinity;
    }
    const dates = seasonCalendar.flatMap(period => {
      const { from } = period;
      const to = 'to' in period ? period.to : '';
      return [parseDate(to), parseDate(from)];
    });
    const closestDate = closestTo(today, dates);
    if (!closestDate) {
      return Infinity;
    }
    return Math.abs(differenceInDays(today, closestDate));
  };
};

export const getValidSeasonCalendar = (
  seasonCalendar: SeasonCalendar
): SeasonCalendar => {
  if (seasonCalendar.every(isSeasonPeriod)) {
    return seasonCalendar?.filter(season => {
      return isCurrentYear(season.to) || isAfterThisYear(season.to);
    });
  }

  return seasonCalendar;
};

export const formatPeriod = (period?: Partial<Period>) => {
  const { from, to } = period ?? {};
  return [
    from && formatDate(from, 'dd.MM.yy'),
    to && formatDate(to, 'dd.MM.yy')
  ]
    .filter(Boolean)
    .join(' - ');
};
