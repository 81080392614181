import { useTranslation } from 'react-i18next';
import { Spacings } from '@dagensmat/carrot/Theme';
import { Typography } from '@dagensmat/carrot/Components';
import { getUpcomingSeason } from 'utils/season';
import { ProductForSale } from 'types/Product';
import ProductReference from '_common/components/product-reference/ProductReference';
import PricingText from './PricingText';

type ProductInfoProps = {
  product: ProductForSale;
  isAuthenticated?: boolean;
  isInSameDA: boolean;
  isOwnShop?: boolean;
  isGuest?: boolean;
  isProducer?: boolean;
};

const ProductInfo = ({
  product,
  isAuthenticated,
  isInSameDA,
  isOwnShop,
  isGuest,
  isProducer
}: ProductInfoProps) => {
  const { producer, pricing, seasonCalendar } = product;

  const { t } = useTranslation();

  const upcomingSeason = getUpcomingSeason(seasonCalendar);
  const ownShopPricing =
    product?.prices?.length === 1 ? product.prices[0] : undefined;

  const showPrice = isAuthenticated && (isGuest || isInSameDA || isProducer);
  return (
    <>
      <Typography variant="paragraphSmall">{producer.name}</Typography>
      <ProductReference
        variant="productView"
        product={product}
        my={Spacings.xxs}
      />
      {showPrice && (
        <PricingText
          pricing={pricing || ownShopPricing}
          {...(isOwnShop && { prices: product.prices })}
        />
      )}
      {upcomingSeason && (
        <Typography variant="paragraphSmall" color="success">
          {t('common:upcomingSeason', {
            month: upcomingSeason.month,
            count: upcomingSeason.diff
          })}
        </Typography>
      )}
    </>
  );
};

export default ProductInfo;
