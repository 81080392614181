import { useEffect, useState } from 'react';
import { Translation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';
import { Typography } from '@dagensmat/carrot/Components';
import { Container as CarrotContainer } from '@dagensmat/carrot/Layout';
import REQ, { ReqType, combineReqs } from 'utils/REQ';
import { getOrders, postAddOrderLines } from 'api';
import { fetchOrders } from '_common/reducers/orders';
import { findRightConsumerPrice } from 'utils/pricing';
import PageHeader from '@components/page-header/PageHeader';
import Button, { ButtonLink } from '_common/components/button/Button.style';
import { useAppDispatch, useAppSelector } from '_common/hooks/reduxHooks';
import { ProductList } from './ProductList';
import { Page } from '@components/page';
import { BottomActions } from '@components/bottom-actions';
import { Box } from '../../../carrot/layout/box';
import { CommonNavbar } from '../../../components/navbar';
import Input from '../../../_common/components/input/Input.style';
import { Order } from '../../../types/Order';
import { useThrowError } from '../../../utils/errorHandling';

const AddOrderLinePage = () => {
  const [submitReq, setSubmitReq] = useState<ReqType>(REQ.INIT);
  const [filterString, setFilterString] = useState<string>('');
  const [basket, setBasket] = useState<{ productId: string; units: number }[]>(
    []
  );
  const [order, setOrder] = useState<Order>();
  const { secret } = useParams<{ secret: string }>();
  const dispatch = useAppDispatch();
  const throwError = useThrowError();

  const { products, userId } = useAppSelector(
    ({ auth, producerProducts: { items } }) => {
      return {
        products: items,
        userId: auth._id
      };
    }
  );

  const getOrder = async () => {
    try {
      const orders = await getOrders({ secret });
      if (orders.length > 0) {
        setOrder(orders[0]);
      } else {
        throwError(
          `No orders found for secret ${secret} for producer id ${userId} on add orderline page!`
        );
      }
    } catch (e) {
      throwError(e);
    }
  };

  const onPick = (id: string, units: number | '') => {
    if (units === 0 || units === '') {
      setBasket(
        basket.filter(({ productId }) => {
          return productId !== id;
        })
      );
    } else {
      setBasket([
        ...basket.filter(({ productId }) => {
          return productId !== id;
        }),
        { productId: id, units }
      ]);
    }
  };

  const onSubmit = async () => {
    setSubmitReq(REQ.PENDING);
    const payload = {
      orderId: order?._id,
      basket: basket.map(({ productId, units }) => {
        return { productId, unitsInBasket: units };
      })
    };

    postAddOrderLines(payload).then(() => {
      dispatch(fetchOrders({ producerId: userId }, { clearStore: false }));
      setSubmitReq(REQ.SUCCESS);
    });
  };

  const filteredProducts = products
    .filter(({ prices = [] }) => {
      return !!findRightConsumerPrice(prices, order?.consumer._id);
    })
    .filter(({ name, type = '' }) => {
      return (
        !filterString ||
        name.toLowerCase().includes(filterString.toLowerCase()) ||
        type.toLowerCase().includes(filterString.toLowerCase())
      );
    });

  useEffect(() => {
    getOrder();
  }, [secret]);

  if (submitReq === REQ.SUCCESS) {
    return <Navigate to={`/orders/${secret}`} />;
  }

  if (!order) {
    return null;
  }

  return (
    <Translation>
      {t => {
        return (
          <Page
            header={
              <>
                <CommonNavbar />
                <Box.FullWidth>
                  <PageHeader
                    headerText={t('producer:AddOrderLine')}
                    subTitle={t('common:OrderWithNumber', {
                      orderNumberString: order?.orderNumberString
                    })}
                  />
                  <Input
                    placeholder={t('producer:SearchInProducts')}
                    value={filterString}
                    onChange={({ target: { value } }) => {
                      return setFilterString(value);
                    }}
                  />
                </Box.FullWidth>
              </>
            }
            bottom={
              <BottomActions border>
                <ButtonLink to={`/orders/${secret}`}>
                  {t('common:BackWithArrow')}
                </ButtonLink>
                {(submitReq === REQ.INIT || submitReq === REQ.ERROR) && (
                  <Button
                    disabled={basket.length < 1}
                    onClick={onSubmit}
                    variant="secondary"
                  >
                    {t('common:addLine', {
                      count: basket.length
                    })}
                  </Button>
                )}
                {submitReq === REQ.PENDING && (
                  <Typography variant="paragraph" color="secondary">
                    {t('producer:AddingOrderLines')}
                  </Typography>
                )}
              </BottomActions>
            }
          >
            <CarrotContainer mb="xl">
              <ProductList
                items={filteredProducts}
                productsWithUnit={basket}
                onPick={onPick}
                consumerId={order?.consumer._id}
              />
            </CarrotContainer>
          </Page>
        );
      }}
    </Translation>
  );
};

const AddOrderLinePageFetcher = () => {
  const req = useAppSelector(
    ({ producerProducts: { req }, orders: { req: orderReq } }) => {
      return combineReqs([req, orderReq]);
    }
  );

  if (req !== REQ.SUCCESS) {
    return <Page.Status req={req} />;
  }

  return <AddOrderLinePage />;
};

export default AddOrderLinePageFetcher;
