import { ReactNode } from 'react';
import { Container } from '@dagensmat/carrot/Layout';
import { Typography } from '@dagensmat/carrot/Components';
import { ColourNames } from '@dagensmat/carrot/Theme/Colours';
import Icon from '_common/components/utils/Icon';

type FormValidationMessageProps = {
  message: ReactNode;
  fill?: ColourNames;
};

const FormValidationMessage = ({
  message,
  fill
}: FormValidationMessageProps) => {
  return (
    <Container
      flex
      wrap="nowrap"
      alignItems="flex-start"
      justify="flex-start"
      mt="xxs"
    >
      <Container mr="xxs">
        <Icon icon="alert" $size="m" fill={fill} />
      </Container>
      <Typography variant="paragraph" as="span">
        {message}
      </Typography>
    </Container>
  );
};

export default FormValidationMessage;
