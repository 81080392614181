import { useTranslation } from 'react-i18next';
import { Typography } from '@dagensmat/carrot/Components';
import { DeliveryWindow } from 'types/Consumer';

type IFormattedDeliveryInformation = {
  deliveryAddress?: string;
  deliveryInfo?: string;
  deliveryWindow?: DeliveryWindow;
};

const FormattedDeliveryInformation = ({
  deliveryAddress,
  deliveryWindow,
  deliveryInfo
}: IFormattedDeliveryInformation) => {
  const { t } = useTranslation();
  if (!deliveryWindow) {
    return null;
  }

  return (
    <>
      <Typography variant="paragraphBold" as="p">
        {deliveryAddress} {t('common:between')} {deliveryWindow.start} -{' '}
        {deliveryWindow.end}
      </Typography>
      <Typography variant="paragraphSmall">{deliveryInfo}</Typography>
    </>
  );
};
export default FormattedDeliveryInformation;
