import { ComboboxOptions } from '@headlessui/react';
import { clsx } from 'clsx';
import { DeliveryAddressResult } from '../delivery-address-field/DeliveryAddressResult';
import { useAddressResults } from '../delivery-information-form/use-address-results';

type Props = {
  query: string;
};

/**
 * @package
 */
export const DeliveryAddressResults = ({ query }: Props) => {
  const { addressesReq, addressesResults } = useAddressResults(query);
  if (query === '') {
    return null;
  }
  return (
    <ComboboxOptions
      static
      transition
      as="ul"
      className={clsx(
        'flex',
        'flex-col',
        'justify-start',
        'border',
        'border-solid',
        'border-brightPurple',
        'bg-white',
        'rounded-small',
        'max-h-[200px]',
        'overflow-y-auto',
        'shadow-lg'
      )}
    >
      <DeliveryAddressResult req={addressesReq} results={addressesResults} />
    </ComboboxOptions>
  );
};
