import { FC } from 'react';
import { components, OptionProps } from 'react-select';
import { Option } from './types';

type Props = OptionProps<Option, true>;

export const SelectMultiOption: FC<Props> = props => {
  const {
    data: { borderBottom }
  } = props;
  return (
    <div style={{ borderBottom }}>
      <components.Option {...props} />
    </div>
  );
};
