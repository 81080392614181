import { clsx } from 'clsx';

const style = clsx(
  'h-s',
  'px-xxs',
  'py-[2px]',
  'inline-flex',
  'items-center',
  'justify-center',
  'text-black',
  'rounded-[12px]',
  'text-allCaps',
  'print:hidden'
);

const defaultBackground = clsx('bg-dagensPurple');
const warningBackground = clsx('bg-warning');

type Props = {
  text: string;
  warning?: boolean;
};

/**
 * @package
 */
export const NavBadge = ({ text, warning }: Props) => {
  const backgroundStyle = warning ? warningBackground : defaultBackground;
  return <div className={clsx(style, backgroundStyle)}>{text}</div>;
};
