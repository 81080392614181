import styled from 'styled-components';
import Theme from '@dagensmat/carrot/Theme';
import { Typography } from '@dagensmat/carrot/Components';
import { ColorValue } from 'theme';
import margins, { MarginProps } from 'utils/style';

type TagStyleProps = {
  bgColor?: ColorValue;
} & MarginProps;

const TagStyle = styled.div<TagStyleProps>`
  height: ${Theme.Spacings.m}px;
  padding: ${Theme.Spacings.xxs}px;
  border-radius: ${Theme.BorderRadius.small}px;
  color: ${Theme.Colours.black};
  background-color: ${({ bgColor = Theme.Colours.noteworthy }) => {
    return bgColor;
  }};
  display: inline-flex;
  ${margins}
`;

type TagProps = TagStyleProps & {
  text: string;
};

const Tag = ({ bgColor, text, ...rest }: TagProps) => {
  return (
    <TagStyle bgColor={bgColor} {...rest}>
      <Typography variant="allCapsTitle">{text}</Typography>
    </TagStyle>
  );
};

export default Tag;
