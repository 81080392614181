/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsForRegex": ["state"] }] */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppDispatch } from 'store';
import { ProductModalTypeProps, ModalType } from 'types/Modal';

const initialState: ModalState = {
  modalType: null,
  modalProps: {} as ProductModalTypeProps
};
type ModalState = {
  modalType: ModalType | null;
  modalProps: ProductModalTypeProps;
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    showModal(state, action: PayloadAction<ModalState>) {
      state.modalType = action.payload.modalType;
      state.modalProps = action.payload.modalProps;
    },
    hideModal() {
      return initialState;
    }
  }
});
export const { showModal, hideModal } = modalSlice.actions;

export const showProductModal = (modalProps: ModalState['modalProps']) => {
  return (dispatch: AppDispatch) => {
    dispatch(showModal({ modalType: 'VIEW_PRODUCT', modalProps }));
  };
};

export default modalSlice.reducer;
