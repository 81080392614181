import { FC } from 'react';
import { components, MultiValueRemoveProps } from 'react-select';
import Icon from '../../_common/components/utils/Icon';
import { Option } from './types';

type Props = MultiValueRemoveProps<Option, true>;

export const SelectMultiRemove: FC<Props> = props => {
  return (
    <components.MultiValueRemove {...props}>
      <Icon icon="clear" fill="white" />
    </components.MultiValueRemove>
  );
};
