import { useTranslation } from 'react-i18next';
import { forSaleStatuses } from '@dagensmat/core';
import CheckboxButtons from '_common/components/radio-buttons/CheckboxButtons';

type SaleStatusFiltersProps = {
  activeFilters: string[];
  setActiveFilters: (values: string[]) => void;
};

const SaleStatusFilters = ({
  activeFilters,
  setActiveFilters
}: SaleStatusFiltersProps) => {
  const { t } = useTranslation();
  return (
    <CheckboxButtons
      headerText={t('producer:filterBySaleStatusHeading')}
      options={Object.values(forSaleStatuses).map(status => {
        return {
          key: status,
          value: t(`common:${status}`)
        };
      })}
      selectedOptions={activeFilters}
      onChange={setActiveFilters}
      margin="m"
    />
  );
};

export default SaleStatusFilters;
