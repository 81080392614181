import { useTranslation } from 'react-i18next';
import Theme from '@dagensmat/carrot/Theme';
import { Typography } from '@dagensmat/carrot/Components';
import { Container } from '@dagensmat/carrot/Layout';
import { formatNok2Decimals } from 'utils/texts';
import { EditablePricing } from 'types/Product';

type DisplayPricingStructureProps = {
  transactionCut: number;
  nokPerPricedUnit: EditablePricing['nokPerPricedUnit'];
};

const serviceFeeAmount = (
  value: EditablePricing['nokPerPricedUnit'],
  transactionCut: number
): string => {
  if (value === '') return '';

  return formatNok2Decimals(value * transactionCut);
};

const getProductValueAfterCut = (
  value: EditablePricing['nokPerPricedUnit'],
  transactionCut: number
): string => {
  if (value === '') return '';
  return formatNok2Decimals(value - value * transactionCut);
};

const DisplayPricingStructure = ({
  transactionCut,
  nokPerPricedUnit
}: DisplayPricingStructureProps) => {
  const { t } = useTranslation();

  const transactionCutPercent = transactionCut * 100;
  const serviceFee = serviceFeeAmount(nokPerPricedUnit, transactionCut);
  const valueAfterCut = getProductValueAfterCut(
    nokPerPricedUnit,
    transactionCut
  );

  return (
    <div style={{ width: 300, marginTop: Theme.Spacings.s, maxWidth: '100%' }}>
      <Container flex justify="space-between" alignItems="baseline" mt="xxs">
        <Typography variant="paragraphSmall">
          {t('producer:ServiceFee', { transactionCutPercent })}
        </Typography>
        <Typography variant="paragraphSmall" mb="xxs" color="black">
          {t('producer:ServiceFeeAmount', { serviceFee })}
        </Typography>
      </Container>
      <div style={{ borderTop: '2px solid black' }}>
        <Container flex justify="space-between" alignItems="baseline">
          <Typography variant="paragraphSmall" mt="xxs">
            {t('producer:YouGetExclVat')}
          </Typography>
          <Typography variant="paragraphSmallBold" as="p" color="black">
            {valueAfterCut}
          </Typography>
        </Container>
      </div>
    </div>
  );
};

export default DisplayPricingStructure;
