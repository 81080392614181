import { StylesConfig } from 'react-select';
import Theme from '@dagensmat/carrot/Theme';
import { Option } from './types';

type Args = {
  showError?: boolean;
};

export const selectMultiStyles = ({
  showError
}: Args): StylesConfig<Option, true> => {
  return {
    clearIndicator: styles => {
      return {
        ...styles,
        padding: `${Theme.Spacings.xxs}px`
      };
    },
    control: (styles, { isFocused }) => {
      const errorBorder = showError ? Theme.Colours.error : null;
      const focusBorder = isFocused ? Theme.Colours.black : null;
      const defaultBorder = Theme.Colours.lightGrey;
      const border = errorBorder ?? focusBorder ?? defaultBorder;

      return {
        ...styles,
        border: `2px solid ${border}`,
        boxShadow: 'none',
        padding: `${Theme.Spacings.xs + Theme.Spacings.xxs}px`,
        ':hover': { borderColor: Theme.Colours.black, cursor: 'pointer' }
      };
    },
    dropdownIndicator: styles => {
      return {
        ...styles,
        padding: `${Theme.Spacings.xxs}px`
      };
    },
    input: styles => {
      return {
        ...styles,
        margin: 0
      };
    },
    menu: styles => {
      return {
        ...styles,
        border: `1px solid ${Theme.Colours.lightGrey}`
      };
    },
    menuList: styles => {
      return {
        ...styles,
        borderRadius: `${Theme.BorderRadius.small}px`
      };
    },
    multiValue: styles => {
      return {
        ...styles,
        backgroundColor: Theme.Colours.black,
        borderRadius: `${Theme.BorderRadius.small}px`,
        ':hover': {
          cursor: 'pointer',
          opacity: 0.5
        }
      };
    },
    multiValueLabel: styles => {
      return {
        ...styles,
        color: Theme.Colours.white,
        fontSize: Theme.FontSizes.button,
        lineHeight: `${Theme.Spacings.s}px`,
        padding: `${Theme.Spacings.xxs}px 0`
      };
    },
    multiValueRemove: styles => {
      return {
        ...styles,
        padding: `0 ${Theme.Spacings.xs}px`,
        ':hover': {
          borderRadius: `${Theme.BorderRadius.small}px`
        }
      };
    },
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: isFocused
          ? Theme.Colours.brightPurple
          : Theme.Colours.white,
        cursor: 'pointer',
        ':active': {
          backgroundColor:
            (isFocused && Theme.Colours.brightPurple) || undefined
        }
      };
    },
    valueContainer: styles => {
      return {
        ...styles,
        padding: 0
      };
    }
  };
};
