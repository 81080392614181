import { FC } from 'react';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';
import { Typography } from '@dagensmat/carrot/Components';
import DagensPhoneLink from '_common/components/text-link/DagensPhoneLink';
import DagensEmailLink from '../text-link/DagensEmailLink';
import Logger from '../../../services/Logger';

const ErrorMessageContainer = styled.div`
  max-width: 260px;
  margin: 60px auto;
  padding: 0 10px;
  text-align: center;
`;

type Props = {
  error?: Error;
};

const ErrorMessage: FC<Props> = ({ error }) => {
  if (error) {
    Logger.error(error);
  }

  const { t } = useTranslation();
  return (
    <ErrorMessageContainer>
      <Typography variant="paragraph" my="m">
        {t('common:errorOccured')}
      </Typography>
      <Typography variant="paragraph" color="secondary" my="m">
        {t('common:errorFeedback')}
      </Typography>
      <Typography variant="paragraph" color="secondary" my="m">
        <Trans
          t={t}
          i18nKey="common:errorFeedback2"
          components={{
            DagensPhoneLink: <DagensPhoneLink />,
            DagensEmailLink: <DagensEmailLink />
          }}
        />
      </Typography>
    </ErrorMessageContainer>
  );
};

export default ErrorMessage;
