import { useLocation } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '_common/hooks/reduxHooks';
import { updateUtils } from '_common/reducers/utils';

const useRedirectLogin = (): {
  get: () => string;
  set: () => void;
  unset: () => void;
} => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  const redirectLogin = useAppSelector(({ utils }) => {
    return utils.redirectLogin;
  });

  const get = (): string => {
    return redirectLogin || '';
  };

  const set = () => {
    dispatch(
      updateUtils({
        redirectLogin: encodeURIComponent(pathname.slice(1))
      })
    );
  };

  const unset = () => {
    dispatch(
      updateUtils({
        redirectLogin: ''
      })
    );
  };

  return { get, set, unset };
};

export default useRedirectLogin;
