import React from 'react';
import styled from 'styled-components';
import Theme from '@dagensmat/carrot/Theme';
import { Typography } from '@dagensmat/carrot/Components';
import Icon from '_common/components/utils/Icon';
import useToggle from '_common/hooks/useToggle';
import { MediaQuery } from 'utils/mediaQueries';

const HeaderContainer = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-radius: ${Theme.BorderRadius.regular}px;
  padding: ${Theme.Spacings.s}px 0 ${Theme.Spacings.xs}px;

  ${MediaQuery.desktopUp} {
    padding: ${Theme.Spacings.s}px;
    :hover {
      background-color: ${Theme.Colours.brightPurple};
    }
  }
`;

const ContentContainer = styled.div`
  padding-top: ${Theme.Spacings.s}px;
  padding-bottom: ${Theme.Spacings.l}px;
  margin-bottom: ${Theme.Spacings.m}px;

  ${MediaQuery.tabletUp} {
    padding-left: ${Theme.Spacings.l}px;
    padding-right: ${Theme.Spacings.l}px;
  }
`;

type SectionProps = {
  collapsed?: boolean;
  headerText?: React.ReactNode;
};

const SectionHOC = <ComponentProps,>(
  SectionComponent: React.ComponentType<ComponentProps>
) => {
  return function withCollapsibleSection(props: ComponentProps & SectionProps) {
    const { collapsed: initialValue = true, headerText, ...rest } = props;
    const [collapsed, toggleCollapsed] = useToggle(initialValue);

    if (!headerText)
      return (
        <SectionComponent
          {...(rest as unknown as ComponentProps & JSX.IntrinsicAttributes)}
        />
      );
    return (
      <>
        <HeaderContainer role="button" onClick={toggleCollapsed}>
          <Typography variant="leadText" as="h3">
            {headerText}
          </Typography>
          <Icon icon="chevronSmall" rotate={collapsed ? 180 : 0} />
        </HeaderContainer>
        {!collapsed && (
          <ContentContainer>
            <SectionComponent
              {...(rest as unknown as ComponentProps & JSX.IntrinsicAttributes)}
            />
          </ContentContainer>
        )}
      </>
    );
  };
};

export default SectionHOC;
