import { ProductForSale } from 'types/Product';
import EzPicker from '_common/components/picker/EzPicker';
import { isSoldOut } from '_consumer/reducers/productsForSale';
import ProductDeliveryDeadlineText from './ProductDeliveryDeadlineText';

type ProductBuyerProps = {
  product: ProductForSale;
  selectedDeliveryDate: string;
};
const ProductBuyer = ({ product, selectedDeliveryDate }: ProductBuyerProps) => {
  const { _id: productId } = product;

  if (!selectedDeliveryDate) return null;
  if (isSoldOut(product, selectedDeliveryDate)) return null;

  return (
    <>
      <EzPicker
        key={productId + selectedDeliveryDate}
        product={product}
        selectedDate={selectedDeliveryDate}
      />
      <ProductDeliveryDeadlineText
        product={product}
        selectedDeliveryDate={selectedDeliveryDate}
      />
    </>
  );
};

export default ProductBuyer;
