import { ButtonComponentProps } from '@carrot/style-components/button/components/as-button';
import { LinkComponentProps } from '@carrot/style-components/button/components/as-link';
import { ButtonWrapper } from '@carrot/style-components/button/wrapper';
import { tv } from 'tailwind-variants';

const style = tv({
  base: ['rounded-none'],
  variants: {
    close: {
      true: [
        'text-error',
        '!border-t-thin',
        'border-t-lightGrey',
        'border-solid',
        'border-b-0',
        'border-x-0',
        'rounded-b'
      ]
    }
  }
});

type Props = (
  | Omit<ButtonComponentProps, 'styles' | 'className'>
  | Omit<LinkComponentProps, 'styles' | 'className'>
) & {
  close?: boolean;
};

export const ModalAction = ({ close, ...props }: Props) => {
  return (
    <ButtonWrapper
      {...props}
      styles={{
        variant: 'borderless',
        size: 'regular'
      }}
      className={style({ close })}
    />
  );
};
