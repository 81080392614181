import { cellStyles } from '@carrot/style-components/table/cell';
import { clsx } from 'clsx';
import { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  hidden?: boolean;
}>;

/**
 * @package
 */
export const HeaderCell = ({ hidden, children }: Props) => {
  return (
    <th
      className={clsx(
        cellStyles(),
        'text-start',
        'text-paragraphSmallBold',
        'text-secondary'
      )}
    >
      {hidden ? <div className={clsx('sr-only')}>{children}</div> : children}
    </th>
  );
};
