import { Typography } from '@dagensmat/carrot/Components';
import { useTranslation } from 'react-i18next';
import { isAfter } from 'date-fns';
import { OnboardingHistoryKeys } from '../../../../types/Producer';
import { Illustrations } from '../../../../utils/Illustrations';
import { useAppSelector } from '../../../../_common/hooks/reduxHooks';
import { getUserFirstName } from '../../../../_common/reducers/auth';
import { Order } from '../../../../types/Order';
import {
  groupOrdersByPickupType,
  splitActiveAndPastOrders
} from '../../../../_common/reducers/orders';
import REQ from '../../../../utils/REQ';
import { parseDate } from '../../../../utils/date/format';
import HomePageOnboardingMessage from './HomePageOnboardingMessage';

type IProducerMessagesOnboardingStep = {
  onboardingHistoryStepKey?: OnboardingHistoryKeys;
};

const getOnboardingReceivedFirstOrderLinkTo = (orders: Order[]) => {
  const { activeOrders } = splitActiveAndPastOrders(orders);
  if (activeOrders.length === 0) return '/orders';
  const groups = groupOrdersByPickupType(activeOrders);
  const ordersFilteredAndSorted = groups
    .filter(group => {
      const parsedDeliveryDate = parseDate(group.deliveryDate);
      const today = new Date();
      return isAfter(parsedDeliveryDate, today);
    })
    .sort((a, b) => {
      const dateA = parseDate(a.deliveryDate);
      const dateB = parseDate(b.deliveryDate);
      return new Date(dateA).getTime() - new Date(dateB).getTime();
    });

  return ordersFilteredAndSorted.length > 0
    ? `/orders/by-delivery/${ordersFilteredAndSorted[0].key}`
    : '/orders';
};

const getOnboardingStepContent = (
  onboardingHistoryStepKey: OnboardingHistoryKeys
) => {
  const { t } = useTranslation();
  const { auth, orders } = useAppSelector(
    ({ auth: producerAuth, orders: { items, req } }) => {
      return {
        auth: producerAuth,
        orders: items,
        ordersReq: req
      };
    }
  );

  switch (onboardingHistoryStepKey) {
    case OnboardingHistoryKeys.filledOutAboutInfo:
      return {
        title: t('producer:onboardingFillOutAboutInfoTitle', {
          name: getUserFirstName(auth)
        }),
        texts: [t('producer:onboardingFillOutAboutInfoText')],
        buttonLabel: t('producer:onboardingFillOutAboutInfoButtonText'),
        toLink: '/profile',
        illustrationSrc: Illustrations.PROUD_PRODUCER_FARM
      };
    case OnboardingHistoryKeys.addedFirstProduct:
      return {
        title: t('producer:onboardingAddFirstProductTitle', {
          name: getUserFirstName(auth)
        }),
        texts: [t('producer:onboardingAddFirstProductText')],
        buttonLabel: t('producer:onboardingAddFirstProductButtonText'),
        toLink: '/my-products',
        illustrationSrc: Illustrations.SO_MUCH_TO_CHOOSE_FROM
      };
    case OnboardingHistoryKeys.setUpInitialDeliveryRoute:
      return {
        title: t('producer:onboardingSetUpInitialDeliveryRouteTitle'),
        texts: [t('producer:onboardingSetUpInitialDeliveryRouteText')],
        buttonLabel: t(
          'producer:onboardingSetUpInitialDeliveryRouteButtonText'
        ),
        toLink: '/profile/delivery-route-request/start',
        illustrationSrc: Illustrations.BIKE_DELIVERY
      };
    case OnboardingHistoryKeys.addedWebshopLink:
      return {
        title: t('producer:onboardingAddWebshopLinkTitle'),
        texts: [t('producer:onboardingAddWebshopLinkText')],
        buttonLabel: t('producer:onboardingAddWebshopLinkButtonText'),
        toLink: '/profile',
        illustrationSrc: Illustrations.ONION_MAN
      };
    case OnboardingHistoryKeys.receivedFirstOrder:
      return {
        title: t('producer:onboardingReceiveFirstOrderTitle'),
        texts: [t('producer:onboardingReceiveFirstOrderText')],
        buttonLabel: t('producer:onboardingReceiveFirstOrderButtonText'),
        toLink: '/orders/add/start',
        illustrationSrc: Illustrations.POTATOES_DATE
      };
    case OnboardingHistoryKeys.addedBankAccount:
      return {
        title: t('producer:onboardingAddBankAccountTitle'),
        texts: [t('producer:onboardingAddBankAccountText')],
        buttonLabel: t('producer:onboardingAddBankAccountButtonText'),
        toLink: '/billing-account',
        illustrationSrc: Illustrations.INTERESTED_CHEF
      };
    case OnboardingHistoryKeys.deliveredFirstOrder:
      return {
        title: t('producer:onboardingDeliverFirstOrderTitle'),
        texts: [t('producer:onboardingDeliverFirstOrderText')],
        buttonLabel: t('producer:onboardingDeliverFirstOrderButtonText'),
        toLink: getOnboardingReceivedFirstOrderLinkTo(orders),
        illustrationSrc: Illustrations.STOCKING_UP
      };
    case OnboardingHistoryKeys.receivedFirstPayment:
      return {
        title: t('producer:onboardingReceiveFirstPaymentTitle'),
        texts: [t('producer:onboardingReceiveFirstPaymentText')],
        buttonLabel: t('producer:onboardingReceiveFirstPaymentButtonText'),
        toLink: '/orders/invoice',
        illustrationSrc: Illustrations.SUCCESSFUL_DELIVERY
      };
    default:
      return undefined;
  }
};

const ProducerMessagesOnboardingStep = ({
  onboardingHistoryStepKey
}: IProducerMessagesOnboardingStep) => {
  const { ordersReq } = useAppSelector(({ orders: { req } }) => {
    return {
      ordersReq: req
    };
  });

  if (ordersReq !== REQ.SUCCESS) return null;

  const onboardingMessageContent = onboardingHistoryStepKey
    ? getOnboardingStepContent(onboardingHistoryStepKey)
    : undefined;

  if (!onboardingMessageContent) return null;

  return (
    <HomePageOnboardingMessage
      title={onboardingMessageContent.title}
      buttonText={onboardingMessageContent.buttonLabel}
      buttonToLink={onboardingMessageContent.toLink}
      imageSrc={onboardingMessageContent.illustrationSrc}
    >
      <>
        {onboardingMessageContent.texts.map(textKey => {
          return (
            <Typography variant="paragraph" key={textKey} mb="xs">
              {textKey}
            </Typography>
          );
        })}
      </>
    </HomePageOnboardingMessage>
  );
};

export default ProducerMessagesOnboardingStep;
