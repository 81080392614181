import { Theme } from '@dagensmat/carrot';
import styled from 'styled-components';

export const NumberInputContainer = styled.div<{
  hasUnit?: boolean;
  disabled?: boolean;
  showError?: boolean;
  width?: number;
}>`
  --padding: ${Theme.Spacings.xs + Theme.Spacings.xxs / 2}px;
  display: flex;
  flex-direction: row;
  border: 2px solid
    ${({ disabled, showError }) => {
      if (showError) {
        return Theme.Colours.error;
      }
      return disabled ? Theme.Colours.transparent : Theme.Colours.black;
    }};
  background-color: ${({ disabled }) => {
    return disabled ? Theme.Colours.lightGrey : Theme.Colours.white;
  }};
  transition: border ${Theme.Animations.regular};
  border-radius: 5px;
  width: ${({ width }) => {
    return `${width}px`;
  }};

  > input {
    flex-grow: 1;
    text-align: right;
    border: none;
    min-width: 0;
    color: ${Theme.Colours.black};
    margin: 0;
    padding: 0; /* Fix iOS Safari */
    padding: var(--padding);
    padding-right: ${({ hasUnit }) => {
      return hasUnit ? '0' : 'var(--padding)';
    }};
    line-height: 24px;
    box-shadow: none; /** Fix Firefox red border on required inputs */
    background-color: inherit;
    border-radius: ${Theme.BorderRadius.regular}px;
    font-size: ${Theme.FontSizes.inputText}px;

    &:disabled {
      background-color: ${Theme.Colours.lightGrey};
      opacity: 1; /** Fix iOS Safari when disabled */
    }

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }
    -moz-appearance: textfield;
  }

  > span {
    line-height: 24px;
    padding: var(--padding);
    padding-left: 4px;
    word-break: keep-all;
  }
`;
