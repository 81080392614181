export enum OrderWeeklyProcessStates {
  INITIATED = 'INITIATED',
  PREFILLED = 'PREFILLED',
  COMPLETED = 'COMPLETED'
}

export type OrderWeeklyProcessState = keyof typeof OrderWeeklyProcessStates;
export const ORDER_WEEKLY_PROCESS_STATE_COOKIE_MAX_AGE = 604800; // 7 days
export const ORDER_WEEKLY_PROCESS_STATE_COOKIE_KEY =
  'dagens_orderWeeklyProcessState';

export enum PrefillProcessStates {
  NUDGE_SHOWN = 'NUDGE_SHOWN',
  PREFILLED = 'PREFILLED',
  FEEDBACK_CLOSED = 'FEEDBACK_CLOSED'
}

export type PrefillProcessState = keyof typeof PrefillProcessStates;

export const PREFILL_PROCESS_COOKIE_MAX_AGE = 604800; // 7 days
export const PREFILL_PROCESS_STATE_COOKIE_KEY = 'dagens_prefillProcessState';

export enum PrefilledFeedbackStates {
  ALL_ADDED = 'ALL_ADDED',
  SOME_ADDED = 'SOME_ADDED',
  NONE_ADDED = 'NONE_ADDED'
}
export type PrefilledFeedbackState = keyof typeof PrefilledFeedbackStates;

export const PREFILL_AVAILABILITY_LIST_KEY = 'dagens_prefillAvailabilityList';
