import { useAppSelector } from '../../_common/hooks/reduxHooks';
import { Navbar } from '@components/navbar/navbar';
import { NavTabs } from '@components/navbar/tabs';
import { useTranslation } from 'react-i18next';
import { Nav, NavLinkProps } from '@carrot/style-components/navbar';
import { NavMenuLogout, NavMenuSecondaryItems } from '@components/navbar/menu';
import { ReactNode } from 'react';
import { countActiveUnopenedOrders } from '_common/reducers/orders';
import { countTotalUnseenMessages } from '_common/reducers/messages';

const useLinks = () => {
  const { t } = useTranslation();
  const { ordersCount = 0, messageCount = 0 } = useAppSelector(
    ({
      auth: { _id: userId },
      messages: { items: messagesItems },
      orders: { items: ordersItems }
    }) => {
      return {
        ordersCount: countActiveUnopenedOrders(ordersItems),
        messageCount: countTotalUnseenMessages(messagesItems, userId)
      };
    }
  );

  return [
    {
      icon: 'home',
      label: t('common:Home'),
      to: '/',
      activeOn: [
        '/profile',
        '/profile/minimum-order',
        '/profile/delivery-route-request',
        '/profile/delivery-route-request/start',
        '/profile/delivery-route-request/hubs',
        '/profile/delivery-route-request/self-delivery-form',
        '/billing-account',
        '/switch-role'
      ]
    },
    {
      icon: 'orders',
      label: t('common:Orders'),
      to: '/orders',
      badge: ordersCount !== 0 && <Nav.Badge text={ordersCount.toString()} />,
      activeOn: ['/profile/packing']
    },
    {
      icon: 'products',
      label: t('common:Product_other'),
      to: '/my-products'
    },
    {
      icon: 'messages',
      label: t('common:Requests'),
      to: '/product-requests',
      badge: messageCount !== 0 && <Nav.Badge text={messageCount.toString()} />
    },
    {
      icon: 'customers',
      label: t('common:Customer_other'),
      to: '/customers'
    }
  ] satisfies NavLinkProps[];
};

const Menu = () => {
  const { t } = useTranslation();
  const { name, email, webshop } = useAppSelector(({ auth }) => {
    return {
      name: auth.name,
      email: auth.loginEmail,
      webshop: auth.shopUrl
    };
  });

  return (
    <Nav.Menu heading={t('common:Menu')}>
      <Nav.MenuGroup title={t('common:Account', { name })}>
        <Nav.MenuPrimaryItem text={t('producer:MyPage')} to="/profile" />
        <Nav.MenuPrimaryItem
          text={t('producer:YourWebshop')}
          to={webshop ? `/${webshop}` : '/profile'}
        />
        <Nav.MenuPrimaryItem
          text={t('common:BillingAccountHeader')}
          to="/billing-account"
        />
      </Nav.MenuGroup>
      <Nav.MenuDivider />
      <Nav.MenuGroup title={t('common:You', { email })}>
        <Nav.MenuPrimaryItem
          text={t('common:header.switchRole')}
          to="/switch-role"
        />
      </Nav.MenuGroup>
      <Nav.MenuDivider />
      <NavMenuSecondaryItems />
      <Nav.MenuDivider />
      <NavMenuLogout />
    </Nav.Menu>
  );
};

type Props = {
  contextActions?: ReactNode;
  contextName?: string;
  showLogo: boolean;
  showActionsOnMobile: boolean;
};

/**
 * @package
 */
export const ProducerNavbar = ({
  contextActions,
  contextName,
  showLogo,
  showActionsOnMobile
}: Props) => {
  const links = useLinks();
  return (
    <Navbar
      showLogo={showLogo}
      showActionsOnMobile={showActionsOnMobile}
      links={links}
      actions={<Menu />}
      contextActions={contextActions}
      contextName={contextName}
    />
  );
};

/**
 * @package
 */
export const ProducerNavTabs = () => {
  const links = useLinks();
  return <NavTabs links={links} />;
};
