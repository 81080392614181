import plugin from 'tailwindcss/plugin';

/**
 * @package
 */
export const themeTransitionDelay = {};
/**
 * @package
 */
export const themeTransitionDuration = {};
/**
 * @package
 */
export const themeTransitionTimingFunction = {};

/**
 * @package
 */
export const transitionPlugin = plugin(({ addBase }) => {
  addBase({
    '.transition-regular': {
      transition: '240ms ease-in-out'
    },
    '.transition-quick': {
      transition: '100ms ease-in-out'
    }
  });
});
