import { useTranslation } from 'react-i18next';
import { userUpdated } from '_common/reducers/auth';
import PageHeader from '@components/page-header/PageHeader';
import { postConsumerUpdate } from 'api';
import ConsumerProfileFields from './components/ConsumerProfileFields';
import { Page } from '@components/page';
import {
  useAppDispatch,
  useAppSelector
} from '../../../_common/hooks/reduxHooks';
import { RootState } from '../../../store';

type ConsumerProfilePageProps = Pick<
  RootState['auth'],
  | 'name'
  | 'deliveryAddress'
  | 'deliveryInfo'
  | 'deliveryWindow'
  | 'contactPerson'
  | 'phone'
  | 'unsubscribeFavoriteNotification'
  | 'unsubscribeWeeklyReminderNotification'
  | 'roleLang'
>;

export type ConsumerProfileChanger = (
  fields: Partial<ConsumerProfilePageProps>
) => Promise<any>;

const ConsumerProfilePage = () => {
  const dispatch = useAppDispatch();
  const {
    userId,
    name,
    deliveryAddress,
    deliveryInfo,
    deliveryWindow,
    contactPerson,
    phone,
    unsubscribeFavoriteNotification,
    unsubscribeWeeklyReminderNotification,
    roleLang
  } = useAppSelector(
    ({
      auth: {
        _id: userId,
        name,
        deliveryAddress,
        deliveryInfo,
        deliveryWindow,
        loginEmail,
        contactPerson,
        phone,
        unsubscribeFavoriteNotification,
        unsubscribeWeeklyReminderNotification,
        roleLang
      }
    }) => {
      return {
        userId,
        name,
        deliveryAddress,
        deliveryInfo,
        deliveryWindow,
        loginEmail,
        contactPerson,
        phone,
        unsubscribeFavoriteNotification,
        unsubscribeWeeklyReminderNotification,
        roleLang
      };
    }
  );

  const onFieldsChange: ConsumerProfileChanger = fields => {
    dispatch(userUpdated(fields));

    return postConsumerUpdate({
      userId,
      ...fields
    });
  };

  const { t } = useTranslation();

  return (
    <Page>
      <PageHeader headerText={t('consumer:MyProfile')} subTitle={name} />
      <ConsumerProfileFields
        contactPerson={contactPerson}
        name={name}
        phone={phone}
        deliveryAddress={deliveryAddress}
        deliveryInfo={deliveryInfo}
        deliveryWindow={deliveryWindow}
        unsubscribeFavoriteNotification={Boolean(
          unsubscribeFavoriteNotification
        )}
        unsubscribeWeeklyReminderNotification={Boolean(
          unsubscribeWeeklyReminderNotification
        )}
        onFieldsChange={onFieldsChange}
        roleLang={roleLang}
        isGuest={userId === 'guest-consumer'}
      />
    </Page>
  );
};

export default ConsumerProfilePage;
