import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { /* Prompt,  */ useLocation } from 'react-router-dom';
import { CountryCode } from '@dagensmat/core';
import { Typography } from '@dagensmat/carrot/Components';
import { Container } from '@dagensmat/carrot/Layout';
import { Spacings } from '@dagensmat/carrot/Theme';
import EditPricingFlow from '_producer/components/edit-pricing-flow/EditPricingFlow';
import TextAreaWithLabel from '_common/components/text-area/TextAreaWithLabel';
import CategoryPicker from '_producer/components/category-picker/CategoryPicker';
import EditProductSaleStatus from '_producer/components/edit-product-sale-status/EditProductSaleStatus';
import { byKey } from 'utils/array';
import {
  makeEditable,
  pricingComplete,
  shouldPriceAllowZeroWeight
} from 'utils/pricing';
import { getValidSeasonCalendar } from 'utils/season';
import { getCertifications } from 'api';
import FormField from '_common/components/input/FormField';
import ConfirmButton from '_common/components/confirm-button/ConfirmButton';
import {
  Certification,
  EditablePricing,
  EditableProduct,
  EditableProductFieldChanger
} from 'types/Product';
/* import { EDIT_PRODUCT_UNSAVED_CHANGES_ALERT_VIEW, track } from 'utils/mixpanel'; */
import { useAppSelector } from '../../../_common/hooks/reduxHooks';
import TemperatureZoneSelector from './TemperatureZoneSelector';
import ProductCapacityInput from './ProductCapacityInput';
import ProductSeasonInput from './ProductSeasonInput';
import ProductImageInput from './ProductImageInput';
import ProductCertifications from './ProductCertifications';
import ProcessedStateSelector from './ProcessedStateSelector';
import PricingPreviews, { getOfferType } from './PricingPreviews';
import PricingPreview from './PricingPreview';

export const FormSection = styled.div`
  margin-bottom: ${Spacings.jumbo}px;

  &:before {
    visibility: hidden;
    display: block;
    height: 70px;
    margin: -70px 0 0;
    content: '';
  }
`;

export const FormSectionIds = {
  AVAILABILITY: 'availability',
  DETAILS: 'details',
  PRICING: 'pricing'
};

export const FormWrapper = styled.div`
  max-width: 490px;
  width: 100%;
  position: relative;
`;

const OfferSummarised = ({
  pricing,
  productId
}: {
  pricing: EditablePricing;
  productId: string | undefined;
}) => {
  const { t } = useTranslation();

  if (!pricingComplete(pricing)) {
    return null;
  }

  return (
    <>
      <Typography variant="inputLabel">
        {t('producer:OfferSummarised')}
      </Typography>
      <PricingPreview
        offerType={getOfferType(pricing)}
        numberOfPrices={1}
        productId={productId}
        pricing={pricing}
      />
      <Typography variant="paragraphSmall" color="secondary" mt="xxs">
        {t('producer:YouCanAddMoreOffers')}
      </Typography>
    </>
  );
};

type ProductFormProps = {
  fieldChanged: EditableProductFieldChanger;
  onDelete?: () => void;
  deliveryDates?: string[];
  /* hasChanges?: boolean; */
  product: Partial<EditableProduct>;
  countryCode?: CountryCode;
};

const ProductForm = ({
  fieldChanged,
  onDelete,
  deliveryDates,
  /* hasChanges, */
  product: {
    readyToEdit,
    _id,
    name = '',
    type = '',
    categoryId,
    description = '',
    image,
    certificationIds,
    prices,
    forSaleStatus,
    seasonCalendar = [],
    temperatureZone,
    capacities = [],
    processedState
  },
  countryCode
}: ProductFormProps) => {
  const { t } = useTranslation();

  const availabilityRef = React.useRef<HTMLDivElement>(null);
  const detailsRef = React.useRef<HTMLDivElement>(null);
  const pricingRef = React.useRef<HTMLDivElement>(null);
  const [certifications, setCertifications] = React.useState<Certification[]>(
    []
  );
  const [isReady, setIsReady] = React.useState(false);

  const { hash, key } = useLocation();
  const sectionId = hash.replace('#', '');

  useEffect(() => {
    const fetchCertifications = async () => {
      const certs = await getCertifications({ countryCode });
      setCertifications(certs);
      setIsReady(true);
    };

    fetchCertifications();
  }, [countryCode]);

  const { categories } = useAppSelector(({ producerCategories }) => {
    return {
      categories: producerCategories.categories
    };
  });
  const allowZeroWeight = shouldPriceAllowZeroWeight(categoryId, categories);

  React.useLayoutEffect(() => {
    if (sectionId && isReady) {
      const el = {
        [FormSectionIds.AVAILABILITY]: availabilityRef,
        [FormSectionIds.DETAILS]: detailsRef,
        [FormSectionIds.PRICING]: pricingRef
      }[sectionId];

      if (el && el.current) {
        const top = el.current.offsetTop;
        window.scrollTo({ top, behavior: 'smooth' });
      }
    }
  }, [sectionId, isReady, key]);

  if (!(readyToEdit && isReady)) return null;

  const seasonCalendarTrimmed = getValidSeasonCalendar(seasonCalendar);
  return (
    <>
      {/* TODO: react-router <Prompt
        message={() => {
          if (hasChanges) {
            track(EDIT_PRODUCT_UNSAVED_CHANGES_ALERT_VIEW);
            const promptMessage = t('common:YouHaveUnsavedChanges', {
              saveAction: t('producer:ProductSaveChangesButtonFeedback')
            });
            return promptMessage;
          }
          return true;
        }}
      /> */}
      <FormWrapper>
        <FormSection>
          <FormField
            name="productName"
            type="text"
            labelText={t('producer:productNameLabelText')}
            helpText={t('producer:productNameHelperText')}
            value={name}
            placeholder={t('producer:productNamePlaceholder')}
            onChange={({ target: { value } }) => {
              return fieldChanged('name')(value);
            }}
          />
          <Container mb="xl">
            <ProductImageInput
              onChange={fieldChanged('image')}
              productImage={image}
            />
          </Container>
          <Container mb="xl">
            <CategoryPicker
              categories={categories}
              categoryId={categoryId}
              onChange={fieldChanged('categoryId')}
            />
          </Container>
        </FormSection>
        <FormSection ref={pricingRef}>
          <>
            <Typography variant="secondaryHeading" mb="xs">
              {t('producer:productPriceFormSection')}
            </Typography>
            <Typography variant="paragraph" mb="s">
              {t('producer:ProductPriceFormSectionSubtitle')}
            </Typography>
          </>

          {onDelete ? (
            <PricingPreviews
              allowZeroWeight={allowZeroWeight}
              productId={_id}
              prices={prices}
            />
          ) : (
            prices !== undefined && (
              <>
                <EditPricingFlow
                  allowZeroWeight={allowZeroWeight}
                  editablePricing={makeEditable(prices[0])}
                  onPricingChange={pricing => {
                    fieldChanged('prices')([pricing]);
                  }}
                  prices={prices}
                />
                <OfferSummarised
                  pricing={makeEditable(prices[0])}
                  productId={_id}
                />
              </>
            )
          )}
        </FormSection>
        <FormSection ref={detailsRef}>
          <Typography variant="secondaryHeading" mb="xl">
            {t('producer:ProductInformation')}
          </Typography>
          <FormField
            name="productType"
            type="text"
            labelText={t('producer:productTypeLabelText', {
              productName: name || t('common:product_one')
            })}
            helpText={t('producer:productTypeHelperText')}
            value={type}
            placeholder={t('producer:productTypePlaceholder')}
            onChange={({ target: { value } }) => {
              return fieldChanged('type')(value);
            }}
            maxLength={30}
            optional
          />
          <Container mb="xl">
            <ProductCertifications
              onChange={fieldChanged('certificationIds')}
              certificationIds={certificationIds ?? []}
              certifications={certifications}
            />
          </Container>
          <Container mb="xl">
            <ProcessedStateSelector
              onChange={fieldChanged('processedState')}
              value={processedState}
            />
          </Container>
          <Container mb="xl">
            <TemperatureZoneSelector
              onChange={fieldChanged('temperatureZone')}
              value={temperatureZone}
            />
          </Container>
          <TextAreaWithLabel
            mb="xl"
            label={t('producer:productDescriptionLabelText')}
            value={description}
            placeholder={t('producer:productDescriptionPlaceholder')}
            optional
            onChange={({ target: { value } }) => {
              return fieldChanged('description')(value);
            }}
          />
        </FormSection>
        <FormSection ref={availabilityRef}>
          <Typography variant="secondaryHeading" mb="xl">
            {t('producer:productFormSection')}
          </Typography>
          <Container mb="xl">
            <EditProductSaleStatus
              forSaleStatus={forSaleStatus}
              onChange={fieldChanged('forSaleStatus')}
            />
          </Container>
          <Container mb="xl">
            <ProductSeasonInput
              onChange={fieldChanged('seasonCalendar')}
              seasonCalendar={seasonCalendarTrimmed}
            />
          </Container>
          {onDelete && (
            <Container mb="xl">
              <ProductCapacityInput
                deliveryDates={deliveryDates ?? []}
                soldOutDates={[...capacities]
                  .sort(byKey('deliveryDate'))
                  .filter(({ units }) => {
                    return units === 0;
                  })}
                onChange={fieldChanged('capacities')}
              />
            </Container>
          )}
        </FormSection>
        {onDelete && (
          <FormSection>
            <ConfirmButton
              confirmText={t('producer:productDeleteParagraph')}
              confirmedText={t('producer:productDeleteButtonConfirmedText')}
              buttonText={t('producer:productDeleteButtonText')}
              rejectButtonText={t('common:NoWait')}
              confirmButtonText={t('producer:productConfirmDeleteButtonText')}
              confirmButtonVariant="error"
              onConfirm={onDelete}
            />
          </FormSection>
        )}
      </FormWrapper>
    </>
  );
};
export default ProductForm;
